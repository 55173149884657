/** @format */

import React from "react";
import { useNavigate, useParams } from "react-router";
import UserAvatar from "../../../Assets/userAvatar.webp";
import { selectBlockcast } from "../../../redux/block/block.action";
import CustomModal from "../../modal/CustomModal";
import {
  selectToken,
  selectUser,
  selectFollowing,
} from "../../../redux/_user/userSelectors";
import { seleteSelectBlock } from "../../../redux/_blockcast/blockcastSelectos";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  handleAddGroupMember,
  handleFetchGroupMembers,
} from "../../../redux/_block/blockSlice";
import CustomPostFormModal from "../../../components/modal/CustomPostForm";
import {
  BiUserPlus,
  BiUserCheck,
  BiArrowBack,
  BiLocationPlus,
} from "react-icons/bi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import "./BlockcastHeader.css";

const BlockCastMainHeader = () => {
  const { t } = useTranslation(["common"]);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const following = useSelector(selectFollowing);
  const selectBlock = useSelector(seleteSelectBlock);
  const dispatch = useDispatch();

  const { id } = useParams();
  const navigate = useNavigate();
  const [openMenu, setOpneMenu] = React.useState(false);
  const [openBlockDetailsModal, setOpenBlockDetailsModal] =
    React.useState(false);
  const [leaveGroupModal, setLeaveGroupModal] = React.useState(false);
  const [joinGroupModal, setJoinGroupModal] = React.useState(false);
  const [members, setMembers] = React.useState(selectBlock?.mem || []);
  const [openMembersModal, setOpenMembersModal] = React.useState(false);
  const [membersList, setMemebersList] = React.useState([]);
  const [loadingMembers, setLoadingMembers] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState("image");
  const [loading, setLoading] = React.useState(false);
  const [linkMsg, setLinkMsg] = React.useState([]);
  const [imageMsgs, setImageMsgs] = React.useState([]);
  const [videoMsgs, setVideoMsgs] = React.useState([]);
  const [audioMsgs, setAudioMsgs] = React.useState([]);
  const [imageModal, setImageModal] = React.useState(false);
  const [imageUrl, setImageUrl] = React.useState("");
  const [imageType, setImageType] = React.useState("");
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  // console.log("*****", selectBlock);

  React.useEffect(() => {
    const currentURL = window.location.href;
    console.log("Current URL:", currentURL);
    const savedUrl = localStorage.getItem("currenturl");
    console.log("Save URL:", savedUrl);
    if (currentURL === savedUrl) {
      console.log("SAME");
    } else {
      console.log("OTHER");
      localStorage.setItem("currenturl", currentURL);
    }
  }, []);

  const menuRef = React.useRef(null);
  React.useEffect(() => {
    const handler = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // setOpenCurrency(false);
        setOpneMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const backBtn = () => {
    // navigate("/blockcast");
    console.log("GO BACK");
    navigate(-1);
  };

  const leaveGroup = (username) => {
    var axios = require("axios");
    var data = JSON.stringify({
      username: username,
    });

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_URL_LINK}api/blockcast/leave/group/${id}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        selectBlockcast(response.data);
        navigate(`/blockcast/chats`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deletGroup = () => {
    var axios = require("axios");

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_URL_LINK}api/blockcast/delete/group/${id}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        selectBlockcast(response.data);
        navigate(`/blockcast/chats`);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const redirectToSettings = () => {
    // navigate(`/blockcast/main/settings/${id}`);
    navigate(`/group/edit/${id}`);
  };

  const redirectToMainGroup = () => {
    // navigate(`/blockcast/main/settings/${id}`);
    navigate(`/group/${id}`);
  };

  const handleOpenBlockDetails = () => {
    setOpenBlockDetailsModal(true);
  };

  const onClose = () => {
    setOpenBlockDetailsModal(false);
    setLeaveGroupModal(false);
    setJoinGroupModal(false);
  };

  const handleCloseModal = () => {
    setOpenMembersModal(false);
  };

  const removeGroup = () => {
    if (members.includes(user.handleUn)) {
      const arr = members;
      const temp = arr.filter((data) => data !== user.handleUn);
      setMembers(temp);
    } else {
      console.log("Not includes");
      setMembers((prev) => [...prev, user.handleUn]);
    }

    var axios = require("axios");

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_URL_LINK}api/group/remove/group/${id}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    axios(config)
      .then(function (response) {
        selectBlockcast(response.data);
        setLeaveGroupModal(false);
      })
      .catch(function (error) {
        console.log(error);
        setLeaveGroupModal(false);
      });
  };

  const followedGroup = async () => {
    if (members.includes(user.handleUn)) {
      const arr = members;
      const temp = arr.filter((data) => data !== user.handleUn);
      setMembers(temp);
    } else {
      console.log("Not includes");
      setMembers((prev) => [...prev, user.handleUn]);
    }
    const data = { token, id };
    const result = await dispatch(handleAddGroupMember(data));
    console.log(result);
    selectBlockcast(result.data);
    setJoinGroupModal(false);
  };

  // *** Handle fetch members
  const handleFetchMemebers = async () => {
    setLoadingMembers(true);
    const data = { id, token };
    const result = await dispatch(handleFetchGroupMembers(data));
    console.log(result);
    setMemebersList(result);
    setLoadingMembers(false);
  };

  const handleOpenMemebrsModal = () => {
    setLoadingMembers(true);
    setOpenMembersModal(true);
    handleFetchMemebers();
  };

  const redirectToProfile = (id) => {
    navigate(`/user/profile/${id}`);
  };

  const handleShare = () => {
    navigator.clipboard.writeText(`http://localhost:3101/blockcast/${id}`);
    setOpneMenu(false);
  };

  React.useEffect(() => {
    if (activeTab === "image") {
      console.log("IMAGE api call");
      setLoading(true);
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL_LINK}api/blockcast/message/image/${id}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          setImageMsgs(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (activeTab === "links") {
      console.log("Links api call");
      setLoading(true);
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL_LINK}api/blockcast/message/links/${id}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          setLinkMsg(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (activeTab === "video") {
      setLoading(true);
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL_LINK}api/blockcast/message/video/${id}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          setVideoMsgs(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (activeTab === "audio") {
      setLoading(true);
      const axios = require("axios");

      let config = {
        method: "get",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_URL_LINK}api/blockcast/message/audio/${id}`,
        headers: {
          Authorization: "Bearer " + token,
        },
      };

      axios
        .request(config)
        .then((response) => {
          console.log(response.data);
          setAudioMsgs(response.data);
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [activeTab]);

  const openImageModal = (data) => {
    console.log(data);
    setImageModal(true);
    setImageUrl(data.image);
    if (data.isImage) {
      setImageType("image");
    } else if (data.isVideo) {
      setImageType("video");
    }
  };

  const handleCloseImageModal = () => {
    setImageModal(false);
    setImageUrl("");
  };

  return (
    <>
      {selectBlock && (
        <div className="__blockcast_header_section">
          {leaveGroupModal && (
            <CustomModal
              onClose={onClose}
              title={t("Blockcast details")}
              body={<>{t("Do you want to leave this group")}?</>}
              footer={
                <div>
                  <button
                    className="blockcast_modal_btn remove_group_btn"
                    onClick={removeGroup}
                  >
                    Okay!
                  </button>
                </div>
              }
            />
          )}

          {joinGroupModal && (
            <CustomModal
              onClose={onClose}
              title={t("Blockcast details")}
              body={<>{t("Do you want to join this group")}?</>}
              footer={
                <div>
                  <button
                    className="blockcast_modal_btn"
                    onClick={followedGroup}
                  >
                    Okay!
                  </button>
                </div>
              }
            />
          )}

          {openMembersModal && (
            <CustomPostFormModal
              onClose={onClose}
              title={
                <div className="custom_modal_title_section">
                  <button
                    className="custom_modal_header_btn"
                    onClick={handleCloseModal}
                  >
                    <BiArrowBack />
                  </button>
                  <div className="custom_modal_title_text">
                    {t("View block members")}
                  </div>
                </div>
              }
              body={
                <div className="___members_modal_body">
                  {loadingMembers ? (
                    <div className="modal_body_loading_section">
                      <span class="modal_memebers_loader"></span>
                    </div>
                  ) : (
                    <div className="memebsrs_modal_list_conatiner">
                      {(membersList || []).length > 0 ? (
                        <>
                          {membersList.map((userData, index) => (
                            <div className="__user_list_card" key={index}>
                              <div
                                className="__user_card_info_section"
                                onClick={() =>
                                  navigate(
                                    `/user/profile/${userData.record.bins.handleUn}`
                                  )
                                }
                              >
                                <img
                                  src={
                                    userData.record.bins.p_i
                                      ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${userData.record.bins.p_i}`
                                      : UserAvatar
                                  }
                                  alt=""
                                  srcset=""
                                  className="__user_card_avatar"
                                />
                                <div>
                                  <span className="__modal_user_name">
                                    {userData.record.bins.fn}{" "}
                                    {userData.record.bins.ln}
                                  </span>
                                  {(userData.record.bins.badges || []).length >
                                    0 && (
                                    <>
                                      {userData.record.bins.badges[
                                        userData.record.bins.badges.length - 1
                                      ] === "claim_sub" ? (
                                        <BsFillPatchCheckFill className="subscriber_badge" />
                                      ) : (
                                        <>
                                          {userData.record.bins.badges[
                                            userData.record.bins.badges.length -
                                              1
                                          ] === "claim_cc" ? (
                                            <BsFillPatchCheckFill className="cc_badge" />
                                          ) : userData.record.bins.badges[
                                              userData.record.bins.badges
                                                .length - 1
                                            ] === "claim_verify" ? (
                                            <BsFillPatchCheckFill className="verify_badge" />
                                          ) : null}
                                        </>
                                      )}
                                    </>
                                  )}
                                  <span className="__modal_user_username">
                                    @{userData.record.bins.handleUn}
                                  </span>
                                  <br />
                                  <div className="__modal_user_username">
                                    {userData.record.bins.p_bio}
                                  </div>
                                  <div className="__modal_user_location">
                                    <BiLocationPlus />{" "}
                                    {userData.record.bins.city},
                                    {userData.record.bins.country}
                                  </div>
                                  <span className="__user_card_follwers">
                                    Followrs:{" "}
                                    <span className="__user_card_followers_count">
                                      {userData.record.bins.flwr_c}
                                    </span>
                                  </span>{" "}
                                  <span className="__user_card_follwing">
                                    Following:{" "}
                                    <span className="__user_card_following_count">
                                      {userData.record.bins.flw_c}
                                    </span>
                                  </span>
                                </div>
                              </div>
                              {user.handleUn ===
                              userData.record.bins.handleUn ? null : (
                                <button
                                  className="user_card_flwr_btn"
                                  onClick={() =>
                                    handleFollow(userData.record.bins.handleUn)
                                  }
                                >
                                  {" "}
                                  {following.includes(
                                    userData.record.bins.handleUn
                                  ) ? (
                                    <BiUserCheck />
                                  ) : (
                                    <BiUserPlus />
                                  )}
                                </button>
                              )}
                            </div>
                          ))}
                        </>
                      ) : (
                        <div className="modal_body_empty_section">
                          {t("No user found")}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              }
            />
          )}
          <div
            className="__blockcast_info_section"
            onClick={() => handleOpenBlockDetails(selectBlock)}
          >
            <button
              className="__custom_header_back_button"
              onClick={() => backBtn()}
            >
              <BiArrowBack />
            </button>
            <img
              src={selectBlock.image ? selectBlock.image : UserAvatar}
              className="__header_user_avatar"
            />
            <div className="__blockcast_inner_info_section">
              <p className="__blockcast_header_title">{selectBlock.name}</p>
              <p className="__custom_blockcast_header_des">
                {selectBlock.des && <>{selectBlock.des.slice(0, 80)}...</>}
              </p>
            </div>
          </div>

          {/* Header Button Section */}
          <div className="header_section_button">
            {selectBlock.b_c_un === user.handleUn ? (
              <button
                className="header_menubtn"
                onClick={() => setOpneMenu((p) => !p)}
              >
                <span class="icon-more"></span>
              </button>
            ) : (
              <>
                {members.includes(user.handleUn) ? (
                  <button
                    className="header_menubtn"
                    onClick={() => setOpneMenu((p) => !p)}
                  >
                    <span class="icon-more"></span>
                  </button>
                ) : (
                  <button
                    className="_header_block_join_button"
                    onClick={followedGroup}
                  >
                    {t("Join")}
                  </button>
                )}
              </>
            )}
          </div>

          {openMenu && (
            <div className="header_menu" ref={menuRef}>
              <li
                className="__header_menu_list"
                onClick={() => redirectToMainGroup()}
              >
                {t("Visit block")}
              </li>
              {selectBlock.b_c_un === user.handleUn && (
                <li
                  className="__header_menu_list"
                  onClick={() => redirectToSettings()}
                >
                  {t("Privacy & Settings")}
                </li>
              )}

              {selectBlock.b_c_un === user.handleUn && (
                <li
                  className="__header_menu_list delete_chat"
                  onClick={() => deletGroup()}
                >
                  {t("Delete")}
                </li>
              )}

              <li
                className="__header_menu_list"
                onClick={() => handleOpenMemebrsModal()}
              >
                {t("View members")}
              </li>

              <li className="__header_menu_list" onClick={() => handleShare()}>
                {t("Share")}
              </li>

              <li
                className="__header_menu_list"
                onClick={() => handleOpenMemebrsModal()}
              >
                {t("Help")}
              </li>

              {/* {selectBlock.b_c_un !== user.handleUn && (
                <>
                  {members.includes(user.handleUn) && (
                    <li
                      className='__header_menu_list delete_chat'
                      onClick={() => setLeaveGroupModal(true)}>
                      {t("Leave Group")}
                    </li>
                  )}
                </>
              )} */}

              {selectBlock.b_c_un !== user.handleUn && (
                <>
                  {members.includes(user.handleUn) ? (
                    <li
                      className="__header_menu_list delete_chat"
                      onClick={() => setLeaveGroupModal(true)}
                    >
                      {t("Leave Group")}
                    </li>
                  ) : (
                    <li
                      className="__header_menu_list"
                      onClick={() => setJoinGroupModal(true)}
                    >
                      {t("Join Group")}
                    </li>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      )}
    </>
  );
};
export default BlockCastMainHeader;
