/** @format */

import React, { useState } from "react";
import "./BlockPageHeader";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate, useParams } from "react-router";
import "./BlockPageHeader.css";
import { MdMoreVert } from "react-icons/md";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FaSearch } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { scrollPosition } from "../../../redux/_page/pageSelectors";
import CustomPostFormModal from "../../modal/CustomPostForm";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import GroupPostCard from "../../Group.Post.component/GroupPostCard";
import { ImSpinner2 } from "react-icons/im";
import { selectGroupData } from "../../../redux/_block/blockSelectors";
import CustomModal from "../../modal/CustomModal";
import { hideBlock } from "../../../redux/_block/blockSlice";
import axios from "axios";

const BlockPageHeader = ({ title }) => {
  const { id } = useParams();
  const group = useSelector(selectGroupData);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollPos = useSelector(scrollPosition);
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);

  const [openBlockModal, setOpenBlockModal] = useState(false);

  const backBtn = () => {
    navigate(-1);
  };

  React.useEffect(() => {
    if (searchTerm.length === 2) {
      handleSearchGroup(searchTerm);
    } else if (searchTerm.length > 2) {
      const delayCall = setTimeout(() => {
        handleSearchGroup(searchTerm);
      }, 1000);

      return () => clearTimeout(delayCall);
    }
  }, [searchTerm, page]);

  const handleSearchGroup = () => {
    if (page === 1) setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL_LINK}api/group/block/search/post/${id}?searchParam=${searchTerm}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        setCount(response.data.length);
        if (page === 1) {
          setPosts(response.data);
        } else {
          setPosts((prev) => [...prev, ...response.data]);
        }
        setLoading(false);
        setBtnLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const incrementPage = () => {
    setBtnLoader(true);
    setPage((prev) => prev + 1);
  };

  const onClose = () => {
    setOpenBlockModal(false);
  };

  const hideBlockHandler = async () => {
    const data = { token, id };
    console.log("CALL 1");
    const result = await dispatch(hideBlock(data));
  };

  return (
    <>
      {group && (
        <div
          className={
            scrollPos > 0
              ? "__block_page_header_container __shadow_block_header_background"
              : "__block_page_header_container"
          }>
          {openModal && (
            <CustomPostFormModal
              title={
                <div className='block_header_section'>
                  <button
                    className='block_header_modal_close_btn'
                    onClick={() => setOpenModal(false)}>
                    <BiArrowBack />
                  </button>
                  <span className='block_modal_title'>Search</span>
                </div>
              }
              body={
                <div className='block_modal_header_body'>
                  <div className='block_modal_form_box'>
                    <input
                      type='text'
                      placeholder='Enter Search'
                      className='block_header_modal_input'
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                  </div>
                  <div className='block_modal_search_body'>
                    {loading ? (
                      <div className='block_modal_loading_secrching'>
                        <span class='block_header_loader'></span>
                      </div>
                    ) : (
                      <div>
                        {(posts || []).length > 0 ? (
                          <div>
                            {posts.map((data) => (
                              <GroupPostCard key={data.p_id} postData={data} />
                            ))}
                            {count === posts.length && (
                              <div className='modal_post_loader'>
                                <button
                                  className='modal_post_loader_btn'
                                  onClick={incrementPage}>
                                  {btnLoader ? <ImSpinner2 /> : <>Load more</>}
                                </button>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className='empty_modal_block_post'>
                            No data found
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              }
            />
          )}
          {openBlockModal && (
            <CustomModal
              onClose={onClose}
              title={"Create nft"}
              body={"Do you want to hide this block?"}
              footer={
                <div>
                  <button
                    className='update_btn'
                    onClick={() => hideBlockHandler()}>
                    Okay!
                  </button>
                </div>
              }
            />
          )}
          <div className='block_header_info_box'>
            <button className='block_custom_btn' onClick={() => backBtn()}>
              <BiArrowBack />
            </button>
            <span className='block_custom_btn'>{title}</span>
          </div>

          <div className='block_header_button_section'>
            <button
              className='block_custom_btn'
              onClick={() => setOpenModal(true)}>
              <FaSearch />
            </button>
            {group.g_c_dun !== user.handleUn && (
              <Menu
                menuButton={
                  <MenuButton className={"block_header_menu_btn"}>
                    <MdMoreVert />
                  </MenuButton>
                }>
                <MenuItem
                  className={"block_header_menu_item"}
                  onClick={() => setOpenBlockModal(true)}>
                  Block group
                </MenuItem>

                <MenuItem
                  className={"block_header_menu_item"}
                  onClick={() => navigate(`/group/edit/${id}`)}>
                  Report group
                </MenuItem>
              </Menu>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default BlockPageHeader;
