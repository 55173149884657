/** @format */

import React from "react";
import ReactDOM from "react-dom";
import { AiOutlineClose } from "react-icons/ai";
import { ThemeContext, ThemeProvider } from "../Common/ThemeProvider";

const CustomPostFormModal = ({ onClose, title, body, footer }) => {
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);

  const dynamicStyles = {
    "--theme-color": themeColor,
    "--font-size": fontSize,
  };

  return ReactDOM.createPortal(
    <div className='custom_modal_container' style={dynamicStyles}>
      <div className='custom_modal_overlay'></div>
      <div className={`small_screen_custom_custom_modal ${theme}`}>
        <div className='modal_title'>{title}</div>
        <div className='custom_modal_body'>{body}</div>
        <div className='custom_modal_footer'>{footer}</div>
      </div>
    </div>,
    document.getElementById("modal-hook")
  );
};

export default CustomPostFormModal;
