/** @format */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import timeDifference from "../../utils/getCreateTime";
import { FiMoreHorizontal } from "react-icons/fi";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { BsEmojiLaughingFill, BsEmojiAngryFill } from "react-icons/bs";
import "react-toastify/dist/ReactToastify.css";
import { AiOutlineSend } from "react-icons/ai";
import { AiFillDislike, AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { t } from "i18next";
import UserAvatar from "../../Assets/userAvatar.webp";
import { RiSpam2Line } from "react-icons/ri";
import { HiOutlineReply } from "react-icons/hi";
import CustomSmallModal from "../modal/CustomSmallModal";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import EmojiLike from "../EmojiLike/EmojiLike";
import GroupPostReply from "../GroupPostReply/GroupPostReply";
import { selectCoins } from "../../redux/_page/pageSelectors.js";
import { AiOutlineClose } from "react-icons/ai";

/**
 * @TOOLKIT
 */
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectToken,
  selectcommentSpam,
  selectLike,
  selectAngry,
  selectHaha,
  selectDislikes,
  selectFollowing,
} from "../../redux/_user/userSelectors";
import {
  addcommentSpam,
  removecommentSpam,
  addLike,
  removeLike,
  addAngry,
  removeAngry,
  addHAHA,
  removeHaha,
  addDislikes,
  removeDislikes,
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
} from "../../redux/_user/userSlice";
import {
  handleEditComment,
  handleBlockCommentHide,
  handleBlockCommentDelete,
  handleBlockPostCommentSpam,
  handleCommentLike,
  handleCommentRemoveLike,
  handleCreateBlockPostReply,
  prepandReplies,
  handleFetchReplies,
  removeReplies,
  setReplies,
  appendReplies,
} from "../../redux/_blockPost/blockPostSlice";
import {
  selectReplies,
  selectCurrentCommentCount,
} from "../../redux/_blockPost/blockPostSelectors";
import {
  BsFillPinAngleFill,
  BsFillPlusCircleFill,
  BsFillCheckCircleFill,
  BsFillPatchCheckFill,
} from "react-icons/bs";

import "./CommentCard.css";
import { ImSpinner2 } from "react-icons/im";
import { checkMention, checkCryptoMention } from "../../utils/checkMensionUser";
import { setCoinDetails } from "../../redux/_page/pageSlice";

const CommentCard = ({ commentData }) => {
  /**
   * @TOOLKIT_INIT
   */

  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const spamList = useSelector(selectcommentSpam);
  const like = useSelector(selectLike);
  const angry = useSelector(selectAngry);
  const haha = useSelector(selectHaha);
  const dislike = useSelector(selectDislikes);
  const replies = useSelector(selectReplies);
  const currentCommentCount = useSelector(selectCurrentCommentCount);
  const following = useSelector(selectFollowing);
  const coins = useSelector(selectCoins);

  /**
   * @END
   */
  const [commnetContent, setCommentContent] = React.useState(
    commentData.comment
  );
  const [cmntText, setCmntText] = React.useState("");
  const [cmntImage, setCmntImage] = React.useState(commentData.image);
  const [isPinn, setIsPinn] = React.useState(commentData.pinn);
  const [isDelete, setIsDelete] = React.useState(commentData.delete);
  const [cmntId, setCmntId] = React.useState("");
  const [isHide, setIsHide] = React.useState(commentData.hide);
  const [likeCount, setLikeCount] = React.useState(commentData.l_c);
  const [dislikeCount, setDisikeCount] = React.useState(commentData.d_c);
  const [spamCount, setSpamCount] = React.useState(commentData.s_c);
  const [shareCount, setShareCount] = React.useState(commentData.reply.length);

  // *** Modal states
  const [openCommentPinnedModal, setOpenCommentPinnedModal] =
    React.useState(false);
  const [openCommentHideModal, setOpenCommentHideModal] = React.useState(false);
  const [openCommentEditModal, setOpenCommentEditModal] = React.useState(false);
  const [showReplies, setShowReplies] = React.useState(false);
  const [openHideModal, setOpenHideModal] = React.useState(false);
  const [openDeletModal, setOpenDeletModal] = React.useState(false);
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);

  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");

  const [openReply, setOpenReply] = React.useState(false);
  const [replyText, setReplyText] = React.useState("");
  const [replyCount, setReplyCount] = React.useState(commentData.c_c || 0);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(3);
  const [show, setShow] = React.useState(true);
  const [isLoading, setIsLoading] = React.useState(false);
  const [paginate, setPaginate] = React.useState(null);
  const [disableCmntBtn, setDisableCmntBtn] = useState(true);
  const [cmntOwner, setCmntOwner] = useState("");
  const [replyFetchCount, setReplyFetchCount] = useState(3);
  const [replyBtnLoading, setReplyBtnLoading] = useState(true);
  const [repliedUser, setRepliedUser] = React.useState("");
  const [replyLoading, setReplyLoading] = useState(false);
  const [hideText, setHideText] = useState(true);

  React.useEffect(() => {
    if (!replyText.trim()) {
      setDisableCmntBtn(true);
    } else {
      setDisableCmntBtn(false);
    }
  }, [replyText]);

  const editModalHandler = (id) => {
    setCmntId(id);
    setOpenCommentEditModal(true);
    // if (id === commentData._id) {
    //   setImg(commentData.img);
    //   setMessage(commentData.message);
    //   setGif(commentData.gif);
    //   setUrl(commentData.url);
    // }
  };

  // *** Delete modal handler
  const deleteModalHandler = (id) => {
    // console.log(id)
    setOpenDeletModal(true);
    setCmntId(id);
  };

  // *** Comment delete
  const deleteCommentHandler = async (id) => {
    const data = { token, cmntId };
    const result = await dispatch(handleBlockCommentDelete(data));
    console.log(result);
    setIsDelete(true);
    setOpenDeletModal(false);
  };

  const hideModalHandler = (id) => {
    console.log(id);
    // alert(id)
    setOpenHideModal(true);
    setCmntId(id);
  };

  const unhideCommentHandler = async (cmntId) => {
    const arr = isHide;
    const temp = arr.filter((data) => data !== user.handleUn);
    setIsHide(temp);
    const data = { token, cmntId };
    const result = await handleBlockCommentHide(data);
    setOpenCommentHideModal(false);
  };

  // *** Comment HIDE handler
  const hideCommentHandler = async (cmntId) => {
    setIsHide((prev) => [...prev, user.handleUn]);
    const data = { token, cmntId };
    const result = await handleBlockCommentHide(data);
    setOpenCommentHideModal(false);
  };

  const handleModalImageChange = (e) => {
    setImg(URL.createObjectURL(e.target.files[0]));
    setSelectImg(e.target.files[0]);
    setGif(e.target.files[0]);
  };

  const updatePostComment = async () => {
    const data = {
      token,
      cmntId,
      message: cmntText,
    };
    const result = await dispatch(handleEditComment(data));
    console.log(result);
    setCommentContent(cmntText);
    setOpenCommentEditModal(false);
  };

  /**
   *
   * @START_FROM_HERE
   */

  // *** Add emoji to input field
  const addEmoji = (e) => {
    setMessage((prev) => prev + e.native);
  };

  // *** Close comment image preview
  const closePrevImage = () => {
    setGif("");
    setImg("");
    setPrevImg("");
  };
  // *** Comment like handler
  // const commentLikeHandler = (cmntId) => {
  //   if (likeList.includes(cmntId)) {
  //     removeToLike(cmntId);
  //     setLikeCount((prev) => prev - 1);
  //   } else {
  //     if (dislikesList.includes(cmntId)) {
  //       setDisikeCount((prev) => prev - 1);
  //       removeToDislikes(cmntId);
  //       setLikeCount((prev) => prev + 1);
  //       addToLikeArray(cmntId);
  //     } else {
  //       setLikeCount((prev) => prev + 1);
  //       addToLikeArray(cmntId);
  //     }
  //   }
  //   var axios = require("axios");

  //   var config = {
  //     method: "put",
  //     url: `${process.env.REACT_APP_URL_LINK}api/group/post/comment/like/${cmntId}`,
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // *** Comment dislike handler
  // const commentDislikeHandler = (cmntId) => {
  //   if (dislikesList.includes(cmntId)) {
  //     removeToDislikes(cmntId);
  //     setDisikeCount((prev) => prev - 1);
  //   } else {
  //     if (likeList.includes(cmntId)) {
  //       setLikeCount((prev) => prev - 1);
  //       removeToLike(cmntId);
  //       setDisikeCount((prev) => prev + 1);
  //       addToDislikes(cmntId);
  //     } else {
  //       setDisikeCount((prev) => prev + 1);
  //       addToDislikes(cmntId);
  //     }
  //   }
  //   var axios = require("axios");
  //   var config = {
  //     method: "put",
  //     url: `${process.env.REACT_APP_URL_LINK}api/group/post/comment/dislike/${cmntId}`,
  //     headers: {
  //       Authorization: "Bearer " + token,
  //     },
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       console.log(JSON.stringify(response.data));
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // *** Comment PINNED modal handler
  // const openPinnedModalHandler = (id) => {
  //   setOpenCommentPinnedModal(true);
  //   setCmntId(id);
  // };
  // *** Comment PINNED handler
  // const pinnedCommentHandler = (cmntId) => {
  //   console.log(cmntId, postId);
  //   var axios = require("axios");
  //   var data = JSON.stringify({
  //     postId: postId,
  //   });

  //   var config = {
  //     method: "put",
  //     url: `${process.env.REACT_APP_URL_LINK}api/group/post/comment/pinned/${cmntId}`,
  //     headers: {
  //       Authorization: "Bearer " + token,
  //       "Content-Type": "application/json",
  //     },
  //     data: data,
  //   };

  //   axios(config)
  //     .then(function (response) {
  //       // console.log(JSON.stringify(response));
  //       // setPinnedPost(response.data.postData);
  //       // updateGroupPost(response);
  //       setOpenCommentPinnedModal(false);
  //     })
  //     .catch(function (error) {
  //       console.log(error);
  //     });
  // };

  // *** Comment SPAM modal handler
  const handleSpamComment = (id) => {
    console.log(id);
    if (spamList.includes(id)) {
      console.log("Already have");
      dispatch(removecommentSpam(id));
      setSpamCount((prev) => prev - 1);
    } else {
      console.log("Not have");
      dispatch(addcommentSpam(id));
      setSpamCount((prev) => prev + 1);
    }
    const data = { id, token };
    dispatch(handleBlockPostCommentSpam(data));
  };

  // *** Comment EDIT modal handler
  const openCommentEditModalHandler = (id) => {
    setOpenCommentEditModal(true);
    setCmntId(id);
  };
  // *** Comment EDIT handler
  const commentEditHandler = (cmntId) => {
    var axios = require("axios");
    var data = JSON.stringify({
      postId: postId,
      comment: comment,
      gif: gif,
    });

    var config = {
      method: "put",
      url: `${process.env.REACT_APP_URL_LINK}api/group/post/comment/edit/${cmntId}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(response.data);
        setPinnedPost(response.data.postData);
        setOpenCommentEditModal(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // *** Handle open reply box
  const handleReplyComment = (data, value, isSelected) => {
    if (openReply) {
      dispatch(removeReplies());
      setOpenReply((p) => !p);
      setHideText(true);
      setRepliedUser("");
    } else {
      setHideText(false);
      if (isSelected) {
        setRepliedUser(data.c_handleUn);
      }
      setOpenReply((p) => !p);
      setCmntId(data.c_id);
      setCmntOwner(data.c_handleUn);
      fetchReply(data.c_id, 1);
    }
  };

  const onClose = () => {
    setOpenHideModal(false);
    setOpenDeletModal(false);
    setOpenCommentEditModal(false);
  };

  React.useEffect(() => {
    if (!cmntText.trim()) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  }, [cmntText]);

  const handleLikeEmoji = (id, username) => {
    setOpenEmojiIcons(true);
    setCmntId(id);
    setOwnerName(username);
  };

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenEmojiIcons(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const likeComment = async (value, id) => {
    const data = { value, id, ownerName, likeCount, token };
    // const responseData = await dispatch(handleCommentLike(data));
    if (value === "like") {
      if (likeCount === 0) {
        setLikeCount((prev) => prev + 1);
        dispatch(addLike(id));
      } else {
        if (!like.includes(id)) {
          dispatch(addLike(id));
          setLikeCount((prev) => prev + 1);
          dispatch(removeAngry(id));
          dispatch(removeHaha(id));
          dispatch(removeDislikes(id));
        } else {
          setLikeCount((prev) => prev - 1);
          dispatch(removeLike(id));
        }
      }
    } else if (value === "dislike") {
      if (likeCount === 0) {
        setLikeCount((prev) => prev + 1);
        dispatch(addDislikes(id));
      } else {
        console.log(">>>>>>> ", dislike.includes(id));
        if (!dislike.includes(id)) {
          dispatch(removeLike(id));
          dispatch(addDislikes(id));
          setLikeCount((prev) => prev + 1);
          dispatch(removeAngry(id));
          dispatch(removeHaha(id));
        } else {
          console.log("*****");
          setLikeCount((prev) => prev - 1);
          dispatch(removeDislikes(id));
        }
      }
    } else if (value === "haha") {
      if (likeCount === 0) {
        setLikeCount((prev) => prev + 1);
        dispatch(addHAHA(id));
      } else {
        if (!haha.includes(id)) {
          dispatch(removeLike(id));
          dispatch(removeDislikes(id));
          dispatch(addHAHA(id));
          setLikeCount((prev) => prev + 1);
          dispatch(removeAngry(id));
        } else {
          setLikeCount((prev) => prev - 1);
          dispatch(addHAHA(id));
        }
      }
    } else if (value === "angry") {
      if (likeCount === 0) {
        dispatch(addAngry(id));
        setLikeCount((prev) => prev + 1);
      } else {
        if (!angry.includes(id)) {
          dispatch(removeLike(id));
          dispatch(removeDislikes(id));
          dispatch(removeHaha(id));
          dispatch(addAngry(id));
          setLikeCount((prev) => prev + 1);
        } else {
          dispatch(addAngry(id));
          setLikeCount((prev) => prev + 1);
        }
      }
    }
    const responseData = await dispatch(handleCommentLike(data));

    console.log(responseData);
    // Here we notify the user
    if (!responseData.notificationData) {
      console.log("OWN POST");
      setOpenEmojiIcons(false);
    } else {
      console.log("Notification send: ", responseData.notificationData);
      setOpenEmojiIcons(false);
      socket.emit("notification receive", responseData);
    }
  };

  const handleRemoveLike = (id, username) => {
    dispatch(removeLike(id));
    dispatch(removeAngry(id));
    dispatch(removeHaha(id));
    dispatch(removeDislikes(id));
    setLikeCount((prev) => prev - 1);
    const data = { token, id };
    dispatch(handleCommentRemoveLike(data));
  };

  const handleSubmitComment = async () => {
    setDisableCmntBtn(true);
    const data = {
      token,
      replyText,
      cmntId,
      cmntOwner,
      repliedUser: repliedUser,
    };
    const result = await dispatch(handleCreateBlockPostReply(data));

    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
    dispatch(prepandReplies(result.data.reply));
    setReplyCount((prev) => prev + 1);
    setReplyText("");
  };

  const handleIncreatemPage = () => {
    setReplyBtnLoading(true);
    setPage(page + 1);
    // console.log("Page increamented ", page);
    fetchReply(cmntId, page);
  };

  // React.useEffect(() => {
  // }, [page]);

  const fetchReply = async (cmntId, page) => {
    if (page === 1) {
      setReplyLoading(true);
    }

    const data = { cmntId, token, page, limit };
    const result = await dispatch(handleFetchReplies(data));
    console.log("###", result);
    setReplyFetchCount(result.length);
    if (data.page === 1) {
      dispatch(setReplies(result));
    } else {
      dispatch(appendReplies(result));
    }
    setReplyBtnLoading(false);
    setReplyLoading(false);
  };

  const PostHandleFollowUser = async (userId) => {
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { isToken, userId };
    const result = await dispatch(handleFollowUser(data));
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
  };

  React.useEffect(() => {
    if (!commentData.delete && commentData.coin_id) {
      const data = { [commentData.coin_id]: commentData.price };
      dispatch(setCoinDetails(data));
    }
  }, [commentData.coin_id]);

  const handleInputKeyDown = (e) => {
    if (!replyText.trim()) {
      if (e.keyCode === 8) {
        setRepliedUser("");
      }
    }
  };

  return (
    <React.Fragment>
      {!isDelete && (
        <React.Fragment>
          {!isHide.includes(user.handleUn) ? (
            <React.Fragment>
              <div
                className={
                  commentData.c_handleUn === user.handleUn
                    ? "comment_card my_post_comment_card"
                    : "comment_card"
                }>
                {/* Edit modal */}
                {openCommentEditModal && (
                  <CustomSmallModal
                    title='Hide comment'
                    onClose={onClose}
                    body={
                      <div className='comment_edit_container'>
                        <textarea
                          placeholder='Update comment'
                          className='modal_textarea'
                          value={cmntText}
                          onChange={(e) =>
                            setCmntText(e.target.value)
                          }></textarea>
                      </div>
                    }
                    footer={
                      <div>
                        {!isBtnDisable && (
                          <button
                            className='update_btn'
                            onClick={updatePostComment}>
                            Hide
                          </button>
                        )}
                      </div>
                    }
                  />
                )}

                {/* Hide modal */}
                {openHideModal && (
                  <CustomSmallModal
                    title='Hide comment'
                    onClose={onClose}
                    body={"Do you want to hide this comment?"}
                    footer={
                      <div>
                        <button
                          className='update_btn'
                          onClick={hideCommentHandler(commentData.c_id)}>
                          Hide
                        </button>
                      </div>
                    }
                  />
                )}

                {/* Delete Modal */}
                {openDeletModal && (
                  <CustomSmallModal
                    title='Hide comment'
                    onClose={onClose}
                    body={"Do you want to delete this comment?"}
                    footer={
                      <div>
                        <button
                          className='update_btn delete_modal_btn'
                          onClick={deleteCommentHandler}>
                          Delete
                        </button>
                      </div>
                    }
                  />
                )}
                {/* Comment Header */}
                <div className='comment_card_header'>
                  <div className='comment_user_section'>
                    <div className='profile_avatar_section'>
                      <img
                        src={
                          commentData.c_pic
                            ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${commentData.c_pic}`
                            : UserAvatar
                        }
                        className='posted_user_avatar'
                        id='profile'
                      />

                      {user.handleUn !== commentData.c_handleUn && (
                        <button
                          className='post_card_follow_btn'
                          onClick={() =>
                            PostHandleFollowUser(commentData.c_handleUn)
                          }>
                          {following.includes(commentData.c_handleUn) ? (
                            <BsFillCheckCircleFill className='postcard_follow_icon' />
                          ) : (
                            <BsFillPlusCircleFill className='postcard_follow_icon' />
                          )}
                        </button>
                      )}
                    </div>
                    <Link
                      to={`/user/profile/${commentData.c_handleUn}`}
                      className='commentData_user_firstname'>
                      {commentData.c_fn} {commentData.c_ln}
                    </Link>
                    <span className='commentData_user_username'>
                      @{commentData.c_handleUn}
                    </span>
                    <span className='creator_time'>
                      {timeDifference(
                        new Date().getTime(),
                        Number(commentData.timestamp)
                      )}
                    </span>
                  </div>

                  <Menu
                    menuButton={
                      <MenuButton className={"__menu_btn"}>
                        <FiMoreHorizontal />
                      </MenuButton>
                    }>
                    {/* Edit menu */}
                    {/* <MenuItem className={"block_post_menuItem"}>Pin</MenuItem> */}
                    {/* Edit menu */}
                    {commentData.c_handleUn === user.handleUn && (
                      <MenuItem
                        className={"block_post_menuItem"}
                        onClick={() => editModalHandler(commentData.c_id)}>
                        Edit
                      </MenuItem>
                    )}
                    {/* Hide menu */}
                    {commentData.c_handleUn !== user.handleUn && (
                      <MenuItem
                        className={"block_post_menuItem"}
                        onClick={() => hideModalHandler(commentData.c_id)}>
                        Hide
                      </MenuItem>
                    )}
                    {/* Delete menu */}
                    {commentData.c_handleUn === user.handleUn && (
                      <MenuItem
                        className={"block_post_menuItem delete"}
                        onClick={() => deleteModalHandler(commentData.c_id)}>
                        Delete
                      </MenuItem>
                    )}
                  </Menu>
                </div>

                {/* Comment body */}
                <div className='comment_body'>
                  {/* <span className='comment_body_text'>{commnetContent}</span> */}
                  <span className='comment_body'>
                    {commnetContent.split(" ").map((val, index) => (
                      <>
                        {val.includes("$") ? (
                          <Link
                            to={`trending/posts/coin/${checkCryptoMention(
                              val
                            )}`}
                            className='crypto_mentions'>
                            {" "}
                            ${checkCryptoMention(val)} (
                            {coins[commentData.coin_id]})
                          </Link>
                        ) : (
                          <>{val}</>
                        )}
                      </>
                    ))}
                  </span>
                  {cmntImage && (
                    <div className='comment_image_container'>
                      <img
                        src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${cmntImage}`}
                        className='comment_image'
                      />
                      {/* */}
                    </div>
                  )}
                </div>

                {/* Comment footer */}
                <div className='comment_card_footer'>
                  {/* Like */}
                  <div className='comment_card_footer_section'>
                    {openEmojiIcons && (
                      <div className='card_emoji_container' ref={wrapperRef}>
                        <EmojiLike
                          id={cmntId}
                          setQuery={setQuery}
                          clickHandler={likeComment}
                        />
                      </div>
                    )}
                    <div className='emoji_icon_card'>
                      {like.includes(commentData.c_id) ? (
                        <button
                          id='like'
                          className='post_footer_btn post_like'
                          onClick={() =>
                            handleRemoveLike(
                              commentData.c_id,
                              commentData.c_handleUn
                            )
                          }>
                          <AiFillHeart
                            id='like'
                            className='post_like_active like_icon'
                          />{" "}
                          {likeCount}
                        </button>
                      ) : (
                        <>
                          {dislike.includes(commentData.c_id) ? (
                            <button
                              id='like'
                              className='post_footer_btn post_like'
                              onClick={() =>
                                handleRemoveLike(
                                  commentData.c_id,
                                  commentData.c_handleUn
                                )
                              }>
                              <AiFillDislike
                                id='like'
                                className='post_like_active dislike_icon'
                              />{" "}
                              {likeCount}
                            </button>
                          ) : (
                            <>
                              {haha.includes(commentData.c_id) ? (
                                <button
                                  id='like'
                                  className='post_footer_btn post_like'
                                  onClick={() =>
                                    handleRemoveLike(
                                      commentData.c_id,
                                      commentData.c_handleUn
                                    )
                                  }>
                                  <BsEmojiLaughingFill
                                    id='like'
                                    className='post_like_active funny_icon'
                                  />{" "}
                                  {likeCount}
                                </button>
                              ) : (
                                <>
                                  {angry.includes(commentData.c_id) ? (
                                    <button
                                      id='like'
                                      className='post_footer_btn post_like'
                                      onClick={() =>
                                        handleRemoveLike(
                                          commentData.c_id,
                                          commentData.c_handleUn
                                        )
                                      }>
                                      <BsEmojiAngryFill
                                        id='like'
                                        className='post_like_active angry_icon'
                                      />{" "}
                                      {likeCount}
                                    </button>
                                  ) : (
                                    <button
                                      id='like'
                                      className='post_footer_btn post_like'
                                      onClick={() =>
                                        handleLikeEmoji(
                                          commentData.c_id,
                                          commentData.c_handleUn
                                        )
                                      }>
                                      <AiOutlineHeart id='like' /> {likeCount}
                                    </button>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>

                  {/* Spam */}
                  <button
                    className='comment_footer_btn'
                    onClick={() => handleSpamComment(commentData.c_id)}>
                    <RiSpam2Line
                      className={
                        spamList.includes(commentData.c_id) ? "active_spam" : ""
                      }
                    />{" "}
                    <span>{spamCount}</span>
                  </button>

                  {/* Share */}
                  <button
                    className='comment_footer_btn'
                    onClick={() =>
                      handleReplyComment(commentData, openReply, true)
                    }>
                    <HiOutlineReply />
                    <span className='comment_count'>{replyCount || 0}</span>
                  </button>
                </div>
              </div>
              {replyCount > 0 && hideText && (
                <div
                  className='view_replies'
                  onClick={() =>
                    handleReplyComment(commentData, openReply, false)
                  }>
                  View {replyCount} replies
                </div>
              )}
              {openReply && (
                <div className='block_reply_section'>
                  {/* Reply Form */}
                  <div className='reply_form_section'>
                    {repliedUser && (
                      <span className='replied_tagged_user'>
                        @{repliedUser}
                      </span>
                    )}
                    <input
                      type='text'
                      placeholder='Enter your reply'
                      className={
                        repliedUser
                          ? "reply_input has_user"
                          : "reply_input no_user"
                      }
                      value={replyText}
                      onChange={(e) =>
                        setReplyText(e.target.value.slice(0, 50))
                      }
                      onKeyDown={(e) => handleInputKeyDown(e)}
                    />
                    {!disableCmntBtn && (
                      <button
                        className='reply_submit_btn'
                        onClick={handleSubmitComment}>
                        <AiOutlineSend />
                      </button>
                    )}
                  </div>

                  {/* Reply component */}
                  <div className='reply_card_section'>
                    {replyLoading ? (
                      <div className='reply_loading_section'>LOADING....</div>
                    ) : (
                      <>
                        {(replies || []).length > 0 ? (
                          <div>
                            {replies.map((data) => (
                              <GroupPostReply
                                key={data.r_id}
                                reply={data}
                                setReplyCount={setReplyCount}
                              />
                            ))}
                            {replyFetchCount === limit && (
                              <button
                                className='reply_load_btn'
                                onClick={() => handleIncreatemPage()}>
                                {replyBtnLoading ? (
                                  <ImSpinner2 />
                                ) : (
                                  <>Load more</>
                                )}
                              </button>
                            )}
                          </div>
                        ) : (
                          <div className='blockempty_reply'>
                            {t("No reply found")}
                          </div>
                        )}
                      </>
                    )}
                  </div>
                </div>
              )}
            </React.Fragment>
          ) : (
            <div className='comment_card'>
              Unhide this comment{" "}
              <button
                className='update_btn'
                onClick={() => unhideCommentHandler(commentData.c_id)}>
                Unhide
              </button>
            </div>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default CommentCard;
