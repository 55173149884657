/** @format */

import React from "react";
import { AiFillHeart, AiFillDislike } from "react-icons/ai";
import { BsEmojiLaughingFill, BsEmojiAngryFill } from "react-icons/bs";
import { FaFire, FaSadTear } from "react-icons/fa";
import { MdArrowUpward } from "react-icons/md";

// import Love from "../../Assets/app-icons/love.svg";
import Love from "../../Assets/app-icons/set4/heart.svg";
// import Haha from "../../Assets/app-icons/haha.svg";
import Haha from "../../Assets/app-icons/set4/haha.svg";
// import Angry from "../../Assets/app-icons/angry.svg";
import Angry from "../../Assets/app-icons/set4/angry.svg";
// import Dislike from "../../Assets/app-icons/dislike.svg";
import Dislike from "../../Assets/app-icons/set4/dislike.svg";

const emoji = [
  "AiOutlineLike",
  "AiOutlineHeart",
  "BsEmojiAngry",
  "BsEmojiLaughing",
  "BsEmojiSunglasses",
];

const EmojiLike = ({ clickHandler, setQuery, id }) => {
  const handleClick = (e) => {
    console.log(e.target.value);
  };

  const newFunc = (e) => {
    var item = e.target.closest(".hove_emoji_btn");

    if (item) {
      setQuery(item.value);
      clickHandler(item.value, id);
    }
  };
  return (
    <div className='emojis'>
      <button
        className='hove_emoji_btn'
        value={"like"}
        onClick={(e) => newFunc(e, id)}>
        {/* <AiFillHeart className='post_emoji_icon' />
         */}
        <img src={Love} className='post_emoji_icon' />
      </button>

      <button
        className='hove_emoji_btn'
        value={"dislike"}
        onClick={(e) => newFunc(e)}>
        {/* <AiFillDislike className='post_emoji_icon dislike_icon' /> */}
        <img src={Dislike} className='post_emoji_icon' />
      </button>

      <button
        className='hove_emoji_btn'
        value={"haha"}
        onClick={(e) => newFunc(e)}>
        {/* <BsEmojiLaughingFill className='post_emoji_icon funny_icon' /> */}
        <img src={Haha} className='post_emoji_icon' />
      </button>

      <button
        className='hove_emoji_btn'
        value={"angry"}
        onClick={(e) => newFunc(e)}>
        {/* <BsEmojiAngryFill className='post_emoji_icon angry_icon' /> */}
        <img src={Angry} className='post_emoji_icon' />
      </button>

      {/* FaFire */}
      <button
        className='hove_emoji_btn'
        value={"fire"}
        onClick={(e) => newFunc(e)}>
        {/* <BsEmojiAngryFill className='post_emoji_icon angry_icon' /> */}
        <FaFire className='post_emoji_icon' />
      </button>

      <button
        className='hove_emoji_btn'
        value={"sad"}
        onClick={(e) => newFunc(e)}>
        {/* <BsEmojiAngryFill className='post_emoji_icon angry_icon' /> */}
        <FaSadTear className='post_emoji_icon' />
      </button>

      <button
        className='hove_emoji_btn'
        value={"upvote"}
        onClick={(e) => newFunc(e)}>
        {/* <BsEmojiAngryFill className='post_emoji_icon angry_icon' /> */}
        <MdArrowUpward className='post_emoji_icon' />
      </button>
    </div>
  );
};

export default EmojiLike;
