/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  getNotifications,
  getPosts,
} from "../../api/_notifications/notificationsApi";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    notifications: [],
    notificationCount: 0,
  },
  reducers: {
    putNotification: (state, action) => {
      state.notifications = action.payload;
    },
    appendNotifications: (state, action) => {
      state.notifications = [...action.payload, ...state.notifications];
    },

    newNotification: (state, action) => {
      state.notifications = [action.payload, ...state.notifications];
    },

    updateNotification: (state, action) => {
      var notificationsCopy = state.notifications;
      const index = notificationsCopy.findIndex(
        (n) => n._id === action.notification._id
      );
      notificationsCopy[index] = action.notification;
      state.notifications = notificationsCopy;
    },

    setNotificationCount: (state, action) => {
      state.notificationCount = action.payload;
    },

    updateNotificationCount: (state, action) => {
      state.notificationCount = state.notificationCount + 1;
    },

    resetNotificationCount: (state, actions) => {
      state.notificationCount = 0;
    },
    removeNotifications: (state, actions) => {
      state.notifications = [];
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = notificationSlice;

// Extract and export each action creator by name
export const {
  putNotification,
  appendNotifications,
  newNotification,
  updateNotification,
  setNotificationCount,
  updateNotificationCount,
  resetNotificationCount,
  removeNotifications,
} = notificationSlice.actions;

export const fetchNotifications = (data) => async (dispatch) => {
  try {
    const notificationsData = await getNotifications(data);
    // console.log(notificationsData);
    if (data.page === 1) {
      dispatch(putNotification(notificationsData));
    } else {
      dispatch(appendNotifications(notificationsData));
    }
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchPosts = (data) => async (dispatch) => {
  try {
    const result = await getPosts(data);
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

// Export the reducer, either as a default or named export
export default notificationSlice.reducer;
