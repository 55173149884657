/** @format */

import React from "react";

const BlockSekeletonLoader = () => {
  return (
    <React.Fragment>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
      <div className='seleton_block_card'>
        <div src='' className='seleton_block_card_img skeleton_color'></div>
        <div className='block_text_skeleton'>
          <div className='seleton_block_card_text_one skeleton_color'></div>
          <div className='seleton_block_card_text_two skeleton_color'></div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default BlockSekeletonLoader;
