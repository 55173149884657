/** @format */

import { createSlice } from "@reduxjs/toolkit";

const pageSlice = createSlice({
  name: "page",
  initialState: {
    pageType: "default",
    isOpen: false,
    openDrawer: false,
    axisValue: "Up",
    scrollHeight: 0,
    coins: {},
    openPostModal: false,
    openBlockCreateModal: false,
    scrollPosition: 0,
    openPostBlockModal: false,
    openEventBlockModal: false,
  },

  reducers: {
    setPageType: (state, action) => {
      state.pageType = action.payload;
    },

    setScrollAxis: (state, action) => {
      state.axisValue = action.payload;
    },

    setScrollPosition: (state, action) => {
      state.scrollPosition = action.payload;
    },

    setOpenPostModal: (state, action) => {
      state.openPostModal = action.payload;
    },

    // Block post modal
    setOpenBlockPostModal: (state, action) => {
      state.openPostBlockModal = action.payload;
    },
    setOpenBlockEventModal: (state, action) => {
      state.openEventBlockModal = action.payload;
    },

    setOpenBlockCreateModal: (state, action) => {
      state.openBlockCreateModal = action.payload;
    },

    setDrawerHandler: (state, action) => {
      state.isOpen = action.payload;
    },

    setCoinDetails: (state, action) => {
      const newPayload = {};

      // Update existing keys and filter out duplicates
      Object.keys(action.payload).forEach((key) => {
        if (state.coins.hasOwnProperty(key)) {
          // Update existing key
          //state.coins[key] = action.payload[key];
          if (state.coins[key].toString() !== action.payload[key].toString()) {
            state.coins[key] = action.payload[key];
          }
        } else {
          // Add new key to newPayload
          newPayload[key] = action.payload[key];
        }
      });

      // Update the state with the filtered payload
      state.coins = { ...state.coins, ...newPayload };
    },

    updateCoinDetails: (state, action) => {
      state.coins = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = pageSlice;

// Extract and export each action creator by name
export const {
  setPageType,
  setScrollAxis,
  setScrollPosition,
  setDrawerHandler,
  setCoinDetails,
  updateCoinDetails,
  setOpenPostModal,
  setOpenBlockPostModal,
  setOpenBlockCreateModal,
  setOpenBlockEventModal,
} = actions;

// Export the reducer, either as a default or named export
export default reducer;
