/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../Assets/userAvatar.webp";
import { Link } from "react-router-dom";
import { AiOutlineClose, AiOutlineHeart, AiOutlineSend } from "react-icons/ai";
import CustomModal from "../modal/CustomModal";
import { BiArrowBack } from "react-icons/bi";
import EmojiLike from "../EmojiLike/EmojiLike";
import { useNavigate } from "react-router-dom";
import CustomPostFormModal from "../modal/CustomPostForm";
import PostCommentCard from "../PostCommentCard/PostCommentCard";
import { AiFillHeart, AiFillDislike } from "react-icons/ai";
import {
  BsEmojiLaughingFill,
  BsEmojiAngryFill,
  BsFillPatchCheckFill,
  BsGraphDown,
} from "react-icons/bs";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {
  BiFilterAlt,
  BiCommentDetail,
  BiLoaderAlt,
  BiRadioCircleMarked,
  BiRadioCircle,
} from "react-icons/bi";
import axios from "axios";
import { socket, useSocket, isSocketConnected } from "../../socket/socket";
import intToString from "../../utils/PostCount";
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  selectToken,
  selectLike,
  selectAngry,
  selectHaha,
  selectDislikes,
  selectFollowing,
  selectSad,
  selectFire,
  selectUpVote,
} from "../../redux/_user/userSelectors";
import { selectSpam } from "../../redux/_user/userSelectors";
import {
  updateSpamPost,
  updateDonatePost,
  updateLikePost,
  handlePostRemoveLike,
  respost,
  postComment,
  fetchPostComment,
  removeComments,
  fetchLikedUser,
  updateSharePost,
  postFalgged,
} from "../../redux/_post/postSlice";
import {
  setAddSpam,
  setRemoveSpam,
  addLike,
  removeLike,
  addAngry,
  removeAngry,
  addHAHA,
  removeHaha,
  addDislikes,
  removeDislikes,
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
  addSad,
  removeSad,
  addFire,
  removeFire,
  addUpVote,
  removeUpvote,
} from "../../redux/_user/userSlice";
import {
  selectComments,
  selectCurrentCommentCount,
  selectCommentUpdate,
} from "../../redux/_post/postSelectors";
import { ThemeContext } from "../Common/ThemeProvider";
import { BiLocationPlus } from "react-icons/bi";
import { FaRegFlag, FaFlag, FaCopy } from "react-icons/fa";
import { AiOutlineShareAlt, AiOutlineStar } from "react-icons/ai";
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
// import Love from "../../Assets/app-icons/love.svg";
import { ReactComponent as Love } from "../../Assets/app-icons/set2/heart.svg";
// import Haha from "../../Assets/app-icons/haha.svg";
import Haha from "../../Assets/app-icons/set2/haha.svg";
// import Angry from "../../Assets/app-icons/angry.svg";
import Angry from "../../Assets/app-icons/set2/angry.svg";
// import Dislike from "../../Assets/app-icons/dislike.svg";
import { ReactComponent as Dislike } from "../../Assets/app-icons/set2/dislike.svg";
import { FaFire, FaSadTear } from "react-icons/fa";
import { MdArrowUpward } from "react-icons/md";

const NormalPostFooter = ({ postData }) => {
  useSocket();
  const dispatch = useDispatch();
  const sad = useSelector(selectSad);
  const fire = useSelector(selectFire);
  const upvote = useSelector(selectUpVote);
  const commentUpdatedData = useSelector(selectCommentUpdate);
  const comments = useSelector(selectComments);
  const currentCommentCount = useSelector(selectCurrentCommentCount);
  const like = useSelector(selectLike);
  const haha = useSelector(selectHaha);
  const dislike = useSelector(selectDislikes);
  const angry = useSelector(selectAngry);
  // const isPost = useSelector(selectPosts);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const following = useSelector(selectFollowing);
  const spam = useSelector(selectSpam);
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const [postId, setPostId] = React.useState("");
  const [post, setPost] = React.useState("");
  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [commentCount, setCommentCount] = React.useState(postData.c_c);
  const [openCommentModal, setOpenCommentModal] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [isFocus, setIsFocus] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [prevImage, setPrevImage] = React.useState("");
  const [isDisable, setIsDisable] = React.useState(true);
  const [lastResponseData, setLastResponseData] = React.useState(1);
  const [sortedBy, setSortedBy] = React.useState("new");
  const [prevSortedBy, setprevSortedBy] = React.useState("popular");
  const [query, setQuery] = React.useState("");
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isVisble, setIsVisble] = React.useState(false);
  const [text, setText] = React.useState("");
  const [gif, setGif] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [spanCount, setSpamCount] = React.useState(postData.sp_c || 0);
  const [likeCount, setLikeCount] = React.useState(postData.l_c);
  const [openDonatModal, setOpenDonatModal] = React.useState(false);
  const [donateValue, setDonateValue] = React.useState(0);
  const [donateBtnVisible, setDonateBtnVisible] = React.useState(false);
  const [openSpamModal, setOpenSpamModal] = React.useState(false);
  const [postUserId, setPostUserId] = React.useState("");
  const [custommessage, setCustomMessage] = React.useState("");
  const [openLikeModal, setOpneLikeModal] = React.useState(false);
  const [likeType, setLikeType] = React.useState("all");
  const [likePage, setLikePage] = React.useState(1);
  const [usersList, setUsersList] = React.useState([]);
  const [sortType, setSortType] = React.useState("new");
  const [fetchPostCount, setFetchPostCount] = React.useState(0);
  const [isLoadingBtn, setIsLoadingBtn] = React.useState(false);
  const [commentPrivacy, setCommentPrivacy] = React.useState(
    postData.cmnt_prv || "all"
  );
  const [sharePrivacy, setSharePrivacy] = React.useState(
    postData.shr_prv || "all"
  );
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);
  const [isPostLike, setPostLike] = React.useState(postData.like || false);
  const [isPostDisLike, setPostDisLike] = React.useState(
    postData.dislike || false
  );
  const [isPostHaha, setPostHaha] = React.useState(postData.haha || false);
  const [isPostAngry, setPostAngry] = React.useState(postData.angry || false);
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [selectPost, setSelectPost] = React.useState(null);
  const [openShareViaModal, setOpenShareViaModal] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);
  const [openFlaggedModal, setOpenFLoggedModal] = React.useState(false);
  const [flag, setFlag] = React.useState(postData.flag || []);

  React.useEffect(() => {
    if (donateValue > 0) {
      setDonateBtnVisible(true);
    } else {
      setDonateBtnVisible(false);
    }
  }, [donateValue]);

  React.useEffect(() => {
    if (!text.trim()) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  }, [text]);

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenEmojiIcons(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  React.useEffect(() => {
    if (!message.trim()) {
      if (!image) {
        setIsDisable(true);
      } else {
        setIsDisable(false);
      }
    } else {
      setIsDisable(false);
    }
  }, [message, image]);

  const closeCmntImage = () => {
    setPrevImage("");
    setImage("");
  };

  const handleImageChange = (e) => {
    setPrevImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };
  const onClose = () => {
    setOpenShareModal(false);
    setOpenDonatModal(false);
    setOpenSpamModal(false);
    setOpneLikeModal(false);
    setOpenFLoggedModal(false);
  };

  const closeCommentModal = () => {
    setOpenCommentModal(false);
    dispatch(removeComments());
    setPage(1);
    setOpneLikeModal(false);
    setPostId("");
  };

  // *** Handle like post

  // *** Handle post comment
  const handlePostComment = async (id) => {
    setIsBtnDisable(true);
    const data = {
      token,
      post: { comment: text, c_img: image, gif: gif, postId: id },
    };
    const result = await dispatch(postComment(data));
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
    setText("");
    setCommentCount((prev) => prev + 1);
  };

  // *** Fetch comments
  const fetchComments = async () => {
    const data = { postId, token, sortedBy, page, limit };
    dispatch(fetchPostComment(data));
  };

  React.useEffect(() => {
    if (openCommentModal) {
      fetchComments();
    } else {
      // removeAllComments([]);
    }
  }, [postId, page, sortedBy, commentUpdatedData]);

  const scrollHandler = (e) => {
    let cl = e.currentTarget.clientHeight;
    console.log(e.currentTarget.clientHeight);
    let sy = Math.round(e.currentTarget.scrollTop);
    // console.log(sy)
    let sh = e.currentTarget.scrollHeight;
    if (cl + sy + 1 >= sh) {
      // setPage((page) => page + 1);
      // fetchComments();
    }
  };

  const handleDonateModal = () => {
    setOpenDonatModal(true);
  };

  const handleDonateChange = (value) => {
    setDonateValue(value);
  };

  const handleSpamButton = (id) => {
    setPostId(id);
    setOpenSpamModal(true);
  };

  const handleDonatPost = async () => {
    const data = { postId, donateValue, postUserId, custommessage, token };
    // console.log(data);
    const result = await dispatch(updateDonatePost(data));
    console.log(result);
    setDonateBtnVisible(false);
    setDonateValue(0);
    setOpenDonatModal(false);
  };

  // *** Fetch post like user
  const fetchPostLikeUsers = async () => {
    const data = { token, postId, likeType };
    const result = await dispatch(fetchLikedUser(data));
    console.log(result);
    setUsersList(result);
  };

  React.useEffect(() => {
    if (openLikeModal) {
      fetchPostLikeUsers();
    }
  }, [openLikeModal, likeType]);

  const redirectToProfile = (id) => {
    navigate(`/user/profile/${id}`);
  };

  // *** Handle sorting comment
  const handleSortComment = (value) => {
    setSortedBy(value);
    // set page to 1
    setPage(1);
    // remove all  comments
    removeAllComments([]);
    // remove all reply
    removeAllReply([]);
    // call fetchComment fn.
  };

  // *** Handle increment page count
  const incrementPage = () => {
    setIsLoadingBtn(true);
    setTimeout(() => {
      setIsLoadingBtn(false);
    }, 1000);
    setPage((prev) => prev + 1);
  };

  // **** Handle remove post like
  const handleRemoveLike = (id) => {
    dispatch(removeLike(id));
    dispatch(removeAngry(id));
    dispatch(removeHaha(id));
    dispatch(removeDislikes(id));
    dispatch(removeSad(id));
    dispatch(removeFire(id));
    dispatch(removeUpvote(id));
    setLikeCount((prev) => prev - 1);
    const data = { token, id };
    dispatch(handlePostRemoveLike(data));
  };

  // **** Like post
  const likePost = async (value, id) => {
    const data = { token, id, value };
    // LIKE
    if (value === "like") {
      if (likeCount === 0) {
        dispatch(addLike(id));
        setPostLike(true);
        setLikeCount((prev) => prev + 1);
      } else {
        if (!like.includes(id)) {
          if (
            dislike.includes(id) ||
            haha.includes(id) ||
            angry.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(addLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addLike(id));
          }
        } else {
          dispatch(removeLike(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // ANGRY
    else if (value === "angry") {
      if (likeCount === 0) {
        dispatch(addAngry(id));
        setLikeCount((prev) => prev + 1);
        // setPostDislike(true);
      } else {
        if (!angry.includes(id)) {
          if (
            dislike.includes(id) ||
            haha.includes(id) ||
            like.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(addAngry(id));
            dispatch(removeLike(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            dispatch(addAngry(id));
            setLikeCount((prev) => prev + 1);
          }
        } else {
          dispatch(removeAngry(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // HAHA
    else if (value === "haha") {
      if (likeCount === 0) {
        setPostHaha(true);
        dispatch(addHAHA(id));
        setLikeCount((prev) => prev + 1);
      } else {
        if (!haha.includes(id)) {
          if (
            dislike.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(addHAHA(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addHAHA(id));
          }
        } else {
          dispatch(removeHaha(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // DISLIKE
    else if (value === "dislike") {
      if (likeCount === 0) {
        dispatch(addDislikes(id));
        setLikeCount((prev) => prev + 1);
        setPostDisLike(true);
      } else {
        if (!dislike.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(addDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addDislikes(id));
          }
        } else {
          dispatch(removeDislikes(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // SAD
    else if (value === "sad") {
      if (likeCount === 0) {
        dispatch(addSad(id));
        setLikeCount((prev) => prev + 1);
        // setPostDisLike(true);
      } else {
        if (!sad.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            dislike.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(addSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addSad(id));
          }
        } else {
          dispatch(removeSad(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // FIRE
    else if (value === "fire") {
      if (likeCount === 0) {
        dispatch(addFire(id));
        setLikeCount((prev) => prev + 1);
        // setPostDisLike(true);
      } else {
        if (!fire.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            dislike.includes(id) ||
            sad.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(addFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addFire(id));
          }
        } else {
          dispatch(removeFire(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // UPVOTE
    else if (value === "upvote") {
      if (upvote === 0) {
        dispatch(addUpVote(id));
        setLikeCount((prev) => prev + 1);
        // setPostDisLike(true);
      } else {
        if (!fire.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            dislike.includes(id) ||
            sad.includes(id) ||
            fire.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(addUpVote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addUpVote(id));
          }
        } else {
          dispatch(removeUpvote(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }

    const result = await dispatch(updateLikePost(data));

    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    } else {
      console.log(result);
    }
    setOpenEmojiIcons(false);
  };

  const handleLikeEmoji = (id, username) => {
    setOpenEmojiIcons(true);
    setPostId(id);
  };

  const handleLikeUser = (id) => {
    setOpneLikeModal(true);
    setPostId(id);
    console.log(id);
  };

  const handleOpenLikeModal = (id) => {
    setPostId(id);
    setOpenCommentModal(true);
  };

  const handleOpenDonateModal = (id) => {
    handleDonateModal();
    setPostId(id);
    setPostUserId(data.u_dun);
  };

  const PostHandleFollowUser = (userId) => {
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { token, userId };
    dispatch(handleFollowUser(data));
  };

  const handleRepost = async (id) => {
    const data = { token, id };
    const result = await dispatch(respost(data));
    if (reposted.includes(user.handleUn)) {
      console.log("Already reposted");
      const temp = reposted;
      const arr = temp.filter((data) => data !== user.handleUn);
      setReposted(arr);
    } else {
      console.log("Not yet");
      setReposted((prev) => [...prev, user.handleUn]);
    }
  };

  const handleOpenShareModal = (data) => {
    setOpenShareModal(true);
    setSelectPost(data);
    setPostId(data.id);
  };

  const closeShareModal = () => {
    setOpenShareModal(false);
    setSelectPost(null);
  };

  // *** Post share handler
  const handleRepostWithQuoteHandler = async () => {
    const data = { isToken: token, content, originalPost: selectPost, postId };
    const response = await dispatch(updateSharePost(data));
    if (response.notificationData) {
      socket.emit("notification receive", response);
    }
    try {
      setOpenShareModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleShareViaModal = (id) => {
    setPostId(id);
    setOpenShareViaModal(true);
  };

  const handleFalggedModal = (id) => {
    setOpenFLoggedModal(true);
    setPostId(id);
  };

  const handlePostFlagged = async () => {
    const data = { token, id: postId };
    const result = await dispatch(postFalgged(data));

    if (flag) {
      const arr = flag;
      const temp = arr.filter((data) => data !== user.handleUn);
      setFlag(temp);
    } else {
      setFlag((prev) => [...prev, user.handleUn]);
    }

    setOpenFLoggedModal(false);
  };

  const handleRedirectToTakeDownPost = (id, badgeLength) => {
    console.log(badgeLength.length);
    if (badgeLength.length > 0) {
      navigate(`/takedown/post/${id}`);
    } else {
      alert("User does not have badge");
    }
  };

  return (
    <div className="full_post_post_card">
      {/* Spam modal */}
      {openFlaggedModal && (
        <CustomModal
          title="Flag Post"
          onClose={onClose}
          body={
            <>{t("Once you mark flag for this post you cannot change it.")}</>
          }
          footer={
            <div className="modal_footer">
              <button
                className="update_btn"
                onClick={() => handlePostFlagged()}
              >
                {t("Okay")}
              </button>
            </div>
          }
        />
      )}

      {/* Post Comment modal */}
      {openCommentModal && (
        <CustomPostFormModal
          title={
            <div className="comment_modal_title_section">
              <div className="cmnt_header_box">
                <button
                  className={`cmnt_modal_back_btn ${theme}`}
                  onClick={closeCommentModal}
                >
                  <BiArrowBack />
                </button>
                <button id="like_user" className="cmnt_like_post_count">
                  {postData.like > 0 && (
                    <AiFillHeart
                      id="like_user"
                      className={`cmnt_small_icon heart_small_icon ${theme}`}
                    />
                  )}
                  {postData.angry > 0 && (
                    <BsEmojiAngryFill
                      id="like_user"
                      className={`cmnt_small_icon angry_small_icon ${theme}`}
                    />
                  )}
                  {postData.haha > 0 && (
                    <BsEmojiLaughingFill
                      id="like_user"
                      className={`cmnt_small_icon haha_small_icon ${theme}`}
                    />
                  )}
                  {postData.dislike > 0 && (
                    <AiFillDislike
                      id="like_user"
                      className={`cmnt_small_icon dislike_small_icon ${theme}`}
                    />
                  )}{" "}
                  {likeCount}
                </button>
              </div>

              <div className="cmnt_modal_title">
                {sortedBy === "pop" ? (
                  <>Popular comments</>
                ) : (
                  <>
                    {sortedBy === "old" ? <>Oldest comments</> : <>Comments</>}
                  </>
                )}
              </div>

              <Menu
                menuButton={
                  <MenuButton className={"cmnt_modal_filter_btn"}>
                    <BiFilterAlt className="cmnt_modal_filter_icons" />{" "}
                    {/* {sortedBy === "new" ? (
                        <>Newest comment</>
                      ) : (
                        <>
                          {sortedBy === "pop" ? (
                            <>Most Popular </>
                          ) : (
                            <>Oldest Comment</>
                          )}
                        </>
                      )} */}
                  </MenuButton>
                }
              >
                <MenuItem
                  className="cmnt_sort_menu_item"
                  onClick={() => handleSortComment("new")}
                >
                  {sortedBy === "new" ? (
                    <BiRadioCircleMarked className="dropdown_radio_icon" />
                  ) : (
                    <BiRadioCircle className="dropdown_radio_icon" />
                  )}
                  <span className="menu_text">Newest comment</span>
                </MenuItem>
                <MenuItem
                  className="cmnt_sort_menu_item"
                  onClick={() => handleSortComment("pop")}
                >
                  {sortedBy === "pop" ? (
                    <BiRadioCircleMarked className="dropdown_radio_icon" />
                  ) : (
                    <BiRadioCircle className="dropdown_radio_icon" />
                  )}
                  <span className="menu_text">Most Popular</span>
                </MenuItem>
                <MenuItem
                  className="cmnt_sort_menu_item"
                  onClick={() => handleSortComment("old")}
                >
                  {sortedBy === "old" ? (
                    <BiRadioCircleMarked className="dropdown_radio_icon" />
                  ) : (
                    <BiRadioCircle className="dropdown_radio_icon" />
                  )}
                  <span className="menu_text">Oldest Comment</span>
                </MenuItem>
              </Menu>
            </div>
          }
          body={
            <div
              className={
                isVisble
                  ? "focus_comment_modal_body_section"
                  : "comment_modal_body_section"
              }
            >
              {/* Commennt sorting section */}
              <div className="comment_card_container">
                {(comments || []).length > 0 ? (
                  <React.Fragment>
                    <div
                      className="comment_card_section"
                      onScroll={(e) => scrollHandler(e)}
                    >
                      {comments.map((data) => (
                        <PostCommentCard
                          key={data.id}
                          commentData={data}
                          owner={postData.u_dun}
                        />
                      ))}

                      {/* Loading button */}
                      {currentCommentCount < limit ? null : (
                        <div className="load_more_btn_container">
                          {isLoadingBtn ? (
                            <div className="loading">
                              <BiLoaderAlt className="spinner" />
                            </div>
                          ) : (
                            <button
                              className="load_more_btn"
                              onClick={incrementPage}
                            >
                              Load more
                            </button>
                          )}
                        </div>
                      )}
                    </div>
                  </React.Fragment>
                ) : (
                  <div className="empty_comment_card_section">
                    {t("No Comment found")}
                  </div>
                )}
              </div>
            </div>
          }
          footer={
            <div className="comment_modal_footer_section">
              <>
                {flag.includes(user.handleUn) ? (
                  <div className="flag_status_text">
                    You marked this post as flag so you cannot comment on this
                    post
                  </div>
                ) : (
                  <>
                    {!isVisble ? (
                      <div>
                        <input
                          type="text"
                          placeholder="Enter your comment"
                          className="comment_input"
                          onFocus={() => setIsVisble(true)}
                        />
                      </div>
                    ) : (
                      <div className="comment_form_section">
                        {/* Preview image */}
                        {prevImage && (
                          <div className="prev_image_section">
                            <img src={prevImage} className="prev_image_box" />
                            <button
                              className="close_btn_prev"
                              onClick={closeCmntImage}
                            >
                              <AiOutlineClose />
                            </button>
                          </div>
                        )}

                        <input
                          type="text"
                          placeholder="Enter your comment"
                          className="comment_input"
                          value={text}
                          onChange={(e) =>
                            setText(e.target.value.slice(0, 100))
                          }
                        />

                        <div className="post_footer_btn_sections">
                          <div>
                            {/* Gallery */}
                            <label
                              htmlFor="post_comment_file"
                              className="modal_file_icon"
                            >
                              <span class="icon-gallery"></span>
                            </label>
                            <input
                              type="file"
                              id="post_comment_file"
                              className="input_file"
                              onChange={(e) => handleImageChange(e)}
                            />

                            {/* Emoji */}
                            <button
                              className="modal_post_icons_button"
                              onClick={() => setOpenEmojiIcons(true)}
                            >
                              <span class="icon-emogy"></span>
                            </button>

                            {/* Gif */}
                            <button className="modal_post_icons_button">
                              <span class="icon-gif"></span>
                            </button>
                          </div>
                          {isBtnDisable ? null : (
                            <button
                              className="send_cmn_btn"
                              onClick={() => handlePostComment(postData.id)}
                            >
                              <AiOutlineSend
                                className={
                                  isBtnDisable ? "disable" : "not_disable"
                                }
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </>
            </div>
          }
        />
      )}

      {/* Donate crypto */}
      {openDonatModal && (
        <CustomModal
          title="Repost"
          onClose={onClose}
          body={
            <div className="modal_body">
              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="50"
                  checked={donateValue === "50"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="Public"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="50"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    50
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="50"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $50 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="100"
                  checked={donateValue === "100"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="Public"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="100"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    100
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="100"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $100 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="200"
                  checked={donateValue === "200"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="200"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="200"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    200
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="200"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $200 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="500"
                  checked={donateValue === "500"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="500"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="500"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    500
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="500"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $500 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="1000"
                  checked={donateValue === "1000"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="1000"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="1000"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    1000
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="1000"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $1000 to your favourite creator
                  </span>
                </div>
              </div>
            </div>
          }
          footer={
            donateBtnVisible && (
              <>
                <textarea
                  type="text"
                  placeholder="Send message"
                  value={custommessage}
                  className="edit_form_section_textarea"
                  onChange={(e) =>
                    setCustomMessage(e.target.value.slice(0, 50))
                  }
                ></textarea>
                <button className="donate_btn" onClick={handleDonatPost}>
                  Donate ${donateValue}{" "}
                </button>
              </>
            )
          }
        />
      )}

      {/* Post like modal */}
      {openLikeModal && (
        <CustomPostFormModal
          title={
            <div className="comment_modal_title_section">
              <div className="modal_comment_box">
                <button
                  className={`modal_header_btn ${theme}`}
                  onClick={closeCommentModal}
                >
                  <BiArrowBack />
                </button>
                <span className="modal_header_title">Post reactions</span>
              </div>
            </div>
          }
          onClose={onClose}
          body={
            <div className="user_list_container_section">
              {/* Tab */}
              <div className="modal_tab">
                <li
                  className={
                    likeType === "all"
                      ? `modal_list modal_list_active ${theme}`
                      : `modal_list ${theme}`
                  }
                  value="all"
                  onClick={(e) => setLikeType("all")}
                >
                  All {likeCount}
                </li>

                <li
                  className={
                    likeType === "likes"
                      ? `modal_list modal_list_active ${theme}`
                      : `modal_list ${theme}`
                  }
                  value="like"
                  onClick={(e) => setLikeType("likes")}
                >
                  Like
                </li>

                <li
                  className={
                    likeType === "haha"
                      ? `modal_list modal_list_active ${theme}`
                      : `modal_list ${theme}`
                  }
                  value="haha"
                  onClick={(e) => setLikeType("haha")}
                >
                  Haha
                </li>

                <li
                  className={
                    likeType === "angry"
                      ? `modal_list modal_list_active ${theme}`
                      : `modal_list ${theme}`
                  }
                  value="angry"
                  onClick={(e) => setLikeType("angry")}
                >
                  Angry
                </li>

                <li
                  className={
                    likeType === "dislikes"
                      ? `modal_list modal_list_active ${theme}`
                      : `modal_list ${theme}`
                  }
                  value="dislikes"
                  onClick={(e) => setLikeType("dislikes")}
                >
                  Dislikes
                </li>
              </div>
              <div className="user_modal_section">
                {(usersList || []).length > 0 ? (
                  <div className="__modal_user_list_container">
                    {usersList.map((data) => (
                      <div className="block_member_info_section">
                        <div
                          className="user_card_info_section"
                          onClick={() =>
                            redirectToProfile(data.record.bins.handleUn)
                          }
                        >
                          <img
                            src={data.record.bins.p_i || UserAvatar}
                            className="modal_user_card_image"
                            alt=""
                            srcSet=""
                          />
                          <div className="__user_card_details">
                            <span className="block_user_card_name">
                              {data.record.bins.fn} {data.record.bins.ln}
                            </span>
                            {(data.record.bins.badges || []).length > 0 && (
                              <>
                                {data.record.bins.badges[
                                  data.record.bins.badges.length - 1
                                ] === "claim_sub" ? (
                                  <BsFillPatchCheckFill className="subscriber_badge" />
                                ) : (
                                  <>
                                    {data.record.bins.badges[
                                      data.record.bins.badges.length - 1
                                    ] === "claim_cc" ? (
                                      <BsFillPatchCheckFill className="cc_badge" />
                                    ) : data.record.bins.badges[
                                        data.record.bins.badges.length - 1
                                      ] === "claim_verify" ? (
                                      <BsFillPatchCheckFill className="verify_badge" />
                                    ) : null}
                                  </>
                                )}
                              </>
                            )}
                            {/* Profile activity */}
                            {data.record.bins.handleUn !== user.handleUn && (
                              <>
                                {data.record.bins.activity_status ===
                                "active" ? (
                                  <div className="active_status"></div>
                                ) : null}
                              </>
                            )}
                            <br />
                            <span className="user_card_bio">
                              {data.record.bins.p_bio ||
                                "Sample test profile bio"}
                            </span>
                            <br />
                            <span className="user_card_location">
                              <BiLocationPlus /> {data.record.bins.city},
                              {data.record.bins.country}
                            </span>
                            <div>
                              <span className="user_card_follwers">
                                Followrs:{" "}
                                <span className="user_card_followers_count">
                                  {data.record.bins.flwr_c}
                                </span>
                              </span>
                              <span className="user_card_follwing">
                                Following:{" "}
                                <span className="user_card_following_count">
                                  {data.record.bins.flw_c}
                                </span>
                              </span>
                            </div>
                          </div>
                        </div>

                        {user.handleUn === data.record.bins.handleUn ? null : (
                          <button
                            className="user_card_flwr_btn"
                            onClick={() => PostHandleFollowUser(postData.u_dun)}
                          >
                            {" "}
                            {following.includes(postData.u_dun) ? (
                              <>Followed</>
                            ) : (
                              <>Following</>
                            )}
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <div className="empty_user_modal_list">No user found</div>
                )}
              </div>
            </div>
          }
        />
      )}

      {/* Post share modal */}
      {openShareModal && (
        <CustomPostFormModal
          title={
            <div className="comment_modal_title_section">
              <div className="modal_comment_box">
                <button
                  className={`modal_header_btn ${theme}`}
                  onClick={closeShareModal}
                >
                  <BiArrowBack />
                </button>
                <span className="modal_header_title">Post share</span>
              </div>
            </div>
          }
          body={
            <div className="share_model_body">
              <textarea
                type="text"
                placeholder={t("share_placeholder")}
                className="share_post_textarea"
                value={content}
                onChange={(e) => setContent(e.target.value.slice(0, 100))}
              ></textarea>
              <PostCard postData={selectPost} />
            </div>
          }
          footer={
            <button
              className="update_btn"
              onClick={handleRepostWithQuoteHandler}
            >
              Share
            </button>
          }
        />
      )}

      {openShareViaModal && (
        <CustomPostFormModal
          title={
            <div className="comment_modal_title_section">
              <div className="cmnt_header_box">
                <button
                  className="cmnt_modal_back_btn"
                  onClick={() => setOpenShareViaModal(false)}
                >
                  <BiArrowBack />
                </button>
                <span className="group_post_modal_title">Share Post</span>
              </div>
            </div>
          }
          body={
            <div>
              <div>
                <div
                  className="share_modal_section_one"
                  onClick={() => handleCopyURL(postData.p_id)}
                >
                  <span className="post_url">
                    http://localhost:3001/group/full/post/{postId}
                  </span>
                  <button className="copy_icon">
                    {isCopied ? (
                      <AiOutlineCheck className="copied" />
                    ) : (
                      <FaCopy />
                    )}
                  </button>
                </div>
                {/* http://localhost:3101/group/full/post/1700629996679718 */}
                <div className="share_modal_body_two">
                  <TwitterShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    className="Demo__some-network__share-button"
                  >
                    <XIcon size={32} round />
                  </TwitterShareButton>

                  <FacebookShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <FacebookMessengerShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>

                  <WhatsappShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          }
        />
      )}

      <div className={`__post_footer_count_section ${theme}`}>
        {/* Post likes count */}
        {/* <button
          id='like_user'
          className='__post_count'
          onClick={() => handleLikeUser(postData.id)}>
          <>
            {likeCount > 0 ? (
              <>
                {isPostLike && (
                  <AiFillHeart
                    id='like_user'
                    className='small_icon heart_small_icon'
                  />
                )}
                {isPostDisLike && (
                  <BsEmojiLaughingFill
                    id='like_user'
                    className='small_icon haha_small_icon'
                  />
                )}
                {isPostHaha && (
                  <AiFillDislike
                    id='like_user'
                    className='small_icon dislike_small_icon'
                  />
                )}
                <span className={`card_count ${theme}`} id='like_user'>
                  {likeCount}
                </span>
              </>
            ) : (
              <>Link: {likeCount}</>
            )}
          </>
        </button> */}
        {likeCount > 0 ? (
          <button
            id="like_user"
            className="__post_count"
            onClick={() => handleLikeUser(postData.id)}
          >
            <>
              {/* <>Like: {likeCount}</> */}
              {postData.like > 0 && (
                // <img src={Love} id='like' className='post_like_active' />
                <Love id="like" className="custom_icon" />
              )}
              {postData.dislike > 0 && (
                // <img src={Dislike} id="like" className="post_like_active" />
                <Dislike id="like" className="custom_icon" />
              )}
              {postData.haha > 0 && (
                <img src={Haha} id="like" className="post_like_active" />
              )}
              {postData.angry > 0 && (
                <img src={Angry} id="like" className="post_like_active" />
              )}
              {like.includes(postData.id) ||
              dislike.includes(postData.id) ||
              haha.includes(postData.id) ||
              angry.includes(postData.id) ? (
                <>You and {likeCount - 1} others</>
              ) : (
                <>{likeCount} react on this post</>
              )}
            </>
          </button>
        ) : (
          <button
            id="like_user"
            className="__post_count"
            onClick={() => handleLikeUser(postData.id)}
          >
            <>
              <>Like: {likeCount}</>
            </>
          </button>
        )}

        {/* Post comment  */}
        <div className="__post_count" id="comment">
          <span className={`count_text ${theme}`} id="comment">
            Comments:{" "}
          </span>
          <span className="card_count" id="comment">
            {commentCount}
          </span>
        </div>

        {/* Post views */}
        <div className="__post_count">
          <span className="__post_count">Views: </span>
          <span className="card_count">{postData.views || 0}</span>
        </div>

        {/* Post Earning */}
        <div className="__post_count">
          <span className="__post_count">Earning: </span>
          <span className="card_count">{intToString(postData.earn || 0)}</span>
        </div>
      </div>

      <div className="post_card_footer_section">
        {/* Post Like button */}
        {flag.includes(user.handleUn) ? (
          <button className="disable_post_footer_btn">
            {/* <AiFillHeart id='like' className='post_like_active like_icon' />{" "} */}
            {/* <img src={Love} id="like" className="post_like_active" /> */}
            <Love />
            {likeCount}
          </button>
        ) : (
          <div className="comment_card_footer_section">
            {openEmojiIcons && (
              <div className="card_emoji_container" ref={wrapperRef}>
                <EmojiLike
                  id={postData.id}
                  setQuery={setQuery}
                  clickHandler={likePost}
                />
              </div>
            )}
            <>
              {like.includes(postData.id) ? (
                <button
                  className="post_like_btn post_like"
                  onClick={() => handleRemoveLike(postData.id)}
                >
                  {/* <AiFillHeart
                    id='like'
                    className='post_like_active like_icon'
                  />{" "} */}
                  {/* <img src={Love} id="like" className="post_like_active" /> */}
                  <Love />

                  {likeCount}
                </button>
              ) : (
                <>
                  {dislike.includes(postData.id) ? (
                    <button
                      className="post_like_btn post_like"
                      onClick={() => handleRemoveLike(postData.id)}
                    >
                      {/* <AiFillDislike className='post_like_active dislike_icon' />{" "} */}
                      {/* <img
                        src={Dislike}
                        id="like"
                        className="post_like_active"
                      /> */}
                      <Dislike id="like" className="custom_icon" />
                      {likeCount}
                    </button>
                  ) : (
                    <>
                      {haha.includes(postData.id) ? (
                        <button
                          className="post_like_btn post_like"
                          onClick={() => handleRemoveLike(postData.id)}
                        >
                          {/* <BsEmojiLaughingFill className='post_like_active funny_icon' />{" "} */}
                          <img
                            src={Haha}
                            id="like"
                            className="post_like_active"
                          />
                          {likeCount}
                        </button>
                      ) : (
                        <>
                          {angry.includes(postData.id) ? (
                            <button
                              className="post_like_btn post_like"
                              onClick={() => handleRemoveLike(postData.id)}
                            >
                              {/* <BsEmojiAngryFill className='post_like_active angry_icon' />{" "} */}
                              <img
                                src={Angry}
                                id="like"
                                className="post_like_active"
                              />
                              {likeCount}
                            </button>
                          ) : (
                            <>
                              {sad.includes(postData.id) ? (
                                <button
                                  className="post_like_btn post_like"
                                  onClick={() => handleRemoveLike(postData.id)}
                                >
                                  <FaSadTear className="post_like_active angry_icon" />{" "}
                                  {likeCount}
                                </button>
                              ) : (
                                <>
                                  {fire.includes(postData.id) ? (
                                    <button
                                      className="post_like_btn post_like"
                                      onClick={() =>
                                        handleRemoveLike(postData.id)
                                      }
                                    >
                                      <FaFire className="post_like_active angry_icon" />{" "}
                                      {likeCount}
                                    </button>
                                  ) : (
                                    <>
                                      {upvote.includes(postData.id) ? (
                                        <button
                                          className="post_like_btn post_like"
                                          onClick={() =>
                                            handleRemoveLike(postData.id)
                                          }
                                        >
                                          <MdArrowUpward className="post_like_active angry_icon" />{" "}
                                          {likeCount}
                                        </button>
                                      ) : (
                                        <button
                                          className="post_like_btn post_like"
                                          onClick={() =>
                                            handleLikeEmoji(postData.id)
                                          }
                                        >
                                          <AiOutlineHeart id="like" />{" "}
                                          {likeCount}
                                        </button>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </>
          </div>
        )}

        {/* Post comment button */}
        <>
          {postData.type === "poll" ? (
            <>
              <button
                id="comment"
                className={`post_footer_btn post_comment_btn ${theme}`}
              >
                {/* <BiCommentDetail id="comment" /> */}
                <BiCommentDetail className="post_card_footer_icon cmnt_footer_icon" />{" "}
              </button>
            </>
          ) : (
            <>
              {postData.is_share ? (
                <button className={`post_footer_btn post_comment_btn ${theme}`}>
                  {/* <BiCommentDetail id="comment" /> */}
                  <BiCommentDetail className="post_card_footer_icon cmnt_footer_icon" />{" "}
                </button>
              ) : (
                <>
                  {postData.cmnt_prv === "all" ? (
                    <button
                      className={`post_footer_btn post_comment_btn ${theme}`}
                    >
                      {/* <BiCommentDetail id="comment" /> */}
                      <BiCommentDetail className="post_card_footer_icon cmnt_footer_icon" />{" "}
                    </button>
                  ) : null}
                </>
              )}
            </>
          )}
        </>

        {/* Share post button */}
        {flag.includes(user.handleUn) ? (
          <button className="disable_post_footer_btn">
            <AiOutlineShareAlt />
          </button>
        ) : (
          <Menu
            menuButton={
              <MenuButton
                className={`post_footer_btn post_comment_btn ${theme}`}
              >
                <AiOutlineShareAlt />
              </MenuButton>
            }
          >
            <MenuItem
              className={"group_post_card_menuItem"}
              onClick={() => handleRepost(postData.id)}
            >
              <>{t("Repost")}</>
            </MenuItem>

            <MenuItem
              className={"group_post_card_menuItem"}
              onClick={() => handleOpenShareModal(postData)}
            >
              {t("Quote")}
            </MenuItem>

            <MenuItem
              className={"group_post_card_menuItem"}
              onClick={() => handleShareViaModal(postData.id)}
            >
              {t("Share via")}
            </MenuItem>
          </Menu>
        )}

        {/* Post donation button */}
        {flag.includes(user.handleUn) ? (
          <button className="disable_post_footer_btn">
            <AiOutlineStar />
          </button>
        ) : (
          <>
            {postData.u_dun !== user.handleUn && (
              <button
                onClick={() =>
                  handleOpenDonateModal(e.target.id, postData.id, postData)
                }
                className={`post_footer_btn post_share_btn ${theme}`}
              >
                {/* <AiOutlineShareAlt id="share" /> */}
                <AiOutlineStar className="post_card_footer_icon" id="donate" />
              </button>
            )}
          </>
        )}

        {/* Post Flag Button */}
        {postData.u_dun !== user.handleUn && (
          <>
            {flag.includes(user.handleUn) ? (
              <button
                className={`post_footer_btn post_comment_btn ${theme}`}
                onClick={() =>
                  handleRedirectToTakeDownPost(postData.id, user.badges)
                }
              >
                <FaFlag className="post_flagged_icon" />
              </button>
            ) : (
              <button
                className={`post_footer_btn post_comment_btn ${theme}`}
                onClick={() => handleFalggedModal(postData.id)}
              >
                <FaRegFlag />
              </button>
            )}
          </>
        )}

        {/* Analytics button */}
        {postData.u_dun === user.handleUn && (
          <button
            onClick={() => navigate(`/post/analytics/${postData.id}`)}
            className={`post_footer_btn post_share_btn ${theme}`}
          >
            {/* <AiOutlineShareAlt id="share" /> */}
            <BsGraphDown className="post_card_footer_icon" id="analytics" />
          </button>
        )}
      </div>
    </div>
  );
};

export default NormalPostFooter;
