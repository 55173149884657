/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  createBlockPost,
  handleFetchBlockPosts,
  handleFetchBlockSubsPosts,
  pinnedBlockPost,
  bookmarkBlockPost,
  deleteBlockPost,
  editBlockPost,
  spamBlockPost,
  likePost,
  fetchLikedUser,
  createBlockPostComment,
  fetchComments,
  editBlockComment,
  hideBlockComment,
  deleteBlockComment,
  spamBlockComment,
  blockCommentLike,
  blockCommentRemoveLike,
  handleBlockPostCommentReply,
  handleBlockPostCommentReplyReply,
  fetchReplies,
  fetchRepliesReply,
  editBlockReply,
  spamBlockReply,
  likeBlockReply,
  dislikeBlockReply,
  DeleteBlockReply,
  createEvent,
  fetchBlockEvent,
  intersetEvent,
  notIntersetEvent,
  joinEvent,
  handleDeleteEvent,
  editEvent,
  blockEventFeed,
  fetchFullEvent,
  handleFetchFullPost,
  hideEvent,
  handleReportEvent,
  handleReportPost,
  handleDonatePost,
  handleFetchPostData,
  handleFetchSinglePostData,
  handleUpdateLikePost,
  handleRemoveLikePost,
  handleNewsUpvote,
  handleNewsDownvote,
  handleNewsFake,
  handleAnnouncementExcited,
  handleAnnouncementDisappointed,
  handleAnnouncementMisleading,
  handleFlagBlockPost,
  fetchUpvotedUser,
  fetchExcitedUser,
  handleSharePost,
  handleRepostPost,
} from "../../api/_blockPost/blockPostApi";

const groupPostSlice = createSlice({
  name: "groupPost",
  initialState: {
    posts: [],
    pinnedPost: null,
    events: [],
    currentEventCount: 0,
    spam: [],
    postData: null,
    comments: [],
    currentCommentCount: 0,
    replies: [],
    currentRepliesCount: 0,
  },
  reducers: {
    addPosts: (state, action) => {
      state.posts = action.payload;
    },
    prepandPosts: (state, action) => {
      state.posts = [action.payload, ...state.posts];
    },
    setPostData: (state, action) => {
      state.postData = action.payload;
    },
    putPost: (state, action) => {
      state.posts = [...state.posts, ...action.payload];
    },

    /**
     * @COMMENTS
     */
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    appendComments: (state, action) => {
      state.comments = [...state.comments, ...action.payload];
    },
    prepandComment: (state, action) => {
      console.log("action.payload ", action.payload);
      state.comments = [action.payload, ...state.comments];
    },
    setCurrentCommentCount: (state, action) => {
      state.currentCommentCount = action.payload;
    },
    setRemoveComments: (state, action) => {
      state.comments = [];
    },

    /**
     * @REPLIES
     */
    setReplies: (state, action) => {
      state.replies = action.payload;
    },
    appendReplies: (state, action) => {
      state.replies = [...state.replies, ...action.payload];
    },
    prepandReplies: (state, action) => {
      state.replies = [action.payload, ...state.replies];
    },
    setCurrentReplyCount: (state, action) => {
      state.currentRepliesCount = action.payload;
    },
    removeReplies: (state, action) => {
      state.replies = [];
    },

    /**
     * @EVENTS
     */
    setEvents: (state, action) => {
      state.events = action.payload;
    },
    addEvents: (state, action) => {
      state.events = [...state.events, ...action.payload];
      console.log("state.events:", state.events);
    },
    removeEvents: (state, action) => {
      state.events = [];
    },
    prepandEvent: (state, action) => {
      state.events = [action.payload, ...state.events];
    },
    setCurrentEventCount: (state, action) => {
      state.currentEventCount = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = groupPostSlice;

// Extract and export each action creator by name
export const {
  addPosts,
  putPost,
  prepandPosts,
  setComments,
  appendComments,
  prepandComment,
  setCurrentCommentCount,
  setRemoveComments,
  setReplies,
  appendReplies,
  prepandReplies,
  setCurrentReplyCount,
  removeReplies,
  setEvents,
  removeEvents,
  prepandEvent,
  setCurrentEventCount,
  addEvents,
} = groupPostSlice.actions;

export const handleCreatePost = (data) => async (dispatch) => {
  // console.log(data);
  const result = await createBlockPost(data);
  console.log("Result: ", result);
  try {
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBlockPosts = (data) => async (dispatch) => {
  const response = await handleFetchBlockPosts(data);
  try {
    return response.posts;
  } catch (error) {
    console.log(error);
  }
};

//
export const fetchSubsBlockPosts = (data) => async (dispatch) => {
  const response = await handleFetchBlockSubsPosts(data);
  try {
    return response.posts;
  } catch (error) {
    console.log(error);
  }
};

export const handlePinnedBlockPost = (data) => async (dispatch) => {
  const result = await pinnedBlockPost(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleBookmarkBlockPost = (data) => async (dispatch) => {
  const result = await bookmarkBlockPost(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleDeleteBlockPost = (data) => async (dispatch) => {
  const result = await deleteBlockPost(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleEditBlockPost = (data) => async (dispatch) => {
  const result = await editBlockPost(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleSpamBlockPost = (data) => async (dispatch) => {
  const result = await spamBlockPost(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleLikeBlockPost = (data) => async (dispatch) => {
  const result = await likePost(data);
  console.log("RESULT: >> ", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchLikedUser = (data) => async (dispatch) => {
  const result = await fetchLikedUser(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @BLOCK_POST_COMMENT
 */
export const handleCreateComment = (data) => async (dispatch) => {
  const result = await createBlockPostComment(data);
  try {
    return result.data;
  } catch (error) {
    console.log(error.message);
  }
};

export const handleFetchComments = (data) => async (dispatch) => {
  const result = await fetchComments(data);
  console.log(result.data);
  dispatch(setCurrentCommentCount(result.data.length));
  if (data.page === 1) {
    dispatch(setComments(result.data));
  } else {
    dispatch(appendComments(result.data));
  }
  return result.data;
};

export const handleEditComment = (data) => async (dispatch) => {
  const result = await editBlockComment(data);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleBlockCommentHide = (data) => async (dispatch) => {
  const result = await hideBlockComment(data);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleBlockCommentDelete = (data) => async (dispatch) => {
  // deleteBlockComment
  const result = await deleteBlockComment(data);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleBlockPostCommentSpam = (data) => async (dispatch) => {
  // deleteBlockComment
  const result = await spamBlockComment(data);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleCommentLike = (data) => async (dispatch) => {
  const result = await blockCommentLike(data);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleCommentRemoveLike = (data) => async (dispatch) => {
  console.log("Like Block post slice");
  const result = await blockCommentRemoveLike(data);
  console.log("RESULT of LIKE api: ", result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

/**
 * @BLOCK_POST_COMMENT_REPLY
 */

export const handleCreateBlockPostReply = (data) => async (dispatch) => {
  const result = await handleBlockPostCommentReply(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleCreateBlockPostReplyReply = (data) => async (dispatch) => {
  const result = await handleBlockPostCommentReplyReply(data);
  console.log(result);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchReplies = (data) => async (dispatch) => {
  const result = await fetchReplies(data);
  setCurrentReplyCount(result.data.length);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchRepliesReply = (data) => async (dispatch) => {
  const result = await fetchRepliesReply(data);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleEditBlockReply = (data) => async (dispatch) => {
  const result = await editBlockReply(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleSpamBlockReply = (data) => async (dispatch) => {
  const result = await spamBlockReply(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleLikBlockReply = (data) => async (dispatch) => {
  const result = await likeBlockReply(data);
  console.log("LIKE: ", result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleDislikBlockReply = (data) => async (dispatch) => {
  const result = await dislikeBlockReply(data);
  console.log("LIKE: ", result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleDeleteBlockReply = (data) => async (dispatch) => {
  const result = await DeleteBlockReply(data);
  console.log("LIKE: ", result);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

/**
 * @Event
 */

export const handleCreateEvent = (data) => async (dispatch) => {
  const result = await createEvent(data);
  try {
    return result;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchBlockEvent = (data) => async (dispatch) => {
  const result = await fetchBlockEvent(data);
  console.log("**", result.data);
  dispatch(setCurrentEventCount(result.data.length));
  if (data.page === 1) {
    dispatch(setEvents(result.data));
  } else {
    console.log("**", result.data);
    dispatch(addEvents(result.data));
  }
};

export const handleInterestEvent = (data) => async (dispatch) => {
  const result = await intersetEvent(data);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const handleNotInterestEvent = (data) => async (dispatch) => {
  const result = await notIntersetEvent(data);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const handleJoinEvent = (data) => async (dispatch) => {
  const result = await joinEvent(data);
  try {
    return result.data;
  } catch (error) {
    console.log("ERROR");
  }
};

export const deleteEvent = (data) => async (dispatch) => {
  console.log("DELETE EVENT");
  const result = await handleDeleteEvent(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const handleEditEvent = (data) => async (dispatch) => {
  const result = await editEvent(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const handleBlockEventFeed = (data) => async (dispatch) => {
  console.log("CAME HERE");
  const result = await blockEventFeed(data);
  try {
    dispatch(setCurrentEventCount(result.data.posts.length));
    if (data.page === 1) {
      dispatch(setEvents(result.data.posts));
    } else {
      console.log(">>>> Page is greater that 1");
      dispatch(addEvents(result));
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchEvent = (data) => async (dispatch) => {
  const result = await fetchFullEvent(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const fetchFullPost = (data) => async (dispatch) => {
  const result = await handleFetchFullPost(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const handleHideEvent = (data) => async (dispatch) => {
  const result = await hideEvent(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const reportEvent = (data) => async (dispatch) => {
  const result = await handleReportEvent(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const reportPost = (data) => async (dispatch) => {
  const result = await handleReportPost(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const updateDonatePost = (data) => async (dispatch) => {
  const result = await handleDonatePost(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const fetchPostData = (data) => async (dispatch) => {
  const result = await handleFetchPostData(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const fetchSinglePostData = (data) => async (dispatch) => {
  const result = await handleFetchSinglePostData(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const updateLikePost = (data) => async (dispatch) => {
  const postData = await handleUpdateLikePost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const removeBlockPostLike = (data) => async (dispatch) => {
  const postData = await handleRemoveLikePost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

// ********* NEWS POST ********* //

// *** Handle News UPVOTE
export const newsUpvote = (data) => async (dispatch) => {
  const result = await handleNewsUpvote(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

// *** Handle News Downvote
export const newsDownvote = (data) => async (dispatch) => {
  const result = await handleNewsDownvote(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

// *** Handle FAKE Downvote
export const newsFake = (data) => async (dispatch) => {
  const result = await handleNewsFake(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

// ********* Announcement POST ********* //

// *** Handle Announcement EXCITED
export const announcementExcited = (data) => async (dispatch) => {
  const result = await handleAnnouncementExcited(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

// *** Handle Announcement DISAPPOINTED
export const announcementDisappointed = (data) => async (dispatch) => {
  const result = await handleAnnouncementDisappointed(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

// *** Handle Announcement MISLEADING
export const announcementMisleading = (data) => async (dispatch) => {
  const result = await handleAnnouncementMisleading(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export const flagBlockPost = (data) => async (dispatch) => {
  const result = await handleFlagBlockPost(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export const handleFetchUpvotedUser = (data) => async (dispatch) => {
  const result = await fetchUpvotedUser(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export const HandleFetchExcitedUser = (data) => async (dispatch) => {
  const result = await fetchExcitedUser(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export const sharePost = (data) => async (dispatch) => {
  const result = await handleSharePost(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export const respost = (data) => async (dispatch) => {
  const result = await handleRepostPost(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

// Export the reducer, either as a default or named export
export default reducer;
