/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import UserAvatar from "../../Assets/userAvatar.webp";
import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import CustomModal from "../modal/CustomModal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AllIcon } from "../../Assets/Icons/all.svg";
import getPricePercentage from "../../utils/getPricePercentage";
import NormalPostCardFooter from "./NormalPostCardFooter";
import timeDifference from "../../utils/getCreateTime";
import { checkMention, checkCryptoMention } from "../../utils/checkMensionUser";
import { MdLocationOn } from "react-icons/md";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import {
  BsFillPinAngleFill,
  BsFillPlusCircleFill,
  BsFillCheckCircleFill,
  BsFillPatchCheckFill,
} from "react-icons/bs";
import "./PostCard.css";
import { BiTrendingDown } from "react-icons/bi";
import { MdOutlineTrendingUp } from "react-icons/md";

/**Toolkit */
import { useSelector, useDispatch } from "react-redux";
import { selectPosts } from "../../redux/_post/postSelectors";
import {
  selectUser,
  selectToken,
  selectFollowing,
  selectReport,
} from "../../redux/_user/userSelectors";
import {
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
  addReport,
  removeReport,
} from "../../redux/_user/userSlice";
import {
  updatePinnedPost,
  updateBookmarkPost,
  updateHidePost,
  setPinnedPost,
  updateSocialPost,
  updateDeletePost,
  updatePostSharePrivacy,
  updatePostCommentPrivacy,
  updateSharePost,
  handleFetchFullPost,
  setComments,
  reportPost,
} from "../../redux/_post/postSlice";
import {
  AiOutlinePushpin,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineShareAlt,
} from "react-icons/ai";
import {
  MdOutlineHideSource,
  MdOutlinePrivacyTip,
  MdVerified,
} from "react-icons/md";
import { BiBitcoin } from "react-icons/bi";
import { handleProfileVisitCount } from "../../redux/_user/userSlice";
import { ThemeContext } from "../Common/ThemeProvider";
import { BiRepost } from "react-icons/bi";
import { selectCoins } from "../../redux/_page/pageSelectors.js";
import { getCoinPrices, getCoinDetails } from "../../redux/_coin/coinSlice";
import { ReactComponent as Bookmark } from "../../Assets/postmenu/bookmark.svg";
import { ReactComponent as Report } from "../../Assets/postmenu/report.svg";
import { ReactComponent as Hide } from "../../Assets/postmenu/hide.svg";
import { ReactComponent as Embeded } from "../../Assets/postmenu/embeded.svg";
import { ReactComponent as Share } from "../../Assets/postmenu/share.svg";
import { ReactComponent as Pin } from "../../Assets/postmenu/pin.svg";
import { ReactComponent as Analytics } from "../../Assets/postmenu/analytics.svg";
import PostCardModal from "./PostCardModal.jsx";

const PostCard = ({ postData }) => {
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);
  const dispatch = useDispatch();
  const isToken = useSelector(selectToken);
  const user = useSelector(selectUser);
  const following = useSelector(selectFollowing);
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const [postId, setPostId] = React.useState("");
  const [post, setPost] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // Post pinned state
  const [openPinnedModal, setOpenPinnedModal] = React.useState(false);
  const [hasPinned, setHasPinned] = React.useState(postData.pinned || 0);
  // Post Delete state
  const [postDelete, setPostDelete] = React.useState(
    postData.isDelete || false
  );
  // Post bookmark state
  const [openBookModal, setOpenBookModal] = React.useState(false);
  // Post hide state
  const [openHideModal, setOpenHideModal] = React.useState(false); // Post Delete state
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [width, setWidth] = React.useState("");

  // Post edit modal
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectPost, setSelectPost] = React.useState(null);
  const [editText, setEditText] = React.useState("");
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [isToxic, setIsToxic] = React.useState(false);
  const [nftModal, setNftModal] = React.useState(false);
  const [openFullPostModal, setOpenFullPostModal] = React.useState(false);
  const [myComments, setMyComments] = React.useState([]);
  const [fullpostData, setFullPostData] = React.useState(null);
  const componentRef = React.createRef(null);
  const [openPrivacyMenu, setOpenPrivacyMenu] = React.useState(false);
  const [commentPrivacy, setCommentPrivacy] = React.useState(
    postData.cmnt_prv || "all"
  );
  const [sharePrivacy, setSharePrivacy] = React.useState(
    postData.shr_prv || "all"
  );

  // comment code
  const [cmntPage, setCmntPage] = React.useState(1);
  const [cmntLimit, setCmntLimit] = React.useState(5);
  const [isModalLoading, setIsModalLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [bookmark, setBookmark] = React.useState(postData.book || []);
  const [openReportModal, setOpenReportModal] = React.useState(false);
  const [reportType, setReportType] = React.useState();
  const [reportMsg, setReportMsg] = React.useState("");
  const [disableReportBtn, setDisableReportBtn] = React.useState(true);
  const [reposted, setReposted] = React.useState(postData.repost || []);
  const [coinPrices, setCoinPrices] = React.useState([]);
  const [coinDetails, setCoinDetails] = React.useState([]);
  const [hide, setHide] = React.useState(postData.hide || []);

  const dynamicStyles = {
    "--theme-color": themeColor,
    "--font-size": fontSize,
  };

  useSocket();
  // socket.emit("block message", result);

  const handleClick = (value, id, data) => {
    if (value === "pin") {
      setPostId(id);
      setOpenPinnedModal(true);
    } else if (value === "profile") {
      // console.log(postData);
      const arg = { isToken, postId: postData.id, id: postData.u_dun };
      if (postData.u_dun !== user.handleUn) {
        dispatch(handleProfileVisitCount(arg));
      }
      navigate(`/user/profile/${postData.u_dun}`);
    } else if (value === "book") {
      setPostId(id);
      setOpenBookModal(true);
    } else if (value === "hide") {
      setPostId(id);
      setOpenHideModal(true);
    } else if (value === "unhide") {
      setPostId(id);
      setOpenHideModal(true);
    } else if (value === "spam") {
      // handlePostSpamHandler(id)
    } else if (value === "share") {
      setPost(data);
      setOpenShareModal(true);
      setPostId(id);
    } else if (value === "comment") {
      setPostId(id);
      // fetchComments();
    } else if (value === "delete") {
      setPostId(id);
      setOpenDeleteModal(true);
    } else if (value === "analytics") {
      // alert(id);
      navigate(`/post/analytics/${id}`);
    } else if (value === "edit") {
      setPost(data);
      setOpenEditModal(true);
      setSelectPost(data);
      setEditText(data.content);
      setPostId(data.id);
    } else if (value === "likeList") {
      setOpenLikeList(true);
      setPostId(id);
      fetchUserLike();
    } else if (value === "visit") {
      navigate(`/user/profile/${data.u_dun}`);
    } else if (value === "post_body" || value === "share_post_body") {
      setOpenFullPostModal(true);
      fetchFullPost(id);
      window.location.hash = "#full-post";
    } else if (value === "nft") {
      setNftModal(true);
      setPostId(id);
    } else if (value === "privacy") {
      setOpenPrivacyMenu(true);
      setPostId(id);
    } else if (value === "report") {
      setOpenReportModal(true);
      setPostId(id);
    } else if (value === "original_post") {
      setOpenFullPostModal(true);
      fetchFullPost(data.o_p_id);
    } else if (value === "embed") {
      navigate(`main-frame-post/${id}`);
    }
  };

  const handleHashChange = () => {
    if (window.location.hash === "") {
      setOpenFullPostModal(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener("hashchange", handleHashChange);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  React.useEffect(() => {
    if (reportType) {
      setDisableReportBtn(false);
    } else {
      setDisableReportBtn(true);
    }
  }, [reportType]);

  React.useEffect(() => {
    setWidth(window.innerWidth);
    // console.log(window.innerWidth);
  }, [width]);

  const onClose = () => {
    setOpenPinnedModal(false);
    setOpenBookModal(false);
    setOpenHideModal(false);
    setOpenEditModal(false);
    setOpenDeleteModal(false);
    setOpenShareModal(false);
    setNftModal(false);
    setOpenPrivacyMenu(false);
    setOpenReportModal(false);
    setPostId("");
  };

  // *** Create post image for nft
  const createNft = () => {
    // exportComponentAsJPEG(componentRef);
    navigate(`/post/nft/${postId}`);
  };

  // *** Fetch full post with post comment
  const fetchFullPost = async (id) => {
    setIsModalLoading(true);
    const data = { id, cmntPage, cmntLimit, isToken };
    const result = await dispatch(handleFetchFullPost(data));
    console.log(result);
    setFullPostData(result.post);
    dispatch(setComments(result.comment));
    setIsModalLoading(false);
  };

  const myPostCardRef = React.useRef(null);
  console.log("user->>", user);

  React.useEffect(() => {
    if (postData.coin_id && postData.coin_id.length > 0) {
      postData.coin_id.map((id) => {
        // console.log(id);
        fetchChartData(id);
      });
    }
  }, [postData.id]);

  const fetchChartData = async (id) => {
    // setGraphLoading(true);
    const data = {
      id: id,
      timeSpan: "2",
    };
    const result = await dispatch(getCoinPrices(data));
    // console.log(result);
    setCoinDetails((prev) => [...prev, { id, price: result }]);
    // setCoinPrices(result);
  };

  const divRef = React.useRef(null);
  const checkScrollableX = () => {
    const divElement = divRef.current;
    // Check if the div is scrollable in x-axis
    if (divElement) {
      const isScrollable = div.scrollWidth > div.clientWidth;
      console.log("Div is scrollable in X-axis:", isScrollable);
    }
  };

  React.useEffect(() => {
    // Check if the div is scrollable in x-axis on initial render
    checkScrollableX();
    // Add event listener for window resize to re-check scrollability on resize
    window.addEventListener("resize", checkScrollableX);
    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkScrollableX);
    };
  }, []);

  return (
    <React.Fragment>
      {nftModal && (
        <CustomModal
          onClose={onClose}
          title={"Create nft"}
          body={t("Do you want to create nft for this post?")}
          footer={
            <div>
              <button className="update_btn" onClick={createNft}>
                {isLoading ? (
                  <ImSpinner2 className="spinner" />
                ) : (
                  <>{t("Make nft")}</>
                )}
              </button>
            </div>
          }
        />
      )}
      {/* Post pinned modal */}
      {openPinnedModal && (
        <PostCardModal
          openPinnedModal={openPinnedModal}
          setOpenPinnedModal={setOpenPinnedModal}
          setHasPinned={setHasPinned}
          hasPinned={hasPinned}
          type="pin"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Post bookmark modal */}
      {openBookModal && (
        <PostCardModal
          openBookModal={openBookModal}
          setOpenBookModal={setOpenBookModal}
          bookmark={bookmark}
          setBookmark={setBookmark}
          type="bookmark"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Post hide modal */}
      {openHideModal && (
        <PostCardModal
          openHideModal={openHideModal}
          setOpenHideModal={setOpenHideModal}
          hide={hide}
          setHide={setHide}
          handleUn={user.handleUn}
          type="hide"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Post Edit modal */}
      {openEditModal && (
        <PostCardModal
          openEditModal={openEditModal}
          setOpenEditModal={setOpenEditModal}
          editText={editText}
          setEditText={setEditText}
          type="edit"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Post delete modal */}
      {openDeleteModal && (
        <PostCardModal
          setPostDelete={setPostDelete}
          openDeleteModal={openDeleteModal}
          setOpenDeleteModal={setOpenDeleteModal}
          type="delete"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* post privacy modal */}
      {openPrivacyMenu && (
        <PostCardModal
          sharePrivacy={sharePrivacy}
          commentPrivacy={commentPrivacy}
          setCommentPrivacy={setCommentPrivacy}
          setSharePrivacy={setSharePrivacy}
          openPrivacyMenu={openPrivacyMenu}
          setOpenPrivacyMenu={setOpenPrivacyMenu}
          type="privacy"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Share modal */}
      {openShareModal && (
        <PostCardModal
          setOpenShareModal={setOpenShareModal}
          openShareModal={openShareModal}
          setOpenDeleteModal={setOpenDeleteModal}
          post={post}
          setPost={setPost}
          postData={postData}
          type="share"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Full post view */}
      {openFullPostModal && (
        <PostCardModal
          setOpenFullPostModal={setOpenFullPostModal}
          openFullPostModal={openFullPostModal}
          isModalLoading={isModalLoading}
          fullpostData={fullpostData}
          type="full-post"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {/* Report post modal */}
      {openReportModal && (
        <PostCardModal
          openReportModal={openReportModal}
          setOpenReportModal={setOpenReportModal}
          type="report"
          postId={postId}
          setPostId={setPostId}
        />
      )}
      {hide.includes(user.handleUn) ? (
        <div className="social_media_unhide_post_card">
          <div className="hide_text">{t("You hide this post")}</div>
          <div className="unhhide_btn_section">
            <button
              className="unhide_btn"
              id="unhide"
              onClick={(e) => handleClick(e.target.id, postData.id)}
            >
              {t("Unhide")}
            </button>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {!postDelete && (
            <>
              {postData.is_share ? (
                <div
                  ref={myPostCardRef}
                  className={
                    reposted.includes(user.handleUn)
                      ? `social_media_post_card reposted_post ${postData.postOf} ${theme}`
                      : `social_media_post_card ${postData.postOf} ${theme}`
                  }
                  id={postData.id}
                  onClick={(e) =>
                    handleClick(e.target.id, postData.id, postData)
                  }
                >
                  <div ref={componentRef} id={postData.id}>
                    <div className="social_post_main_header">
                      <div className="social_media_post_card_header">
                        <div className="profile_avatar_section">
                          <img
                            src={
                              postData.u_img
                                ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                                : UserAvatar
                            }
                            className="posted_user_avatar"
                            id="profile"
                          />

                          {user.handleUn !== postData.s_u_dun && (
                            <button
                              className="post_card_follow_btn"
                              onClick={() =>
                                PostHandleFollowUser(postData.s_u_dun)
                              }
                            >
                              {following.includes(postData.s_u_dun) ? (
                                <BsFillCheckCircleFill className="postcard_follow_icon" />
                              ) : (
                                <BsFillPlusCircleFill className="postcard_follow_icon" />
                              )}
                            </button>
                          )}
                        </div>
                        {/* Post creator personal info */}
                        <div className="post_creator_info">
                          <>
                            {/* creator name */}
                            <span className="creator_name" id="profile">
                              {width <= 506 && postData.s_u_fn.length > 9 ? (
                                <>{postData.s_u_fn.slice(0, 9) + ".."}</>
                              ) : (
                                <>{postData.s_u_fn}</>
                              )}{" "}
                              {width <= 506 && postData.s_u_ln.length > 5 ? (
                                <>{postData.s_u_ln.slice(0, 5) + "..."}</>
                              ) : (
                                <>{postData.s_u_ln}</>
                              )}
                            </span>
                            {/* creator username */}
                            <span className="creator_username">
                              @
                              {width <= 506 &&
                              postData.s_u_dun.length > 11 &&
                              postData.s_u_dun.length > 5 ? (
                                <>{postData.s_u_dun.slice(0, 11) + "..."}</>
                              ) : (
                                <>{postData.s_u_dun}</>
                              )}
                            </span>
                            {/* Post create time */}
                            <span className="creator_time">
                              {timeDifference(
                                new Date().getTime(),
                                Number(postData.c_t)
                              )}
                            </span>
                          </>
                          <div className="post_basic_info">
                            {/* post privacy section */}
                            <span className="post_privacy">
                              {postData.privacy === "Public" ? (
                                <AllIcon className="post_icon" />
                              ) : (
                                <>
                                  {postData.privacy === "follower" ? (
                                    <span class="icon-followers"></span>
                                  ) : (
                                    <span class="icon-only_me"></span>
                                  )}
                                </>
                              )}
                            </span>

                            {/* post paid promotion */}
                            <span className="post_paid_section">
                              {postData.isPaid === "true" && (
                                <span className="paid_promotion">
                                  Paid promotion with
                                  <Link
                                    to={`/group/${postData.blockId}`}
                                    className="cmpny_name"
                                  >
                                    {postData.cName}
                                  </Link>
                                </span>
                              )}
                            </span>
                            <br />
                            <span className="status_text">
                              {postData.statusText}
                            </span>
                          </div>
                          {postData.userLocation && (
                            <div className="post_user_location">
                              <MdLocationOn className="location_icon" />
                              {postData.userLocation}
                            </div>
                          )}
                          {/* post feelings */}
                          <div className="post_feelings">
                            {postData.feelingIcon}
                            <span className="post_feelings_text">
                              {postData.feeling}
                            </span>
                          </div>
                          {postData.city && postData.country && (
                            <div className="post_user_location">
                              <MdLocationOn className="location_icon" />
                              {postData.city}, {postData.country}
                            </div>
                          )}
                        </div>
                      </div>
                      <Menu
                        menuButton={
                          <MenuButton className={"social_post_menu_button"}>
                            <span class="icon-more"></span>
                          </MenuButton>
                        }
                      >
                        {/* Hide post menu button */}
                        {
                          <MenuItem
                            id="hide"
                            className={"social_post_menu_item"}
                          >
                            <div id="hide" className="menu_item_section">
                              {" "}
                              <MdOutlineHideSource
                                id="hide"
                                className="menu_item_icon"
                              />
                              {t("Hide")}
                            </div>
                          </MenuItem>
                        }

                        {/* View Original post */}
                        <MenuItem
                          id="original_post"
                          className={"social_post_menu_item"}
                        >
                          <div id="original_post" className="menu_item_section">
                            View Original post
                          </div>
                        </MenuItem>

                        {/* Delete share post */}
                        {postData.s_u_dun === user.handleUn && (
                          <MenuItem
                            id="delete"
                            className={"social_post_menu_item"}
                          >
                            <div id="delete" className="menu_item_section">
                              {" "}
                              <AiOutlineDelete
                                id="delete"
                                className="menu_item_icon"
                              />
                              {t("Delete")}
                            </div>
                          </MenuItem>
                        )}

                        {sharePrivacy === "all" ? (
                          <MenuItem
                            id="share"
                            className={"social_post_menu_item"}
                          >
                            <div id="share" className="menu_item_section">
                              <AiOutlineShareAlt
                                id="share"
                                className="menu_item_icon"
                              />
                              {t("Share")}
                            </div>
                          </MenuItem>
                        ) : (
                          <>
                            {postData.u_dun !== user.handleUn ? null : (
                              <MenuItem
                                id="share"
                                className={"social_post_menu_item"}
                              >
                                <div id="share" className="menu_item_section">
                                  <AiOutlineShareAlt
                                    id="share"
                                    className="menu_item_icon"
                                  />
                                  {t("Share")}
                                </div>
                              </MenuItem>
                            )}
                          </>
                        )}
                      </Menu>
                    </div>
                    <div className="post_card_body" id="share_post_body">
                      <span className="main_body_text">
                        {postData.s_content.split(" ").map((val, index) => (
                          <span
                            key={index}
                            className={val.includes("#") ? "trend_tag" : ""}
                          >
                            {val}{" "}
                          </span>
                        ))}
                      </span>
                    </div>

                    {/* Original post data */}
                    <div className="shared_post">
                      <div className="social_media_post_card_header">
                        <div className="profile_avatar_section">
                          <img
                            src={
                              postData.u_img
                                ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                                : UserAvatar
                            }
                            className="posted_user_avatar"
                            id="profile"
                          />

                          {user.handleUn !== postData.u_dun && (
                            <button
                              className="post_card_follow_btn"
                              onClick={() =>
                                dispatch(
                                  handleFollowUser({
                                    userId: postData.u_dun,
                                    isToken,
                                  })
                                )
                              }
                            >
                              {following.includes(postData.u_dun) ? (
                                <BsFillCheckCircleFill className="postcard_follow_icon" />
                              ) : (
                                <BsFillPlusCircleFill className="postcard_follow_icon" />
                              )}
                            </button>
                          )}
                        </div>
                        {/* Post creator personal info */}
                        <div className="post_creator_info">
                          <>
                            {/* creator name */}
                            <span className="creator_name" id="profile">
                              {width <= 506 && postData.u_fn.length > 9 ? (
                                <>{postData.u_fn.slice(0, 9) + ".."}</>
                              ) : (
                                <>{postData.u_fn}</>
                              )}{" "}
                              {width <= 506 && postData.u_ln.length > 5 ? (
                                <>{postData.u_ln.slice(0, 5) + "..."}</>
                              ) : (
                                <>{postData.u_ln}</>
                              )}
                            </span>
                            {/* Badge */}
                            {postData.badges === "claim_sub" ? (
                              <BsFillPatchCheckFill className="subscriber_badge" />
                            ) : (
                              <>
                                {postData.badges === "claim_cc" ? (
                                  <BsFillPatchCheckFill className="cc_badge" />
                                ) : null}
                              </>
                            )}
                            {/* creator username */}
                            <span className="creator_username">
                              @
                              {width <= 506 &&
                              postData.u_dun.length > 11 &&
                              postData.u_dun.length > 5 ? (
                                <>{postData.u_dun.slice(0, 11) + "..."}</>
                              ) : (
                                <>{postData.u_dun}</>
                              )}
                            </span>
                            {/* Post create time */}
                            <span className="creator_time">
                              {timeDifference(
                                new Date().getTime(),
                                Number(postData.c_t)
                              )}
                            </span>
                            {/* Post Trend */}
                            {postData.trend && (
                              <>
                                {postData.trend === "Bullish" ? (
                                  <span className="trend_post_tag bullish">
                                    <MdOutlineTrendingUp className="trend_icon" />{" "}
                                    Bullish
                                  </span>
                                ) : (
                                  <span className="trend_post_tag bearish">
                                    <BiTrendingDown className="trend_icon" />{" "}
                                    Bearish
                                  </span>
                                )}
                              </>
                            )}
                          </>
                          <div className="post_basic_info">
                            {/* post privacy section */}
                            <span className="post_privacy">
                              {postData.privacy === "Public" ? (
                                <AllIcon className="post_icon" />
                              ) : (
                                <>
                                  {postData.privacy === "follower" ? (
                                    <span class="icon-followers"></span>
                                  ) : (
                                    <span class="icon-only_me"></span>
                                  )}
                                </>
                              )}
                            </span>

                            {/* post paid promotion */}
                            <span className="post_paid_section">
                              {postData.isPaid === "true" && (
                                <span className="paid_promotion">
                                  Paid promotion with
                                  <Link
                                    to={`/group/${postData.blockId}`}
                                    className="cmpny_name"
                                  >
                                    {postData.cName}
                                  </Link>
                                </span>
                              )}
                            </span>
                            <br />
                            <span className="status_text">
                              {postData.statusText}
                            </span>
                          </div>
                          {postData.userLocation && (
                            <div className="post_user_location">
                              <MdLocationOn className="location_icon" />
                              {postData.userLocation}
                            </div>
                          )}
                          {/* post feelings */}
                          <div className="post_feelings">
                            {postData.feelingIcon}
                            <span className="post_feelings_text">
                              {postData.feeling}
                            </span>
                          </div>
                          {postData.city && postData.country && (
                            <div className="post_user_location">
                              <MdLocationOn className="location_icon" />
                              {postData.city}, {postData.country}
                            </div>
                          )}
                        </div>
                      </div>

                      {/* Post card body */}
                      {postData.isDisable ? (
                        <div className="post_card_body post_disable_body">
                          <div className="loader_stick"></div>
                          <div className="loader_stick"></div>
                        </div>
                      ) : (
                        <div className={"post_card_body"} id="post_body">
                          <span className="main_body_text" id="post_body">
                            {postData.content.split(" ").map((val, index) => (
                              <span
                                id="post_body"
                                key={index}
                                className={val.includes("#") ? "trend_tag" : ""}
                              >
                                {val.includes("@") ? (
                                  <Link
                                    to={`/user/profile/${checkMention(val)}`}
                                    className="mentions"
                                  >
                                    {" "}
                                    @{checkMention(val)}
                                  </Link>
                                ) : (
                                  <>
                                    {val.includes("$") ? (
                                      <Link
                                        to={`trending/posts/coin/${checkCryptoMention(
                                          val
                                        )}`}
                                        className="crypto_mentions"
                                      >
                                        {" "}
                                        {/* ${checkCryptoMention(val)} */}
                                      </Link>
                                    ) : (
                                      <>{val} </>
                                    )}
                                  </>
                                )}
                              </span>
                            ))}
                          </span>
                          <br />
                          {postData.image && (
                            <img
                              src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.image}`}
                              className="post_card__body_image"
                              id="post_body"
                            />
                          )}
                          {postData.gif && (
                            <img
                              src={postData.gif}
                              className="post_card__body_image"
                              id="post_body"
                            />
                          )}
                          {postData.video && (
                            // <img   id="post_body" />
                            <video
                              controls
                              autoPlay
                              className="post_card__body_image"
                            >
                              <source src={postData.video} />
                            </video>
                          )}
                        </div>
                      )}
                    </div>

                    {/* Post card footer */}
                    <NormalPostCardFooter
                      postData={postData}
                      setIsToxic={setIsToxic}
                      reposted={reposted}
                      setReposted={setReposted}
                    />
                  </div>
                </div>
              ) : (
                <>
                  {reposted.includes(user.handleUn) && (
                    <span>
                      <BiRepost /> Reposted
                    </span>
                  )}
                  <div
                    onTouchStart={() => setOpenFullPostModal(false)}
                    ref={myPostCardRef}
                    className={
                      reposted.includes(user.handleUn)
                        ? `social_media_post_card reposted_post ${postData.postOf} ${theme}`
                        : `social_media_post_card ${postData.postOf} ${theme}`
                    }
                    id={postData.id}
                    onClick={(e) =>
                      handleClick(e.target.id, postData.id, postData)
                    }
                  >
                    {/* Pinned post icon */}
                    {hasPinned > 0 && (
                      <div>
                        <BsFillPinAngleFill className="pinned_icon" />
                      </div>
                    )}
                    {/* Bookmark post */}
                    {bookmark.includes(user.handleUn) && (
                      <span class="icon-bookmark_one"></span>
                    )}

                    {/* If this is a share post than this part of of code will run */}

                    <div ref={componentRef} id={postData.id}>
                      {/* Post card header */}
                      <div className="social_post_main_header">
                        <div className="social_media_post_card_header">
                          <div className="profile_avatar_section">
                            <img
                              src={
                                postData.p_i
                                  ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.p_i}`
                                  : UserAvatar
                              }
                              className="posted_user_avatar"
                              id="profile"
                            />

                            {user.handleUn !== postData.u_dun && (
                              <button
                                className="post_card_follow_btn"
                                onClick={() =>
                                  dispatch(
                                    handleFollowUser({
                                      userId: postData.u_dun,
                                      isToken,
                                    })
                                  )
                                }
                              >
                                {following.includes(postData.u_dun) ? (
                                  <BsFillCheckCircleFill className="postcard_follow_icon" />
                                ) : (
                                  <BsFillPlusCircleFill className="postcard_follow_icon" />
                                )}
                              </button>
                            )}
                          </div>
                          {/* Post creator personal info */}
                          <div className="post_creator_info">
                            <>
                              {/* creator name */}
                              <span className="_creator_name" id="profile">
                                {width <= 506 && postData.u_fn.length > 9 ? (
                                  <>{postData.u_fn.slice(0, 9) + ".."}</>
                                ) : (
                                  <>{postData.u_fn}</>
                                )}{" "}
                                {width <= 506 && postData.u_ln.length > 5 ? (
                                  <>{postData.u_ln.slice(0, 5) + "..."}</>
                                ) : (
                                  <>{postData.u_ln}</>
                                )}
                              </span>
                              {/* Badge */}
                              {postData.badges === "claim_sub" ? (
                                <BsFillPatchCheckFill className="subscriber_badge" />
                              ) : (
                                <>
                                  {postData.badges === "claim_cc" ? (
                                    <BsFillPatchCheckFill className="cc_badge" />
                                  ) : null}
                                </>
                              )}
                              {/* creator username */}
                              <span className="_creator_username">
                                @
                                {width <= 506 &&
                                postData.u_dun.length > 11 &&
                                postData.u_dun.length > 5 ? (
                                  <>{postData.u_dun.slice(0, 11) + "..."}</>
                                ) : (
                                  <>{postData.u_dun}</>
                                )}
                              </span>
                              {/* Post create time */}
                              <span className="_creator_time">
                                {timeDifference(
                                  new Date().getTime(),
                                  Number(postData.c_t)
                                )}
                              </span>
                              {/* Post Trend */}
                              {postData.trend && (
                                <>
                                  {postData.trend === "Bullish" ? (
                                    <span className="trend_post_tag bullish">
                                      <MdOutlineTrendingUp className="trend_icon" />{" "}
                                      Bullish
                                    </span>
                                  ) : (
                                    <span className="trend_post_tag bearish">
                                      <BiTrendingDown className="trend_icon" />{" "}
                                      Bearish
                                    </span>
                                  )}
                                </>
                              )}
                            </>
                            <div className="post_basic_info">
                              {/* post privacy section */}
                              <span className="post_privacy">
                                {postData.privacy === "Public" ? (
                                  <AllIcon className="post_icon" />
                                ) : (
                                  <>
                                    {postData.privacy === "follower" ? (
                                      <span class="icon-followers"></span>
                                    ) : (
                                      <span class="icon-only_me"></span>
                                    )}
                                  </>
                                )}
                              </span>

                              {/* post paid promotion */}
                              <span className="post_paid_section">
                                {postData.isPaid === "true" && (
                                  <span className="paid_promotion">
                                    Paid promotion with
                                    <Link
                                      to={`/group/${
                                        postData.cmpLink.split("/")[
                                          postData.cmpLink.split("/").length - 1
                                        ]
                                      }`}
                                      className="cmpny_name"
                                    >
                                      {postData.cName}
                                    </Link>
                                  </span>
                                )}
                              </span>
                              <br />
                              <span className="status_text">
                                {postData.statusText}
                              </span>
                            </div>
                            {postData.userLocation && (
                              <div className="post_user_location">
                                <MdLocationOn className="location_icon" />
                                {postData.userLocation}
                              </div>
                            )}
                            {/* post feelings */}
                            <div className="post_feelings">
                              {postData.feelingIcon}
                              <span className="post_feelings_text">
                                {postData.feeling}
                              </span>
                            </div>
                            {postData.city && postData.country && (
                              <div className="post_user_location">
                                <MdLocationOn className="location_icon" />
                                {postData.city}, {postData.country}
                              </div>
                            )}
                          </div>
                        </div>

                        {!postData.is_share && (
                          <Menu
                            className={`${theme}`}
                            menuButton={
                              <MenuButton
                                className={`social_post_menu_button ${theme}`}
                              >
                                <span class="icon-more"></span>
                              </MenuButton>
                            }
                          >
                            {/* Pinned post menu button */}
                            {postData.u_dun === user.handleUn && (
                              <MenuItem
                                id="pin"
                                className={"social_post_menu_item"}
                              >
                                {hasPinned > 0 ? (
                                  <div id="pin" className="menu_item_section">
                                    <Pin
                                      id="pin"
                                      className="menu_item_icon custom_icon"
                                    />
                                    {t("Unpin")}
                                  </div>
                                ) : (
                                  <div id="pin" className="menu_item_section">
                                    {" "}
                                    <AiOutlinePushpin
                                      id="pin"
                                      className="menu_item_icon"
                                    />
                                    {t("Pin")}
                                  </div>
                                )}
                              </MenuItem>
                            )}
                            {/* Bookmark post menu button */}
                            {
                              <MenuItem
                                id="book"
                                className={"social_post_menu_item"}
                              >
                                {bookmark.includes(user.handleUn) ? (
                                  <div id="book" className="menu_item_section">
                                    {/* <BsBookmark
                                      id='book'
                                      className='menu_item_icon'
                                        /> */}
                                    <Bookmark
                                      id="book"
                                      className="menu_item_icon custom_icon"
                                    />
                                    {t("Remove")}
                                  </div>
                                ) : (
                                  <div id="book" className="menu_item_section">
                                    <Bookmark
                                      id="book"
                                      className="menu_item_icon custom_icon"
                                    />
                                    {t("Save")}
                                  </div>
                                )}
                              </MenuItem>
                            }
                            {/* Hide post menu button */}
                            {
                              <MenuItem
                                id="hide"
                                className={"social_post_menu_item"}
                              >
                                <div id="hide" className="menu_item_section">
                                  {" "}
                                  <Hide
                                    id="hide"
                                    className="menu_item_icon custom_icon"
                                  />
                                  {t("Hide")}
                                </div>
                              </MenuItem>
                            }
                            {/* Post analytics menu */}
                            {postData.u_dun === user.handleUn && (
                              <MenuItem
                                id="analytics"
                                className={"social_post_menu_item"}
                              >
                                <div
                                  id="analytics"
                                  className="menu_item_section"
                                >
                                  <Analytics
                                    id="analytics"
                                    className="menu_item_icon custom_icon"
                                  />
                                  {t("Analytics")}
                                </div>
                              </MenuItem>
                            )}

                            {/* Post edit menu */}
                            {postData.u_dun === user.handleUn && (
                              <MenuItem
                                id="edit"
                                className={"social_post_menu_item"}
                              >
                                <div id="edit" className="menu_item_section">
                                  <AiOutlineEdit
                                    id="book"
                                    className="menu_item_icon"
                                  />
                                  {t("Edit")}
                                </div>
                              </MenuItem>
                            )}

                            {/* Post nft menu */}
                            {postData.u_dun === user.handleUn && (
                              <MenuItem
                                id="nft"
                                className={"social_post_menu_item"}
                              >
                                <div id="nft" className="menu_item_section">
                                  {" "}
                                  <BiBitcoin
                                    id="nft"
                                    className="menu_item_icon"
                                  />
                                  {t("Make nft")}
                                </div>
                              </MenuItem>
                            )}

                            {/* Delete post menu */}
                            {postData.u_dun === user.handleUn && (
                              <MenuItem
                                id="delete"
                                className={"social_post_menu_item"}
                              >
                                <div id="delete" className="menu_item_section">
                                  {" "}
                                  <AiOutlineDelete
                                    id="delete"
                                    className="menu_item_icon"
                                  />
                                  {t("Delete")}
                                </div>
                              </MenuItem>
                            )}

                            {/* privacy settings menu */}
                            {postData.u_dun === user.handleUn && (
                              <MenuItem
                                id="privacy"
                                className={"social_post_menu_item"}
                              >
                                <div id="privacy" className="menu_item_section">
                                  {" "}
                                  <MdOutlinePrivacyTip
                                    id="privacy"
                                    className="menu_item_icon"
                                  />
                                  {t("Privacy")}
                                </div>
                              </MenuItem>
                            )}

                            {/* report settings menu */}
                            {postData.u_dun !== user.handleUn && (
                              <MenuItem
                                id="report"
                                className={"social_post_menu_item"}
                              >
                                <div id="report" className="menu_item_section">
                                  {" "}
                                  <Report
                                    id="report"
                                    className="menu_item_icon custom_icon"
                                  />
                                  {t("Report")}
                                </div>
                              </MenuItem>
                            )}

                            {/* Embed */}
                            <MenuItem
                              id="embed"
                              className={"social_post_menu_item"}
                            >
                              <div id="embed" className="menu_item_section">
                                {" "}
                                <Embeded
                                  id="embed"
                                  className="menu_item_icon custom_icon"
                                />
                                {t("Embed")}
                              </div>
                            </MenuItem>
                          </Menu>
                        )}
                      </div>

                      {/* Post card body */}
                      {postData.isDisable ? (
                        <div className="post_card_body post_disable_body">
                          <div className="loader_stick"></div>
                          <div className="loader_stick"></div>
                        </div>
                      ) : (
                        <div className={"post_card_body"} id="post_body">
                          <span className="main_body_text" id="post_body">
                            {postData.content.split(" ").map((val, index) => (
                              <span
                                id="post_body"
                                key={index}
                                className={val.includes("#") ? "trend_tag" : ""}
                              >
                                {val.includes("@") ? (
                                  <Link
                                    to={`/user/profile/${checkMention(val)}`}
                                    className="mentions"
                                  >
                                    {" "}
                                    @{checkMention(val)}
                                  </Link>
                                ) : (
                                  <>
                                    {val.includes("$") ? (
                                      <Link
                                        to={`trending/posts/coin/${checkCryptoMention(
                                          val
                                        )}`}
                                        className="crypto_mentions"
                                      >
                                        {" "}
                                        ${checkCryptoMention(val)}
                                        {/* ({coins[postData.coin_id]}){" "} */}
                                      </Link>
                                    ) : (
                                      <>{val} </>
                                    )}
                                  </>
                                )}
                              </span>
                            ))}
                          </span>
                          <br />
                          {postData.image && (
                            <img
                              src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.image}`}
                              className="post_card__body_image"
                              id="post_body"
                            />
                          )}
                          {postData.gif && (
                            <img
                              src={postData.gif}
                              className="post_card__body_image"
                              id="post_body"
                            />
                          )}
                          {postData.video && (
                            // <img   id="post_body" />
                            <video
                              controls
                              autoPlay
                              className="post_card__body_image"
                            >
                              <source src={postData.video} />
                            </video>
                          )}
                        </div>
                      )}
                    </div>

                    {(coinDetails || []).length > 0 && (
                      <div className="_coin_obj_section" ref={divRef}>
                        {coinDetails.map((data) => (
                          <>
                            {getPricePercentage(data.price) > 0 ? (
                              <div className="_coin_price_btn high_btn">
                                <span className="coin_symbol high">
                                  {data.id.toUpperCase()}
                                </span>
                                <span className="_coin_price high">
                                  {getPricePercentage(data.price)}%
                                </span>
                              </div>
                            ) : (
                              <div className="_coin_price_btn low_btn">
                                <span className="coin_symbol low">
                                  {data.id.toUpperCase()}
                                </span>
                                <span className="_coin_price low">
                                  {getPricePercentage(data.price)}%
                                </span>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    )}

                    {/* Post card footer */}
                    {postData.postOf === "np" ? (
                      <NormalPostCardFooter
                        postData={postData}
                        setIsToxic={setIsToxic}
                        reposted={reposted}
                        setReposted={setReposted}
                      />
                    ) : (
                      <>
                        {postData.postOf === "news" ? (
                          <NewsPostCardFooter postData={postData} />
                        ) : (
                          <>
                            {postData.postOf === "anc" ? (
                              <AnnouncementPostCardFooter postData={postData} />
                            ) : (
                              <InformationPostCardFooter postData={postData} />
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </>
              )}
            </>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default PostCard;
