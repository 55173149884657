/** @format */

import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectToken,
  selectUser,
  selectLike,
  selectDislikes,
} from "../../redux/_user/userSelectors";
import timeDifference from "../../utils/getCreateTime";
import { useSocket, socket } from "../../socket/socket";
import UserAvatar from "../../Assets/userAvatar.webp";
import "./MessageBody.css";
import { FaHeart, FaGripfire } from "react-icons/fa";
import { BsEmojiLaughingFill, BsEmojiDizzyFill } from "react-icons/bs";
import { AiFillDislike } from "react-icons/ai";
import {
  handleEmojiLikeMessage,
  handleDeleteMessage,
  updateMessage,
} from "../../redux/_blockcast/blockcastSlice";
import { MdMoreHoriz } from "react-icons/md";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useTranslation } from "react-i18next";
import CustomModal from "../modal/CustomModal";
import { GoReply } from "react-icons/go";

const MessageBody = ({ data, setOpenReply, setSelectMsgData }) => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);
  const [msgId, setMsgId] = React.useState(data.c_id);
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [like, setLike] = React.useState(data.like || []);
  const [haha, setHaha] = React.useState(data.haha || []);
  const [wow, setWow] = React.useState(data.wow || []);
  const [dislike, setDislike] = React.useState(data.dislike || []);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [text, setText] = React.useState(data.content);
  const [prevText, setPrevText] = React.useState(data.content);
  const [isDelete, setIsDelete] = React.useState(data.isDelete || false);
  const [disable, setDisable] = React.useState(false);
  const navigate = useNavigate();

  const navigateToProfile = (id) => {
    navigate(`/user/profile/${id}`);
  };

  const likeMessage = async (id, value) => {
    if (value === "like") {
      if (like.includes(user.handleUn)) {
        const temp = like;
        const arr = temp.filter((data) => data !== user.handleUn);
        setLike(arr);
      } else {
        setLike((prev) => [...prev, user.handleUn]);
      }
    } else if (value === "haha") {
      if (haha.includes(user.handleUn)) {
        const temp = haha;
        const arr = temp.filter((data) => data !== user.handleUn);
        setHaha(arr);
      } else {
        setHaha((prev) => [...prev, user.handleUn]);
      }
    } else if (value === "wow") {
      if (wow.includes(user.handleUn)) {
        const temp = wow;
        const arr = temp.filter((data) => data !== user.handleUn);
        setWow(arr);
      } else {
        setWow((prev) => [...prev, user.handleUn]);
      }
    } else if (value === "dislike") {
      if (like.includes(user.handleUn)) {
        const temp = dislike;
        const arr = temp.filter((data) => data !== user.handleUn);
        setDislike(arr);
      } else {
        setDislike((prev) => [...prev, user.handleUn]);
      }
    }
    setOpenEmoji(false);
    const data = { token, id, value };
    await dispatch(handleEmojiLikeMessage(data));
  };

  const onClose = () => {
    setOpenDeleteModal(false);
    setOpenEditModal(false);
    setText(data.content);
  };
  const deleteMessage = async () => {
    const data = { token, mid: msgId };
    const result = await dispatch(handleDeleteMessage(data));
    console.log("RESULT:", result);
    setIsDelete(true);
    setOpenDeleteModal(false);
  };

  React.useEffect(() => {
    if (!text.trim()) {
      setDisable(true);
    } else if (prevText === text) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [text]);

  const handleEditMessage = async () => {
    const data = { token, id: msgId, text };
    const result = await dispatch(updateMessage(data));
    setPrevText(text);
    setText("");
    setDisable(true);
    setOpenEditModal(false);
  };

  const handleReplyMessage = (data) => {
    setOpenReply(true);
    setSelectMsgData(data);
  };

  return (
    <React.Fragment>
      {openDeleteModal && (
        <CustomModal
          onClose={onClose}
          title={"Delete message"}
          body={t("Do you want to delete this message?")}
          footer={
            <div>
              <button
                className='message_modal_btn delete_message_modal_btn'
                onClick={deleteMessage}>
                <>{t("Delete")}</>
              </button>
            </div>
          }
        />
      )}

      {openEditModal && (
        <CustomModal
          onClose={onClose}
          title={"Edit message"}
          body={
            <input
              type='text'
              className='message_modal_input'
              placeholder='Update message'
              value={text}
              onChange={(e) => setText(e.target.value.slice(0, 300))}
            />
          }
          footer={
            <div>
              {disable ? (
                <button
                  className={"message_modal_btn disable_message_modal_btn"}>
                  <>{t("Edit")}</>
                </button>
              ) : (
                <button
                  className={"message_modal_btn"}
                  onClick={handleEditMessage}>
                  <>{t("Edit")}</>
                </button>
              )}
            </div>
          }
        />
      )}
      {!isDelete && (
        <React.Fragment>
          <div
            className={
              user.handleUn === data.c_u_dun
                ? "message_card same_sender"
                : "message_card __other_sender"
            }>
            {user.handleUn === data.c_u_dun ? (
              <div className='own_message_container'>
                <div className='same_sender_message_body_content_container'>
                  <div className='same_sender_message_body_content_container_inner'>
                    {data.isReply && (
                      <div className='message_reply_container'>
                        <span className='reply_text'>
                          {data.original_msg.message}
                        </span>
                        <div className='reply_prev_msg_box'>
                          {data.original_msg.m_u_fn} {data.original_msg.m_u_ln}
                        </div>
                      </div>
                    )}

                    {prevText}
                    {data.image.trim() && (
                      <img src={data.image} className='message_content_image' />
                    )}
                  </div>
                  <div className='msg_timestamp'>
                    {timeDifference(new Date().getTime(), Number(data.c_t))}
                  </div>
                </div>
                <Menu
                  menuButton={
                    <MenuButton className='menu_btn_message'>
                      <MdMoreHoriz />
                    </MenuButton>
                  }>
                  <MenuItem
                    className={"message_menu_item"}
                    onClick={() => setOpenEditModal(true)}>
                    {t("Edit")}
                  </MenuItem>
                  <MenuItem
                    className={"message_menu_item message_menu_item_delete"}
                    onClick={() => setOpenDeleteModal(true)}>
                    {t("Delete")}
                  </MenuItem>
                </Menu>
              </div>
            ) : (
              <div className='other_sender_message_body_content_container'>
                <img
                  src={data.c_u_img || UserAvatar}
                  className='message_sender_avatar'
                  onClick={() => navigateToProfile(data.c_u_dun)}
                />
                <div className='message_recive_box'>
                  <div
                    className='message_sender_name'
                    onClick={() => navigateToProfile(data.c_u_dun)}>
                    {data.c_u_fn} {data.c_u_ln}
                  </div>

                  <div className='message_sender_body'>
                    {data.content}
                    {data.image.trim() && (
                      <img src={data.image} className='message_content_image' />
                    )}

                    {openEmoji && (
                      <div className='message_emoji_container'>
                        {/* Heart */}
                        <button
                          className='message_emoji_icon_btn'
                          onClick={() => likeMessage(data.c_id, "like")}>
                          <FaHeart className='message_heart' />
                        </button>

                        {/* Funny */}
                        <button
                          className='message_emoji_icon_btn'
                          onClick={() => likeMessage(data.c_id, "haha")}>
                          <BsEmojiLaughingFill className='message_funny' />
                        </button>

                        {/* Wow */}
                        <button
                          className='message_emoji_icon_btn'
                          onClick={() => likeMessage(data.c_id, "wow")}>
                          <BsEmojiDizzyFill className='message_wow' />
                        </button>

                        {/* dislikes */}
                        <button
                          className='message_emoji_icon_btn'
                          onClick={() => likeMessage(data.c_id, "dislike")}>
                          <AiFillDislike className='message_dilike' />
                        </button>
                      </div>
                    )}

                    <div className='show_emoji_container'>
                      {like.includes(user.handleUn) && (
                        <FaHeart className='message_heart' />
                      )}
                      {haha.includes(user.handleUn) && (
                        <BsEmojiLaughingFill className='message_funny' />
                      )}
                      {wow.includes(user.handleUn) && (
                        <BsEmojiDizzyFill className='message_wow' />
                      )}
                      {dislike.includes(user.handleUn) && (
                        <AiFillDislike className='message_dilike' />
                      )}
                    </div>
                  </div>

                  <div
                    className='msg_timestamp'
                    onClick={() => handleReplyMessage(data)}>
                    {timeDifference(new Date().getTime(), Number(data.c_t))}
                    <span className='reply_icon'>
                      <GoReply />
                    </span>
                  </div>
                </div>
                <div
                  className='message_emoji_btn'
                  onClick={() => setOpenEmoji((p) => !p)}>
                  <FaHeart />
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default MessageBody;
