/** @format */

import { BASE_URL } from "../constants";
import { GETWITHOUTTOKEN } from "../index";

export const handleGetCoinPrice = async (data) => {
  const endpoint = `api/coin/price/details/${data.id}?timeSpan=${data.timeSpan}`;
  const response = await GETWITHOUTTOKEN(BASE_URL, endpoint);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGetCoinDetails = async (data) => {
  const endpoint = `api/coin/details/${data.id}`;
  const response = await GETWITHOUTTOKEN(BASE_URL, endpoint);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGetCoinList = async (data) => {
  const endpoint = `api/coin/?page=${data.page}&limit=${data.limit}`;
  const response = await GETWITHOUTTOKEN(BASE_URL, endpoint);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGettrendingCoinDetails = async (data) => {
  const endpoint = `api/coin/trending-details/${data.id}`;
  console.log(endpoint);
  const response = await GETWITHOUTTOKEN(BASE_URL, endpoint);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};
