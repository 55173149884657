/** @format */

import { BASE_URL } from "../constants";
import { POST, GET, PUT } from "../index";

export const getNotifications = async (data) => {
  const ENDPOINT = `api/notifications?page=${data.page}&limit=${data.limit}&type=${data.type}`;
  console.log("ENDPOINT:", ENDPOINT);
  return handleGetNotifications(ENDPOINT, data.isToken);
};

const handleGetNotifications = async (endpoint, token) => {
  try {
    const response = await GET(BASE_URL, endpoint, token);
    return response.data;
  } catch (error) {
    console.log("Error in notifications");
  }
};

export const getPosts = async (data) => {
  try {
    const ENDPOINT = `api/notifications/post/details/${data.id}`;
    const response = await GET(BASE_URL, ENDPOINT, data.token);
    return response.data;
  } catch (error) {
    console.log("Error in notifications");
  }
};
