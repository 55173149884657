/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import UserAvatar from "../../Assets/userAvatar.webp";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  BsPinAngleFill,
  BsEmojiLaughingFill,
  BsEmojiAngryFill,
  BsFillPatchCheckFill,
} from "react-icons/bs";
import CustomSmallModal from "../modal/CustomSmallModal";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
  AiFillHeart,
  AiOutlineHeart,
} from "react-icons/ai";
import { HiReply } from "react-icons/hi";
import { RiSpam2Line, RiSpam2Fill } from "react-icons/ri";
import ReplyForm from "../CommentReplyComponent/ReplyForm";
import CommentReply from "../CommentReplyComponent/CommentReply";

import timeDifference from "../../utils/getCreateTime";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import { BsPencil } from "react-icons/bs";
import EmojiLike from "../EmojiLike/EmojiLike";
import "./PostCommentCard.css";

/**Toolkit imports */
import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  selectToken,
  selectcommentSpam,
  selectLike,
  selectAngry,
  selectHaha,
  selectDislikes,
  selectFollowing,
} from "../../redux/_user/userSelectors";
import {
  addcommentSpam,
  removecommentSpam,
  addLike,
  removeLike,
  addAngry,
  removeAngry,
  addHAHA,
  removeHaha,
  addDislikes,
  removeDislikes,
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
} from "../../redux/_user/userSlice";
import { selectReply } from "../../redux/_post/postSelectors";

import {
  updatePinnedComment,
  setUpdateComment,
  handleCommentEditComment,
  handlePostCommentDelete,
  handlePostCommentSpam,
  handleCommentLike,
  handleCommentRemoveLike,
  appendReply,
  removeReply,
  handleFetchReplies,
} from "../../redux/_post/postSlice";
import {
  BsFillPinAngleFill,
  BsFillPlusCircleFill,
  BsFillCheckCircleFill,
} from "react-icons/bs";
import { checkMention, checkCryptoMention } from "../../utils/checkMensionUser";
import { setCoinDetails } from "../../redux/_page/pageSlice";
import { selectCoins } from "../../redux/_page/pageSelectors.js";

const PostCommentCard = ({ commentData, setCmntPage, owner }) => {
  // console.log(commentData);
  useSocket();
  const { t } = useTranslation(["common"]);
  // Tookit code start
  const dispatch = useDispatch();
  const following = useSelector(selectFollowing);
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const commentSpam = useSelector(selectcommentSpam);
  const like = useSelector(selectLike);
  const angry = useSelector(selectAngry);
  const haha = useSelector(selectHaha);
  const dislike = useSelector(selectDislikes);
  const replies = useSelector(selectReply);
  const coins = useSelector(selectCoins);

  // Toolkit end here....
  const [openPinModal, setOpenPinModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [text, setText] = React.useState("");
  const [cmntId, setCmntId] = React.useState("");
  const [cmntPin, setCmntPin] = React.useState(commentData.pinn);
  const [cmntDelete, setCmntDelete] = React.useState(commentData.delete);
  const [comment, setComment] = React.useState(commentData.comment);
  const [likeCount, setLikeCount] = React.useState(commentData.l_c);
  const [dislikeCount, setDisikeCount] = React.useState(commentData.d_c);
  const [spamCount, setSpamCount] = React.useState(commentData.s_c);
  const [replyCount, setReplyCount] = React.useState(commentData.reply_c);
  const [visibleReply, setVisibleReply] = React.useState(false);
  const [isActive, setIsActive] = React.useState(false);
  const [isEditBtnDisable, setIsEditBtnDisable] = React.useState(true);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [cmntReplies, setCmntReplies] = React.useState([]);
  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [ownerName, setOwnerName] = React.useState("");
  const [openReplyForm, setOpenReplyForm] = React.useState(false);
  const [openReplyBox, setOpenReplyBox] = React.useState(false);

  // *** Handle close modal
  const closeModal = () => {
    setCmntId("");
    setOpenDeleteModal(false);
    setOpenEditModal(false);
    setOpenPinModal(false);
  };

  // *** Handle pinn and unpinn modal
  const handlePinnedModal = (id) => {
    setCmntId(id);
    setOpenPinModal(true);
  };
  const handlePinnComment = async () => {
    const data = { token, cmntId };
    const commentData = await dispatch(updatePinnedComment(data));
    if (commentData.notificationData) {
      socket.emit("notification receive", commentData);
    }
    try {
      dispatch(setUpdateComment(commentData));
      setCmntPin((prev) => !prev);
      setOpenPinModal(false);
    } catch (error) {
      console.log("Error");
    }
  };

  // *** Handle edit modal
  const handleEditModal = (data) => {
    setCmntId(data.id);
    setText(data.comment);
    setOpenEditModal(true);
  };
  // *** Handle delete comment
  const handleEditComment = async () => {
    const data = { token, cmntId, text };
    const commentData = await dispatch(handleCommentEditComment(data));
    try {
      setComment(text);
      setOpenEditModal(false);
    } catch (error) {
      console.log(error);
    }
  };

  // *** Handle delete modal
  const handleDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setCmntId(id);
  };
  // *** Handle delete comment
  const handleDeleteComment = async () => {
    const data = { token, cmntId };
    await dispatch(handlePostCommentDelete(data));
    try {
      setOpenDeleteModal(false);
      setCmntDelete(true);
    } catch (error) {
      console.log(error);
    }
  };

  // *** Handle comment spam
  const handleCommentSpam = (id) => {
    console.log(id);
    if (commentSpam.includes(id)) {
      console.log("Already have");
      dispatch(removecommentSpam(id));
      setSpamCount((prev) => prev - 1);
    } else {
      console.log("Not have");
      dispatch(addcommentSpam(id));
      setSpamCount((prev) => prev + 1);
    }
    const data = { id, token };
    dispatch(handlePostCommentSpam(data));
  };

  const handleCommentReplyBox = (id) => {
    if (!openReplyBox) {
      setOpenReplyBox(true);
      setCmntId(id);
      setOpenReplyForm(false);
    } else {
      setOpenReplyForm(false);
      setOpenReplyBox(false);
      setCmntId("");
      dispatch(removeReply());
      setPage(1);
    }
  };

  // *** Handle comment reply
  const handleCommentReply = (id) => {
    if (!openReplyForm) {
      setOpenReplyForm(true);
      setOpenReplyBox(true);
      setCmntId(id);
    } else {
      setOpenReplyForm(false);
      setOpenReplyBox(false);
      setCmntId("");
      dispatch(removeReply());
      setPage(1);
    }
  };

  // *** Fetch comment reply
  const fetchCommentReply = (id) => {
    console.log("fetch reply");
    const data = { id, token, page, limit };
    dispatch(handleFetchReplies(data));
  };

  React.useEffect(() => {
    if (cmntId && openReplyBox) {
      fetchCommentReply(cmntId);
    }
  }, [page, cmntId]);

  const handleShowReply = (id) => {
    setVisibleReply(true);
    setCmntId(id);
    // fetchCommentReply(id)
  };

  const handleHideReply = (id) => {
    setVisibleReply(false);
    setCmntId("");
    if (!visibleReply) {
      // removeAllReply([]);
    }
  };

  React.useEffect(() => {
    if (!text.trim()) {
      setIsEditBtnDisable(true);
    } else {
      setIsEditBtnDisable(false);
    }
  }, [text]);

  const handleLikeEmoji = (id, username) => {
    setOpenEmojiIcons(true);
    setCmntId(id);
    setOwnerName(username);
  };

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenEmojiIcons(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  // *** Like post comment
  const likeComment = async (value, id) => {
    const data = { value, id, ownerName, likeCount, token };
    // console.log(data);
    if (value === "like") {
      if (likeCount === 0) {
        setLikeCount((prev) => prev + 1);
        dispatch(addLike(id));
      } else {
        if (!like.includes(id)) {
          dispatch(addLike(id));
          setLikeCount((prev) => prev + 1);
          dispatch(removeAngry(id));
          dispatch(removeHaha(id));
          dispatch(removeDislikes(id));
        } else {
          setLikeCount((prev) => prev - 1);
          dispatch(removeLike(id));
        }
      }
    } else if (value === "dislike") {
      if (likeCount === 0) {
        setLikeCount((prev) => prev + 1);
        dispatch(addDislikes(id));
      } else {
        console.log(">>>>>>> ", dislike.includes(id));
        if (!dislike.includes(id)) {
          dispatch(removeLike(id));
          dispatch(addDislikes(id));
          setLikeCount((prev) => prev + 1);
          dispatch(removeAngry(id));
          dispatch(removeHaha(id));
        } else {
          console.log("*****");
          setLikeCount((prev) => prev - 1);
          dispatch(removeDislikes(id));
        }
      }
    } else if (value === "haha") {
      if (likeCount === 0) {
        setLikeCount((prev) => prev + 1);
        dispatch(addHAHA(id));
      } else {
        if (!haha.includes(id)) {
          dispatch(removeLike(id));
          dispatch(removeDislikes(id));
          dispatch(addHAHA(id));
          setLikeCount((prev) => prev + 1);
          dispatch(removeAngry(id));
        } else {
          setLikeCount((prev) => prev - 1);
          dispatch(addHAHA(id));
        }
      }
    } else if (value === "angry") {
      if (likeCount === 0) {
        dispatch(addAngry(id));
        setLikeCount((prev) => prev + 1);
      } else {
        if (!angry.includes(id)) {
          dispatch(removeLike(id));
          dispatch(removeDislikes(id));
          dispatch(removeHaha(id));
          dispatch(addAngry(id));
          setLikeCount((prev) => prev + 1);
        } else {
          dispatch(addAngry(id));
          setLikeCount((prev) => prev + 1);
        }
      }
    }
    const responseData = await dispatch(handleCommentLike(data));

    console.log(responseData);
    // Here we notify the user
    if (!responseData.notificationData) {
      console.log("OWN POST");
      setOpenEmojiIcons(false);
    } else {
      console.log("Notification send: ", responseData.notificationData);
      setOpenEmojiIcons(false);
      socket.emit("notification receive", responseData);
    }
  };

  // **** Remove like post comment
  const handleRemoveLike = (id, username) => {
    dispatch(removeLike(id));
    dispatch(removeAngry(id));
    dispatch(removeHaha(id));
    dispatch(removeDislikes(id));
    setLikeCount((prev) => prev - 1);
    const data = { token, id };
    dispatch(handleCommentRemoveLike(data));
  };

  const PostHandleFollowUser = (userId) => {
    // alert(cardUsername);
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { isToken: token, userId: userId };
    dispatch(handleFollowUser(data));
  };

  React.useEffect(() => {
    if (!commentData.delete && commentData.coin_id) {
      const data = { [commentData.coin_id]: commentData.price };
      dispatch(setCoinDetails(data));
    }
  }, [commentData.coin_id]);

  return (
    <React.Fragment>
      {!cmntDelete && (
        <div
          className={
            replyCount > 0
              ? "comment_and_replies_container has_replies"
              : "comment_and_replies_container has_no_reply"
          }>
          <div
            className={
              owner === commentData.c_u_du
                ? "post_comment_card owner_card"
                : commentData.c_u_du === user.handleUn
                ? "post_comment_card my_post_comment_card"
                : "post_comment_card"
            }>
            <div className='post_comment_card_inner'>
              {/* Comment pinn unpinn modal */}
              {openPinModal && (
                <CustomSmallModal
                  onClose={closeModal}
                  title={
                    !commentData.pinn ? (
                      <>{t("Pinn comment")}</>
                    ) : (
                      <>{t("Unpinn comment")}</>
                    )
                  }
                  body={
                    !commentData.pinn ? (
                      <>{t("Do you want to pinn comment?")}</>
                    ) : (
                      <>{t("Do you want to unpinn comment?")}</>
                    )
                  }
                  footer={
                    <button className='update_btn' onClick={handlePinnComment}>
                      {!commentData.pinn ? (
                        <>{t("Pinn")}</>
                      ) : (
                        <>{t("Unpinn")}</>
                      )}
                    </button>
                  }
                />
              )}

              {/* Comment pinn unpinn modal */}
              {openEditModal && (
                <CustomSmallModal
                  onClose={closeModal}
                  title={
                    !commentData.pinn ? (
                      <>{t("Pinn comment")}</>
                    ) : (
                      <>{t("Unpinn comment")}</>
                    )
                  }
                  body={
                    <>
                      <textarea
                        type='text'
                        className='edit_textarea_input'
                        placeholder='Change your comment'
                        value={text}
                        onChange={(e) => setText(e.target.value.slice(0, 100))}
                      />
                    </>
                  }
                  footer={
                    !isEditBtnDisable && (
                      <button
                        className='update_btn'
                        onClick={handleEditComment}>
                        <>{t("Update")}</>
                      </button>
                    )
                  }
                />
              )}

              {/* Comment delete modal */}
              {openDeleteModal && (
                <CustomSmallModal
                  onClose={closeModal}
                  title={<>{t("Delete comment")}</>}
                  body={<>{t("Do you want to delete this comment?")}</>}
                  footer={
                    <button
                      className='update_btn delete_btn'
                      onClick={handleDeleteComment}>
                      {<>{t("Delete")}</>}
                    </button>
                  }
                />
              )}

              {/* Pinned comment status */}
              {cmntPin ? <BsPinAngleFill className='cmnt_pin_icon' /> : null}

              {/* Post comment card */}
              <div className='post_comment_card_header'>
                <div className='post_comment_user'>
                  <div className='profile_avatar_section'>
                    <img
                      src={
                        commentData.c_u_img
                          ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${commentData.c_u_img}`
                          : UserAvatar
                      }
                      className='comment_avatar'
                    />

                    {user.handleUn !== commentData.c_u_du && (
                      <button
                        className='post_card_follow_btn'
                        onClick={() =>
                          PostHandleFollowUser(commentData.c_u_du)
                        }>
                        {following.includes(commentData.c_u_du) ? (
                          <BsFillCheckCircleFill className='postcard_follow_icon' />
                        ) : (
                          <BsFillPlusCircleFill className='postcard_follow_icon' />
                        )}
                      </button>
                    )}
                  </div>
                  <div>
                    <Link
                      to={`/user/profile/${commentData.c_u_du}`}
                      className='comment_user_name'>
                      {commentData.c_fn} {commentData.c_ln}
                    </Link>
                    {/* Badge */}
                    {commentData.badge === "claim_sub" ? (
                      <BsFillPatchCheckFill className='subscriber_badge' />
                    ) : (
                      <>
                        {commentData.badge === "claim_cc" ? (
                          <BsFillPatchCheckFill className='cc_badge' />
                        ) : null}
                      </>
                    )}
                    <span className='comment_user_username'>
                      @{commentData.c_u_du}
                    </span>
                    {owner === commentData.c_u_du && (
                      <span className='owner_icon'>
                        <BsPencil />
                      </span>
                    )}
                    <div className='comment_time'>
                      {timeDifference(
                        new Date().getTime(),
                        Number(commentData.c_t)
                      )}
                    </div>
                  </div>
                </div>
                <Menu
                  menuButton={
                    <MenuButton className={"post_menu_button"}>
                      <FiMoreHorizontal />
                    </MenuButton>
                  }>
                  {/* Pinned Comment menu */}
                  {
                    // user.handleUn === selectedPost.u_dun &&
                    <MenuItem
                      onClick={(e) => handlePinnedModal(commentData.id)}
                      className={"post_card_menuitem"}>
                      {!commentData.pinn ? (
                        <>{t("Pinned")}</>
                      ) : (
                        <>{t("Unpinned")}</>
                      )}
                    </MenuItem>
                  }

                  {/* Edit comment menu */}
                  {user.handleUn === commentData.c_u_du && (
                    <MenuItem
                      className={"post_card_menuitem"}
                      onClick={(e) => handleEditModal(commentData)}>
                      {t("Edit")}
                    </MenuItem>
                  )}

                  {/* Delete comment menu */}
                  {user.handleUn === commentData.c_u_du && (
                    <MenuItem
                      onClick={() => handleDeleteModal(commentData.id)}
                      className={"post_card_menuitem delete"}>
                      {t("Delete")}
                    </MenuItem>
                  )}
                </Menu>
              </div>

              {/* Post comment body */}
              <div className='post_comment_card_body'>
                <span className='comment_body'>
                  {comment.split(" ").map((val, index) => (
                    <>
                      {val.includes("$") ? (
                        <Link
                          to={`trending/posts/coin/${checkCryptoMention(val)}`}
                          className='crypto_mentions'>
                          {" "}
                          ${checkCryptoMention(val)} (
                          {coins[commentData.coin_id]})
                        </Link>
                      ) : (
                        <>{val}</>
                      )}
                    </>
                  ))}
                </span>
                {commentData.img && (
                  <div className='comment_body_image_section'>
                    <img
                      src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${commentData.img}`}
                      className='comment_body_image'
                    />
                  </div>
                )}
              </div>

              {/* Post comment footer */}
              <div className='post_comment_card_footer'>
                <div className='comment_card_footer_section'>
                  {openEmojiIcons && (
                    <div className='card_emoji_container' ref={wrapperRef}>
                      <EmojiLike
                        id={cmntId}
                        setQuery={setQuery}
                        clickHandler={likeComment}
                      />
                    </div>
                  )}
                  <>
                    {like.includes(commentData.id) ? (
                      <button
                        id='like'
                        className='post_footer_btn post_like'
                        onClick={() =>
                          handleRemoveLike(commentData.id, commentData.c_u_du)
                        }>
                        <AiFillHeart
                          id='like'
                          className='post_like_active like_icon'
                        />{" "}
                        {likeCount}
                      </button>
                    ) : (
                      <>
                        {dislike.includes(commentData.id) ? (
                          <button
                            id='like'
                            className='post_footer_btn post_like'
                            onClick={() =>
                              handleRemoveLike(
                                commentData.id,
                                commentData.c_u_du
                              )
                            }>
                            <AiFillDislike
                              id='like'
                              className='post_like_active dislike_icon'
                            />{" "}
                            {likeCount}
                          </button>
                        ) : (
                          <>
                            {haha.includes(commentData.id) ? (
                              <button
                                id='like'
                                className='post_footer_btn post_like'
                                onClick={() =>
                                  handleRemoveLike(
                                    commentData.id,
                                    commentData.c_u_du
                                  )
                                }>
                                <BsEmojiLaughingFill
                                  id='like'
                                  className='post_like_active funny_icon'
                                />{" "}
                                {likeCount}
                              </button>
                            ) : (
                              <>
                                {angry.includes(commentData.id) ? (
                                  <button
                                    id='like'
                                    className='post_footer_btn post_like'
                                    onClick={() =>
                                      handleRemoveLike(
                                        commentData.id,
                                        commentData.c_u_du
                                      )
                                    }>
                                    <BsEmojiAngryFill
                                      id='like'
                                      className='post_like_active angry_icon'
                                    />{" "}
                                    {likeCount}
                                  </button>
                                ) : (
                                  <button
                                    id='like'
                                    className='post_footer_btn post_like'
                                    onClick={() =>
                                      handleLikeEmoji(
                                        commentData.id,
                                        commentData.c_u_du
                                      )
                                    }>
                                    <AiOutlineHeart id='like' /> {likeCount}
                                  </button>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                </div>

                {/* Spam button */}
                <button className='comment_card_footer_btn cmnt_spam_btn'>
                  {commentSpam.includes(commentData.id) ? (
                    <RiSpam2Fill className='cmnt_spam_actv_icon' />
                  ) : (
                    <RiSpam2Line className='cmnt_icon' />
                  )}{" "}
                  <span
                    className='spam_count cmnt_count_footer'
                    onClick={() => handleCommentSpam(commentData.id)}>
                    {spamCount}
                  </span>
                </button>

                {/* Reply  button */}
                <button
                  className='comment_card_footer_btn cmnt_reply_btn'
                  onClick={() => handleCommentReply(commentData.id)}>
                  <HiReply
                    className={
                      isActive ? "cmnt_icon active_cmnt_icon" : "cmnt_icon"
                    }
                  />{" "}
                  <span className='reply_count cmnt_count_footer'></span>
                </button>
              </div>

              {replyCount > 0 && (
                <div
                  className='show_reply_link'
                  onClick={() => handleCommentReplyBox(commentData.id)}>
                  {openReplyBox ? (
                    <>View less replies</>
                  ) : (
                    <>Show {replyCount} more replies</>
                  )}
                </div>
              )}

              {/* Comment reply form */}
              {openReplyForm && (
                <div className='reply_card'>
                  <div className='reply_container_section'>
                    <ReplyForm cmntId={cmntId} setReplyCount={setReplyCount} />
                  </div>
                </div>
              )}
              {openReplyBox && (
                <>
                  {(replies || []).length > 0 ? (
                    <div className='cmnt_reply_card_container'>
                      {replies.map((data) => (
                        <CommentReply key={data.id} replyData={data} />
                      ))}
                      {replies.length === limit && (
                        <button
                          className='reply_load_more_btn'
                          onClick={() => setPage((prev) => prev + 1)}>
                          Load
                        </button>
                      )}
                    </div>
                  ) : (
                    <div className='empty_comment_reply'>No comment found</div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default PostCommentCard;
