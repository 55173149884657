/** @format */

const ActionTypes = {
  SINGLE_BLOCK_CAST: "SINGLE_BLOCK_CAST",
  ADD_MESSAGE: "ADD_MESSAGE",
  DELETE_MESSAGE: "DELETE_MESSAGE",
  ADD_NEW_MESSAGE: "ADD_NEW_MESSAGE",
  PINNED_MESSAGE: "PINNED_MESSAGE",
  REMOVE_PINNED_MESSAGE: "REMOVE_PINNED_MESSAGE",

  MESSAGE_LIKE: "MESSAGE_LIKE",
  MESSAGE_LIKE_REMOVE: "MESSAGE_LIKE_REMOVE",

  MESSAGE_HEART: "MESSAGE_HEART",
  MESSAGE_HEART_REMOVE: "MESSAGE_HEART_REMOVE",

  MESSAGE_IDEA: "MESSAGE_IDEA",
  MESSAGE_IDEA_REMOVE: "MESSAGE_IDEA_REMOVE",
  MESSAGE_DISLIKE: "MESSAGE_DISLIKE",
  MESSAGE_DISLIKE_REMOVE: "MESSAGE_DISLIKE_REMOVE",

  MESSAGE_FUNNY: "MESSAGE_FUNNY",
  MESSAGE_FUNNY_REMOVE: "MESSAGE_FUNNY_REMOVE",

  ADD_NEW_COMMENTS: "ADD_NEW_COMMENTS",
  ADD_COMMENTS: "ADD_COMMENTS",

  ADD_COMMENT_LIKE: "ADD_COMMENT_LIKE",
  ADD_COMMENT_HEART: "ADD_COMMENT_HEART",
  ADD_COMMENT_IDEA: "ADD_COMMENT_IDEA",
  ADD_COMMENT_DISLIKE: "ADD_COMMENT_DISLIKE",
  ADD_COMMENT_FUNNY: "ADD_COMMENT_FUNNY",

  UPDATE_BLOCKCAST: "UPDATE_BLOCKCAST",

  JOIN_BLOCK: "JOIN_BLOCK",
  REMOVE_BLOCK: "REMOVE_BLOCK",
  SELECT_BLOCK: "SELECT_BLOCK",
  UNSELECT_BLOCK: "UNSELECT_BLOCK",
};

export default ActionTypes;
