/** @format */

import { Link, NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectUser, selectToken } from "../../../redux/_user/userSelectors";
import { ReactComponent as Logo } from "../../../Assets/Icons/logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { FaRegUser, FaUser } from "react-icons/fa";
import "../socialLeftSidebar/SocialLeftSideBar.css";
import { BsCameraReels, BsCameraReelsFill, BsWallet } from "react-icons/bs";
import { IoGameControllerOutline, IoGameController } from "react-icons/io5";
import { RiHome7Line, RiHome7Fill } from "react-icons/ri";
import { FaBitcoin } from "react-icons/fa";
import { setOpenBlockCreateModal } from "../../../redux/_page/pageSlice";
import { openBlockCreateModal } from "../../../redux/_page/pageSelectors";
// import Block from "../../../Assets/LeftSidebarIcon/block.svg";
// import Profile from "../../../Assets/LeftSidebarIcon/profile.svg";
// import Blockcast from "../../../Assets/LeftSidebarIcon/blockcast.svg";
// import Reels from "../../../Assets/LeftSidebarIcon/reels.svg";
// import Nft from "../../../Assets/LeftSidebarIcon/nft.svg";
// import Gaming from "../../../Assets/LeftSidebarIcon/gaming.svg";
// import Wallet from "../../../Assets/LeftSidebarIcon/wallet.svg";
import { ReactComponent as Block } from "../../../Assets/LeftSidebarIcon/block.svg";
import { ReactComponent as Profile } from "../../../Assets/LeftSidebarIcon/profile.svg";
import { ReactComponent as Blockcast } from "../../../Assets/LeftSidebarIcon/blockcast.svg";
import { ReactComponent as Reels } from "../../../Assets/LeftSidebarIcon/reels.svg";
import { ReactComponent as Nft } from "../../../Assets/LeftSidebarIcon/nft.svg";
import { ReactComponent as Gaming } from "../../../Assets/LeftSidebarIcon/gaming.svg";
import { ReactComponent as Wallet } from "../../../Assets/LeftSidebarIcon/wallet.svg";

const BlockLeftSideBar = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(openBlockCreateModal);
  console.log(isOpen);
  const { t } = useTranslation(["common"]);
  const user = useSelector(selectUser);
  const handleLogout = (e) => {
    e.preventDefault();
    logout();
  };

  const handleClickButton = () => {
    window.location.hash = "#create-block";
    dispatch(setOpenBlockCreateModal(true));
  };
  return (
    <div className='_left_navbar_container'>
      {/* Logo */}
      <div className='left_navbar_icon_box'>
        <Link className='social_left_nav_item_link' to='/'>
          <Logo className='logo_icon' />
        </Link>
      </div>

      <div className='navbar_icon_group_container'>
        {/* Home */}
        <NavLink to='/' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                <RiHome7Fill />
                <span className='navbar_icon_name'>{t("Home")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                <RiHome7Line />
                <span className='navbar_icon_name'>{t("Home")}</span>
              </div>
            )
          }
        </NavLink>

        {/* Block */}
        <NavLink to='/group' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <span class='icon-join_blockcast'></span> */}
                <Block className='join_blockcast custom_icon' />
                <span className='navbar_icon_name'>{t("Block")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <span class='icon-join_blockcast'></span> */}
                <Block className='join_blockcast custom_icon' />
                <span className='navbar_icon_name'>{t("Block")}</span>
              </div>
            )
          }
        </NavLink>

        {/* Profile */}
        <NavLink
          to={`/user/profile/${user.handleUn}`}
          className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <FaUser /> */}
                <Profile className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Profile")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <FaRegUser /> */}
                <Profile className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Profile")}</span>
              </div>
            )
          }
        </NavLink>

        {/* Blockcast */}
        <NavLink to='/blockcast' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <span class='icon-join_blockcast'></span> */}
                <Blockcast className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Blockcast")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <span class='icon-join_blockcast'></span> */}
                <Blockcast className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Blockcast")}</span>
              </div>
            )
          }
        </NavLink>

        {/* Reels */}
        <NavLink to='/reels' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <BsCameraReelsFill /> */}
                <Reels className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Reels")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <BsCameraReels /> */}
                <Reels className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Reels")}</span>
              </div>
            )
          }
        </NavLink>

        {/* NFT */}
        <NavLink to='/nft' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <FaBitcoin /> */}
                <Nft className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("NFT")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <FaBitcoin /> */}
                <Nft className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("NFT")}</span>
              </div>
            )
          }
        </NavLink>

        {/* Gaming */}
        <NavLink to='/gaming' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <IoGameController /> */}
                <Gaming className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Gaming")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <IoGameControllerOutline /> */}
                <Gaming className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Gaming")}</span>
              </div>
            )
          }
        </NavLink>

        {/* Wallet */}
        <NavLink to='/wallet' className={"navbar_icon_section"}>
          {(navData) =>
            navData.isActive ? (
              <div className='navbar_content_section active_navbar_content_section'>
                {/* <BsWallet /> */}
                <Wallet className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Wallet")}</span>
              </div>
            ) : (
              <div className='navbar_content_section'>
                {/* <BsWallet /> */}
                <Wallet className='custom_icon join_blockcast' />
                <span className='navbar_icon_name'>{t("Wallet")}</span>
              </div>
            )
          }
        </NavLink>
      </div>

      <div className='create_post_button_container'>
        <button
          className='navbar_post_create_button'
          onClick={() => handleClickButton()}>
          {t("Create Block")}
        </button>
      </div>
    </div>
  );
};

export default BlockLeftSideBar;
