/** @format */

export const selectBlockPost = (state) => state.groupPost.posts;
export const seleectPosts = (state) => state.groupPost.posts;
export const selectPostData = (state) => state.groupPost.postData;
export const selectComments = (state) => state.groupPost.comments;
export const selectcurrentCommentCount = (state) =>
  state.groupPost.currentCommentCount;

export const selectReplies = (state) => state.groupPost.replies;
export const selectCurrentCommentCount = (state) =>
  state.groupPost.currentCommentCount;

export const selectEvents = (state) => state.groupPost.events;
export const selectEventCount = (state) => state.groupPost.currentEventCount;
