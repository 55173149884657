/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  getSearchResult,
  getUsersSearch,
  getBlocksSearch,
  getPostsSearch,
} from "../../api/_search/SearchAPi";

const searchSlice = createSlice({
  name: "search",
  initialState: {
    search: null,
    all: [],
    searchposts: [],
    people: [],
    searchblock: [],
    searchKey: "",
    // search_tag: [],
    search_history: [],
    searchType: "users",
  },
  reducers: {
    setSearch: (state, action) => {
      console.log("action.data: ", action.data);
      state.all = action.payload;
    },

    setSearchType: (state, action) => {
      state.searchType = action.payload;
    },

    setPostSearch: (state, action) => {
      state.searchposts = action.payload;
    },

    setPeopleSearch: (state, action) => {
      state.people = action.payload;
    },

    setBlockSearch: (state, action) => {
      state.searchblock = action.payload;
    },

    setSearchKey: (state, action) => {
      state.searchKey = action.payload;
    },
  },
});

const { actions, reducer } = searchSlice;

// Extract and export each action creator by name
export const {
  setSearch,
  setSearchType,
  setPostSearch,
  setPeopleSearch,
  setBlockSearch,
  setSearchKey,
} = searchSlice.actions;

export const fetchAllSearchResult = (data) => async (dispatch) => {
  const response = await getSearchResult(data);
  const searchData = response.map((res) => res.data);
  var arr = [
    ...searchData[0].user,
    ...searchData[1].block,
    ...searchData[2].posts,
  ];
  dispatch(setSearch(arr));
  dispatch(setPostSearch(searchData[2].posts));
  dispatch(setPeopleSearch(searchData[0].user));
  dispatch(setBlockSearch(searchData[1].block));
  return arr;
};

export const fetchUserSearchResult = (data) => async (dispatch) => {
  try {
    const response = await getUsersSearch(data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBlockSearchResult = (data) => async (dispatch) => {
  try {
    const response = await getBlocksSearch(data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPostsSearchResult = (data) => async (dispatch) => {
  try {
    const response = await getPostsSearch(data);
    return response;
  } catch (error) {
    console.log(error);
  }
};

// Export the reducer, either as a default or named export
export default searchSlice.reducer;
