/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import "./EarningCenterDownNavbar.css";
import { FaHome } from "react-icons/fa";
import { MdOutlineRssFeed } from "react-icons/md";
import { FaUsers } from "react-icons/fa";
import { SiGoogleanalytics } from "react-icons/si";
import { useTranslation } from "react-i18next";

const EarningCenterDownNavbar = () => {
  const { t } = useTranslation(["common"]);
  return (
    <div className="earning_down_navbar_container">
      <NavLink
        to={`/earning/center`}
        className={(navData) =>
          navData.isActive
            ? `downnavbar_link_btn active_downnavbar_link_btn`
            : `downnavbar_link_btn`
        }
      >
        <MdOutlineRssFeed />
        <br />
        <span className="dow_navbar_title">{t("feed")}</span>
      </NavLink>

      <NavLink
        to={`/earning/center/ranking`}
        className={(navData) =>
          navData.isActive
            ? `downnavbar_link_btn active_downnavbar_link_btn`
            : `downnavbar_link_btn`
        }
      >
        <FaUsers />
        <br />
        <span className="dow_navbar_title">{t("ranking")}</span>
      </NavLink>

      <NavLink
        to={`/earning/analytics`}
        className={(navData) =>
          navData.isActive
            ? `downnavbar_link_btn active_downnavbar_link_btn`
            : `downnavbar_link_btn`
        }
      >
        <SiGoogleanalytics />
        <br />
        <span className="dow_navbar_title">{t("analytics")}</span>
      </NavLink>
    </div>
  );
};

export default EarningCenterDownNavbar;
