/** @format */

import Cookies from "js-cookie";
import { createContext, useState, useEffect } from "react";

const ThemeContext = createContext();

const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState("light"); // Default theme is light
  const [themeColor, setThemeColor] = useState("#ffa01");
  const [fontSize, setFontSize] = useState("16"); // Default font size is 16px

  // When component mounts, read from the cookie
  useEffect(() => {
    const storedTheme = Cookies.get("theme");
    const storedThemeColor = Cookies.get("themeColor");

    if (storedTheme) {
      setTheme(storedTheme);
    } else {
      setTheme("light");
    }

    if (storedThemeColor) {
      setThemeColor(storedThemeColor);
    } else {
      setThemeColor("royalblue");
    }
  });

  const toggleTheme = (value) => {
    console.log(">>>", value);
    setTheme(() => {
      Cookies.set("theme", value, { expires: 365 });
      return value;
    });

    // setTheme((prevTheme) => {
    //   const newTheme = prevTheme === "light" ? "dark" : "light";
    //   Cookies.set("theme", newTheme, { expires: 365 }); // Set cookie with new theme
    //   return newTheme;
    // });
  };

  const changeThemeColor = (newColor) => {
    setThemeColor(newColor);
    Cookies.set("themeColor", newColor, { expires: 365 }); // Set cookie with new theme color
  };

  const changeFontSize = (newSize) => {
    console.log("SIZE:", newSize);
    setFontSize(newSize);
    Cookies.set("fontSize", newSize, { expires: 365 }); // Set cookie with new font size
  };

  return (
    <ThemeContext.Provider
      value={{
        theme,
        toggleTheme,
        changeThemeColor,
        themeColor,
        changeFontSize,
        fontSize,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export { ThemeContext, ThemeProvider };
