/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  handleGetCoinPrice,
  handleGetCoinDetails,
  handleGetCoinList,
  handleGettrendingCoinDetails,
} from "../../api/_coin/coinApi";
export const coinSlice = createSlice({
  name: "coin",
  initialState: {},
  reducers: {},
});

export const getCoinPrices = (data) => async (dispatch) => {
  const result = await handleGetCoinPrice(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getCoinDetails = (data) => async (dispatch) => {
  const result = await handleGetCoinDetails(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getCoinList = (data) => async (dispatch) => {
  const result = await handleGetCoinList(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getTrendingCoinDetails = (data) => async (dispatch) => {
  const result = await handleGettrendingCoinDetails(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};
export default coinSlice.reducer;
