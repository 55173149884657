/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import UserAvatar from "../../Assets/userAvatar.webp";
import { Link } from "react-router-dom";
import { ImSpinner2 } from "react-icons/im";
import CustomModal from "../modal/CustomModal";
import { useNavigate } from "react-router-dom";
import { ReactComponent as AllIcon } from "../../Assets/Icons/all.svg";
import PostCommentCard from "../PostCommentCard/PostCommentCard";

import timeDifference from "../../utils/getCreateTime";
import { checkMention, checkCryptoMention } from "../../utils/checkMensionUser";
import { BiArrowBack } from "react-icons/bi";
import { MdLocationOn } from "react-icons/md";
import CustomPostFormModal from "../modal/CustomPostForm";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import axios from "axios";
import { BsFillPinAngleFill } from "react-icons/bs";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import FullPostLoader from "../SkeletonLoading/FullPostLoader";
import { ReportConfig } from "../../config/Report.js";
import { getCoinList, getCoinPrices } from "../../redux/_coin/coinSlice";
/**Toolkit */
import { useSelector, useDispatch } from "react-redux";
import {
  selectPosts,
  selectComments,
  selectCurrentCommentCount,
  selectCommentUpdate,
} from "../../redux/_post/postSelectors";
import {
  selectUser,
  selectToken,
  selectFollowing,
} from "../../redux/_user/userSelectors";
import {
  handleFollowUser,
  setRemoveFollowing,
} from "../../redux/_user/userSlice";
import {
  updatePinnedPost,
  updateBookmarkPost,
  updateHidePost,
  setPinnedPost,
  updateSocialPost,
  updateDeletePost,
  updatePostSharePrivacy,
  updatePostCommentPrivacy,
  updateSharePost,
  handleFetchFullPost,
  reportPost,
  fetchPostComment,
  postComment,
} from "../../redux/_post/postSlice";
import {
  AiOutlinePushpin,
  AiOutlineEdit,
  AiOutlineDelete,
  AiOutlineShareAlt,
  AiOutlineClose,
  AiOutlineSend,
} from "react-icons/ai";
import { BsBookmark } from "react-icons/bs";
import { MdOutlineHideSource, MdOutlinePrivacyTip } from "react-icons/md";
import { BiBitcoin } from "react-icons/bi";
import { GrAnalytics } from "react-icons/gr";
import { handleProfileVisitCount } from "../../redux/_user/userSlice";
import NormalPostFooter from "./NormalPostFooter";
import "./FullPost.css";
import {
  BsFillPlusCircleFill,
  BsFillCheckCircleFill,
  BsFillPatchCheckFill,
} from "react-icons/bs";
import { BiLoaderAlt } from "react-icons/bi";
import getPricePercentage from "../../utils/getPricePercentage";
import { BiTrendingDown } from "react-icons/bi";
import { MdOutlineTrendingUp } from "react-icons/md";

const FullPostComp = ({ postData, openFullPostModal }) => {
  const dispatch = useDispatch();
  const isToken = useSelector(selectToken);
  const user = useSelector(selectUser);
  const following = useSelector(selectFollowing);
  const commentUpdatedData = useSelector(selectCommentUpdate);
  const comments = useSelector(selectComments);
  const currentCommentCount = useSelector(selectCurrentCommentCount);

  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);
  const [postId, setPostId] = React.useState(postData.id);
  const [post, setPost] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  // Post pinned state
  const [openPinnedModal, setOpenPinnedModal] = React.useState(false);
  const [hasPinned, setHasPinned] = React.useState(postData.pinned || 0);
  // Post Delete state
  const [postDelete, setPostDelete] = React.useState(
    postData.isDelete || false
  );
  // Post bookmark state
  const [openBookModal, setOpenBookModal] = React.useState(false);
  // Post hide state
  const [openHideModal, setOpenHideModal] = React.useState(false); // Post Delete state
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [width, setWidth] = React.useState("");

  // Post edit modal
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [selectPost, setSelectPost] = React.useState(null);
  const [editText, setEditText] = React.useState("");
  const [openShareModal, setOpenShareModal] = React.useState(false);
  const [content, setContent] = React.useState("");
  const [isToxic, setIsToxic] = React.useState(false);
  const [nftModal, setNftModal] = React.useState(false);
  const [myComments, setMyComments] = React.useState([]);
  const [fullpostData, setFullPostData] = React.useState(null);
  const componentRef = React.createRef(null);
  const [activeTab, setActiveTab] = React.useState("one");
  const [openPrivacyMenu, setOpenPrivacyMenu] = React.useState(false);
  const [commentPrivacy, setCommentPrivacy] = React.useState(
    postData.cmnt_prv || "all"
  );
  const [sharePrivacy, setSharePrivacy] = React.useState(
    postData.shr_prv || "all"
  );
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);

  // comment code
  const [cmntPage, setCmntPage] = React.useState(1);
  const [cmntLimit, setCmntLimit] = React.useState(5);
  const [commentsData, setCommentsData] = React.useState([]);

  const [collectId, setCollectId] = React.useState("");
  const [isModalLoading, setIsModalLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [bookmark, setBookmark] = React.useState(postData.book || []);
  const [openReportModal, setOpenReportModal] = React.useState(false);
  const [reposted, setReposted] = React.useState(postData.repost || []);
  const [postReport, setPostReport] = React.useState("");
  const [reportType, setReportType] = React.useState();
  const [disableReportBtn, setDisableReportBtn] = React.useState(true);
  const [reportMsg, setReportMsg] = React.useState("");
  const [sortedBy, setSortedBy] = React.useState("new");
  const [prevSortedBy, setprevSortedBy] = React.useState("popular");
  const [cmntLoading, setCmntLoading] = React.useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = React.useState(false);
  const [flag, setFlag] = React.useState(postData.flag || []);
  const [isVisble, setIsVisble] = React.useState(false);
  const [image, setImage] = React.useState("");
  const [prevImage, setPrevImage] = React.useState("");
  const [text, setText] = React.useState("");
  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);
  const [commentCount, setCommentCount] = React.useState(postData.c_c);
  const [gif, setGif] = React.useState("");

  const [openCryptoList, setOpenCryptoList] = React.useState(false);
  const [coinpage, setCoinPage] = React.useState(1);
  const [searchCoinList, setSearchCoinList] = React.useState([]);
  const [cointCount, setCoinCount] = React.useState(20);
  const [coinlimit, setCoinLimit] = React.useState(20);
  const [mentionCoin, setMentionCoin] = React.useState("");
  const [mentionCoinCurrentPrice, setMentionCoinCurrentPrice] =
    React.useState("");
  const [mentionCoinId, setMentionCoinId] = React.useState("");
  const [coinDetails, setCoinDetails] = React.useState([]);

  useSocket();

  const handleClick = (value, id, data) => {
    if (value === "pin") {
      alert("Pinn");
      setPostId(id);
      setOpenPinnedModal(true);
    } else if (value === "profile") {
      console.log(postData);
      const arg = { isToken, postId: postData.id, id: postData.u_dun };
      if (postData.u_dun !== user.handleUn) {
        dispatch(handleProfileVisitCount(arg));
      }
      navigate(`/user/profile/${postData.u_dun}`);
    } else if (value === "book") {
      setPostId(id);
      setOpenBookModal(true);
    } else if (value === "hide") {
      setPostId(id);
      setOpenHideModal(true);
    } else if (value === "unhide") {
      setPostId(id);
      setOpenHideModal(true);
    } else if (value === "spam") {
      // handlePostSpamHandler(id)
    } else if (value === "share") {
      setPost(data);
      setOpenShareModal(true);
      setPostId(id);
    } else if (value === "comment") {
      setPostId(id);
      fetchComments();
    } else if (value === "delete") {
      setPostId(id);
      setOpenDeleteModal(true);
    } else if (value === "analytics") {
      // alert(id);
      navigate(`/post/analytics/${id}`);
    } else if (value === "edit") {
      setPost(data);
      setOpenEditModal(true);
      setSelectPost(data);
      setEditText(data.content);
      setPostId(data.id);
    } else if (value === "likeList") {
      setOpenLikeList(true);
      setPostId(id);
      fetchUserLike();
    } else if (value === "visit") {
      navigate(`/user/profile/${data.u_dun}`);
    } else if (value === "post_body") {
    } else if (value === "nft") {
      setNftModal(true);
      setPostId(id);
    } else if (value === "privacy") {
      setOpenPrivacyMenu(true);
      setPostId(id);
    }
  };

  // **** Handle update of post comment privacy
  const updateCommentPrivacy = async (value) => {
    const data = { isToken, postId, commentPrivacy: value };
    const post = await dispatch(updatePostCommentPrivacy(data));
    setPinnedPost(post);
    setSharePrivacy(value);
    setOpenPrivacyMenu(false);
  };

  React.useEffect(() => {
    setWidth(window.innerWidth);
    console.log(window.innerWidth);
  }, [width]);

  const onClose = () => {
    setOpenPinnedModal(false);
    setOpenBookModal(false);
    setOpenHideModal(false);
    setOpenEditModal(false);
    setOpenDeleteModal(false);
    setOpenShareModal(false);
    setNftModal(false);
    setOpenPrivacyMenu(false);
    setOpenReportModal(false);
  };

  const PostHandleFollowUser = (userId) => {
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { isToken, userId };
    dispatch(handleFollowUser(data));
  };

  // *** HandleOpen Pinned post modal
  const handleClickPin = (id) => {
    setPostId(id);
    setOpenPinnedModal(true);
  };
  // *** Pinned post handle
  const pinnedPostHandler = async () => {
    setIsLoading(true);
    // updating useState here
    if (!hasPinned) {
      setHasPinned((prev) => prev + 1);
    } else {
      setHasPinned((prev) => prev - 1);
    }
    const data = { postId, isToken };
    // from here we call the postSlice method
    const post = await dispatch(updatePinnedPost(data));
    try {
      console.log("TRY: ", post);
      if (post.status === 200) {
        setPostId("");
        setOpenPinnedModal(false);
        setIsLoading(false);
      } else {
        setError("Something went wrong");
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  // *** Handle open Bookmark Modal
  const HandleOpenBookmarkModal = (id) => {
    setPostId(id);
    setOpenBookModal(true);
  };
  // *** Handle bookmark post
  const handleBookmarkPost = async () => {
    // updating useState here
    if (bookmark.includes(user.handleUn)) {
      const arr = bookmark;
      const temp = arr.filter((data) => data !== user.handleUn);
      setBookmark(temp);
    } else {
      setBookmark((prev) => [...prev, user.handleUn]);
    }
    const data = { isToken, postId };
    // from here we call the postSlice method
    const post = await dispatch(updateBookmarkPost(data));
    try {
      console.log("TRY: ", post);
      if (post.status === 200) {
        setOpenBookModal(false);
        setPostId("");
        setIsLoading(false);
      } else {
        setError("Something went wrong");
        const arr = bookmark;
        const temp = arr.filter((data) => data !== user.handleUn);
        setBookmark(temp);
      }
    } catch (error) {
      setError("Something went wrong");
      const arr = bookmark;
      const temp = arr.filter((data) => data !== user.handleUn);
      setBookmark(temp);
    }
  };

  // *** Handle Open Hide modal
  const handleOpenHideModal = (id) => {
    setOpenHideModal(true);
    setPostId(id);
  };
  // *** Handle hide post
  const handleHidePost = async () => {
    setIsLoading(true);
    const data = { isToken, postId };
    // from here we call the postSlice method
    const post = await dispatch(updateHidePost(data));
    dispatch(setPinnedPost(post));
    setOpenHideModal(false);
    setIsLoading(false);
  };

  // *** Handle navigate to Post Analytics page
  const handleRedirctToAnalytics = (id) => {
    navigate(`/post/analytics/${id}`);
  };

  // *** HandleOpen Edit modal
  const handleOpenEditModal = () => {
    setPost(data);
    setOpenEditModal(true);
    setSelectPost(data);
    setEditText(data.content);
    setPostId(data.id);
  };
  // *** Edit post
  const handleEditPost = async () => {
    const data = { editText, isToken, postId };
    const post = await dispatch(updateSocialPost(data));
    dispatch(setPinnedPost(post));
    setPostId("");
    setEditText("");
    setOpenEditModal(false);
  };

  // *** Post privacy update Modal
  const handleOpenPrivacyModal = () => {
    setOpenPrivacyMenu(true);
    setPostId(id);
  };
  // **** Handle update of post share privacy
  const updateSharePrivacy = async (value) => {
    const data = { isToken, postId, sharePrivacy: value };
    const post = await dispatch(updatePostSharePrivacy(data));
    setPinnedPost(post);
    setSharePrivacy(value);
    setOpenPrivacyMenu(false);
  };

  // *** Handle open Delete modal
  const handleOpenDeleteModal = (id) => {
    setPostId(id);
    setOpenDeleteModal(true);
  };
  // *** Delete Post
  const handleDeletePost = async () => {
    setPostDelete(true);
    const data = { isToken, postId };
    const post = await dispatch(updateDeletePost(data));
    dispatch(setPinnedPost(post));
    setOpenDeleteModal(false);
  };

  // *** Handle open Report modal
  const handleOpenShareModal = (id) => {
    setOpenReportModal(true);
    setPostId(id);
  };
  // *** Handle Report Modal
  const handleAddReport = (data) => {
    console.log(data);
    if (!postReport) {
      setPostReport(data.id);
    } else {
      if (data.id === postReport) {
        setPostReport("");
      } else {
        setPostReport(data.id);
      }
    }
  };

  const handleReportPost = async () => {
    const data = {
      id: postId,
      token: isToken,
      value: postReport,
      msg: reportMsg,
    };
    console.log(data);
    await dispatch(reportPost(data));
    setOpenReportModal(false);
    setReportMsg("");
    setReportType();
    setDisableReportBtn(true);
    if (report.includes(postId)) {
      dispatch(removeReport(postId));
    } else {
      dispatch(addReport(postId));
    }
  };
  React.useEffect(() => {
    if (reportType) {
      setDisableReportBtn(false);
    } else {
      setDisableReportBtn(true);
    }
  }, [reportType]);

  const fetchComments = async () => {
    console.log("This fn.  called");
    const data = { postId, token: isToken, sortedBy, page, limit };
    dispatch(fetchPostComment(data));
  };

  React.useEffect(() => {
    if (openFullPostModal) {
      fetchComments();
    }
  }, [openFullPostModal, page, sortedBy]);

  // *** Handle increment page count
  const incrementPage = () => {
    setIsLoadingBtn(true);
    setTimeout(() => {
      setIsLoadingBtn(false);
    }, 1000);
    setPage((prev) => prev + 1);
  };

  const handleImageChange = (e) => {
    setPrevImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const closeCmntImage = () => {
    setPrevImage("");
    setImage("");
  };

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenEmojiIcons(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  React.useEffect(() => {
    if (!text.trim()) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  }, [text]);

  const handlePostComment = async (id) => {
    setIsBtnDisable(true);
    const data = {
      token: isToken,
      post: {
        comment: text,
        c_img: image,
        gif: gif,
        postId: id,
        mentionCoinCurrentPrice: mentionCoinCurrentPrice,
        mentionCoinId: mentionCoinId,
      },
    };
    const result = await dispatch(postComment(data));
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
    setText("");
    setCommentCount((prev) => prev + 1);
  };

  const handleKeyChange = (e) => {
    if (e.keyCode === 32 || e.keyCode === 8) {
      setOpenCryptoList(false);
    } else if (e.keyCode === 50) {
      setOpenCryptoList(false);
    } else if (e.keyCode === 52) {
      console.log("Call suggestion crypto");
      setOpenCryptoList(true);
    }
  };

  React.useEffect(() => {
    if (openCryptoList) {
      fetchCoins();
    }
  }, [openCryptoList, coinpage]);

  const fetchCoins = async () => {
    const data = { page: coinpage, limit: coinlimit };
    const result = await dispatch(getCoinList(data));
    if (page === 1) {
      setSearchCoinList(result);
    } else {
      setSearchCoinList((prev) => [...prev, ...result]);
    }
    console.log(result);
  };

  function handleScrollToFetchCoins(e) {
    let cl = e.currentTarget.clientHeight;
    let sy = Math.round(e.currentTarget.scrollTop);
    let sh = e.currentTarget.scrollHeight;
    if (cl + sy + 1 >= sh) {
      if (postCount !== 0) {
        setCoinPage((prev) => prev + 1);
      }
    }
  }

  const handleSelectMentionCoin = (data) => {
    setMentionCoinId(data.id);
    setMentionCoinCurrentPrice(data.current_price);
    setMentionCoin(data.name);
    const arr = content.split(" ");
    const temp = arr.filter((data) => !data.includes("$"));
    setText(temp.join(" "));

    setText((prev) => prev + " $" + data.name + " ");
    setOpenCryptoList(false);
  };

  React.useEffect(() => {
    if (postData.coin_id.length > 0) {
      postData.coin_id.map((id) => {
        fetchChartData(id);
      });
    }
  }, [postData.id]);

  const fetchChartData = async (id) => {
    // setGraphLoading(true);
    const data = {
      id: id,
      timeSpan: "2",
    };
    const result = await dispatch(getCoinPrices(data));
    console.log(result);
    setCoinDetails((prev) => [...prev, { id, price: result }]);
    // setCoinPrices(result);
  };

  return (
    <React.Fragment>
      {/* Post pinned modal */}
      {openPinnedModal && (
        <CustomModal
          onClose={onClose}
          title={
            hasPinned > 0 ? <>{t("Unpinned post")}</> : <>{t("Pinned post")}</>
          }
          body={
            hasPinned > 0 ? (
              <>{t("Do you want to unpinned this post?")}</>
            ) : (
              <>{t("Do you want to pinned this post?")}</>
            )
          }
          footer={
            <div>
              <button className='update_btn' onClick={pinnedPostHandler}>
                {isLoading ? (
                  <ImSpinner2 className='spinner' />
                ) : (
                  <>{hasPinned > 0 ? <>{t("Unpin")}</> : <>{t("Pin")}</>}</>
                )}
              </button>
            </div>
          }
        />
      )}
      {/* Post bookmark modal */}
      {openBookModal && (
        <CustomModal
          onClose={onClose}
          title={
            postData.book.includes(user.handleUn) ? (
              <>{t("Remove post")}</>
            ) : (
              <>{t("Bookmark post")}</>
            )
          }
          body={
            postData.book.includes(user.handleUn) ? (
              <>{t("Do you want to remove this post")}</>
            ) : (
              <>{t("Do you want to Save this post")}</>
            )
          }
          footer={
            <div className='modal_footer_section'>
              <button className='update_btn' onClick={handleBookmarkPost}>
                {isLoading ? (
                  <ImSpinner2 className='spinner' />
                ) : (
                  <>
                    {postData.book.includes(user.handleUn) ? (
                      <>{t("Remove")}</>
                    ) : (
                      <>{t("Bookmark")}</>
                    )}
                  </>
                )}
              </button>
            </div>
          }
        />
      )}
      {/* Post Hide/Unhide modal */}
      {openHideModal && (
        <CustomModal
          onClose={onClose}
          title={
            postData.hide.includes(user.handleUn) ? (
              <>{t("Unhide post")}</>
            ) : (
              <>{t("Hide post")}</>
            )
          }
          body={
            postData.hide.includes(user.handleUn) ? (
              <>{t("Do you want to unhide this post")}</>
            ) : (
              <>{t("Do you want to hide this post")}</>
            )
          }
          footer={
            <div className='modal_footer_section'>
              <button className='update_btn' onClick={handleHidePost}>
                {isLoading ? (
                  <ImSpinner2 className='spinner' />
                ) : (
                  <>
                    {postData.hide.includes(user.handleUn) ? (
                      <>{t("Unhide")}</>
                    ) : (
                      <>{t("Hide")}</>
                    )}
                  </>
                )}
              </button>
            </div>
          }
        />
      )}
      {/* Post Edit modal */}
      {openEditModal && (
        <CustomModal
          onClose={onClose}
          title='Edit post'
          body={
            <div className='edit_modal_section'>
              <textarea
                className='textarea_modal'
                placeholder='Enter quote'
                value={editText}
                onChange={(e) =>
                  setEditText(e.target.value.slice(0, 100))
                }></textarea>
            </div>
          }
          footer={
            <div className='modal_footer_section'>
              <button className='post_modal_btn' onClick={handleEditPost}>
                {isLoading ? (
                  <ImSpinner2 className='spinner' />
                ) : (
                  <>{t("Edit")}</>
                )}
              </button>
            </div>
          }
        />
      )}

      {/* post privacy modal */}
      {openPrivacyMenu && (
        <CustomPostFormModal
          title={
            <div className='comment_modal_title_section'>
              <div className='cmnt_header_box'>
                <button
                  className='cmnt_modal_back_btn'
                  onClick={() => setOpenPrivacyMenu(false)}>
                  <BiArrowBack />
                  <span>Post privacy settings</span>
                </button>
              </div>
            </div>
          }
          onClose={onClose}
          body={
            <div className={`share_content_section `}>
              {/* Share privacy settings */}
              <div className='modal_privacy_seetings_section'>
                <div className='modal_privacy_seetings_text_section'>
                  <div className='modal_privacy_seetings_title_section'>
                    Share settings
                  </div>
                  <div className='modal_privacy_seetings_subheader_section'>
                    Only selected type of user can only share your post
                  </div>
                </div>

                <div className='privacy_modal_radio_section'>
                  <div
                    className='radio_btn_section'
                    onClick={() => updateSharePrivacy("all")}>
                    {sharePrivacy === "all" ? (
                      <BiRadioCircleMarked className='active_privacy_radio_icon' />
                    ) : (
                      <BiRadioCircle className='privacy_radio_icon' />
                    )}
                    <span className='privacy_radio_text'>All</span>
                  </div>

                  <div
                    className='radio_btn_section'
                    onClick={() => updateSharePrivacy("none")}>
                    {sharePrivacy === "none" ? (
                      <BiRadioCircleMarked className='active_privacy_radio_icon' />
                    ) : (
                      <BiRadioCircle className='privacy_radio_icon' />
                    )}
                    <span className='privacy_radio_text'>None</span>
                  </div>
                </div>
              </div>

              {/* Comment privacy settings */}
              <div className='modal_privacy_seetings_section'>
                <div className='modal_privacy_seetings_text_section'>
                  <div className='modal_privacy_seetings_title_section'>
                    Comment settings
                  </div>
                  <div className='modal_privacy_seetings_subheader_section'>
                    Only selected type of user can only comment on your post
                  </div>
                </div>

                <div className='privacy_modal_radio_section'>
                  <div
                    className='radio_btn_section'
                    onClick={() => updateCommentPrivacy("all")}>
                    {commentPrivacy === "all" ? (
                      <BiRadioCircleMarked className='active_privacy_radio_icon' />
                    ) : (
                      <BiRadioCircle className='privacy_radio_icon' />
                    )}
                    <span className='privacy_radio_text'>All</span>
                  </div>

                  <div
                    className='radio_btn_section'
                    onClick={() => updateCommentPrivacy("none")}>
                    {commentPrivacy === "none" ? (
                      <BiRadioCircleMarked className='active_privacy_radio_icon' />
                    ) : (
                      <BiRadioCircle className='privacy_radio_icon' />
                    )}
                    <span className='privacy_radio_text'>None</span>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/* Post delete modal */}
      {openDeleteModal && (
        <CustomModal
          onClose={onClose}
          title={<>{t("Delete post")}</>}
          body={<>{t("Do you want to delete this post")}</>}
          footer={
            <div className='modal_footer_section'>
              <button
                className='update_btn delete_btn'
                onClick={handleDeletePost}>
                {isLoading ? (
                  <ImSpinner2 className='spinner' />
                ) : (
                  <>{t("Delete")}</>
                )}
              </button>
            </div>
          }
        />
      )}

      {/* Post report modal */}
      {openReportModal && (
        <CustomPostFormModal
          title={
            <div className='post_form_modal_title'>
              <div className='modal_post_form_section'>
                <button className='post_close_modal_button' onClick={onClose}>
                  <BiArrowBack />
                </button>
                <span className='modal_title_text'>Report</span>
              </div>
              {postReport && (
                <button
                  className='_modal_post_button'
                  onClick={handleReportPost}>
                  Report
                </button>
              )}
            </div>
          }
          body={
            <div className='group_post_report_card_body'>
              {Object.keys(ReportConfig).map((data, index) => (
                <div
                  className='post_report_card'
                  key={index}
                  onClick={() => handleAddReport(ReportConfig[data])}>
                  <div className='radio_section'>
                    {postReport === ReportConfig[data].id ? (
                      <BiRadioCircleMarked className='radio_active' />
                    ) : (
                      <BiRadioCircle />
                    )}
                  </div>
                  <div className='post_report_card_info_section'>
                    <div className='post_card_report_title'>
                      {ReportConfig[data].title}
                    </div>
                    <div className='post_card_report_details'>
                      {ReportConfig[data].details}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          }
        />
      )}

      {postData.hide.includes(user.handleUn) ? (
        <div className='social_media_unhide_post_card'>
          <div className='hide_text'>{t("You hide this post")}</div>
          <div className='unhhide_btn_section'>
            <button
              className='unhide_btn'
              id='unhide'
              onClick={(e) => handleClick(e.target.id, postData.id)}>
              {t("Unhide")}
            </button>
          </div>
        </div>
      ) : (
        <React.Fragment>
          {!postDelete && (
            <>
              {postData.is_share ? (
                <>
                  <div className='social_post_main_header'>
                    <div className='social_media_post_card_header'>
                      <div className='profile_avatar_section'>
                        <img
                          src={
                            postData.u_img
                              ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                              : UserAvatar
                          }
                          className='posted_user_avatar'
                          id='profile'
                        />

                        {user.handleUn !== postData.s_u_dun && (
                          <button
                            className='post_card_follow_btn'
                            onClick={() =>
                              PostHandleFollowUser(postData.s_u_dun)
                            }>
                            {following.includes(postData.s_u_dun) ? (
                              <BsFillCheckCircleFill className='postcard_follow_icon' />
                            ) : (
                              <BsFillPlusCircleFill className='postcard_follow_icon' />
                            )}
                          </button>
                        )}
                      </div>
                      {/* Post creator personal info */}
                      <div className='post_creator_info'>
                        <>
                          {/* creator name */}
                          <span className='creator_name' id='profile'>
                            {width <= 506 && postData.s_u_fn.length > 9 ? (
                              <>{postData.s_u_fn.slice(0, 9) + ".."}</>
                            ) : (
                              <>{postData.s_u_fn}</>
                            )}{" "}
                            {width <= 506 && postData.s_u_ln.length > 5 ? (
                              <>{postData.s_u_ln.slice(0, 5) + "..."}</>
                            ) : (
                              <>{postData.s_u_ln}</>
                            )}
                          </span>
                          {/* creator username */}
                          <span className='creator_username'>
                            @
                            {width <= 506 &&
                            postData.s_u_dun.length > 11 &&
                            postData.s_u_dun.length > 5 ? (
                              <>{postData.s_u_dun.slice(0, 11) + "..."}</>
                            ) : (
                              <>{postData.s_u_dun}</>
                            )}
                          </span>
                          {/* Post create time */}
                          <span className='creator_time'>
                            {timeDifference(
                              new Date().getTime(),
                              Number(postData.c_t)
                            )}
                          </span>
                        </>
                        <div className='post_basic_info'>
                          {/* post privacy section */}
                          <span className='post_privacy'>
                            {postData.privacy === "Public" ? (
                              <AllIcon className='post_icon' />
                            ) : (
                              <>
                                {postData.privacy === "follower" ? (
                                  <span class='icon-followers'></span>
                                ) : (
                                  <span class='icon-only_me'></span>
                                )}
                              </>
                            )}
                          </span>

                          {/* post paid promotion */}
                          <span className='post_paid_section'>
                            {postData.isPaid === "true" && (
                              <span className='paid_promotion'>
                                Paid promotion with
                                <Link
                                  to={`/group/${postData.blockId}`}
                                  className='cmpny_name'>
                                  {postData.cName}
                                </Link>
                              </span>
                            )}
                          </span>
                          <br />
                          <span className='status_text'>
                            {postData.statusText}
                          </span>
                        </div>
                        {postData.userLocation && (
                          <div className='post_user_location'>
                            <MdLocationOn className='location_icon' />
                            {postData.userLocation}
                          </div>
                        )}
                        {/* post feelings */}
                        <div className='post_feelings'>
                          {postData.feelingIcon}
                          <span className='post_feelings_text'>
                            {postData.feeling}
                          </span>
                        </div>
                        {postData.city && postData.country && (
                          <div className='post_user_location'>
                            <MdLocationOn className='location_icon' />
                            {postData.city}, {postData.country}
                          </div>
                        )}
                      </div>
                    </div>
                    <Menu
                      menuButton={
                        <MenuButton className={"social_post_menu_button"}>
                          <span class='icon-more'></span>
                        </MenuButton>
                      }>
                      {/* Hide post menu button */}
                      {
                        <MenuItem id='hide' className={"social_post_menu_item"}>
                          <div id='hide' className='menu_item_section'>
                            {" "}
                            <MdOutlineHideSource
                              id='hide'
                              className='menu_item_icon'
                            />
                            {t("Hide")}
                          </div>
                        </MenuItem>
                      }

                      {/* View Original post */}
                      <MenuItem
                        id='original_post'
                        className={"social_post_menu_item"}>
                        <div id='original_post' className='menu_item_section'>
                          View Original post
                        </div>
                      </MenuItem>

                      {/* Delete share post */}
                      {postData.s_u_dun === user.handleUn && (
                        <MenuItem
                          id='delete'
                          className={"social_post_menu_item"}>
                          <div id='delete' className='menu_item_section'>
                            {" "}
                            <AiOutlineDelete
                              id='delete'
                              className='menu_item_icon'
                            />
                            {t("Delete")}
                          </div>
                        </MenuItem>
                      )}

                      {sharePrivacy === "all" ? (
                        <MenuItem
                          id='share'
                          className={"social_post_menu_item"}>
                          <div id='share' className='menu_item_section'>
                            <AiOutlineShareAlt
                              id='share'
                              className='menu_item_icon'
                            />
                            {t("Share")}
                          </div>
                        </MenuItem>
                      ) : (
                        <>
                          {postData.u_dun !== user.handleUn ? null : (
                            <MenuItem
                              id='share'
                              className={"social_post_menu_item"}>
                              <div id='share' className='menu_item_section'>
                                <AiOutlineShareAlt
                                  id='share'
                                  className='menu_item_icon'
                                />
                                {t("Share")}
                              </div>
                            </MenuItem>
                          )}
                        </>
                      )}
                    </Menu>
                  </div>
                  <div className='post_card_body' id='share_post_body'>
                    <span className='main_body_text'>
                      {postData.s_content.split(" ").map((val, index) => (
                        <span
                          key={index}
                          className={val.includes("#") ? "trend_tag" : ""}>
                          {val}{" "}
                        </span>
                      ))}
                    </span>
                  </div>
                </>
              ) : null}
              <div className='full_post_card'>
                {/* Post section */}
                <div className='modal_post_section'>
                  {/* Pinned post icon */}
                  {hasPinned > 0 && (
                    <div>
                      <BsFillPinAngleFill className='full_post_pinned_icon' />
                    </div>
                  )}

                  {/* Bookmark post icon */}
                  {bookmark.includes(user.handleUn) && (
                    <span class='icon-bookmark_one'></span>
                  )}
                  <div
                    className={
                      postData.is_share ? "shared_full_post_card" : ""
                    }>
                    {/* Header */}
                    <div className='full_post_header'>
                      <div className='post_card_user_section'>
                        <div className='profile_avatar_section'>
                          <img
                            src={
                              postData.u_img
                                ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                                : UserAvatar
                            }
                            className='posted_user_avatar'
                            id='profile'
                          />

                          {user.handleUn !== postData.u_dun && (
                            <button
                              className='post_card_follow_btn'
                              onClick={() =>
                                PostHandleFollowUser(postData.u_dun)
                              }>
                              {following.includes(postData.u_dun) ? (
                                <BsFillCheckCircleFill className='postcard_follow_icon' />
                              ) : (
                                <BsFillPlusCircleFill className='postcard_follow_icon' />
                              )}
                            </button>
                          )}
                        </div>

                        <div className='createor_info_section'>
                          <>
                            {/* creator name */}
                            <span className='_creator_name' id='profile'>
                              {width <= 506 && postData.u_fn.length > 9 ? (
                                <>{postData.u_fn.slice(0, 9) + ".."}</>
                              ) : (
                                <>{postData.u_fn}</>
                              )}{" "}
                              {width <= 506 && postData.u_ln.length > 5 ? (
                                <>{postData.u_ln.slice(0, 5) + "..."}</>
                              ) : (
                                <>{postData.u_ln}</>
                              )}
                            </span>
                            {/* Badge */}
                            {postData.badges === "claim_sub" ? (
                              <BsFillPatchCheckFill className='subscriber_badge' />
                            ) : (
                              <>
                                {postData.badges === "claim_cc" ? (
                                  <BsFillPatchCheckFill className='cc_badge' />
                                ) : null}
                              </>
                            )}
                            {/* creator username */}
                            <span className='_creator_username'>
                              @
                              {width <= 506 &&
                              postData.u_dun.length > 11 &&
                              postData.u_dun.length > 5 ? (
                                <>{postData.u_dun.slice(0, 11) + "..."}</>
                              ) : (
                                <>{postData.u_dun}</>
                              )}
                            </span>
                            {/* Post create time */}
                            <span className='_creator_time'>
                              {timeDifference(
                                new Date().getTime(),
                                Number(postData.c_t)
                              )}
                            </span>
                            {/* Post Trend */}
                            {postData.trend && (
                              <>
                                {postData.trend === "Bullish" ? (
                                  <span className='trend_post_tag bullish'>
                                    <MdOutlineTrendingUp className='trend_icon' />{" "}
                                    Bullish
                                  </span>
                                ) : (
                                  <span className='trend_post_tag bearish'>
                                    <BiTrendingDown className='trend_icon' />{" "}
                                    Bearish
                                  </span>
                                )}
                              </>
                            )}
                          </>
                          <div className='post_basic_info'>
                            {/* post privacy section */}
                            <span className='post_privacy'>
                              {postData.privacy === "Public" ? (
                                <AllIcon className='post_icon' />
                              ) : (
                                <>
                                  {postData.privacy === "follower" ? (
                                    <span class='icon-followers'></span>
                                  ) : (
                                    <span class='icon-only_me'></span>
                                  )}
                                </>
                              )}
                            </span>

                            {/* post paid promotion */}
                            <span className='post_paid_section'>
                              {postData.isPaid === "true" && (
                                <span className='paid_promotion'>
                                  Paid promotion with
                                  <Link
                                    to={`/group/${postData.blockId}`}
                                    className='cmpny_name'>
                                    {postData.cName}
                                  </Link>
                                </span>
                              )}
                            </span>
                            <br />
                            <span className='status_text'>
                              {postData.statusText}
                            </span>
                          </div>
                          {postData.userLocation && (
                            <div className='post_user_location'>
                              <MdLocationOn className='location_icon' />
                              {postData.userLocation}
                            </div>
                          )}
                          {/* post feelings */}
                          <div className='post_feelings'>
                            {postData.feelingIcon}
                            <span className='post_feelings_text'>
                              {postData.feeling}
                            </span>
                          </div>
                          {postData.city && postData.country && (
                            <div className='post_user_location'>
                              <MdLocationOn className='location_icon' />
                              {postData.city}, {postData.country}
                            </div>
                          )}
                        </div>
                      </div>
                      {!postData.is_share && (
                        <Menu
                          menuButton={
                            <MenuButton className={"social_post_menu_button"}>
                              <span class='icon-more'></span>
                            </MenuButton>
                          }>
                          {/* Pinned post menu button */}
                          {postData.u_dun === user.handleUn && (
                            <MenuItem
                              onClick={() => handleClickPin(postData.id)}
                              className={"social_post_menu_item"}>
                              {hasPinned > 0 ? (
                                <div id='pin' className='menu_item_section'>
                                  <AiOutlinePushpin
                                    id='pin'
                                    className='menu_item_icon'
                                  />
                                  {t("Unpin")}
                                </div>
                              ) : (
                                <div id='pin' className='menu_item_section'>
                                  {" "}
                                  <AiOutlinePushpin
                                    id='pin'
                                    className='menu_item_icon'
                                  />
                                  {t("Pin")}
                                </div>
                              )}
                            </MenuItem>
                          )}
                          {/* Bookmark post menu button */}]
                          {
                            <MenuItem
                              id='book'
                              className={"social_post_menu_item"}
                              onClick={() =>
                                HandleOpenBookmarkModal(postData.id)
                              }>
                              {bookmark.includes(user.handleUn) ? (
                                <div id='book' className='menu_item_section'>
                                  <BsBookmark
                                    id='book'
                                    className='menu_item_icon'
                                  />
                                  {t("Remove")}
                                </div>
                              ) : (
                                <div id='book' className='menu_item_section'>
                                  <BsBookmark
                                    id='book'
                                    className='menu_item_icon'
                                  />
                                  {t("Save")}
                                </div>
                              )}
                            </MenuItem>
                          }
                          {/* Hide post menu button */}
                          {
                            <MenuItem
                              id='hide'
                              onClick={() => handleOpenHideModal(postData.id)}
                              className={"social_post_menu_item"}>
                              <div id='hide' className='menu_item_section'>
                                {" "}
                                <MdOutlineHideSource
                                  id='hide'
                                  className='menu_item_icon'
                                />
                                {t("Hide")}
                              </div>
                            </MenuItem>
                          }
                          {/* Post analytics menu */}
                          {postData.u_dun === user.handleUn && (
                            <MenuItem
                              id='analytics'
                              className={"social_post_menu_item"}
                              onClick={() =>
                                handleRedirctToAnalytics(postData.id)
                              }>
                              <div id='analytics' className='menu_item_section'>
                                {" "}
                                <GrAnalytics
                                  id='analytics'
                                  className='menu_item_icon'
                                />
                                {t("Analytics")}
                              </div>
                            </MenuItem>
                          )}
                          {/* Post edit menu */}
                          {postData.u_dun === user.handleUn && (
                            <MenuItem
                              id='edit'
                              className={"social_post_menu_item"}
                              onClick={() => handleOpenEditModal(postData)}>
                              <div id='edit' className='menu_item_section'>
                                <AiOutlineEdit
                                  id='book'
                                  className='menu_item_icon'
                                />
                                {t("Edit")}
                              </div>
                            </MenuItem>
                          )}
                          {/* Post nft menu */}
                          {postData.u_dun === user.handleUn && (
                            <MenuItem
                              id='nft'
                              className={"social_post_menu_item"}>
                              <div id='nft' className='menu_item_section'>
                                {" "}
                                <BiBitcoin
                                  id='nft'
                                  className='menu_item_icon'
                                />
                                {t("Make nft")}
                              </div>
                            </MenuItem>
                          )}
                          {/* Share menu */}
                          {sharePrivacy === "all" ? (
                            <MenuItem
                              id='share'
                              className={"social_post_menu_item"}>
                              <div id='share' className='menu_item_section'>
                                <AiOutlineShareAlt
                                  id='share'
                                  className='menu_item_icon'
                                />
                                {t("Share")}
                              </div>
                            </MenuItem>
                          ) : (
                            <>
                              {postData.u_dun !== user.handleUn ? null : (
                                <MenuItem
                                  id='share'
                                  className={"social_post_menu_item"}>
                                  <div id='share' className='menu_item_section'>
                                    <AiOutlineShareAlt
                                      id='share'
                                      className='menu_item_icon'
                                    />
                                    {t("Share")}
                                  </div>
                                </MenuItem>
                              )}
                            </>
                          )}
                          {/* Delete post menu */}
                          {postData.u_dun === user.handleUn && (
                            <MenuItem
                              id='delete'
                              className={"social_post_menu_item"}
                              onClick={() =>
                                handleOpenDeleteModal(postData.id)
                              }>
                              <div id='delete' className='menu_item_section'>
                                {" "}
                                <AiOutlineDelete
                                  id='delete'
                                  className='menu_item_icon'
                                />
                                {t("Delete")}
                              </div>
                            </MenuItem>
                          )}
                          {/* privacy settings menu */}
                          {postData.u_dun === user.handleUn && (
                            <MenuItem
                              id='privacy'
                              onClick={() =>
                                handleOpenPrivacyModal(postData.id)
                              }
                              className={"social_post_menu_item"}>
                              <div id='privacy' className='menu_item_section'>
                                {" "}
                                <MdOutlinePrivacyTip
                                  id='privacy'
                                  className='menu_item_icon'
                                />
                                {t("Privacy")}
                              </div>
                            </MenuItem>
                          )}
                          {/* Post Report Modal */}
                          <MenuItem
                            id='hide'
                            onClick={() => handleOpenShareModal(postData.id)}
                            className={"social_post_menu_item"}>
                            <div id='hide' className='menu_item_section'>
                              {t("Report")}
                            </div>
                          </MenuItem>
                        </Menu>
                      )}
                    </div>

                    {/* Body */}
                    <div className='full_post_body'>
                      {postData.isDisable ? (
                        <div className='post_card_body post_disable_body'>
                          <div className='loader_stick'></div>
                          <div className='loader_stick'></div>
                        </div>
                      ) : (
                        <div className={"post_card_body"} id='post_body'>
                          <span className='main_body_text' id='post_body'>
                            {postData.content.split(" ").map((val, index) => (
                              <span
                                id='post_body'
                                key={index}
                                className={
                                  val.includes("#") ? "trend_tag" : ""
                                }>
                                {val.includes("@") ? (
                                  <Link
                                    to={`/user/profile/${checkMention(val)}`}
                                    className='mentions'>
                                    {" "}
                                    @{checkMention(val)}
                                  </Link>
                                ) : (
                                  <>
                                    {val.includes("$") ? (
                                      <Link
                                        to={`trending/posts/coin/${checkCryptoMention(
                                          val
                                        )}`}
                                        className='crypto_mentions'>
                                        {" "}
                                        ${checkCryptoMention(val)}
                                      </Link>
                                    ) : (
                                      <>{val} </>
                                    )}
                                  </>
                                )}
                              </span>
                            ))}
                          </span>
                          <br />
                          {postData.image && (
                            <img
                              src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.image}`}
                              className='post_card__body_image'
                              id='post_body'
                            />
                          )}
                          {postData.gif && (
                            <img
                              src={postData.gif}
                              className='post_card__body_image'
                              id='post_body'
                            />
                          )}
                          {postData.video && (
                            // <img   id="post_body" />
                            <video
                              controls
                              autoPlay
                              className='post_card__body_image'>
                              <source src={postData.video} />
                            </video>
                          )}
                        </div>
                      )}
                    </div>

                    {(coinDetails || []).length > 0 && (
                      <div className='_coin_obj_section'>
                        {coinDetails.map((data) => (
                          <>
                            {getPricePercentage(data.price) > 0 ? (
                              <div className='_coin_price_btn high_btn'>
                                <span className='coin_symbol high'>
                                  {data.id.toUpperCase()}
                                </span>
                                <span className='_coin_price high'>
                                  {getPricePercentage(data.price)}%
                                </span>
                              </div>
                            ) : (
                              <div className='_coin_price_btn low_btn'>
                                <span className='coin_symbol low'>
                                  {data.id.toUpperCase()}
                                </span>
                                <span className='_coin_price low'>
                                  {getPricePercentage(data.price)}%
                                </span>
                              </div>
                            )}
                          </>
                        ))}
                      </div>
                    )}
                  </div>

                  {/* Footer Section */}
                  <NormalPostFooter
                    postData={postData}
                    reposted={reposted}
                    setReposted={setReposted}
                  />
                </div>
                {/* Comment Section */}
                <div className='full_post_cmnt_form_container'>
                  {flag.includes(user.handleUn) ? (
                    <div className='flag_status_text'>
                      You marked this post as flag so you cannot comment on this
                      post
                    </div>
                  ) : (
                    <>
                      {!isVisble ? (
                        <div className='post_cmnt_form_input'>
                          <input
                            type='text'
                            placeholder='Enter your comment'
                            className='comment_input'
                            onFocus={() => setIsVisble(true)}
                          />
                        </div>
                      ) : (
                        <div className='post_cmnt_form_input focused_input'>
                          {/* Preview image */}
                          {prevImage && (
                            <div className='prev_image_section'>
                              <img src={prevImage} className='prev_image_box' />
                              <button
                                className='close_btn_prev'
                                onClick={closeCmntImage}>
                                <AiOutlineClose />
                              </button>
                            </div>
                          )}

                          {/* Show crypto coins */}
                          {openCryptoList && (
                            <div
                              className='cryto_list_section'
                              onScroll={(e) => handleScrollToFetchCoins(e)}>
                              {(searchCoinList || []).length > 0 ? (
                                <React.Fragment>
                                  {searchCoinList.map((data) => (
                                    <div
                                      className='mention_user_card'
                                      onClick={() =>
                                        handleSelectMentionCoin(data)
                                      }>
                                      <img
                                        src={`https://assets.coingecko.com/coins/images/${data.image}`}
                                        className='user_small_avatar'
                                      />

                                      <span className='user_list_name'>
                                        {data.name}
                                      </span>
                                    </div>
                                  ))}
                                </React.Fragment>
                              ) : (
                                <div>No user found</div>
                              )}
                            </div>
                          )}
                          <input
                            type='text'
                            placeholder='Enter your comment'
                            className='comment_input'
                            value={text}
                            onKeyDown={(e) => handleKeyChange(e)}
                            onChange={(e) =>
                              setText(e.target.value.slice(0, 100))
                            }
                          />
                          {/* Gallery */}
                          <label
                            htmlFor='post_comment_file'
                            className='full_post_cmnt_icon_btn'>
                            <span class='icon-gallery'></span>
                          </label>
                          <input
                            type='file'
                            id='post_comment_file'
                            className='input_file'
                            onChange={(e) => handleImageChange(e)}
                            accept='image/*'
                          />

                          {/* Emoji */}
                          <button
                            className='full_post_cmnt_icon_btn'
                            onClick={() => setOpenEmojiIcons(true)}>
                            <span class='icon-emogy'></span>
                          </button>

                          {isBtnDisable ? null : (
                            <button
                              className='full_post_cmnt_icon_btn'
                              onClick={() => handlePostComment(postData.id)}>
                              <AiOutlineSend
                                className={
                                  isBtnDisable ? "disable" : "not_disable"
                                }
                              />
                            </button>
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/* Post Comment Section */}
                <div className='full_post_comment_section'>
                  {cmntLoading ? (
                    <div className='full_post_cmnt_loading'>Loading....</div>
                  ) : (
                    <React.Fragment>
                      {(comments || []).length > 0 ? (
                        <>
                          {comments.map((data) => (
                            <PostCommentCard
                              key={data.id}
                              commentData={data}
                              owner={postData.u_dun}
                            />
                          ))}
                          {currentCommentCount < limit ? null : (
                            <div className='load_more_btn_container'>
                              {isLoadingBtn ? (
                                <div className='loading'>
                                  <BiLoaderAlt className='spinner' />
                                </div>
                              ) : (
                                <button
                                  className='load_more_btn'
                                  onClick={incrementPage}>
                                  Load more
                                </button>
                              )}
                            </div>
                          )}
                        </>
                      ) : (
                        <div className='empty_cmnt_section'>
                          No comment found
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default FullPostComp;
