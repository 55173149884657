/** @format */

const ActionTypes = {
  NEW_COMMENT: "NEW_COMMENT",
  UPDATE_COMMENT: "UPDATE_COMMENT",
  DELETE_COMMENT: "DELETE_COMMENT",
  REMOVE_ALL_COMMENTS: "REMOVE_ALL_COMMENTS",
  ADD_NEW_COMMENT: "ADD_NEW_COMMENT",

  SET_COMMENT: "SET_COMMENT",
  PUT_COMMENT_LAST: "PUT_COMMENT_LAST",
};

export default ActionTypes;
