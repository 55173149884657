/** @format */

import * as React from "react";
import { BiArrowBack } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import UserAvatar from "../../../Assets/userAvatar.webp";
import { AiOutlineSearch } from "react-icons/ai";
import { BiMenu } from "react-icons/bi";
import "./TestHeader.css";
import MenuComp from "./MenuComp";
import "dexbrosicons/style.css";
import {
  scrollAxis,
  pageTypeSelector,
} from "../../../redux/_page/pageSelectors";

import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import {
  setNotificationCount,
  resetNotificationCount,
} from "../../../redux/_notification/notificationSlice";
import { selectNotificationCount } from "../../../redux/_notification/notificationSelectors";

import { ThemeContext } from "../../Common/ThemeProvider";
import { BsCashCoin } from "react-icons/bs";
let useClickOutside = (handler) => {
  let domNode = React.useRef();
  const [openMenu, setOpenMenu] = React.useState(false);

  React.useEffect(() => {
    let maybeHandler = (event) => {
      if (!domNode.current.contains(event.target)) {
        handler();
      }
    };

    document.addEventListener("mousedown", maybeHandler);

    return () => {
      document.removeEventListener("mousedown", maybeHandler);
    };
  });

  return domNode;
};

const SocialHeader = () => {
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const pageType = useSelector(pageTypeSelector);
  const token = useSelector(selectToken);
  const axisValue = useSelector(scrollAxis);
  const isToken = useSelector(selectToken);
  const notificationCount = useSelector(selectNotificationCount);
  const navigate = useNavigate();
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openNotificationMenu, setOpenNotificationMenu] = React.useState(false);
  const [notifications, setNotifications] = React.useState([]);
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);
  const dynamicStyles = {
    "--theme-color": themeColor,
    "--font-size": fontSize,
  };

  React.useEffect(() => {
    console.log("openMenu useEffect:", openMenu);
  }, [openMenu]);

  const openDropDownMenu = () => {
    console.log("open dropDown:", openMenu);
    console.log("reverse open dropDown:", !openMenu);
    // setOpenMenu((p) => !p);
  };

  const menuRef = React.useRef(null);
  let domNode = useClickOutside(() => {
    // setOpenMenu(false);
    setOpenNotificationMenu(false);
  });

  const handleOpenNotification = () => {
    dispatch(resetNotificationCount());
    setOpenNotificationMenu((p) => !p);
    // fetchNotification();
    // *** Redirect to notification page
    navigate("/notifications");
  };

  const fetchNotification = () => {
    var axios = require("axios");

    var config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL_LINK}api/notifications/fetch/notification/count`,
      headers: {
        Authorization: "Bearer " + isToken,
      },
    };

    axios(config)
      .then(function (response) {
        dispatch(setNotificationCount(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  React.useEffect(() => {
    fetchNotification();
  }, []);

  React.useEffect(() => {
    const currentURL = window.location.href;
    console.log("Current URL:", currentURL);
    const savedUrl = localStorage.getItem("currenturl");
    console.log("Save URL:", savedUrl);
    if (currentURL === savedUrl) {
      console.log("SAME");
    } else {
      console.log("OTHER");
      localStorage.setItem("currenturl", currentURL);
    }
  }, []);

  const handlePreviousPage = () => {
    navigate("/");
  };

  const handleOpenMenu = () => {
    setOpenMenu(true);
    window.location.hash = "#profile-menu";
  };

  // theme
  return (
    <>
      <div
        className={
          axisValue === "Up"
            ? `mobile_navbar_section ${theme}`
            : `hidden_mobile_navbar_section ${theme}`
        }
      >
        {/* Logo Section */}
        <div className="navbar_logo">
          {pageType === "social" ? (
            <button className="navbar_logo_btn">
              <span className="icon icon-d_icon navbar_logo_icon"></span>
            </button>
          ) : (
            <div className="header_back_section">
              <button
                className={`back_btn ${theme}`}
                onClick={handlePreviousPage}
              >
                <BiArrowBack />
              </button>
              <span className="_page_title_name">
                {pageType.charAt(0).toUpperCase() + pageType.slice(1)}
              </span>
            </div>
          )}
        </div>

        {/* Other menu */}
        <div className="__other_menu_section" ref={domNode}>
          {pageType === "social" && (
            <button
              className={`navbar_icon_btn ${theme}`}
              onClick={() => navigate("/earning/center")}
            >
              <BsCashCoin size={27} />
            </button>
          )}
          {/* Search icon */}
          <button
            className={`navbar_icon_btn ${theme}`}
            onClick={() => navigate("/search/page")}
          >
            <AiOutlineSearch size={27} />
          </button>

          {/* Notification icon */}
          <button
            className={`notification_nav_icon ${theme}`}
            onClick={handleOpenNotification}
          >
            <span class="icon-notification_i"></span>
            {notificationCount > 0 && (
              <span className="notification_count">{notificationCount}</span>
            )}
          </button>

          {/* Profile menu */}
          {pageType === "social" && (
            <div className="profile_menu" onClick={handleOpenMenu}>
              <img
                src={
                  user.p_i
                    ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${user.p_i}`
                    : UserAvatar
                }
                className="avatar_menu_btn"
              />
              <BiMenu className="profile_menu_icon" />
            </div>
          )}
        </div>
      </div>

      {openMenu && (
        <div className={`menu_component ${theme}`}>
          <MenuComp setOpenMenu={setOpenMenu} />
        </div>
      )}
    </>
  );
};

export default SocialHeader;
