/** @format */

import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SiBlockchaindotcom } from "react-icons/si";
import { GrUserSettings } from "react-icons/gr";
import { CgTrending } from "react-icons/cg";
import { CgFeed } from "react-icons/cg";
import { BsCameraReels } from "react-icons/bs";
import { BiBadgeCheck } from "react-icons/bi";
import { GrGamepad } from "react-icons/gr";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import CustomSmallModal from "../../modal/CustomSmallModal";
import { ThemeProvider, ThemeContext } from "../../Common/ThemeProvider";
import { encryptData } from "../../../utils/encrypt";
import { BiArrowBack } from "react-icons/bi";
import UserAvatar from "../../../Assets/userAvatar.webp";
import { useSelector, useDispatch } from "react-redux";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import { setLogout } from "../../../redux/_user/userSlice";
import { maskString } from "../../../utils/maskString";
import { FaRegCopy, FaCopy } from "react-icons/fa";
import {
  appendMyBlock,
  addBlocks,
  fetchMyBlock,
} from "../../../redux/_block/blockSlice";
import { selectMyGroup } from "../../../redux/_block/blockSelectors";
import GroupAvatar from "../../../Assets/group.webp";
import { ReactComponent as News } from "../../../Assets/menu_set/news.svg";
import { ReactComponent as Home } from "../../../Assets/menu_set/home.svg";
import { ReactComponent as Nft } from "../../../Assets/menu_set/nft.svg";
import { ReactComponent as Trending } from "../../../Assets/menu_set/trending.svg";
import { ReactComponent as Activity } from "../../../Assets/menu_set/activity.svg";
import { ReactComponent as Block } from "../../../Assets/menu_set/block.svg";
import { ReactComponent as Blockcast } from "../../../Assets/menu_set/blockcast.svg";
import { ReactComponent as Gaming } from "../../../Assets/menu_set/gaming.svg";
import { ReactComponent as Wallet } from "../../../Assets/menu_set/wallet.svg";
import { ReactComponent as Reels } from "../../../Assets/menu_set/reels.svg";
import { ReactComponent as AccountSettings } from "../../../Assets/menu_set/account_settings.svg";
import { ReactComponent as AccountBadge } from "../../../Assets/menu_set/account_badge.svg";
import { ReactComponent as AccountAnalytics } from "../../../Assets/menu_set/account_analytics.svg";
import { ReactComponent as DisplaySettings } from "../../../Assets/menu_set/display_settings.svg";
import { ReactComponent as EarningCenter } from "../../../Assets/menu_set/earning_center.svg";
import { ReactComponent as BackIcon } from "../../../Assets/Icons/arrow1.svg";
// display_settings

const MenuComp = ({ setScrollAxis, setOpenMenu }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isToken = useSelector(selectToken);
  const isUser = useSelector(selectUser);
  const myBlock = useSelector(selectMyGroup);
  const [openModal, setOpenModal] = React.useState(false);
  const [isCopy, setIsCopy] = React.useState(false);
  const [hashData, setHashData] = React.useState("");

  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [count, setCount] = React.useState(5);

  const {
    theme,
    toggleTheme,
    themeColor,
    changeThemeColor,
    fontSize,
    changeFontSize,
  } = useContext(ThemeContext);
  console.log("themeColor:", themeColor);
  const dynamicStyles = {
    // backgroundColor: themeColor,
    "--font-size": `${fontSize}px`,
  };
  const [balance, setBalance] = React.useState(0);

  const handleLogout = (e) => {
    e.preventDefault();
    dispatch(setLogout(isUser));
  };

  const handleRedirect = (tab) => {
    const queryParams = new URLSearchParams();
    queryParams.set("main-tab", tab);
    const temp = window.location.href;
    const arr = temp.split("/");
    const result = arr.splice(arr.length - 1, 1);

    const currentUrl = `${arr.join("/")}/?${queryParams.toString()}`;
    console.log("currentUrl:", currentUrl);
    navigate(`/?${queryParams.toString()}`);
    setOpenMenu(false);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  React.useEffect(() => {
    const axios = require("axios");
    // url = /api/dex/balance/${data.token}/${data.accountName}
    // eta token = "ETH" r accountName = "bruno_da_legend" request koris
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `https://wallet.websiteclubs.com/api/dex/balance/${"ETH"}/${
        isUser.handleUn
      }`,
      headers: {
        Authorization: "Bearer " + isToken,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setBalance(response.data.balance);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  function copyToClipboardUsingAPI(text) {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setIsCopy(true);
        setTimeout(() => {
          setIsCopy(false);
        }, 1000);
      })
      .catch((err) => {
        console.error("Unable to copy text to clipboard:", err);
      });
  }

  React.useEffect(() => {
    async function fetchData() {
      const data = { token: isToken, page, limit };
      const result = await dispatch(fetchMyBlock(data));

      setCount(result.length);
      if (page === 1) {
        dispatch(addBlocks(result));
      } else {
        // Append new blocks
        dispatch(appendMyBlock(result));
        console.log(result);
      }
    }
    fetchData();
  }, [page]);

  const handleRedirectToGroup = (id) => {
    // e.stopPropagation();
    // console.log("Outer")
    navigate(`/group/${id}`);
  };

  const handleCloseMenu = () => {
    setOpenMenu(false);
    navigate(-1);
  };

  return (
    <div className="menu_page_container">
      {/* Profile section */}
      {openModal && (
        <CustomSmallModal
          onClose={onClose}
          title={"Customize your view"}
          body={
            <div className="theme_modal">
              {/* Demo text */}

              {/* Font size section */}
              <div className="font_section">
                <span className="theme_section_header">Font Size</span>
                {/* Demo card */}
                <div className="demo_card">
                  {/* Demo card header */}
                  <div className="demo_card_header">
                    <img
                      src={
                        isUser.p_i
                          ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${isUser.p_i}`
                          : UserAvatar
                      }
                      className="demo_card_img"
                    />
                    <span className="demo_card_name">
                      {isUser.fn} {isUser.ln}
                    </span>
                  </div>

                  {/* Demo card body */}
                  <div className="demo_card_body" style={dynamicStyles}>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s
                  </div>
                </div>

                {/* Range */}
                <label for="points">Pixels (between 6 and 30):</label>
                <input
                  type="range"
                  id="points"
                  name="points"
                  value={fontSize}
                  onChange={(e) => changeFontSize(e.target.value)}
                  min="14"
                  max="25"
                ></input>
              </div>
              {/* Color change section */}
              <div className="theme_section">
                <span className="theme_section_header">Color</span>
                <br />
                <button className="demo_btn">Demo button</button>
                <div className="color_selector_section">
                  <div
                    className="color_selector"
                    onClick={() => changeThemeColor("#ffd401")}
                    style={{ backgroundColor: "#ffd401" }}
                  ></div>

                  <div
                    className="color_selector"
                    onClick={() => changeThemeColor("#f91810")}
                    style={{ backgroundColor: "#f91810" }}
                  ></div>

                  <div
                    className="color_selector"
                    onClick={() => changeThemeColor("#00ba7b")}
                    style={{ backgroundColor: "#00ba7b" }}
                  ></div>

                  <div
                    className="color_selector"
                    onClick={() => changeThemeColor("#7756ff")}
                    style={{ backgroundColor: "#7756ff" }}
                  ></div>

                  <div
                    className="color_selector"
                    onClick={() => changeThemeColor("#1d9bf0")}
                    style={{ backgroundColor: "#1d9bf0" }}
                  ></div>
                </div>
              </div>

              {/* Background color section */}
              <div className="theme_section">
                <span className="theme_section_header">Background</span>
                <div className="theme_box_section">
                  <div
                    className="theme_box"
                    onClick={() => toggleTheme("light")}
                  >
                    {theme === "light" ? (
                      <BiRadioCircleMarked className="theme_icon" />
                    ) : (
                      <BiRadioCircle className="theme_icon" />
                    )}
                    <span className="theme_text">Light</span>
                  </div>

                  <div
                    className="theme_box dimColor"
                    onClick={() => toggleTheme("dim")}
                  >
                    {theme === "dim" ? (
                      <BiRadioCircleMarked className="theme_icon" />
                    ) : (
                      <BiRadioCircle className="theme_icon" />
                    )}
                    <span className="theme_text">Dim</span>
                  </div>

                  <div
                    className="theme_box darkColor"
                    onClick={() => toggleTheme("dark")}
                  >
                    {theme === "dark" ? (
                      <BiRadioCircleMarked className="theme_icon" />
                    ) : (
                      <BiRadioCircle className="theme_icon" />
                    )}
                    <span className="theme_text">Dark</span>
                  </div>
                </div>
              </div>
            </div>
          }
          footer={<></>}
        />
      )}

      <div className="menu_header_section">
        <button className="menu_header_btn" onClick={() => handleCloseMenu()}>
          <BackIcon className="custom_icon" />
        </button>
        <span className="menu_header_text__header">Menu</span>
      </div>
      <div className="meanu_page_inner_section">
        <div
          className="menu_profile_section"
          onClick={() => navigate(`/user/profile/${isUser.handleUn}`)}
        >
          <div className="social_profile_menu">
            <img
              src={
                isUser.p_i
                  ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${isUser.p_i}`
                  : UserAvatar
              }
              className="profile_menu_avatar"
            />
            <div className="menu_user_info">
              <span className="social_menu_user_name">
                {isUser.fn} {isUser.ln}
              </span>

              <span className="social_menu_user_username">
                @{isUser.handleUn}
              </span>
            </div>
          </div>
        </div>

        {/* Wallet */}
        <div className="menu_wallet_section">
          <div className="wallet_title">Wallet Details</div>
          <div className="user_wallet">
            <div className="wallet_section_one">
              <span className="wallet_address">
                {isUser.w_address && (
                  <>
                    {maskString(isUser.w_address)}{" "}
                    <span
                      className="menu_copy_icon"
                      onClick={() => copyToClipboardUsingAPI(isUser.w_address)}
                    >
                      {isCopy ? <FaCopy /> : <FaRegCopy />}
                    </span>
                  </>
                )}
                x0en...eute
              </span>
              <span className="wallet_section_title">Address </span>
            </div>

            <div className="wallet_section_one">
              <span className="wallet_amount">{balance}200 ORO</span>
              <span className="wallet_section_title">Balance </span>
            </div>
          </div>
        </div>

        {/* Blocks */}
        <>
          {(myBlock || []).length > 0 && (
            <>
              <div className="menu_block_title">Block shortcut</div>
              <div className="my_block_menu_container">
                {myBlock.map((groupData) => (
                  <div
                    className="my_group_menu_card"
                    key={groupData.g_id}
                    onClick={(e) => handleRedirectToGroup(groupData.g_id)}
                  >
                    <img
                      src={
                        groupData.g_p_img
                          ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${groupData.g_p_img}`
                          : GroupAvatar
                      }
                      className="__menu_block_avatar"
                    />
                    <div className="__menu_block_card_name">
                      {groupData.g_n}
                    </div>
                  </div>
                ))}
              </div>
            </>
          )}
        </>

        <div className="menu_other_link">
          <span className="menu_header_text">All shortcuts</span>

          <div className="menu_sortcut_links">
            {/* Feed */}
            <div
              className="link_container"
              onClick={() => handleRedirect("home")}
            >
              {/* <CgFeed className='menu_link_icon' /> */}
              <div className="link__container__icon">
                <Home className="tab_home_icon custom_icon" />
              </div>

              <span className="_menu_link_text">Feed</span>
            </div>

            {/* Reels */}
            <a
              href={`https://creels.websiteclubs.com/?un=${hashData})}`}
              className="link_container"
            >
              <div className="link__container__icon">
                <Reels className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Reel</span>
            </a>

            {/* Trending */}
            <div
              className="link_container"
              onClick={() => handleRedirect("trending")}
            >
              {/* <span class='icon-trending_two tab_home_icon'></span> */}
              <div className="link__container__icon">
                <Trending className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Trending</span>
            </div>

            {/* News Link */}
            <div
              className="link_container"
              onClick={() => handleRedirect("news")}
            >
              {/* <span class='icon-news'></span> */}
              <div className="link__container__icon">
                <News className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">News</span>
            </div>

            {/* Activity Link */}
            <div className="link_container" onClick={() => navigate("news")}>
              {/* <span class='ico-_activity_three'></span> */}
              <div className="link__container__icon">
                <Activity className="tab_home_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Activity</span>
            </div>

            {/* Block Link */}
            <div className="link_container" onClick={() => navigate(`/group`)}>
              {/* <SiBlockchaindotcom className='menu_link_icon' /> */}
              <div className="link__container__icon">
                <Block className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Block</span>
            </div>

            {/* Blockcast Link */}
            <div
              className="link_container"
              onClick={() => navigate(`/blockcast`)}
            >
              <div className="link__container__icon">
                <Blockcast className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Blockcast</span>
            </div>

            {/* Gaming */}
            <a
              className="link_container"
              href={`https://csports.websiteclubs.com/?un=${hashData})}`}
            >
              <div className="link__container__icon">
                <Gaming className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Gaming</span>
            </a>

            {/* Crypto Gaming */}
            {/* <a
              className='link_container'
              href={`https://ccrypto.websiteclubs.com/?un=${hashData})}`}>
              <GrGamepad className='menu_link_icon' />
              <br />
              <span className='_menu_link_text'>Crypto Gaming</span>
            </a> */}

            {/* NFT */}
            <div className="link_container" onClick={() => navigate("/nft")}>
              <div className="link__container__icon">
                <Nft className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">NFT</span>
            </div>

            {/* Wallet Link */}
            <a
              className="link_container"
              href={`https://cwallet.websiteclubs.com/?un=${hashData})}`}
            >
              {/* <span class='icon-walet-one tab_home_icon'></span> */}
              <div className="link__container__icon">
                <Wallet className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Wallet</span>
            </a>

            <div
              className="link_container"
              onClick={() => navigate("/earning/center")}
            >
              <div className="link__container__icon">
                <EarningCenter className="tab_home_icon custom_icon" />
              </div>
              <br />
              <span className="_menu_link_text">Earn center</span>
            </div>
          </div>
        </div>

        {/* Account Settings */}
        <div className="menu_account_settings">
          <span className="menu_header_text">Account shortcuts</span>
          <div className="menu_sortcut_links">
            {/* Account settings */}
            <div
              className="link_container"
              onClick={() => navigate(`/profile/update/${isUser.handleUn}`)}
            >
              {/* <GrUserSettings className='menu_link_icon' /> AccountSettings */}
              <div className="link__icon__whole__container">
                <div className="link__container__icon">
                  <AccountSettings className="tab_home_icon custom_icon" />
                </div>
              </div>

              <span className="_menu_link_text">Account settings</span>
            </div>

            {/* Account badge */}
            <div className="link_container" onClick={() => navigate(`/badge`)}>
              <div className="link__icon__whole__container">
                <div className="link__container__icon">
                  <AccountBadge className="tab_home_icon custom_icon" />
                </div>
              </div>

              <span className="_menu_link_text">Account badge</span>
            </div>

            {/* Account analytics */}
            <div
              className="link_container"
              onClick={() => navigate(`/profile/analytics/${isUser.handleUn}`)}
            >
              {/* <DisplaySettings className='menu_link_icon' /> */}
              <div className="link__icon__whole__container">
                <div className="link__container__icon">
                  <AccountAnalytics className="tab_home_icon custom_icon" />
                </div>
              </div>
              <br />
              <span className="_menu_link_text">Account analytics</span>
            </div>

            {/* Display settings */}
            <div className="link_container" onClick={() => setOpenModal(true)}>
              <div className="link__icon__whole__container">
                <div className="link__container__icon">
                  <DisplaySettings className="tab_home_icon custom_icon" />
                </div>
              </div>

              <br />
              <span className="_menu_link_text">Display Settings</span>
            </div>
          </div>
        </div>

        {/* Logout button */}
        <div className="logout_container">
          <button
            className="menu_logout_btn"
            onClick={handleLogout}
            style={dynamicStyles}
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default MenuComp;
