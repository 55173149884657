/** @format */

import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import UserAvatar from "../../Assets/userAvatar.webp";
import timeDifference from "../../utils/getCreateTime";
import NormalPost from "./NormalPost";
import NewsPost from "./NewsPost";
import InformativePost from "./InformativePost";
import AnnouncementPost from "./AnnouncementPost";
import CustomModal from "../modal/CustomModal";
import { useNavigate } from "react-router-dom";
import { BsFillPatchCheckFill } from "react-icons/bs";

const FullPostWithoutToken = ({ post }) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const onClose = () => {
    setOpenModal(false);
  };

  return (
    <div>
      {post ? (
        <div>
          {openModal && (
            <CustomModal
              onClose={onClose}
              title={"Register & Login"}
              body={
                <div className='register_login_section'>
                  <button
                    className='__login_header_btn'
                    onClick={() => navigate("/login")}>
                    Login
                  </button>
                  <br />
                  <button
                    className='__register_header_btn'
                    onClick={() => navigate("/register")}>
                    Register
                  </button>
                </div>
              }
              footer={<></>}
            />
          )}
          {!post.isDelete && (
            <div
              className={`social_media_post_card ${post.postOf}`}
              onClick={() => handleOpenModal()}>
              <div className='social_post_main_header'>
                <div className='social_media_post_card_header'>
                  <img
                    src={
                      post.u_img
                        ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${post.u_img}`
                        : UserAvatar
                    }
                    className='full_posted_user_avatar'
                  />

                  {/* Post creator personal info */}
                  <div className='_post_creator_info'>
                    {/* creator name */}
                    {post.g_n ? (
                      <span className='creator_name' id='profile'>
                        {post.g_n}
                      </span>
                    ) : (
                      <span className='creator_name' id='profile'>
                        {post.u_fn} {post.u_ln}
                      </span>
                    )}

                    {/* Badge */}
                    {post.badges === "claim_sub" ? (
                      <BsFillPatchCheckFill className='subscriber_badge' />
                    ) : (
                      <>
                        {post.badges === "claim_cc" ? (
                          <BsFillPatchCheckFill className='cc_badge' />
                        ) : null}
                      </>
                    )}

                    {/* creator username */}
                    {!post.g_n && (
                      <span className='creator_username'>@{post.u_dun}</span>
                    )}

                    {/* Post create time */}
                    <span className='creator_time'>
                      {timeDifference(new Date().getTime(), Number(post.c_t))}
                    </span>
                  </div>
                </div>
              </div>

              <div className={"post_card_body"}>
                <span className='main_body_text' id='post_body'>
                  {post.content.split(" ").map((val, index) => (
                    <span
                      id='post_body'
                      key={index}
                      className={val.includes("#") ? "trend_tag" : ""}>
                      {val.includes("@") ? (
                        <Link
                          to={`/user/profile/${checkMention(val)}`}
                          className='mentions'>
                          {" "}
                          @{checkMention(val)}
                        </Link>
                      ) : (
                        <>
                          {val.includes("$") ? (
                            <Link
                              to={`trending/posts/coin/${checkCryptoMention(
                                val
                              )}`}
                              className='crypto_mentions'>
                              {" "}
                              ${checkCryptoMention(val)}
                            </Link>
                          ) : (
                            <>{val} </>
                          )}
                        </>
                      )}
                    </span>
                  ))}
                </span>

                {post.image && (
                  <img
                    src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${post.image}`}
                    className='post_card__body_image'
                    id='post_body'
                  />
                )}
              </div>

              {post.postOf === "np" ? (
                <NormalPost postData={post} />
              ) : (
                <>
                  {post.postOf === "news" ? (
                    <NewsPost postData={post} />
                  ) : (
                    <>
                      {post.postOf === "anc" ? (
                        <AnnouncementPost postData={post} />
                      ) : (
                        <InformativePost postData={post} />
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      ) : (
        <div className='empty_post'>No post found</div>
      )}
    </div>
  );
};
export default FullPostWithoutToken;
