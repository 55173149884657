/** @format */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./SocialDownNavbar.css";
import { ThemeContext } from "../../Common/ThemeProvider";
import { ReactComponent as Block } from "../../../Assets/LeftSidebarIcon/block.svg";
import { ReactComponent as Profile } from "../../../Assets/LeftSidebarIcon/profile.svg";
import { ReactComponent as Blockcast } from "../../../Assets/LeftSidebarIcon/blockcast.svg";
import { ReactComponent as Reels } from "../../../Assets/LeftSidebarIcon/reels.svg";
import { ReactComponent as Nft } from "../../../Assets/LeftSidebarIcon/nft.svg";
import { ReactComponent as Gaming } from "../../../Assets/LeftSidebarIcon/gaming.svg";
import { ReactComponent as Wallet } from "../../../Assets/LeftSidebarIcon/wallet.svg";
import ExternalLinkLoader from "../../../components/SkeletonLoading/ExternalLinkLoader";
import LoadingOverlay from "../../../components/Common/LoadingOverlay";

import { useTranslation } from "react-i18next";

import Apiip from "apiip.net";
const apiip = Apiip("28052519-acc6-412a-8469-961eca613fbe");

const SocialDownNav = () => {
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  const [loadingType, setLoadingType] = useState("");
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const [iframeSrc, setIframeSrc] = useState("");

  const handleSubLinkClick = (type) => {
    console.log("here the type", type);
    setLoadingType(type);
    setIframeLoaded(false);
    if (type === "nft") {
      setIframeSrc(`${REACT_APP_NAV_NFT}`);
    } else if (type === "wallet") {
      setIframeSrc(`${process.env.REACT_APP_NAV_WALLET}`);
    } else if (type === "gaming") {
      setIframeSrc(`${process.env.REACT_APP_NAV_GAMING}`);
    } else if (type === "reels") {
      setIframeSrc(`${process.env.REACT_APP_NAV_REELS}`);
    }
    // Set the source when button is clicked
  };

  const onIframeLoad = () => {
    setIframeLoaded(true);
    setLoadingType("");
    // Proceed to navigate to the URL directly
    window.location.href = iframeSrc;
    setIframeSrc(""); // Clear the source to prevent re-loading
  };

  return (
    <div className={`down_navbar_container ${theme}`}>
      {/* Post modal form */}
      {loadingType && <ExternalLinkLoader />}

      <button
        className={`down_nav_brn ${theme}`}
        onClick={() => navigate("/group")}
      >
        {/* <span class='icon-join_blockcast tab_home_icon'></span> */}
        <Block className="tab_home_icon custom_icon" />
        <br />
        <span className="down_nav_icon_text">{t("Blockcast")}</span>
      </button>

      {/* Reels */}
      <button
        className={`down_nav_brn ${theme}`}
        onClick={() => handleSubLinkClick("reels")}
      >
        {/* <span class='icon-reels_one'></span> */}
        <LoadingOverlay isLoading={loadingType === "reels"} />

        <Reels className="custom_icon tab_home_icon" />
        <br />
        <span className="down_nav_icon_text">{t("reel")}</span>
      </button>

      {/* Post create button */}
      <button
        className={`down_nav_brn ${theme}`}
        onClick={() => handleSubLinkClick("nft")}
      >
        <LoadingOverlay isLoading={loadingType === "nft"} />

        <Nft className="custom_icon tab_home_icon" />
        <br />
        <span className="down_nav_icon_text">{t("NFT")}</span>
      </button>

      <button className={`down_nav_brn ${theme}`}>
        {/* <span class='icon-gaming'></span> */}
        <LoadingOverlay isLoading={loadingType === "gaming"} />
        {/* <span class='icon-walet-one tab_home_icon'></span> */}
        <Gaming className="custom_icon tab_home_icon" />
        <br />
        <span className="down_nav_icon_text">{t("gaming")}</span>
      </button>

      <button
        className={`down_nav_brn ${theme}`}
        onClick={() => handleSubLinkClick("wallet")}
      >
        <LoadingOverlay isLoading={loadingType === "wallet"} />
        {/* <span class='icon-walet-one tab_home_icon'></span> */}
        <Wallet className="custom_icon tab_home_icon" />
        <br />
        <span className="down_nav_icon_text">{t("wallet")}</span>
      </button>

      {/* Conditionally render iframe only when src is set */}
      {iframeSrc && (
        <iframe
          src={iframeSrc}
          onLoad={onIframeLoad}
          style={{ display: "none" }}
        />
      )}
    </div>
  );
};

export default SocialDownNav;
