/** @format */

import React from "react";
import { GrGallery } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { AiOutlineCamera } from "react-icons/ai";
import "./BlockMain.css";
import { io } from "socket.io-client";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import ChatSkeleton from "../../components/SkeletonLoading/ChatSkeleton";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import MessageBody from "../../components/MessageBody/MessageBody";
import { AiOutlineClose } from "react-icons/ai";

import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "../../redux/_user/userSelectors";
import {
  seleteSelectBlock,
  selectComments,
} from "../../redux/_blockcast/blockcastSelectos";
import {
  handleCreateComment,
  appendComments,
  handleFetchComment,
  handleReplyMessage,
  handleFetchBlockcast,
  setSelectBlock,
} from "../../redux/_blockcast/blockcastSlice";
import { useTranslation } from "react-i18next";
import EmojiPicker from "emoji-picker-react";
import timeDifference from "../../utils/getCreateTime";
import { FaReply } from "react-icons/fa";

const CommentsPage = () => {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const selectBlock = useSelector(seleteSelectBlock);
  const comments = useSelector(selectComments);

  const { id } = useParams();
  // const [selectBlock, setselectBlock] = React.useState(null);
  const [message, setMessage] = React.useState("");
  const [gif, setGif] = React.useState("");
  const [image, setImage] = React.useState("");
  const [prevImage, setPrevImage] = React.useState("");
  const [openEmoji, setOpenEmoji] = React.useState(false);
  const [typing, setTyping] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);
  const [commentsData, setCommentsData] = React.useState([]);
  // who can send messages in blockcast channel
  const [loading, setLoading] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [pageIncementLoader, setPageIncrementLoader] = React.useState(false);
  const [openReply, setOpenReply] = React.useState(false);
  const [selectMsgData, setSelectMsgData] = React.useState(null);
  // const [message, setMessage] = React.useState("");
  const [canSendMessage, setCanSendMessage] = React.useState(false);

  // console.log(">>> ", blockCast);
  useSocket();

  const bottomRef = React.useRef(null);
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  React.useEffect(() => {
    socket
      .off("block comment received")
      .on("block comment received", (newMessage) => {
        console.log(newMessage);
        dispatch(appendComments(newMessage));
      })
      .on("typing", () => setIsTyping(true))
      .on("stop typing", () => setIsTyping(false));
  });

  const handleSubmitComment = async () => {
    const data = {
      token,
      id,
      post: {
        content: message,
        gif: gif,
        image: image,
      },
    };
    const result = await dispatch(handleCreateComment(data));
    console.log("Comment Result >> ", result);
    dispatch(appendComments(result));
    setCommentsData((prev) => [...prev, result]);
    setMessage("");
    setPrevImage("");
    setImage("");
    setGif("");
    socket.emit("block comment", result);
    scrollToBottom();
    setCanSendMessage(false);
    setTimeout(() => {
      setCanSendMessage(true);
    }, 60 * 1000); // 1 minute in milliseconds
  };

  const fetchData = async (id) => {
    const data = { token, id };
    const result = await dispatch(handleFetchBlockcast(data));
    dispatch(setSelectBlock(result));
  };

  React.useEffect(() => {
    fetchData(id);
  }, [id]);

  const handleImageChange = (e) => {
    setPrevImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const menuRef = React.useRef(null);
  React.useEffect(() => {
    const handler = (event) => {
      if (!menuRef.current.contains(event.target)) {
        setOpenEmoji(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  // fetch comments
  const fetchComments = async () => {
    if (page === 1) {
      setLoading(true);
    }
    const data = { token, id, page: page, limit: limit };
    const result = await dispatch(handleFetchComment(data));

    if (page === 1) {
      scrollToBottom();
      setCommentsData(result.reverse());
    } else {
      setCommentsData((prev) => [...result.reverse(), ...prev]);
      setPageIncrementLoader(false);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchComments();
  }, [id, page]);

  const closePreviewImage = () => {
    setPrevImage("");
    setImage("");
  };

  const handleMessageInput = (e) => {
    setMessage(e.target.value.slice(0, 400));
  };
  React.useEffect(() => {
    if (!message.trim()) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [message]);

  const handleAddEmoji = (event, emojiObject) => {
    setMessage((prev) => prev + emojiObject.emoji);
  };

  const handleScroll = (e) => {
    if (e.target.scrollTop === 0) {
      console.log("Page count incremented");
      setPageIncrementLoader(true);
      setPage((prev) => prev + 1);
    }
  };

  const handleSubmitreply = async () => {
    const data = {
      token,
      id: selectMsgData.c_id,
      text: message,
      selectMsgData,
    };
    const result = await dispatch(handleReplyMessage(data));
    dispatch(appendComments(result));
    setCommentsData((prev) => [...prev, result]);
    setMessage("");
    setPrevImage("");
    setImage("");
    setGif("");
    socket.emit("block comment", result);
    scrollToBottom();
    setOpenReply(false);
    setSelectMsgData(null);
  };

  React.useEffect(() => {
    // Enable sending after the initial 1-minute delay
    const initialTimeout = setTimeout(() => {
      setCanSendMessage(true);
    }, 60 * 1000); // 1 minute in milliseconds

    // Clear the timeout when the component unmounts
    return () => clearTimeout(initialTimeout);
  }, []);

  return (
    <React.Fragment>
      {selectBlock ? (
        <div className='__chat_container'>
          <div className='__chat_body'>
            {loading ? (
              <div className='channel_message_loader_section'>
                <span className='msg_loader'></span>
              </div>
            ) : (
              <>
                {(commentsData || []).length > 0 ? (
                  <div
                    className='channel_message_container'
                    onScroll={(e) => handleScroll(e)}>
                    {pageIncementLoader && (
                      <div className='page_increment_loader'>
                        <span class='message_page_loader'></span>
                      </div>
                    )}
                    {commentsData.map((comment) => (
                      <MessageBody
                        key={comment.c_id}
                        data={comment}
                        setOpenReply={setOpenReply}
                        setSelectMsgData={setSelectMsgData}
                      />
                    ))}
                    <div ref={bottomRef} />
                  </div>
                ) : (
                  <div className='empty_channel_message_container'>
                    No data found
                  </div>
                )}
              </>
            )}
          </div>

          <div className='__chat_form_container'>
            {openReply && (
              <div className='reply_message_container'>
                <div className='share_message_container_box'>
                  <div className='content_box'>{selectMsgData.content}</div>
                  <div className='shere_message_user_info_box'>
                    <span className='share_msg_user_name'>
                      {selectMsgData.c_u_fn} {selectMsgData.c_u_ln}
                    </span>
                    <span className='share_message_timestamp'>
                      {timeDifference(
                        new Date().getTime(),
                        Number(selectMsgData.c_t)
                      )}
                    </span>
                  </div>
                </div>
              </div>
            )}
            {openEmoji && (
              <div className='__chat_emoji_section' ref={menuRef}>
                <EmojiPicker onEmojiClick={handleAddEmoji} />
              </div>
            )}
            {prevImage && (
              <div className='chat_image_container'>
                <img src={prevImage} className='chat_prevImage' />
                <button
                  className='message_prev_close_btn'
                  onClick={closePreviewImage}>
                  <AiOutlineClose />
                </button>
              </div>
            )}
            <div className='__chat_footer_form'>
              <input
                type='text'
                placeholder='Enter your message here'
                className='__chat_form_input'
                value={message}
                onChange={(e) => handleMessageInput(e)}
              />
              <button
                className='message_form_icons'
                onClick={() => setOpenEmoji(true)}>
                <span class='icon-emogy'></span>
              </button>

              {!openReply ? (
                <>
                  <button className='send_btn' onClick={handleSubmitComment}>
                    <span class='icon-send_one'></span>
                  </button>
                </>
              ) : (
                <>
                  {!isDisable && (
                    <button className='send_btn' onClick={handleSubmitreply}>
                      <FaReply />
                    </button>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      ) : (
        <ChatSkeleton />
      )}
    </React.Fragment>
  );
};

export default CommentsPage;
