/** @format */
import React from "react";
import { useEffect, Suspense, lazy } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import "dexbrosicons/style.css";
import axios from "axios";
import { useSocket, isSocketConnected, socket } from "./socket/socket";

// REACT TOASTIFY IMPORT
import "react-toastify/dist/ReactToastify.css";

const Register = lazy(() => import("./pages/Register/Register.jsx"));
const Login = lazy(() => import("./pages/LoginPage/LoginPage.jsx"));
const HomePage = lazy(() => import("./pages/Home/HomePage"));
const TakeDownPost = lazy(() => import("./pages/TakdownPostPage/TakeDownPost"));
const PostAnalytics = lazy(() =>
  import("./pages/PostAnalyticsPage/PostAnalytics.js")
);
const ProfileAnalytics = lazy(() =>
  import("./pages/Profile/ProfileAnalyticsPage/ProfileAnalyticsPage.jsx")
);
const Search = lazy(() => import("./pages/Search/SearchMain"));
const SearchPage = lazy(() => import("./pages/Search/Search"));
const MainSearch = lazy(() =>
  import("./pages/Search/MainSearchPage/MainSearch")
);

const NotificationPage = lazy(() =>
  import("./pages/NotificationPage/Notification.jsx")
);
const MainProfileSettingsPage = lazy(() =>
  import("./pages/Profile/ProfileSettings/MainProfileSettings.jsx")
);

/*
 * GROUP OR BLOCK IMPORT SECTION
 * This is block or group section that's used for import of pages and components.. Refere to pages/group
 */
const GroupPage = lazy(() => import("./pages/Group/GroupPage/GroupPage"));
// const RecomemndedGroup = lazy(() =>
//   import("./pages/Group/GroupPage/Components/Recomemded.jsx")
// );
// const MyGroup = lazy(() => import("./pages/Group/GroupPage/Components/MyGroup.jsx"));

const SingleGroupPage = lazy(() =>
  import("./pages/Group/GroupProfilePage/GroupProfilePage.jsx")
);
const GroupBadge = lazy(() =>
  import("./pages/Group/GroupBadgePage/GroupBadgePage.jsx")
);

const GroupEditPage = lazy(() =>
  import("./pages/Group/GroupSettingsPage/GroupSettings.jsx")
);
const GroupDetails = lazy(() =>
  import("./pages/Group/GroupDetailsPage/GroupDetails.jsx")
);
const GroupPostAnalyticsPage = lazy(() =>
  import("./pages/GroupPostAnalyticsPage/GroupPostAnalytics")
);
const GroupPostMain = lazy(() =>
  import("./pages/Frame/GroupPost/GroupPostMain")
);
const GroupPostFrame = lazy(() => import("./pages/Frame/GroupPost/GroupPost"));
const GroupFullPost = lazy(() =>
  import("./pages/GroupFullPostPage/GroupFullPost")
);

/*
 * PROFILE IMPORT SECTION
 */
const UserProfile = lazy(() => import("./pages/Profile/Profile"));
const AboutProfile = lazy(() => import("./pages/Profile/ProfileAboutPage"));
// );
const Admin = lazy(() => import("./pages/AdminPage/AdminPage"));
const Ranking = lazy(() => import("./pages/EarningCenter/Ranking"));
const EarningFeed = lazy(() => import("./pages/EarningCenter/Feed"));
const ProfileEarnings = lazy(() =>
  import("./pages/EarningCenter/PerofileEarnings")
);
const UserEarningAnalytics = lazy(() =>
  import("./pages/EarningCenter/UserEarningAnalytics")
);

// *** Blockcast
const BlockCast = lazy(() => import("./pages/BlockCastPage/BlockCast.jsx"));
const Recomended = lazy(() =>
  import("./pages/BlockCastPage/components/Recomended.jsx")
);
const SubscribedBlock = lazy(() =>
  import("./pages/BlockCastPage/components/SubscribedBlock.jsx")
);
// const Chats = lazy(() => import("./pages/BlockCast/Chats"));
const JoinedBlockCast = lazy(() =>
  import("./pages/BlockCastPage/components/JoinedBlockcast.jsx")
);
const MyBlockCast = lazy(() =>
  import("./pages/BlockCastPage/components/MyBlockCast.jsx")
);
const MyDmMessages = lazy(() =>
  import("./pages/BlockCastPage/components/UserDms.jsx")
);

const SingleBlockCast = lazy(() =>
  import("./pages/BlockCastPage/BlockMain.jsx")
);
import CommentsPage from "./pages/BlockCastPage/Comments.jsx";
const SingleBlockCastChat = lazy(() =>
  import("./pages/BlockCastPage/SingleChat.jsx")
);
const CastFeed = lazy(() => import("./pages/BlockCastPage/CastFeed.jsx"));
const DmChats = lazy(() => import("./pages/BlockCastPage/DmChats.js"));
const DmMessage = lazy(() => import("./pages/BlockCastPage/DmMessage.jsx"));

const FullEvent = lazy(() => import("./pages/FullEvent/FullEvent"));
const TrendingPosts = lazy(() => import("./pages/TrendingPosts/TrendingPosts"));
const TrendingPostsForCoin = lazy(() =>
  import("./pages/TrendingPosts/TrendingPostsForCoin")
);
/**
 * Should be solved
 */
const BadgePage = lazy(() => import("./pages/BadgesPage/Badges.jsx"));
const DetailsHistory = lazy(() =>
  import("./pages/EarnHistoryPage/DetailsHistoryPage.jsx")
);
const FullHistory = lazy(() =>
  import("./pages/EarnHistoryPage/FullHistoryPage.jsx")
);
const CreateNftPage = lazy(() => import("./pages/NftPage/MainPage.jsx"));
const NFTForm = lazy(() => import("./pages/NftPage/NFTForm.jsx"));
const PageNotFound = lazy(() => import("./pages/404Page/PageNotFound"));

// const Search = lazy(() => import("./pages/Search/SearchMain"));
const FullPost = lazy(() => import("./pages/FullPost/FullPost"));

const PostFrame = lazy(() => import("./pages/Frame/Post/FullPost"));
const PostFrameMain = lazy(() => import("./pages/Frame/Post/Post"));
const MainEventFrame = lazy(() => import("./pages/Frame/Event/MainEvent"));
const EventFrame = lazy(() => import("./pages/Frame/Event/Event"));

const Logout = lazy(() => import("./pages/Logout/Logout"));

import MainLoading from "./pages/MainLoader/MainLoading";

import { setScrollAxis } from "./redux/_page/pageSlice";
import {
  newNotification,
  updateNotificationCount,
} from "./redux/_notification/notificationSlice";
import { useDispatch, useSelector } from "react-redux";
import { selectUser, selectToken } from "./redux/_user/userSelectors";

import { selectCoins } from "./redux/_page/pageSelectors";
import { setCoinDetails } from "./redux/_page/pageSlice";
function App() {
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const dispatch = useDispatch();
  const coins = useSelector(selectCoins);
  useSocket();
  const location = useLocation();
  const [prevPathName, setPrevPathName] = React.useState("/");

  React.useEffect(() => {
    if (isSocketConnected) {
      socket
        .off("new notification receive")
        .on("new notification receive", (data) => {
          console.log("New notification receive **");
          dispatch(newNotification(data.notificationData));
          dispatch(updateNotificationCount(1));
        });
    }
  });

  useEffect(() => {
    if (location.pathname !== prevPathName) {
      setPrevPathName(location.pathname);
      console.log("UP");
      dispatch(setScrollAxis("Up"));
    }
  }, [location]);

  // fetch config file
  const fetchConfigData = () => {
    console.log("****CALL API");
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: "https://social.websiteclubs.com/api/config/notification-config",
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  React.useEffect(() => {
    const interval = setInterval(fetchConfigData(), 3600000);
    // Clean up the interval to avoid memory leaks
    return () => clearInterval(interval);
  }, []);

  return (
    <Suspense fallback={<MainLoading />}>
      <Routes>
        <Route
          path="/logout"
          element={user ? <Logout /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/"
          element={user ? <HomePage /> : <Navigate to="/login" replace />}
        ></Route>
        <Route
          path={"/trending/posts/:key"}
          element={user ? <TrendingPosts /> : <Navigate to="/login" replace />}
        />

        <Route
          path={"/takedown/post/:id"}
          element={user ? <TakeDownPost /> : <Navigate to="/login" replace />}
        />

        <Route
          path={"/admin"}
          element={user ? <Admin /> : <Navigate to="/login" replace />}
        />

        <Route
          path={"/trending/posts/coin/:key"}
          element={
            user ? <TrendingPostsForCoin /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/post/analytics/:id"
          element={user ? <PostAnalytics /> : <Navigate to="/login" replace />}
        />
        {/* NEW USER PROFILE */}
        <Route
          path="/user/profile/:handleUn"
          element={user ? <UserProfile /> : <Navigate to="/login" replace />}
        ></Route>

        <Route path="/user/about/:handleUn" element={<AboutProfile />} />
        <Route
          path="/profile/analytics/:handleUn"
          element={
            user ? <ProfileAnalytics /> : <Navigate to="/login" replace />
          }
        ></Route>

        <Route
          path="/earning/center/ranking"
          element={user ? <Ranking /> : <Navigate to="/login" replace />}
        ></Route>

        <Route
          path="/earning/center"
          element={user ? <EarningFeed /> : <Navigate to="/login" replace />}
        ></Route>

        <Route
          path="/group/edit/:id"
          element={user ? <GroupEditPage /> : <Navigate to="/login" replace />}
        ></Route>

        <Route
          path="/earning/analytics"
          element={
            user ? <ProfileEarnings /> : <Navigate to="/login" replace />
          }
        ></Route>

        {/*  */}
        <Route
          path="/earning/:username"
          element={
            user ? <UserEarningAnalytics /> : <Navigate to="/login" replace />
          }
        ></Route>
        <Route
          path="/profile/update/:handleUn"
          element={
            user ? (
              <MainProfileSettingsPage />
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route
          path="/analytics/:handleUn"
          element={
            user ? <ProfileAnalytics /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/login"
          element={user ? <Navigate to="/" replace /> : <Login />}
        />
        <Route
          path="/register"
          element={user ? <Navigate to="/" replace /> : <Register />}
        />

        <Route
          path="/search/page"
          element={user ? <Search /> : <Navigate to="/login" replace />}
        ></Route>

        <Route
          path="/main/search/:id"
          element={user ? <MainSearch /> : <Navigate to="/login" replace />}
        ></Route>
        <Route
          path="/notifications"
          element={
            user ? <NotificationPage /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/group"
          element={user ? <GroupPage /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/group/details/:id"
          element={user ? <GroupDetails /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/group/:id"
          element={
            user ? <SingleGroupPage /> : <Navigate to="/login" replace />
          }
        ></Route>
        <Route
          path="/group/post/:id/analytics"
          element={
            user ? <GroupPostAnalyticsPage /> : <Navigate to="/login" replace />
          }
        />
        <Route
          path="/block/badge/:id"
          element={user ? <GroupBadge /> : <Navigate to="/login" replace />}
        />

        {/* BLOCK CAST */}
        <Route
          path="/blockcast"
          element={user ? <BlockCast /> : <Navigate to="/login" replace />}
        >
          <Route
            path=""
            element={user ? <Recomended /> : <Navigate to="/login" replace />}
          />
          <Route
            path="subscribe"
            element={
              user ? <SubscribedBlock /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="join"
            element={
              user ? <JoinedBlockCast /> : <Navigate to="/login" replace />
            }
          />
          <Route
            path="my_blockcast"
            element={user ? <MyBlockCast /> : <Navigate to="/login" replace />}
          />
          <Route
            path="my_dms"
            element={user ? <MyDmMessages /> : <Navigate to="/login" replace />}
          />
        </Route>

        <Route
          path="/blockcast/:id"
          element={
            user ? <SingleBlockCast /> : <Navigate to="/login" replace />
          }
        >
          <Route path="" element={<CastFeed />} />
          <Route path="comments" element={<CommentsPage />} />
          <Route path="dm" element={<DmChats />} />
        </Route>

        {/* Blockcast for single chat */}
        <Route
          path="/blockcast/single/:id"
          element={
            user ? <SingleBlockCastChat /> : <Navigate to="/login" replace />
          }
        ></Route>

        <Route
          path="/blockcast/dm/:id"
          element={user ? <DmMessage /> : <Navigate to="/login" replace />}
        ></Route>

        <Route
          path="/badge"
          element={user ? <BadgePage /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/view/full/details/:id"
          element={user ? <DetailsHistory /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/view/history/:id"
          element={user ? <FullHistory /> : <Navigate to="/login" replace />}
        />

        <Route
          path="/post/nft/:id"
          element={user ? <CreateNftPage /> : <Navigate to="/login" replace />}
        />
        {/* NFTForm */}
        <Route
          path="/nft/form"
          element={user ? <NFTForm /> : <Navigate to="/login" replace />}
        />

        {/* Group search */}
        {/* <Route
          path='/search/page'
          element={user ? <Search /> : <Navigate to='/login' replace />}
        /> */}

        <Route
          path="/group/full/post/:id"
          element={user ? <GroupFullPost /> : <Navigate to="/login" replace />}
        />
        <Route path="/full-post/:id" element={<FullPost />} />

        <Route path="/main-frame-post/:id" element={<PostFrameMain />} />

        <Route path="/frame-post/:id" element={<PostFrame />} />

        <Route path="/main-frame-event/:id" element={<MainEventFrame />} />

        <Route path="/frame-event/:id" element={<EventFrame />} />

        <Route path="/main-frame-group-post/:id" element={<GroupPostMain />} />

        <Route path="/frame-group-post/:id" element={<GroupPostFrame />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </Suspense>
  );
}

export default App;
