/** @format */

import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { scrollPosition } from "../../../redux/_page/pageSelectors";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useParams } from "react-router-dom";
// import { FeedbackData } fro../../../config/Feedback.js.js";
import CustomPostFormModal from "../../modal/CustomPostForm";
import { CgRadioChecked, CgRadioCheck } from "react-icons/cg";
import { MdMoreVert } from "react-icons/md";
import { FaSearch } from "react-icons/fa";
import axios from "axios";
import PostCard from "../../PostCardComp/PostCard";

const ProfileHeader = ({ title }) => {
  const { id } = useParams();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollPos = useSelector(scrollPosition);
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
  const [selecttype, setSelecttype] = React.useState("");
  const [openModal, setOpenModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [count, setCount] = useState(5);
  const [loading, setLoading] = useState(false);
  const [posts, setPosts] = useState([]);
  const [btnLoader, setBtnLoader] = useState(false);
  // console.log(scrollPos);

  const FeedbackData = [
    "User Interface (UI) Design",
    "User Experience (UX)",
    "Content Quality",
    "Performance",
    "Responsiveness",
    "Privacy Settings",
    "Account Security",
    "Notifications",
    "Customization Options",
    "Search Functionality",
    "Messaging System",
    "Profile Customization",
    "News Feed Algorithm",
    "Advertisement Relevance",
    "Community Guidelines Enforcement",
    "Reporting System Effectiveness",
    "Customer Support",
    "Mobile App Experience",
    "Accessibility",
    "Overall Satisfaction",
  ];

  const goBack = () => {
    navigate(-1);
  };

  const onClose = () => {
    setOpenFeedbackModal(false);
  };

  const handleAddFeed = (data) => {
    setSelecttype(data);
  };

  React.useEffect(() => {
    if (searchTerm.length === 2) {
      handleProfileSearch(searchTerm);
    } else if (searchTerm.length > 2) {
      const delayCall = setTimeout(() => {
        handleProfileSearch(searchTerm);
      }, 1000);

      return () => clearTimeout(delayCall);
    }
  }, [searchTerm, page]);

  const handleProfileSearch = () => {
    if (page === 1) setLoading(true);
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_URL_LINK}api/users/profile/search/post/${id}?searchParam=${searchTerm}`,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response.data);
        setCount(response.data.length);
        if (page === 1) {
          setPosts(response.data);
        } else {
          setPosts((prev) => [...prev, ...response.data]);
        }
        setLoading(false);
        setBtnLoader(false);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div
      className={
        scrollPos > 0
          ? "__block_page_header_container __shadow_block_header_background"
          : "__block_page_header_container"
      }>
      {/* post privacy modal */}
      {openFeedbackModal && (
        <CustomPostFormModal
          title={
            <div className='comment_modal_title_section'>
              <button
                className='cmnt_modal_back_btn'
                onClick={() => setOpenFeedbackModal(false)}>
                <BiArrowBack />
                <span>Post privacy settings</span>
              </button>
            </div>
          }
          onClose={onClose}
          body={
            <div className={`feedback_content_section `}>
              <div className='feedback_section'>
                {FeedbackData.map((data) => (
                  <div
                    className='fedd_back_card'
                    onClick={() => handleAddFeed(data)}>
                    {data === selecttype ? (
                      <CgRadioChecked />
                    ) : (
                      <CgRadioCheck />
                    )}
                    <span className='fedd_back_title'>{data}</span>
                  </div>
                ))}
              </div>
              <textarea
                type='text'
                placeholder='Enter message'
                className='feedback_input'
              />
            </div>
          }
          footer={
            <div className='modal_footer_section'>
              {selecttype && (
                <button className='modal_feedback_btn'>Submit</button>
              )}
            </div>
          }
        />
      )}

      {openModal && (
        <CustomPostFormModal
          title={
            <div className='block_header_section'>
              <button
                className='block_header_modal_close_btn'
                onClick={() => setOpenModal(false)}>
                <BiArrowBack />
              </button>
              <span className='block_modal_title'>Search</span>
            </div>
          }
          body={
            <div className='block_modal_header_body'>
              <div className='block_modal_form_box'>
                <input
                  type='text'
                  placeholder='Enter Search'
                  className='block_header_modal_input'
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div>
              <div className='block_modal_search_body'>
                {loading ? (
                  <div className='block_modal_loading_secrching'>
                    <span class='block_header_loader'></span>
                  </div>
                ) : (
                  <div>
                    {(posts || []).length > 0 ? (
                      <div>
                        {posts.map((data) => (
                          <PostCard key={data.p_id} postData={data} />
                        ))}
                        {count === posts.length && (
                          <div className='modal_post_loader'>
                            <button
                              className='modal_post_loader_btn'
                              onClick={incrementPage}>
                              {btnLoader ? <ImSpinner2 /> : <>Load more</>}
                            </button>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='empty_modal_block_post'>
                        No data found
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          }
        />
      )}

      <button className='badge_page_back_btn' onClick={goBack}>
        <BiArrowBack />
      </button>
      <span className='badge_page_title'>{title}</span>

      <div className='block_header_button_section'>
        <button className='block_custom_btn' onClick={() => setOpenModal(true)}>
          <FaSearch />
        </button>
        <Menu
          menuButton={
            <MenuButton className={"block_header_menu_btn"}>
              <MdMoreVert />
            </MenuButton>
          }>
          <MenuItem
            className={"block_header_menu_item"}
            onClick={() => setOpenFeedbackModal(true)}>
            Feedback
          </MenuItem>

          <MenuItem className={"block_header_menu_item"}>FAQ</MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default ProfileHeader;
