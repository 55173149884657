/** @format */

export const ReportConfig = {
  1: {
    id: 1,
    title: "Hate",
    details:
      "Slurs, Racist or sexist stereotypes, Dehumanization, Incitement of fear or discrimination, Hateful references, Hateful symbols & logos",
  },

  2: {
    id: 2,
    title: "Abuse & Harassment",
    details:
      "Insults, Unwanted Sexual Content & Graphic Objectification, Unwanted NSFW & Graphic Content, Violent Event Denial, Targeted Harassment and Inciting Harassment",
  },
  3: {
    id: 3,
    title: "Violent Speech",
    details:
      "Violent Threats, Wish of Harm, Glorification of Violence, Incitement of Violence, Coded Incitement of Violence",
  },
  4: {
    id: 4,
    title: "Violent Speech",
    details:
      "Violent Threats, Wish of Harm, Glorification of Violence, Incitement of Violence, Coded Incitement of Violence",
  },
  5: {
    id: 5,
    title: "Child Safety",
    details:
      "Child sexual exploitation, grooming, physical child abuse, underage user",
  },
  6: {
    id: 6,
    title: "Privacy",
    details:
      "Sharing private information, threatening to share/expose private information, sharing non-consensual intimate images, sharing images of me that I don’t want on the platform",
  },
  7: {
    id: 7,
    title: "Spam",
    details:
      "Financial scams, posting malicious links, misusing hashtags, fake engagement, repetitive replies, Retweets, or Direct Messages",
  },
  8: {
    id: 8,
    title: "Suicide or self-harm",
    details:
      "Encouraging, promoting, providing instructions or sharing strategies for self-harm.",
  },
  9: {
    id: 9,
    title: "Sensitive or disturbing media",
    details:
      "Graphic Content, Gratutitous Gore, Adult Nudity & Sexual Behavior, Violent Sexual Conduct, Bestiality & Necrophilia, Media depicting a deceased individual",
  },
  10: {
    id: 10,
    title: "Violent & hateful entities",
    details: "Violent extremism and terrorism, hate groups & networks",
  },
};
