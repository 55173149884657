/** @format */

import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import timeDifference from "../../utils/getCreateTime";
import getPricePercentage from "../../utils/getPricePercentage";
import { FiMoreHorizontal } from "react-icons/fi";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { useTranslation } from "react-i18next";
import UserAvatar from "../../Assets/userAvatar.webp";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import { useDispatch, useSelector } from "react-redux";
import { selectGroupData } from "../../redux/_block/blockSelectors";
import {
  fetchSinglePostData,
  handleCreateComment,
  prepandComment,
  handleFetchComments,
  reportPost,
} from "../../redux/_blockPost/blockPostSlice";
import { getCoinPrices, getCoinDetails } from "../../redux/_coin/coinSlice";
import {
  selectcurrentCommentCount,
  selectComments,
} from "../../redux/_blockPost/blockPostSelectors";
import {
  selectToken,
  selectUser,
  selectLike,
  selectAngry,
  selectHaha,
  selectFollowing,
  selectDislikes,
  selectSpam,
  selectReport,
} from "../../redux/_user/userSelectors";
import "./GroupPost.css";
import { BsFillPinAngleFill, BsBookmarkFill } from "react-icons/bs";
import NormalPostFooter from "./NormalPostFooter";
import NewsPostFooter from "./NewsPostFooter";
import AnnouncementPostFooter from "./AnnouncementPostFooter";
import { BiRepost } from "react-icons/bi";
import { checkMention, checkCryptoMention } from "../../utils/checkMensionUser";
// import { setCoinDetails } from "../../redux/_page/pageSlice";
import { selectCoins } from "../../redux/_page/pageSelectors.js";
import { getCoinList } from "../../redux/_coin/coinSlice";
import { BiTrendingDown } from "react-icons/bi";
import { MdOutlineTrendingUp } from "react-icons/md";
import PostModalComponent from "./PostModalComponent";
import { ReactComponent as ThreeDotIcon } from "../../Assets/Icons/v_threedot.svg";
const GroupPostCard = ({ postData }) => {
  useSocket();
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const token = useSelector(selectToken);

  const [postId, setPostId] = useState(postData.p_id);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [content, setContent] = useState(postData.content);

  // *** Delete post state
  const [deletePost, setDeletePost] = React.useState(postData.deleted || false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  // *** Pin post state
  const [isPin, setIsPin] = useState(postData.pinned || 0);
  const [openPinModal, setOpenPinModal] = useState(false);

  // *** Bookmark post state
  const [bookmark, setBookmark] = useState(postData.bookmark || []);
  const [openBookmarkModal, setOpenBookmarkModal] = useState(false);

  // *** Post Edit State
  const [openEditModal, setOpenEditModal] = useState(false);
  const [updateContent, setUpdateContent] = useState("");
  const [isDisableEditBtn, setIsDisableEditBtn] = useState(true);

  // *** Full post State
  const [openFullpostModal, setOpenFullpostModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState(null);
  const [comment, setComment] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [cmntCount, setCmntCount] = useState(10);

  // *** Post Report State
  const [openReportModal, setOpenReportModal] = useState(false);

  // *** Post Comment
  const [isCommentsLoading, setIsCommentLoading] = React.useState(false);
  const [sortedBy, setSortedBy] = React.useState("new");

  const [isVisble, setIsVisble] = React.useState(false);
  const [text, setText] = React.useState("");
  const [image, setImage] = React.useState("");
  const [prevImage, setPrevImage] = React.useState("");
  // const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);
  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [reposted, setReposted] = React.useState(postData.repost || []);
  const [flag, setFlag] = React.useState(postData.flag || []);

  const [openCryptoList, setOpenCryptoList] = React.useState(false);
  const [mentionCoin, setMentionCoin] = React.useState("");
  const [mentionCoinCurrentPrice, setMentionCoinCurrentPrice] =
    React.useState("");
  const [mentionCoinId, setMentionCoinId] = React.useState("");
  const [searchCoinList, setSearchCoinList] = React.useState([]);
  const [coinpage, setCoinPage] = React.useState(1);
  const [coinlimit, setCoinLimit] = React.useState(20);
  const [coinCount, setCoinCount] = React.useState(20);
  const [coinLoading, setCoinLoading] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [coinDetails, setCoinDetails] = React.useState([]);

  const coins = useSelector(selectCoins);

  // **** Handle post edit modal
  const handlePostEditModal = (data) => {
    setOpenEditModal(true);
    setUpdateContent(data.content);
  };

  useEffect(() => {
    if (updateContent.trim() && content === updateContent) {
      setIsDisableEditBtn(true);
    } else {
      setIsDisableEditBtn(false);
    }
  }, [updateContent]);

  //  **** Handle fetch full group post
  const handleFullPostModal = (id) => {
    setOpenFullpostModal(true);
    setPostId(id);
  };

  // *** Handle close full post button
  const closeFullPostModal = () => {
    setOpenFullpostModal(false);
  };

  // *** Fetch full group post
  async function fetchFullPost() {
    setLoading(true);
    const data = { page, limit, token, postId };
    const result = await dispatch(fetchSinglePostData(data));
    // console.log(result);
    setLoading(false);
    setPost(result);
  }

  useEffect(() => {
    // setLoading(true);
    if (openFullpostModal) {
      fetchFullPost();
    } else {
      console.log("Not open");
    }
  }, [postId, openFullpostModal]);

  const handleRedirectToEmbedPage = (id) => {
    navigate(`/main-frame-group-post/${id}`);
  };
  // **** Handle fetching comments...
  const fetchComments = async () => {
    if (page == 1) {
      setIsCommentLoading(true);
    }
    const data = { postId, token, page, limit, sortedBy };
    const result = await dispatch(handleFetchComments(data));
    setCmntCount(result.length);
    setIsCommentLoading(false);
    setBtnLoading(false);
  };

  React.useEffect(() => {
    if (openFullpostModal) {
      fetchComments(postId, sortedBy);
    }
  }, [page, postId, sortedBy, openFullpostModal]);

  React.useEffect(() => {
    if (!text.trim()) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  }, [text, prevImage]);

  const fetchCoins = async () => {
    setCoinLoading(true);
    const data = { page: coinpage, limit: coinlimit };
    const result = await dispatch(getCoinList(data));
    setCoinCount(result.length);
    if (coinpage === 1) {
      setSearchCoinList(result);
    } else {
      setSearchCoinList((prev) => [...prev, ...result]);
    }
    setCoinLoading(false);
  };
  React.useEffect(() => {
    if (openCryptoList) {
      fetchCoins();
    }
  }, [openCryptoList, coinpage]);

  const handleIncrementPage = () => {
    setPage((prev) => prev + 1);
    setBtnLoading(true);
  };

  React.useEffect(() => {
    if (postData.coin_id.length > 0) {
      postData.coin_id.map((id) => {
        fetchChartData(id);
      });
    }
  }, [postData.id]);

  const fetchChartData = async (id) => {
    // setGraphLoading(true);
    const data = {
      id: id,
      timeSpan: "2",
    };
    const result = await dispatch(getCoinPrices(data));
    console.log(result);
    setCoinDetails((prev) => [...prev, { id, price: result }]);
    // setCoinPrices(result);
  };

  return (
    <>
      {reposted.includes(user.handleUn) && (
        <>
          <BiRepost /> <span>Reposted</span>
        </>
      )}
      {/* Group Post Pinned Modal */}
      {openPinModal && (
        <PostModalComponent
          type="pin"
          postId={postId}
          setIsPin={setIsPin}
          openPinModal={openPinModal}
          setOpenPinModal={setOpenPinModal}
          isPin={isPin}
        />
      )}
      {/* Group Post Delete Modal */}
      {openDeleteModal && (
        <PostModalComponent
          type="delete"
          postId={postId}
          setDeletePost={setDeletePost}
          setOpenDeleteModal={setOpenDeleteModal}
        />
      )}
      {/* Group Post Bookmark Modal */}
      {openBookmarkModal && (
        <PostModalComponent
          type="bookmark"
          postId={postId}
          setOpenBookmarkModal={setOpenBookmarkModal}
          setBookmark={setBookmark}
          bookmark={bookmark}
        />
      )}

      {/* Group Post EDIT Modal */}
      {openEditModal && (
        <PostModalComponent
          type="edit"
          postId={postId}
          setOpenEditModal={setOpenEditModal}
          updateContent={updateContent}
          setUpdateContent={setUpdateContent}
          isDisableEditBtn={isDisableEditBtn}
          setIsDisableEditBtn={setIsDisableEditBtn}
          setContent={setContent}
        />
      )}

      {/* Group Post REPORT Modal */}
      {openReportModal && (
        <PostModalComponent
          type="report"
          postId={postId}
          setOpenReportModal={setOpenReportModal}
          postData={postData}
          flag={flag}
          setFlag={setFlag}
        />
      )}

      {/* Full post modal */}
      {openFullpostModal && (
        <PostModalComponent
          type="full-post"
          postId={postId}
          setOpenFullpostModal={setOpenFullpostModal}
          openFullpostModal={openFullpostModal}
          reposted={reposted}
          setReposted={setReposted}
        />
      )}
      {!deletePost && (
        <div
          className={
            reposted.includes(user.handleUn)
              ? "group_post_card my_reposted_post_card"
              : postData.sub_view === "subs"
              ? "group_post_card subscriber_only_post"
              : "group_post_card"
          }
        >
          {/* Show post PIN icon */}
          {isPin > 0 && (
            <span className="group_post_pinn_icon">
              <BsFillPinAngleFill />
            </span>
          )}
          {/* Show BOOKMARK icon */}
          {bookmark.includes(user.handleUn) && (
            <BsBookmarkFill className="group_post_bookmark_icon" />
          )}

          {postData.is_share ? (
            <>
              <div className="group_post_header_section">
                <div className="group_post_card_user_info">
                  {/* Avatar */}
                  <img
                    src={
                      postData.s_u_pi
                        ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.s_u_pi}`
                        : UserAvatar
                    }
                    className="group_post_card_avatar"
                    id="profile"
                  />

                  <div className="group_post_info_box">
                    {/* Block name */}
                    {/* Group post card Name */}
                    <span className="share_user_name">
                      {postData.s_u_fn} {postData.s_u_ln}
                    </span>
                    <span className="share_user_username">
                      {postData.s_u_dun}
                    </span>
                    {/* Group post time */}
                    <span className="group_post_card_time">
                      {timeDifference(
                        new Date().getTime(),
                        Number(postData.s_c_t)
                      )}
                    </span>
                  </div>
                </div>

                {/* Post card Menu section */}
                <Menu
                  menuButton={
                    <MenuButton className={"group_post_card_menu_btn"}>
                      <ThreeDotIcon className={custom_icon} />
                    </MenuButton>
                  }
                >
                  {/* Edit Post menu */}
                  {user.handleUn === postData.s_u_dun && (
                    <MenuItem
                      className={"group_post_card_menuItem"}
                      onClick={() => handlePostEditModal(postData)}
                    >
                      {t("Edit")}
                    </MenuItem>
                  )}

                  {/* Delete Post menu */}
                  {user.handleUn === postData.s_u_dun && (
                    <MenuItem
                      onClick={() => setOpenDeleteModal(true)}
                      className={"group_post_card_menuItem delete_menu_item"}
                    >
                      {t("Delete")}
                    </MenuItem>
                  )}

                  {/* Embed Page menu */}
                  <MenuItem
                    className={"group_post_card_menuItem"}
                    onClick={() => handleRedirectToEmbedPage(postData.p_id)}
                  >
                    {t("Embed")}
                  </MenuItem>

                  {/* Report page menu */}
                  {postData.s_u_dun !== user.u_id && (
                    <MenuItem
                      className={"group_post_card_menuItem"}
                      onClick={() => setOpenReportModal(true)}
                    >
                      {t("Report")}
                    </MenuItem>
                  )}
                </Menu>
              </div>
              <div className="share_post_body">{postData.share_text}</div>
              <div className="share_original_post_section">
                {/* Header Seaction */}
                <div className="group_post_header_section">
                  <div className="group_post_card_user_info">
                    {/* Avatar */}
                    <img
                      src={
                        postData.u_img
                          ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                          : UserAvatar
                      }
                      className="group_post_card_avatar"
                      id="profile"
                    />

                    <div className="group_post_info_box">
                      {/* Block name */}
                      <Link
                        to={`/group/${postData.group_id}`}
                        className="post_card_block_name"
                      >
                        {postData.g_n}
                      </Link>

                      {/* Group post card Name */}

                      {/* Group post time */}
                      <span className="group_post_card_time">
                        {timeDifference(
                          new Date().getTime(),
                          Number(postData.timestamp)
                        )}
                      </span>
                    </div>
                  </div>
                </div>
                {/* Body Section */}
                <div
                  className="group_post_body_section"
                  onClick={() => handleFullPostModal(postData.p_id)}
                >
                  <span className="block_post_content">
                    {content.split(" ").map((val, index) => (
                      <span
                        id="post_body"
                        key={index}
                        className={val.includes("#") ? "trend_tag" : ""}
                      >
                        {val.includes("@") ? (
                          <Link
                            to={`/user/profile/${checkMention(val)}`}
                            className="mentions"
                          >
                            {" "}
                            @{checkMention(val)}
                          </Link>
                        ) : (
                          <>
                            {val.includes("$") ? (
                              <Link
                                to={`trending/posts/coin/${checkCryptoMention(
                                  val
                                )}`}
                                className="crypto_mentions"
                              >
                                {" "}
                                ${checkCryptoMention(val)}
                              </Link>
                            ) : (
                              <>{val} </>
                            )}
                          </>
                        )}
                      </span>
                    ))}
                  </span>
                  {postData.image && (
                    <img
                      src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.image}`}
                      className="block_post_image"
                    />
                  )}
                </div>
              </div>
            </>
          ) : (
            <>
              {/* Header Seaction */}
              <div className="group_post_header_section">
                <div className="group_post_card_user_info">
                  {/* Avatar */}
                  <img
                    src={
                      postData.u_img
                        ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                        : UserAvatar
                    }
                    className="group_post_card_avatar"
                    id="profile"
                  />

                  <div className="group_post_info_box">
                    {/* Block name */}
                    <Link
                      to={`/group/${postData.group_id}`}
                      className="post_card_block_name"
                    >
                      {postData.g_n}
                    </Link>
                    {postData.isSubs === "true" && (
                      <div className="subscriber_user_info">
                        <span className="subscriber_name">
                          {postData.u_fn} {postData.u_ln}
                        </span>

                        <span className="subscriber_username">
                          @{postData.u_dun}
                        </span>

                        <span className="subscriber_tag">Subscriber</span>
                      </div>
                    )}

                    {postData.isCreator === "true" && (
                      <div className="subscriber_user_info">
                        <span className="subscriber_name">
                          {postData.u_fn} {postData.u_ln}
                        </span>

                        <span className="subscriber_username">
                          @{postData.u_dun}
                        </span>

                        <span className="subscriber_tag block_creator_tag">
                          Creator
                        </span>
                      </div>
                    )}

                    {/* Group post card Name */}

                    {/* Group post time */}
                    <span className="group_post_card_time">
                      {timeDifference(
                        new Date().getTime(),
                        Number(postData.timestamp)
                      )}
                    </span>
                    {/* Post Trend */}
                    {postData.trend && (
                      <>
                        {postData.trend === "Bullish" ? (
                          <span className="trend_post_tag bullish">
                            <MdOutlineTrendingUp className="trend_icon" />{" "}
                            Bullish
                          </span>
                        ) : (
                          <span className="trend_post_tag bearish">
                            <BiTrendingDown className="trend_icon" /> Bearish
                          </span>
                        )}
                      </>
                    )}

                    {/* Group post status */}
                    {postData.statusText && (
                      <span className="group_post_statusText">
                        {postData.statusText}
                      </span>
                    )}
                  </div>
                </div>

                {/* Post card Menu section */}
                {!postData.is_share && (
                  <Menu
                    menuButton={
                      <MenuButton className={"group_post_card_menu_btn"}>
                        <FiMoreHorizontal />
                      </MenuButton>
                    }
                  >
                    {/* Pin Post menu */}
                    {user.handleUn === postData.g_c_dun && (
                      <MenuItem
                        className={"group_post_card_menuItem"}
                        onClick={() => setOpenPinModal(true)}
                      >
                        {isPin ? <>{t("Unpin")}</> : <>{t("Pin")}</>}
                      </MenuItem>
                    )}

                    {/* Bookmark post menu */}
                    <MenuItem
                      className={"group_post_card_menuItem"}
                      onClick={() => setOpenBookmarkModal(true)}
                    >
                      {bookmark.includes(user.handleUn) ? (
                        <>{t("Remove")}</>
                      ) : (
                        <>{t("bookmark")}</>
                      )}
                    </MenuItem>

                    {/* Edit Post menu */}
                    {user.handleUn === postData.g_c_dun && (
                      <MenuItem
                        className={"group_post_card_menuItem"}
                        onClick={() => handlePostEditModal(postData)}
                      >
                        {t("Edit")}
                      </MenuItem>
                    )}

                    {/* Analytics Page menu */}
                    {user.handleUn === postData.g_c_dun && (
                      <MenuItem
                        className={"group_post_card_menuItem"}
                        onClick={() =>
                          navigate(`/group/post/${postData.p_id}/analytics`)
                        }
                      >
                        {t("Analytics")}
                      </MenuItem>
                    )}

                    {/* Delete Post menu */}
                    {user.handleUn === postData.g_c_dun && (
                      <MenuItem
                        onClick={() => setOpenDeleteModal(true)}
                        className={"group_post_card_menuItem delete_menu_item"}
                      >
                        {t("Delete")}
                      </MenuItem>
                    )}

                    {/* Embed Page menu */}
                    <MenuItem
                      className={"group_post_card_menuItem"}
                      onClick={() => handleRedirectToEmbedPage(postData.p_id)}
                    >
                      {t("Embed")}
                    </MenuItem>

                    {/* Report page menu */}
                    {postData.u_id !== user.u_id && (
                      <MenuItem
                        className={"group_post_card_menuItem"}
                        onClick={() => setOpenReportModal(true)}
                      >
                        {t("Report")}
                      </MenuItem>
                    )}
                  </Menu>
                )}
              </div>
              {/* Body Section */}
              <div
                className="group_post_body_section"
                onClick={() => handleFullPostModal(postData.p_id)}
              >
                <span className="block_post_content">
                  {content.split(" ").map((val, index) => (
                    <span
                      id="post_body"
                      key={index}
                      className={val.includes("#") ? "trend_tag" : ""}
                    >
                      {val.includes("@") ? (
                        <Link
                          to={`/user/profile/${checkMention(val)}`}
                          className="mentions"
                        >
                          {" "}
                          @{checkMention(val)}
                        </Link>
                      ) : (
                        <>
                          {val.includes("$") ? (
                            <Link
                              to={`trending/posts/coin/${checkCryptoMention(
                                val
                              )}`}
                              className="crypto_mentions"
                            >
                              {" "}
                              ${checkCryptoMention(val)}{" "}
                              {/* (
                              {coins[postData.coin_id]}) */}
                            </Link>
                          ) : (
                            <>{val} </>
                          )}
                        </>
                      )}
                    </span>
                  ))}
                </span>
                {postData.image && (
                  <img
                    src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.image}`}
                    className="block_post_image"
                  />
                )}
              </div>
            </>
          )}

          {(coinDetails || []).length > 0 && (
            <div className="_coin_obj_section">
              {coinDetails.map((data) => (
                <>
                  {getPricePercentage(data.price) > 0 ? (
                    <div className="_coin_price_btn high_btn">
                      <span className="coin_symbol high">
                        {data.id.toUpperCase()}
                      </span>
                      <span className="_coin_price high">
                        {getPricePercentage(data.price)}%
                      </span>
                    </div>
                  ) : (
                    <div className="_coin_price_btn low_btn">
                      <span className="coin_symbol low">
                        {data.id.toUpperCase()}
                      </span>
                      <span className="_coin_price low">
                        {getPricePercentage(data.price)}%
                      </span>
                    </div>
                  )}
                </>
              ))}
            </div>
          )}

          {/* Post card FOOTER Section */}
          {postData.postOf === "np" ? (
            <NormalPostFooter
              postData={postData}
              reposted={reposted}
              setReposted={setReposted}
            />
          ) : (
            <>
              {postData.postOf === "news" ? (
                <NewsPostFooter
                  postData={postData}
                  reposted={reposted}
                  setReposted={setReposted}
                />
              ) : (
                <>
                  {postData.postOf === "anc" ? (
                    <AnnouncementPostFooter
                      postData={postData}
                      reposted={reposted}
                      setReposted={setReposted}
                    />
                  ) : null}
                </>
              )}
            </>
          )}
        </div>
      )}
    </>
  );
};

export default GroupPostCard;
