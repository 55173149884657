/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { AiOutlineSend } from "react-icons/ai";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import {
  newsUpvote,
  newsDownvote,
  newsFake,
  handleCreateComment,
  prepandComment,
  handleFetchComments,
  setRemoveComments,
  updateDonatePost,
  flagBlockPost,
  handleFetchUpvotedUser,
  sharePost,
  respost,
} from "../../redux/_blockPost/blockPostSlice";
import {
  selectcurrentCommentCount,
  selectComments,
} from "../../redux/_blockPost/blockPostSelectors";
import {
  selectToken,
  selectUser,
  selectUpvote,
  selectFake,
  selectDownvote,
  selectReport,
} from "../../redux/_user/userSelectors";
import {
  setUpvote,
  setRemoveUpvote,
  setDownvote,
  setRemoveDownvote,
  setFakePost,
  setRemoveFakePost,
} from "../../redux/_user/userSlice";
import BlockCommentLoading from "../SkeletonLoading/BlockCommentLoading";
import { useNavigate } from "react-router-dom";
import { BiComment, BiArrowBack, BiBlock } from "react-icons/bi";
import { AiFillFlag, AiOutlineShareAlt, AiOutlineStar } from "react-icons/ai";
import { BiUpvote, BiDownvote } from "react-icons/bi";
import CustomPostForm from "../modal/CustomPostForm";
import CommentCard from "../Group.Post.Comment/CommentCard";
import CustomModal from "../modal/CustomModal";
import { BsFlag } from "react-icons/bs";
import TestUser from "../user/TestUser";
import GroupPostCard from "./GroupPostCard";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FaCopy } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { getCoinList } from "../../redux/_coin/coinSlice";

const NewsPostFooter = ({ postData, reposted, setReposted }) => {
  useSocket();
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const report = useSelector(selectReport);
  const token = useSelector(selectToken);
  const currentCommentCount = useSelector(selectcurrentCommentCount);
  const comments = useSelector(selectComments);
  const upvote = useSelector(selectUpvote);
  const downvote = useSelector(selectDownvote);
  const fake = useSelector(selectFake);

  const [postId, setPostId] = React.useState(postData.p_id);

  // *** Group post comment state
  const [cmntCount, setCmntCount] = React.useState(postData.c_c || 0);
  const [openCommentModal, setOpenCommentModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [commentCount, setCommentCount] = React.useState(5);
  const [isCommentsLoading, setIsCommentLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const [image, setImage] = React.useState("");
  const [prevImage, setPrevImage] = React.useState("");
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);
  const [sortedBy, setSortedBy] = React.useState("new");
  const [prevSortedBy, setprevSortedBy] = React.useState("new");

  // *** Group Post donate state
  const [openDonatModal, setOpenDonatModal] = React.useState(false);
  const [donateValue, setDonateValue] = React.useState(0);
  const [donateBtnVisible, setDonateBtnVisible] = React.useState(false);
  const [custommessage, setCustomMessage] = React.useState("");

  const [upvoteCounter, setUpvoteCounter] = React.useState(
    postData.upvote_c || 0
  );
  const [downvoteCounter, setDownvoteCounter] = React.useState(
    postData.downvote_c || 0
  );
  const [fakeCounter, setFakeCounter] = React.useState(postData.fake_c || 0);

  const [openFlagModal, setOpenFlagModal] = React.useState(false);
  const [flag, setFlag] = React.useState(postData.flag || []);

  const [openUpvoteModal, setOpenUpvoteModal] = React.useState(false);
  const [listPage, setListPage] = React.useState(1);
  const [listLimit, setListLimit] = React.useState(10);
  const [userCount, setUserCount] = React.useState(10);
  const [listUsers, setListUsers] = React.useState([]);
  const [listLoading, setListLoading] = React.useState(false);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [owner, setOwner] = React.useState(postData.u_dun);
  const [openNewsMenu, setOpenNewsMenu] = React.useState(false);
  const [isCopied, setIsCopied] = React.useState(false);

  // *** Group post share state
  const [openSheareModal, setOpenShareModal] = React.useState(false);
  const [postDetails, setPostDetails] = React.useState(null);
  const [sharedText, setSharedText] = React.useState("");
  const [openShareViaModal, setOpenShareViaModal] = React.useState(false);

  const [isUpvoted, setIsUpvoted] = React.useState(postData.upvote || false);
  const [isDownvote, setIsDownvote] = React.useState(postData.donvote || false);
  const [isFake, setIsFake] = React.useState(postData.fake || false);
  const [totalCount, setTotalCount] = React.useState(postData.upvote_c || 0);

  const [openCryptoList, setOpenCryptoList] = React.useState(false);
  const [mentionCoin, setMentionCoin] = React.useState("");
  const [mentionCoinCurrentPrice, setMentionCoinCurrentPrice] =
    React.useState("");
  const [mentionCoinId, setMentionCoinId] = React.useState("");
  const [coinpage, setCoinPage] = React.useState(1);
  const [searchCoinList, setSearchCoinList] = React.useState([]);
  const [coinlimit, setCoinLimit] = React.useState(20);
  const [coinLoading, setCoinLoading] = React.useState(false);
  const [coinCount, setCoinCount] = React.useState(20);

  React.useEffect(() => {
    setTotalCount(
      postData.upvote_c || 0 + postData.fake_c || 0 + postData.downvote_c || 0
    );
  });

  const commentModalHandler = (id) => {
    setOpenCommentModal(true);
    setPostId(id);
  };
  const closeCommentModal = () => {
    setOpenCommentModal(false);
    dispatch(setRemoveComments());
    setPostId("");
  };

  React.useEffect(() => {
    if (!text.trim() && !prevImage) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  }, [text, prevImage]);

  // *** Handle submin block post comment
  const handleCommentsubmit = async () => {
    setIsBtnDisable(true);
    // alert("Comment");
    const data = {
      token,
      postId,
      post: {
        cmnt_img: image,
        text: text,
        mentionCoinCurrentPrice: mentionCoinCurrentPrice,
        mentionCoinId: mentionCoinId,
      },
    };
    const result = await dispatch(handleCreateComment(data));
    // console.log(result.comment.bins);
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
    setCmntCount((p) => p + 1);
    dispatch(prepandComment(result.comment.bins));
    setPrevImage("");
    setImage("");
    setText("");
    setIsBtnLoading(false);
    // setIsVisble(false);
  };

  // **** Handle fetching comments...
  const fetchComments = async () => {
    console.log("Call fetch comments");
    const data = { postId, token, page, limit, sortedBy };
    dispatch(handleFetchComments(data));
  };

  React.useEffect(() => {
    if (openCommentModal) {
      fetchComments(postId, sortedBy);
    }
  }, [page, postId, sortedBy, openCommentModal]);

  const handleDonateModal = () => {
    setOpenDonatModal(true);
  };
  const handleDonateChange = (value) => {
    setDonateValue(value);
  };
  const onClose = () => {
    setOpenDonatModal(false);
    setOpenFlagModal(false);
  };
  React.useEffect(() => {
    if (donateValue > 0) {
      setDonateBtnVisible(true);
    } else {
      setDonateBtnVisible(false);
    }
  }, [donateValue]);
  const handleDonatPost = async () => {
    const data = {
      token,
      postId,
      donateValue,
      postUserId: postData.g_c_dun,
      custommessage,
    };
    const result = await dispatch(updateDonatePost(data));
    console.log(result);
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
    setOpenDonatModal(false);
    setDonateValue("");
    setDonateBtnVisible(false);
    setCustomMessage("");
  };

  /**
   * HANDLE UPDATE UPVOTE POST
   */
  const handleNewsPostUpvote = async (postId, type) => {
    if (upvote.includes(postId)) {
      dispatch(setRemoveUpvote(postId));
    } else {
      dispatch(setRemoveDownvote(postId));
      dispatch(setRemoveFakePost(postId));
      dispatch(setUpvote(postId));
    }
    const data = { postId, token, owner };
    const result = await dispatch(newsUpvote(data));
    console.log(result);
    // *** Notification
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
    if (downvote.includes(postId)) {
      setDownvoteCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    }
    if (fake.includes(postId)) {
      setFakeCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    }
    if (upvote.includes(postId)) {
      setUpvoteCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    } else {
      setUpvoteCounter((prev) => prev + 1);
      setTotalCount((p) => p + 1);
    }
    setIsUpvoted(true);
    setOpenNewsMenu(false);
  };

  /**
   * HANDLE UPDATE DOWNVOTE POST
   */
  const handleNewsPostDownvote = async (postId, type) => {
    if (downvote.includes(postId)) {
      dispatch(setRemoveDownvote(postId));
    } else {
      dispatch(setDownvote(postId));
      dispatch(setRemoveFakePost(postId));
      dispatch(setRemoveUpvote(postId));
    }
    const data = { token, postId };
    const result = await dispatch(newsDownvote(data));
    console.log(result);
    if (upvote.includes(postId)) {
      setUpvoteCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    }
    if (fake.includes(postId)) {
      setFakeCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    }
    if (downvote.includes(postId)) {
      setDownvoteCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    } else {
      setDownvoteCounter((prev) => prev + 1);
      setTotalCount((p) => p + 1);
    }
    setIsDownvote(true);
    setOpenNewsMenu(false);
  };

  /**
   * HANDLE UPDATE FAKE POST
   */
  const handleNewsPostFake = async (postId, type) => {
    if (fake.includes(postId)) {
      dispatch(setRemoveFakePost(postId));
    } else {
      dispatch(setFakePost(postId));
      dispatch(setRemoveUpvote(postId));
      dispatch(setRemoveDownvote(postId));
    }
    const data = { token, postId };
    const result = await dispatch(newsFake(data));
    if (upvote.includes(postId)) {
      setUpvoteCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    }
    if (downvote.includes(postId)) {
      setDownvoteCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    }
    if (fake.includes(postId)) {
      setFakeCounter((prev) => prev - 1);
      setTotalCount((p) => p - 1);
    } else {
      setFakeCounter((prev) => prev + 1);
      setTotalCount((p) => p + 1);
    }
    setIsFake(true);
    setOpenNewsMenu(false);
  };

  // *** Handle Flag post
  const handleFlagPost = async () => {
    const data = { token, postId };
    const result = await dispatch(flagBlockPost(data));
    setFlag((prev) => [...prev, user.handleUn]);
    setOpenFlagModal(false);
  };

  const handleOpenUpvoteUsers = () => {
    setOpenUpvoteModal(true);
  };

  async function fetchUpvotedUsers() {
    if (listPage === 1) {
      setListLoading(true);
    }
    const data = { postId, token, listPage, listLimit };
    const result = await dispatch(handleFetchUpvotedUser(data));
    console.log(result);
    setUserCount(result.length);
    if (listPage === 1) {
      setListUsers(result);
    } else {
      setListUsers((prev) => [...prev, ...result]);
    }
    setListLoading(false);
    setBtnLoading(false);
  }

  React.useEffect(() => {
    if (openUpvoteModal) {
      fetchUpvotedUsers();
    }
  }, [listPage, openUpvoteModal]);

  const closeUpvoteModal = () => {
    setOpenUpvoteModal(false);
  };

  const handleIncrementPage = () => {
    setListPage((prev) => prev + 1);
    setBtnLoading(true);
  };

  // **************************************** //
  const handleOpenShareModal = (data) => {
    setOpenShareModal(true);
    setPostDetails(data);
  };

  const handleSharePost = async () => {
    console.log(postDetails);
    const data = { token, post: postDetails, sharedText };
    const result = await dispatch(sharePost(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
    console.log(result);
    setOpenShareModal(false);
    setSharedText("");
    setPostDetails(null);
  };

  const handleRepost = async (id) => {
    const data = { token, id };
    const result = await dispatch(respost(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
    if (reposted.includes(user.handleUn)) {
      console.log("Already reposted");
      const temp = reposted;
      const arr = temp.filter((data) => data !== user.handleUn);
      setReposted(arr);
    } else {
      console.log("Not yet");
      setReposted((prev) => [...prev, user.handleUn]);
    }
  };

  const handleShareViaModal = (id) => {
    setPostId(id);
    setOpenShareViaModal(true);
  };

  const handleCopyURL = (id) => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleRedirectToTakeDownPost = (id, badgeLength) => {
    if (badgeLength.length > 0) {
      navigate(`/takedown/post/${id}`);
    } else {
      alert("User does not have badge");
    }
  };

  const handleKeyChange = (e) => {
    if (e.keyCode === 32 || e.keyCode === 8) {
      setOpenCryptoList(false);
    } else if (e.keyCode === 50) {
      setOpenCryptoList(false);
    } else if (e.keyCode === 52) {
      setOpenCryptoList(true);
    }
  };

  const handleSelectMentionCoin = (data) => {
    setMentionCoinId(data.id);
    setMentionCoinCurrentPrice(data.current_price);
    setMentionCoin(data.name);
    const arr = text.split(" ");
    const temp = arr.filter((data) => !data.includes("$"));
    setText(temp.join(" "));

    setText((prev) => prev + " $" + data.name + " ");
    setOpenCryptoList(false);
  };

  React.useEffect(() => {
    if (openCryptoList) {
      fetchCoins();
    }
  }, [openCryptoList, coinpage]);

  const fetchCoins = async () => {
    setCoinLoading(true);
    const data = { page: coinpage, limit: coinlimit };
    const result = await dispatch(getCoinList(data));
    if (coinpage === 1) {
      setSearchCoinList(result);
    } else {
      setSearchCoinList((prev) => [...prev, ...result]);
    }
    setCoinCount(result.length);
    setCoinLoading(false);
  };

  const handleImageChange = (e) => {
    setPrevImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const closeCmntImage = () => {
    setPrevImage("");
    setImage("");
  };

  function handleScrollToFetchCoins(e) {
    let cl = e.currentTarget.clientHeight;
    let sy = Math.round(e.currentTarget.scrollTop);
    let sh = e.currentTarget.scrollHeight;
    if (cl + sy + 1 >= sh) {
      if (coinCount !== 0) {
        setCoinPage((prev) => prev + 1);
      }
    }
  }

  return (
    <div className='blockcast_footer_section'>
      {/* Group Post COmment Modal */}
      {openCommentModal && (
        <CustomPostForm
          title={
            <div className='comment_modal_title_section'>
              <div className='cmnt_header_box'>
                <button
                  className='cmnt_modal_back_btn'
                  onClick={closeCommentModal}>
                  <BiArrowBack />
                </button>
                <span className='group_post_modal_title'>Comment</span>
              </div>
            </div>
          }
          body={
            <div className='comment_modal_body'>
              {isCommentsLoading ? (
                <BlockCommentLoading />
              ) : (
                <>
                  {(comments || []).length > 0 ? (
                    <>
                      {comments.map((data) => (
                        <CommentCard key={data.c_id} commentData={data} />
                      ))}
                      {currentCommentCount === limit && (
                        <div className='comment_load_more_btn_section'>
                          <button onClick={() => setPage((p) => p + 1)}>
                            Load more
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='empty_block_modal_comment'>
                      No comment found
                    </div>
                  )}
                </>
              )}
            </div>
          }
          footer={
            <>
              {flag.includes(user.handleUn) ? (
                <div className='gp_flag_status_text'>
                  You marked this post as flag so you cannot comment on this
                  post
                </div>
              ) : (
                <div className='comment_modal_footer'>
                  {/* Preview image */}
                  {prevImage && (
                    <div className='prev_image_section'>
                      <img src={prevImage} className='prev_image_box' />
                      <button
                        className='close_btn_prev'
                        onClick={closeCmntImage}>
                        <AiOutlineClose />
                      </button>
                    </div>
                  )}
                  <input
                    type='text'
                    placeholder='Enter your comment'
                    className='comment_input'
                    value={text}
                    onKeyDown={(e) => handleKeyChange(e)}
                    onChange={(e) => setText(e.target.value.slice(0, 100))}
                  />
                  {/* Show crypto coins */}
                  {openCryptoList && (
                    <div
                      className='group_crypto_list'
                      onScroll={(e) => handleScrollToFetchCoins(e)}>
                      {coinLoading ? (
                        <div className='loader_section'>
                          <span className='loader'></span>
                        </div>
                      ) : (
                        <>
                          {(searchCoinList || []).length > 0 ? (
                            <React.Fragment>
                              {searchCoinList.map((data) => (
                                <div
                                  className='mention_user_card'
                                  onClick={() => handleSelectMentionCoin(data)}>
                                  <img
                                    src={`https://assets.coingecko.com/coins/images/${data.image}`}
                                    className='user_small_avatar'
                                  />

                                  <span className='user_list_name'>
                                    {data.name}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ) : (
                            <div>No user found</div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {/* Gallery */}
                  <label
                    htmlFor='post_comment_file'
                    className='modal_file_icon'>
                    <span class='icon-gallery'></span>
                  </label>
                  <input
                    type='file'
                    id='post_comment_file'
                    className='input_file'
                    onChange={(e) => handleImageChange(e)}
                    accept='image/*'
                  />

                  {/* Emoji */}
                  <button
                    className='modal_post_icons_button'
                    onClick={() => setOpenEmojiIcons(true)}>
                    <span class='icon-emogy'></span>
                  </button>

                  {isBtnDisable ? null : (
                    <button
                      className='send_cmn_btn'
                      onClick={() => handleCommentsubmit()}>
                      <AiOutlineSend
                        className={isBtnDisable ? "disable" : "not_disable"}
                      />
                    </button>
                  )}
                </div>
              )}
            </>
          }
        />
      )}

      {/* Donate crypto */}
      {openDonatModal && (
        <CustomModal
          title='Repost'
          onClose={onClose}
          body={
            <div className='modal_body'>
              <div className='option_box'>
                <input
                  type='radio'
                  name='donateValue'
                  value='50'
                  checked={donateValue === "50"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className='options_text_scection'
                  id='Public'
                  onClick={(e) => handleDonateChange(e.target.id)}>
                  <span
                    className='options_text_scection_header'
                    id='50'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    50
                  </span>
                  <br />
                  <span
                    className='options_text_scection_text'
                    id='50'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    Donate $50 to your favourite creator
                  </span>
                </div>
              </div>

              <div className='option_box'>
                <input
                  type='radio'
                  name='donateValue'
                  value='100'
                  checked={donateValue === "100"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className='options_text_scection'
                  id='Public'
                  onClick={(e) => handleDonateChange(e.target.id)}>
                  <span
                    className='options_text_scection_header'
                    id='100'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    100
                  </span>
                  <br />
                  <span
                    className='options_text_scection_text'
                    id='100'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    Donate $100 to your favourite creator
                  </span>
                </div>
              </div>

              <div className='option_box'>
                <input
                  type='radio'
                  name='donateValue'
                  value='200'
                  checked={donateValue === "200"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className='options_text_scection'
                  id='200'
                  onClick={(e) => handleDonateChange(e.target.id)}>
                  <span
                    className='options_text_scection_header'
                    id='200'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    200
                  </span>
                  <br />
                  <span
                    className='options_text_scection_text'
                    id='200'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    Donate $200 to your favourite creator
                  </span>
                </div>
              </div>

              <div className='option_box'>
                <input
                  type='radio'
                  name='donateValue'
                  value='500'
                  checked={donateValue === "500"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className='options_text_scection'
                  id='500'
                  onClick={(e) => handleDonateChange(e.target.id)}>
                  <span
                    className='options_text_scection_header'
                    id='500'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    500
                  </span>
                  <br />
                  <span
                    className='options_text_scection_text'
                    id='500'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    Donate $500 to your favourite creator
                  </span>
                </div>
              </div>

              <div className='option_box'>
                <input
                  type='radio'
                  name='donateValue'
                  value='1000'
                  checked={donateValue === "1000"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className='options_text_scection'
                  id='1000'
                  onClick={(e) => handleDonateChange(e.target.id)}>
                  <span
                    className='options_text_scection_header'
                    id='1000'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    1000
                  </span>
                  <br />
                  <span
                    className='options_text_scection_text'
                    id='1000'
                    onClick={(e) => handleDonateChange(e.target.id)}>
                    Donate $1000 to your favourite creator
                  </span>
                </div>
              </div>
            </div>
          }
          footer={
            donateBtnVisible && (
              <>
                <textarea
                  type='text'
                  placeholder='Send message'
                  value={custommessage}
                  className='edit_form_section_textarea'
                  onChange={(e) =>
                    setCustomMessage(e.target.value.slice(0, 50))
                  }></textarea>
                <button className='donate_btn' onClick={handleDonatPost}>
                  Donate ${donateValue}{" "}
                </button>
              </>
            )
          }
        />
      )}

      {/* Open Flag Modal */}
      {openFlagModal && (
        <CustomModal
          onClose={onClose}
          title={t("Flag post")}
          body={<>{t("Do you want to flag this post?")}</>}
          footer={
            <div>
              <button className='update_btn' onClick={handleFlagPost}>
                <>{t("Flag")}</>
              </button>
            </div>
          }
        />
      )}

      {/* Upvoted user's modal */}
      {openUpvoteModal && (
        <CustomPostForm
          title={
            <div className='comment_modal_title_section'>
              <div className='cmnt_header_box'>
                <button
                  className='cmnt_modal_back_btn'
                  onClick={closeUpvoteModal}>
                  <BiArrowBack />
                </button>
                <span className='group_post_modal_title'>Upvoted users</span>
              </div>
            </div>
          }
          body={
            <div className='upvoted_list_body_section'>
              {listLoading ? (
                <div className='upvoted_loading_section'>Loading...</div>
              ) : (
                <>
                  {(listUsers || []).length > 0 ? (
                    <>
                      {listUsers.map((data) => (
                        <TestUser
                          userData={data}
                          key={data.record.bins.handleUn}
                        />
                      ))}
                      {listLimit === userCount && (
                        <div className='loading_btn_section'>
                          <button
                            className='loadin_btn'
                            onClick={() => handleIncrementPage()}>
                            {btnLoading ? <>Loading...</> : <>Load more</>}
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className='empty_upvoted_user_section'>
                      No user found
                    </div>
                  )}
                </>
              )}
            </div>
          }
        />
      )}

      {openSheareModal && (
        <CustomPostForm
          title={
            <div className='comment_modal_title_section'>
              <div className='cmnt_header_box'>
                <button
                  className='cmnt_modal_back_btn'
                  onClick={() => setOpenShareModal(false)}>
                  <BiArrowBack />
                </button>
                <span className='group_post_modal_title'>Share Post</span>
              </div>
            </div>
          }
          body={
            <div>
              <textarea
                type='text'
                placeholder='Enter something...'
                className='modal_textarea'
                value={sharedText}
                onChange={(e) =>
                  setSharedText(e.target.value.slice(0, 100))
                }></textarea>
              <GroupPostCard postData={postDetails} />
            </div>
          }
          footer={
            <button
              className='share_post_btn'
              onClick={() => handleSharePost()}>
              Share
            </button>
          }
        />
      )}

      {openShareViaModal && (
        <CustomPostForm
          title={
            <div className='comment_modal_title_section'>
              <div className='cmnt_header_box'>
                <button
                  className='cmnt_modal_back_btn'
                  onClick={() => setOpenShareViaModal(false)}>
                  <BiArrowBack />
                </button>
                <span className='group_post_modal_title'>Share Post</span>
              </div>
            </div>
          }
          body={
            <div>
              <div>
                <div
                  className='share_modal_section_one'
                  onClick={() => handleCopyURL(postData.p_id)}>
                  <span className='post_url'>
                    http://localhost:3001/group/full/post/{postId}
                  </span>
                  <button className='copy_icon'>
                    {isCopied ? (
                      <AiOutlineCheck className='copied' />
                    ) : (
                      <FaCopy />
                    )}
                  </button>
                </div>
                {/* http://localhost:3101/group/full/post/1700629996679718 */}
                <div className='share_modal_body_two'>
                  <TwitterShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    className='Demo__some-network__share-button'>
                    <XIcon size={32} round />
                  </TwitterShareButton>

                  <FacebookShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    className='Demo__some-network__share-button'>
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <FacebookMessengerShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    appId='521270401588372'
                    className='Demo__some-network__share-button'>
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>

                  <WhatsappShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    separator=':: '
                    className='Demo__some-network__share-button'>
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/* Footer Section one */}
      <div className='footer_section_one'>
        {/* Upvote count */}
        <div
          className='section_one_box'
          onClick={() => handleOpenUpvoteUsers()}>
          <span className='box_one_title'>
            {isUpvoted && <BiUpvote className='upvote' />}
            {isDownvote && <BiDownvote className='downvote' />}
            {isFake && <BiBlock className='downvote' />}
          </span>
          <span className='box_one_count'>{upvoteCounter}</span>
        </div>

        {/* Comment count */}
        <div
          className='section_one_box'
          onClick={() => commentModalHandler(postData.p_id)}>
          <span className='box_one_title'>{t("Comments")}:</span>
          <span className='box_one_count'>{cmntCount || 0}</span>
        </div>

        {/* Views count */}
        <div className='section_one_box'>
          <span className='box_one_title'>{t("Views")}:</span>
          <span className='box_one_count'>{postData.views || 0}</span>
        </div>

        {/* Earning count */}
        <div className='section_one_box'>
          <span className='box_one_title'>{t("Earning")}:</span>
          <span className='box_one_count'>{postData.earn || 0}</span>
        </div>
      </div>

      {/* Footer Section Two */}
      {flag.includes(user.handleUn) ? (
        <div className='footer_section_two group_post_last_footer'>
          {/* Upvote */}
          <button className='post_footer_button falgged_btn'>
            <BiUpvote />
          </button>

          {/* Comment */}
          <button className='post_footer_button falgged_btn'>
            <BiComment />
          </button>

          {/* Flag post */}
          <button
            className='post_footer_button falgged_btn'
            onClick={() =>
              handleRedirectToTakeDownPost(postData.p_id, user.badges)
            }>
            <AiFillFlag />
          </button>

          {/* Share */}
          <button className='post_footer_button falgged_btn'>
            <AiOutlineShareAlt />
          </button>

          {/* Gift button */}
          <>
            <button
              className='post_footer_button falgged_btn'
              onClick={handleDonateModal}>
              <AiOutlineStar />
            </button>
          </>
        </div>
      ) : (
        <div className='footer_section_two group_post_last_footer'>
          {openNewsMenu && (
            <div className='news_reaction_section'>
              {/* Upvote */}
              {flag.includes(user.handleUn) ? (
                <button className='post_footer_button falgged_btn'>
                  <BiUpvote />
                </button>
              ) : (
                <button
                  className='post_footer_button'
                  onClick={() => handleNewsPostUpvote(postData.p_id, "upvote")}>
                  {upvote.includes(postData.p_id) ? (
                    <BiUpvote className='upvote' />
                  ) : (
                    <BiUpvote />
                  )}
                </button>
              )}

              {/* Downvote */}
              {flag.includes(user.handleUn) ? (
                <button className='post_footer_button falgged_btn'>
                  <BiDownvote />
                </button>
              ) : (
                <button
                  className='post_footer_button'
                  onClick={() =>
                    handleNewsPostDownvote(postData.p_id, "downvote")
                  }>
                  {downvote.includes(postData.p_id) ? (
                    <BiDownvote className='downvote' />
                  ) : (
                    <BiDownvote />
                  )}
                </button>
              )}

              {/* Fake post */}
              {flag.includes(user.handleUn) ? (
                <button className='post_footer_button falgged_btn'>
                  <BiBlock />
                </button>
              ) : (
                <button
                  className='post_footer_button'
                  onClick={() => handleNewsPostFake(postData.p_id, "fake")}>
                  {fake.includes(postData.p_id) ? (
                    <BiBlock className='downvote' />
                  ) : (
                    <BiBlock />
                  )}
                </button>
              )}
            </div>
          )}
          {/* Upvote */}
          <button
            className='post_footer_button'
            onClick={() => setOpenNewsMenu(true)}>
            {upvote.includes(postData.p_id) ? (
              <BiUpvote className='upvote' />
            ) : (
              <>
                {downvote.includes(postData.p_id) ? (
                  <BiDownvote className='downvote' />
                ) : (
                  <>
                    {fake.includes(postData.p_id) ? (
                      <BiBlock className='downvote' />
                    ) : (
                      <BiUpvote />
                    )}
                  </>
                )}
              </>
            )}
          </button>

          {/* Comment */}
          <button
            className='post_footer_button'
            onClick={() => commentModalHandler(postData.p_id)}>
            <BiComment />
          </button>

          {/* Flag post */}
          {flag.includes(user.handleUn) ? (
            <button className='post_footer_button falgged_btn'>
              <AiFillFlag />
            </button>
          ) : (
            <button
              className='post_footer_button'
              onClick={() => setOpenFlagModal(true)}>
              <BsFlag />
            </button>
          )}

          {/* Share */}
          {postData.sub_view !== "subs" && (
            <Menu
              menuButton={
                <MenuButton lassName='post_footer_button'>
                  {reposted.includes(user.handleUn) ? (
                    <AiOutlineShareAlt className='active_reposte' />
                  ) : (
                    <AiOutlineShareAlt />
                  )}
                </MenuButton>
              }>
              <MenuItem
                className={"group_post_card_menuItem"}
                onClick={() => handleRepost(postData.p_id)}>
                {reposted.includes(user.handleUn) ? (
                  <>{t("Remove")}</>
                ) : (
                  <>{t("Repost")}</>
                )}
              </MenuItem>

              <MenuItem
                className={"group_post_card_menuItem"}
                onClick={() => handleOpenShareModal(postData)}>
                {t("Quote")}
              </MenuItem>

              <MenuItem
                className={"group_post_card_menuItem"}
                onClick={() => handleShareViaModal(postData.p_id)}>
                {t("Share via")}
              </MenuItem>
            </Menu>
          )}

          {/* Gift button */}
          <>
            {postData.g_c_dun !== user.handleUn && (
              <button
                className='post_footer_button'
                onClick={handleDonateModal}>
                <AiOutlineStar />
              </button>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default NewsPostFooter;
