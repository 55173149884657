/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import { BiArrowBack } from "react-icons/bi";
import "./FullEventHeader.css";
import { scrollPosition } from "../../../redux/_page/pageSelectors";
import { useNavigate } from "react-router-dom";

const FullEventHeader = () => {
  const navigate = useNavigate();
  const user = useSelector(selectUser) || null;
  const scrollPos = useSelector(scrollPosition);
  const dispatch = useDispatch();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div>
      {user ? (
        <div
          className={
            scrollPos > 0
              ? "__block_page_header_container __shadow_block_header_background"
              : "__block_page_header_container"
          }>
          <div className='section_one'>
            <button className='badge_page_back_btn' onClick={goBack}>
              <BiArrowBack />
            </button>
            <span className='post_nav_header_title'>Event</span>
          </div>
        </div>
      ) : (
        <div className='post_header_without_token'>
          <div className='section_one'>
            <BiArrowBack className='post_navbar_back_btn' />
            <span className='post_nav_header_title'>Event</span>
          </div>

          <div className='section_two'>
            <button
              className='login_header_btn'
              onClick={() => navigate("/login")}>
              Login
            </button>
            <button
              className='register_header_btn'
              onClick={() => navigate("/register")}>
              Register
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullEventHeader;
