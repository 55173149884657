/** @format */

import { createSlice, current } from "@reduxjs/toolkit";
import {
  getMyBlock,
  getJoinBlock,
  createNewBlock,
  fetchRecomendedBlock,
  fetchSubscriptionBlock,
  addGroupMember,
  blockSearch,
  fetchBlock,
  uploadCoverBlockImage,
  uploadProfileBlockImage,
  fetchGroupMembers,
  updateBlockName,
  updateBlockBio,
  getBlockMembers,
  addBlockAdmin,
  updateBlockJoinPrivacy,
  updateBlockPrivacy,
  updateBlockMemberPrivacy,
  updateBlockPostPrivacy,
  updateBlockEventPrivacy,
  updateBlocktimelinePrivacy,
  updateBlockChannelPrivacy,
  updateDmBlockPrivacy,
  createBlockEvent,
  blockFeed,
  handleBuyBadge,
  handleFetchTransactionDetails,
  handleAddAdmin,
  handleAddMods,
  handleBlockSubscription,
  handleOpenSubscriberMode,
  handleGetSubsData,
  handleHideBlock,
  handleFetchTrendingBlocks,
  handleUpdateSubscriberSettings,
  updateBlockPrivacySettingApi,
} from "../../api/_block/blockApi";
import { setUpdateUser } from "../_user/userSlice";
import { socket } from "../../socket/socket";

const groupSlice = createSlice({
  name: "group",
  initialState: {
    join_groups: [],
    recomended_group: [],
    my_group: [],
    selectGroup: null,
    groupData: null,
    updatedGroup: null,

    posts: [],
    comments: [],
    replies: [],
    updatePost: null,
    updateComment: null,

    myBlock: [],
    joinBlock: [],
  },
  reducers: {
    addRecomended: (state, action) => {
      state.recomended_group = action.payload;
    },
    appendRecomended: (state, action) => {
      state.recomended_group = [...state.recomended_group, ...action.payload];
    },
    addBlocks: (state, action) => {
      state.my_group = action.payload;
    },
    appendMyBlock: (state, action) => {
      state.my_group = [...state.my_group, ...action.payload];
    },

    putMyBlock: (state, action) => {
      state.my_group = [...state.my_group, action.payload];
    },

    joinedBlock: (state, action) => {
      state.join_groups = action.payload;
    },
    appendJoinedGroup: (state, action) => {
      state.join_groups = [...state.join_groups, ...actions.payload];
    },

    setGroupData: (state, action) => {
      state.groupData = action.payload;
    },
    setUpdatedGroupData: (state, action) => {
      state.updatedGroup = action.payload;
    },

    setPosts: (state, action) => {
      state.posts = action.payload;
    },
    appendPost: (state, action) => {
      state.posts = [...state.posts, ...action.payload];
    },
    setSubscribe: (state, action) => {
      console.log("redux ->>", state.groupData, action.payload);
      state.groupData.subscribe = action.payload;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = groupSlice;

// Extract and export each action creator by name
export const {
  addRecomended,
  appendRecomended,
  addBlocks,
  joinedBlock,
  appendJoinedGroup,
  appendMyBlock,
  putMyBlock,
  setGroupData,
  setUpdatedGroupData,
  setPosts,
  appendPost,
  setSubscribe,
} = groupSlice.actions;

export const fetchMyBlock = (data) => async (dispatch) => {
  const response = await getMyBlock(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchJoinedBlock = (data) => async (dispatch) => {
  const response = await getJoinBlock(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// create a new block
export const handleCreateNewBlock = (data) => async (dispatch) => {
  const response = await createNewBlock(data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log("Error");
  }
};

// fetch recomended block
export const handleFetchRecomendedBlock = (data) => async (dispatch) => {
  const response = await fetchRecomendedBlock(data);
  try {
    return response;
  } catch (error) {
    console.log("Error");
  }
};

export const handleFetchSubscription = (data) => async (dispatch) => {
  const response = await fetchSubscriptionBlock(data);
  try {
    return response;
  } catch (error) {
    console.log("Error");
  }
};

// join or remove from group as a member
export const handleAddGroupMember = (data) => async (dispatch, getState) => {
  const originalUser = getState().user; // Get the original user state
  console.log("follow-following data", data, originalUser);

  try {
    let btnType;

    if (!originalUser.user?.mem_grp?.includes(data.id)) {
      const updatedUser = {
        ...originalUser.user,
        mem_grp: [...originalUser.user?.mem_grp, data.id],
      };
      btnType = "follow";
      dispatch(setUpdateUser(updatedUser));
    } else {
      const mem_grp = originalUser.user?.mem_grp?.filter((e) => e !== data.id);
      const updatedUser = { ...originalUser.user, mem_grp: mem_grp };
      btnType = "unfollow";
      dispatch(setUpdateUser(updatedUser));
    }

    const response = await addGroupMember({ ...data, btnType });
    if (response.notificationData) {
      socket.emit("notification receive", response);
    }
  } catch (error) {
    console.log(error);
    const updatedUser = {
      ...originalUser.user,
      mem_grp: originalUser.user?.mem_grp,
    };
    dispatch(setUpdateUser(updatedUser)); // Revert back to original state
  }
};

export const handleBlockSearch = (data) => async (dispatch) => {
  const result = await blockSearch(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchBlock = (data) => async (dispatch) => {
  try {
    const result = await fetchBlock(data);
    console.log("result.data", result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleCoverImage = (data) => async (dispatch) => {
  const response = await uploadCoverBlockImage(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleProfileImage = (data) => async (dispatch) => {
  const response = await uploadProfileBlockImage(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchGroupMembers = (data) => async (dispatch) => {
  const response = await fetchGroupMembers(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockName = (data) => async (dispatch) => {
  const response = await updateBlockName(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockBio = (data) => async (dispatch) => {
  const response = await updateBlockBio(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchBlockMembers = (data) => async (dispatch) => {
  const response = await getBlockMembers(data);
  dispatch(setGroupData(response.data));
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleAddBlockAdmin = (data) => async (dispatch) => {
  const response = await addBlockAdmin(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockJoinPrivacy = (data) => async (dispatch) => {
  const response = await updateBlockJoinPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockPrivacy = (data) => async (dispatch) => {
  const response = await updateBlockPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockMembersPrivacy = (data) => async (dispatch) => {
  const response = await updateBlockMemberPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockPostPrivacy = (data) => async (dispatch) => {
  const response = await updateBlockPostPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockEventPrivacy = (data) => async (dispatch) => {
  const response = await updateBlockEventPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockTimelinePrivacy = (data) => async (dispatch) => {
  const response = await updateBlocktimelinePrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBlockChannelPrivacy = (data) => async (dispatch) => {
  const response = await updateBlockChannelPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateDmBlockPrivacy = (data) => async (dispatch) => {
  const response = await updateDmBlockPrivacy(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleCreateEvent = (data) => async (dispatch) => {
  const response = await createBlockEvent(data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleBlockFeed = (data) => async (dispatch) => {
  const result = await blockFeed(data);
  try {
    if (data.page === 1) {
      dispatch(setPosts(result));
    } else {
      console.log(">>>> Page is greater that 1");
      dispatch(appendPost(result));
    }
  } catch (error) {
    console.log(error);
  }
};

export const buyBadge = (data) => async (dispatch) => {
  const result = await handleBuyBadge(data);
  try {
    console.log(result.data);
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTransactionData = (data) => async (dispatch) => {
  const result = await handleFetchTransactionDetails(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const addAdmin = (data) => async (dispatch) => {
  const result = await handleAddAdmin(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const addMods = (data) => async (dispatch) => {
  const result = await handleAddMods(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

// Subscribe to block
export const blockSubscription = (data) => async (dispatch, getState) => {
  try {
    const result = await handleBlockSubscription(data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const openSubscriberMode = (data) => async (dispatch) => {
  const result = await handleOpenSubscriberMode(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

// Update subscriber settings
export const updateSubscriberSettings = (data) => async (dispatch) => {
  const result = await handleUpdateSubscriberSettings(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const getSubsData = (data) => async (dispatch) => {
  const result = await handleGetSubsData(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const hideBlock = (data) => async (dispatch) => {
  const result = await handleHideBlock(data);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchTrendingBlocks = (data) => async (dispatch) => {
  const result = await handleFetchTrendingBlocks(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

/*
here using one common fn. for all privacy settings
*/
export const updateBlockPrivacySetting = (data, token) => async (dispatch) => {
  console.log(data);
  try {
    const response = await updateBlockPrivacySettingApi(data, token);
    console.log("response", response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// Export the reducer, either as a default or named export
export default reducer;
