/** @format */

import * as React from "react";
import { BiArrowBack } from "react-icons/bi";
import { MdMoreVert } from "react-icons/md";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
// import "./Block.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { scrollPosition } from "../../../redux/_page/pageSelectors";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import "./BadgeHeader.css";
import { useParams } from "react-router-dom";
// import { FeedbackData } fro../../../config/Feedback.js.js";
import CustomPostFormModal from "../../modal/CustomPostForm";
import { CgRadioChecked, CgRadioCheck } from "react-icons/cg";

const BadgeHeader = ({ title }) => {
  const { id } = useParams();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const scrollPos = useSelector(scrollPosition);
  const [openFeedbackModal, setOpenFeedbackModal] = React.useState(false);
  const [selecttype, setSelecttype] = React.useState("");
  const FeedbackData = [
    "User Interface (UI) Design",
    "User Experience (UX)",
    "Content Quality",
    "Performance",
    "Responsiveness",
    "Privacy Settings",
    "Account Security",
    "Notifications",
    "Customization Options",
    "Search Functionality",
    "Messaging System",
    "Profile Customization",
    "News Feed Algorithm",
    "Advertisement Relevance",
    "Community Guidelines Enforcement",
    "Reporting System Effectiveness",
    "Customer Support",
    "Mobile App Experience",
    "Accessibility",
    "Overall Satisfaction",
  ];

  const goBack = () => {
    navigate(-1);
  };

  const onClose = () => {
    setOpenFeedbackModal(false);
  };

  const handleAddFeed = (data) => {
    setSelecttype(data);
  };

  return (
    <div
      className={
        scrollPos > 0
          ? "__block_page_header_container __shadow_block_header_background"
          : "__block_page_header_container"
      }
    >
      {/* post privacy modal */}
      {openFeedbackModal && (
        <CustomPostFormModal
          title={
            <div className="comment_modal_title_section">
              <button
                className="cmnt_modal_back_btn"
                onClick={() => setOpenFeedbackModal(false)}
              >
                <BiArrowBack />
                <span>Post privacy settings</span>
              </button>
            </div>
          }
          onClose={onClose}
          body={
            <div className={`feedback_content_section `}>
              <div className="feedback_section">
                {FeedbackData.map((data) => (
                  <div
                    className="fedd_back_card"
                    onClick={() => handleAddFeed(data)}
                  >
                    {data === selecttype ? (
                      <CgRadioChecked />
                    ) : (
                      <CgRadioCheck />
                    )}
                    <span className="fedd_back_title">{data}</span>
                  </div>
                ))}
              </div>
              <textarea
                type="text"
                placeholder="Enter message"
                className="feedback_input"
              />
            </div>
          }
          footer={
            <div className="modal_footer_section">
              {selecttype && (
                <button className="modal_feedback_btn">Submit</button>
              )}
            </div>
          }
        />
      )}
      <button className="badge_page_back_btn" onClick={goBack}>
        <BiArrowBack size={27} />
      </button>
      <span className="badge_page_title">{title}</span>

      <Menu
        menuButton={
          <MenuButton className={"block_header_menu_btn"}>
            <MdMoreVert />
          </MenuButton>
        }
      >
        <MenuItem
          className={"block_header_menu_item"}
          onClick={() => setOpenFeedbackModal(true)}
        >
          Feedback
        </MenuItem>

        <MenuItem className={"block_header_menu_item"}>FAQ</MenuItem>
      </Menu>
    </div>
  );
};

export default BadgeHeader;
