/** @format */

import { BASE_URL } from "../constants";
import {
  POST,
  GET,
  PUT,
  FormPost,
  DELETE,
  POSTWITHTOKEN,
  POSTFILE,
} from "../index";

export const fetchRecomendenBlockcast = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/recomended?page=${data.page}&limit=${data.limit}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchUserDms = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/user/dm`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchSubscribeBlockcast = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/subscribed?page=${data.page}&limit=${data.limit}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchMyBlockcast = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/myblock?page=${data.page}&limit=${data.limit}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const joinBlockcast = async (data) => {
  const endpoint = `api/blockcast/add-remove/blockcast/${data.id}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchjoinBlockcast = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/join?page=${data.page}&limit=${data.limit}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const searchBlockcast = async (data) => {
  // console.log(data);
  const endpoint = `api/group/search/group?search=${data.search}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchBlockCast = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/${data.id}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const submitImageMessage = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/message/${data.id}`;
  const resonse = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteMessage = async (data) => {
  console.log(data);
  const endpoint = `api/blockcast/message/delete/${data.mid}`;
  // /message/delete/:id
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const pinnedMessage = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/message/pinn/${data.msgData.chatId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data.msgData);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// likeMessage
export const likeMessage = async (data) => {
  console.log(data);
  const endpoint = `api/blockcast/message/${data.type}/${data.id}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createComment = async (data) => {
  const endpoint = `api/blockcast/comment/${data.id}`;
  const resonse = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchComment = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/comment/${data.id}?page=${data.page}&limit=${data.limit}`;
  console.log(endpoint);
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDms = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/dm/${data.id}?page=${data.page}}&limit=${data.limit}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const createDms = async (data) => {
  const endpoint = `api/blockcast/dm`;
  console.log(data.group);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    group: data.group,
    user: data.user,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const createDmMessage = async (data) => {
  const endpoint = `api/blockcast/comment/${data.id}`;
  const resonse = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

//
export const fetchDmMessage = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/comment/${data.id}?page=${data.page}&limit=${data.limit}`;
  const resonse = await GET(BASE_URL, endpoint, data.token);
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const replyMessage = async (data) => {
  // console.log(data);
  const endpoint = `api/blockcast/message/reply/${data.id}`;
  console.log("Endpoint", endpoint);
  const resonse = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    text: data.text,
    selectMsgData: data.selectMsgData,
  });
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateMessage = async (data) => {
  console.log(data);
  const endpoint = `api/blockcast/message/edit/${data.id}`;
  const resonse = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    text: data.text,
  });
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleDonateMessage = async (data) => {
  // console.log(data); /message/donation/:id
  const endpoint = `api/blockcast/message/donation/${data.id}`;
  const resonse = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    value: data.value,
  });
  try {
    return resonse.data;
  } catch (error) {
    console.log(error);
  }
};

export const likeComment = async (data) => {
  const endpoint = `api/blockcast/like/${data.id}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const dislikeComment = async (data) => {
  const endpoint = `api/blockcast/dislike/${data.id}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const emojiLikeMessage = async (data) => {
  const endpoint = `api/blockcast/message/${data.id}?value=${data.value}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};
