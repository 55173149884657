/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  AiFillHeart,
  AiFillDislike,
  AiOutlineSend,
  AiFillFlag,
} from "react-icons/ai";
import { BsEmojiLaughingFill, BsEmojiAngryFill, BsFlag } from "react-icons/bs";
import EmojiLike from "../EmojiLike/EmojiLike";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";
import { selectGroupData } from "../../redux/_block/blockSelectors";
import {
  handleSpamBlockPost,
  handleCreateComment,
  prepandComment,
  handleFetchComments,
  setRemoveComments,
  updateDonatePost,
  updateLikePost,
  flagBlockPost,
  removeBlockPostLike,
  sharePost,
  respost,
} from "../../redux/_blockPost/blockPostSlice";
import {
  selectcurrentCommentCount,
  selectComments,
} from "../../redux/_blockPost/blockPostSelectors";
import {
  selectToken,
  selectUser,
  selectLike,
  selectAngry,
  selectHaha,
  selectFollowing,
  selectDislikes,
  selectSpam,
  selectReport,
} from "../../redux/_user/userSelectors";
import {
  setAddSpam,
  setRemoveSpam,
  addLike,
  removeLike,
  addAngry,
  removeAngry,
  addHAHA,
  removeHaha,
  addDislikes,
  removeDislikes,
  addReport,
  removeReport,
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
  addSad,
  removeSad,
  addFire,
  removeFire,
  addUpVote,
  removeUpvote,
} from "../../redux/_user/userSlice";
import BlockCommentLoading from "../SkeletonLoading/BlockCommentLoading";
import { useNavigate } from "react-router-dom";
import { BiComment, BiArrowBack } from "react-icons/bi";
import {
  AiOutlineHeart,
  AiOutlineShareAlt,
  AiOutlineStar,
} from "react-icons/ai";
import { RiSpam3Line, RiSpam3Fill } from "react-icons/ri";
import CustomPostForm from "../modal/CustomPostForm";
import CommentCard from "../Group.Post.Comment/CommentCard";
import CustomModal from "../modal/CustomModal";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import GroupPostCard from "./GroupPostCard";
import {
  TwitterShareButton,
  XIcon,
  FacebookShareButton,
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share";
import { FaCopy } from "react-icons/fa";
import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import { getCoinList } from "../../redux/_coin/coinSlice";
import { ReactComponent as Comment } from "../../Assets/postFooter/message.svg";
import { ReactComponent as Share } from "../../Assets/postFooter/share.svg";
import { ReactComponent as Gift } from "../../Assets/postFooter/gift.svg";
import { ReactComponent as Flag } from "../../Assets/postFooter/flag.svg";
import { ReactComponent as Love } from "../../Assets/postFooter/love_one.svg";

const NormalPostFooter = ({ postData, reposted, setReposted }) => {
  useSocket();
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const report = useSelector(selectReport);
  const token = useSelector(selectToken);
  const following = useSelector(selectFollowing);
  const like = useSelector(selectLike);
  const angry = useSelector(selectAngry);
  const haha = useSelector(selectHaha);
  const dislike = useSelector(selectDislikes);
  const spamList = useSelector(selectSpam);
  const groupData = useSelector(selectGroupData);
  const currentCommentCount = useSelector(selectcurrentCommentCount);
  const comments = useSelector(selectComments);

  const [postId, setPostId] = React.useState(postData.p_id);
  const [openShareViaModal, setOpenShareViaModal] = React.useState(false);

  // *** Group post like state
  const [likeCount, setLikeCount] = React.useState(postData.l_c);
  const [openLikeModal, setOpneLikeModal] = React.useState(false);
  const [likeType, setLikeType] = React.useState("all");
  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [isPostLike, setPostLike] = React.useState(postData.like || false);

  // *** Group post comment state
  const [cmntCount, setCmntCount] = React.useState(postData.c_c || 0);
  const [openCommentModal, setOpenCommentModal] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [commentCount, setCommentCount] = React.useState(5);
  const [isCommentsLoading, setIsCommentLoading] = React.useState(false);
  const [text, setText] = React.useState("");
  const [image, setImage] = React.useState("");
  const [prevImage, setPrevImage] = React.useState("");
  const [isBtnLoading, setIsBtnLoading] = React.useState(false);
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);
  const [sortedBy, setSortedBy] = React.useState("new");
  const [prevSortedBy, setprevSortedBy] = React.useState("new");

  // *** Group post spam count state
  const [spamCount, setSpamCount] = React.useState(postData.sp_c);

  // *** Group Post donate state
  const [openDonatModal, setOpenDonatModal] = React.useState(false);
  const [donateValue, setDonateValue] = React.useState(0);
  const [donateBtnVisible, setDonateBtnVisible] = React.useState(false);
  const [custommessage, setCustomMessage] = React.useState("");

  const [openFlagModal, setOpenFlagModal] = React.useState(false);
  const [flag, setFlag] = React.useState(postData.flag || []);

  // *** Group post share state
  const [openSheareModal, setOpenShareModal] = React.useState(false);
  const [postDetails, setPostDetails] = React.useState(null);
  const [sharedText, setSharedText] = React.useState("");
  const [isCopied, setIsCopied] = React.useState(false);

  const [openCryptoList, setOpenCryptoList] = React.useState(false);
  const [mentionCoin, setMentionCoin] = React.useState("");
  const [mentionCoinCurrentPrice, setMentionCoinCurrentPrice] =
    React.useState("");
  const [mentionCoinId, setMentionCoinId] = React.useState("");
  const [coinpage, setCoinPage] = React.useState(1);
  const [searchCoinList, setSearchCoinList] = React.useState([]);
  const [coinlimit, setCoinLimit] = React.useState(20);
  const [coinCount, setCoinCount] = React.useState(20);
  const [coinLoading, setCoinLoading] = React.useState(false);

  const commentModalHandler = (id) => {
    setOpenCommentModal(true);
    setPostId(id);
  };
  const closeCommentModal = () => {
    setOpenCommentModal(false);
    dispatch(setRemoveComments());
    setPostId("");
  };

  React.useEffect(() => {
    if (!text.trim() && !prevImage) {
      setIsBtnDisable(true);
    } else {
      setIsBtnDisable(false);
    }
  }, [text, prevImage]);

  // *** Handle submin block post comment
  const handleCommentsubmit = async () => {
    setIsBtnDisable(true);
    // alert("Comment");
    const data = {
      token,
      postId,
      post: {
        cmnt_img: image,
        text: text,
        mentionCoinCurrentPrice: mentionCoinCurrentPrice,
        mentionCoinId: mentionCoinId,
      },
    };
    const result = await dispatch(handleCreateComment(data));
    console.log(result.comment.bins);
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
    setCmntCount((p) => p + 1);
    dispatch(prepandComment(result.comment.bins));
    setPrevImage("");
    setImage("");
    setText("");
    setIsBtnLoading(false);
    // setIsVisble(false);
  };

  // **** Handle fetching comments...
  const fetchComments = async () => {
    console.log("Call fetch comments");
    const data = { postId, token, page, limit, sortedBy };
    dispatch(handleFetchComments(data));
  };

  React.useEffect(() => {
    if (openCommentModal) {
      fetchComments(postId, sortedBy);
    }
  }, [page, postId, sortedBy, openCommentModal]);

  const handleDonateModal = () => {
    setOpenDonatModal(true);
  };
  const handleDonateChange = (value) => {
    setDonateValue(value);
  };
  const onClose = () => {
    setOpenDonatModal(false);
  };
  React.useEffect(() => {
    if (donateValue > 0) {
      setDonateBtnVisible(true);
    } else {
      setDonateBtnVisible(false);
    }
  }, [donateValue]);
  const handleDonatPost = async () => {
    const data = {
      token,
      postId,
      donateValue,
      postUserId: postData.g_c_dun,
      custommessage,
    };
    const result = await dispatch(updateDonatePost(data));
    if (result.notificationData) {
      socket.emit("notification receive", result);
    }
    setOpenDonatModal(false);
    setDonateValue("");
    setDonateBtnVisible(false);
    setCustomMessage("");
  };

  function useOutsideAlerter(ref) {
    React.useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpenEmojiIcons(false);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  const wrapperRef = React.useRef(null);
  useOutsideAlerter(wrapperRef);

  const handleRemoveLike = async (id) => {
    const data = { token, id };
    const result = await dispatch(removeBlockPostLike(data));
    dispatch(removeLike(id));
    dispatch(removeAngry(id));
    dispatch(removeHaha(id));
    dispatch(removeDislikes(id));
    dispatch(removeSad(id));
    dispatch(removeFire(id));
    dispatch(removeUpvote(id));
  };

  const handleLikeEmoji = (id, username) => {
    // alert("HEY!!!!!!!!!");
    setOpenEmojiIcons(true);
    setPostId(id);
  };
  const likePost = async (value, id) => {
    const data = { token, id, value };
    // LIKE
    if (value === "like") {
      if (likeCount === 0) {
        dispatch(addLike(id));
        setPostLike(true);
        setLikeCount((prev) => prev + 1);
      } else {
        if (!like.includes(id)) {
          if (
            dislike.includes(id) ||
            haha.includes(id) ||
            angry.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(addLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addLike(id));
          }
        } else {
          dispatch(removeLike(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // ANGRY
    else if (value === "angry") {
      if (likeCount === 0) {
        dispatch(addAngry(id));
        setLikeCount((prev) => prev + 1);
        // setPostDislike(true);
      } else {
        if (!angry.includes(id)) {
          if (
            dislike.includes(id) ||
            haha.includes(id) ||
            like.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(addAngry(id));
            dispatch(removeLike(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            dispatch(addAngry(id));
            setLikeCount((prev) => prev + 1);
          }
        } else {
          dispatch(removeAngry(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // HAHA
    else if (value === "haha") {
      if (likeCount === 0) {
        setPostHaha(true);
        dispatch(addHAHA(id));
        setLikeCount((prev) => prev + 1);
      } else {
        if (!haha.includes(id)) {
          if (
            dislike.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(addHAHA(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addHAHA(id));
          }
        } else {
          dispatch(removeHaha(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // DISLIKE
    else if (value === "dislike") {
      if (likeCount === 0) {
        dispatch(addDislikes(id));
        setLikeCount((prev) => prev + 1);
        setPostDisLike(true);
      } else {
        if (!dislike.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            sad.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(addDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addDislikes(id));
          }
        } else {
          dispatch(removeDislikes(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // SAD
    else if (value === "sad") {
      if (likeCount === 0) {
        dispatch(addSad(id));
        setLikeCount((prev) => prev + 1);
        // setPostDisLike(true);
      } else {
        if (!sad.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            dislike.includes(id) ||
            fire.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(addSad(id));
            dispatch(removeFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addSad(id));
          }
        } else {
          dispatch(removeSad(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // FIRE
    else if (value === "fire") {
      if (likeCount === 0) {
        dispatch(addFire(id));
        setLikeCount((prev) => prev + 1);
        // setPostDisLike(true);
      } else {
        if (!fire.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            dislike.includes(id) ||
            sad.includes(id) ||
            upvote.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(addFire(id));
            dispatch(removeUpvote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addFire(id));
          }
        } else {
          dispatch(removeFire(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }
    // UPVOTE
    else if (value === "upvote") {
      if (upvote === 0) {
        dispatch(addUpVote(id));
        setLikeCount((prev) => prev + 1);
        // setPostDisLike(true);
      } else {
        if (!fire.includes(id)) {
          if (
            haha.includes(id) ||
            like.includes(id) ||
            angry.includes(id) ||
            dislike.includes(id) ||
            sad.includes(id) ||
            fire.includes(id)
          ) {
            dispatch(removeLike(id));
            dispatch(removeAngry(id));
            dispatch(removeHaha(id));
            dispatch(removeDislikes(id));
            dispatch(removeSad(id));
            dispatch(removeFire(id));
            dispatch(addUpVote(id));
          } else {
            setLikeCount((prev) => prev + 1);
            dispatch(addUpVote(id));
          }
        } else {
          dispatch(removeUpvote(id));
          setLikeCount((prev) => prev - 1);
        }
      }
    }

    const result = await dispatch(updateLikePost(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    } else {
      console.log(result);
    }
    setOpenEmojiIcons(false);
  };

  // *** Handle Flag post
  const handleFlagPost = async () => {
    const data = { token, postId };
    const result = await dispatch(flagBlockPost(data));
    setFlag((prev) => [...prev, user.handleUn]);
    setOpenFlagModal(false);
  };

  // const handleSharePost = async (post) => {
  //   const data = {
  //     post,
  //     token,
  //   };
  //   const result = await dispatch(sharePost(data));
  // };

  // **************************************** //
  const handleOpenShareModal = (data) => {
    setOpenShareModal(true);
    setPostDetails(data);
  };

  const handleSharePost = async () => {
    console.log(postDetails);
    const data = { token, post: postDetails, sharedText };
    const result = await dispatch(sharePost(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
    setOpenShareModal(false);
    setSharedText("");
    setPostDetails(null);
  };

  const handleRepost = async (id) => {
    const data = { token, id };
    const result = await dispatch(respost(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
    if (reposted.includes(user.handleUn)) {
      console.log("Already reposted");
      const temp = reposted;
      const arr = temp.filter((data) => data !== user.handleUn);
      setReposted(arr);
    } else {
      console.log("Not yet");
      setReposted((prev) => [...prev, user.handleUn]);
    }
  };

  const handleShareViaModal = (id) => {
    setPostId(id);
    setOpenShareViaModal(true);
  };

  const handleCopyURL = (id) => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  const handleRedirectToTakeDownPost = (id, badgeLength) => {
    if (badgeLength.length > 0) {
      navigate(`/takedown/post/${id}`);
    } else {
      alert("User does not have badge");
    }
  };

  const handleKeyChange = (e) => {
    if (e.keyCode === 32 || e.keyCode === 8) {
      setOpenCryptoList(false);
    } else if (e.keyCode === 50) {
      setOpenCryptoList(false);
    } else if (e.keyCode === 52) {
      setOpenCryptoList(true);
    }
  };

  const handleSelectMentionCoin = (data) => {
    setMentionCoinId(data.id);
    setMentionCoinCurrentPrice(data.current_price);
    setMentionCoin(data.name);
    const arr = text.split(" ");
    const temp = arr.filter((data) => !data.includes("$"));
    setText(temp.join(" "));

    setText((prev) => prev + " $" + data.name + " ");
    setOpenCryptoList(false);
  };

  React.useEffect(() => {
    if (openCryptoList) {
      fetchCoins();
    }
  }, [openCryptoList, coinpage]);

  const fetchCoins = async () => {
    setCoinLoading(true);
    const data = { page: coinpage, limit: coinlimit };
    const result = await dispatch(getCoinList(data));
    setCoinCount(result.length);
    if (coinpage === 1) {
      setSearchCoinList(result);
    } else {
      setSearchCoinList((prev) => [...prev, ...result]);
    }
    setCoinLoading(false);
  };

  const handleImageChange = (e) => {
    setPrevImage(URL.createObjectURL(e.target.files[0]));
    setImage(e.target.files[0]);
  };

  const closeCmntImage = () => {
    setPrevImage("");
    setImage("");
  };

  function handleScrollToFetchCoins(e) {
    let cl = e.currentTarget.clientHeight;
    let sy = Math.round(e.currentTarget.scrollTop);
    let sh = e.currentTarget.scrollHeight;
    if (cl + sy + 1 >= sh) {
      if (coinCount !== 0) {
        setCoinPage((prev) => prev + 1);
      }
    }
  }

  return (
    <div className="blockcast_footer_section">
      {/* Group Post COmment Modal */}
      {openCommentModal && (
        <CustomPostForm
          title={
            <div className="comment_modal_title_section">
              <div className="cmnt_header_box">
                <button
                  className="cmnt_modal_back_btn"
                  onClick={closeCommentModal}
                >
                  <BiArrowBack />
                </button>
                <span className="group_post_modal_title">Comment</span>
              </div>
            </div>
          }
          body={
            <div className="comment_modal_body">
              {isCommentsLoading ? (
                <BlockCommentLoading />
              ) : (
                <>
                  {(comments || []).length > 0 ? (
                    <>
                      {comments.map((data) => (
                        <CommentCard key={data.c_id} commentData={data} />
                      ))}
                      {currentCommentCount === limit && (
                        <div className="comment_load_more_btn_section">
                          <button onClick={() => setPage((p) => p + 1)}>
                            Load more
                          </button>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="empty_block_modal_comment">
                      No comment found
                    </div>
                  )}
                </>
              )}
            </div>
          }
          footer={
            <>
              {flag.includes(user.handleUn) ? (
                <div className="gp_flag_status_text">
                  You marked this post as flag so you cannot comment on this
                  post
                </div>
              ) : (
                <div className="comment_modal_footer">
                  {/* Preview image */}
                  {prevImage && (
                    <div className="prev_image_section">
                      <img src={prevImage} className="prev_image_box" />
                      <button
                        className="close_btn_prev"
                        onClick={closeCmntImage}
                      >
                        <AiOutlineClose />
                      </button>
                    </div>
                  )}
                  <input
                    type="text"
                    placeholder="Enter your comment"
                    className="comment_input"
                    value={text}
                    onKeyDown={(e) => handleKeyChange(e)}
                    onChange={(e) => setText(e.target.value.slice(0, 100))}
                  />
                  {/* Show crypto coins */}
                  {openCryptoList && (
                    <div
                      className="group_crypto_list"
                      onScroll={(e) => handleScrollToFetchCoins(e)}
                    >
                      {coinLoading ? (
                        <div className="loader_section">
                          <span className="loader"></span>
                        </div>
                      ) : (
                        <>
                          {(searchCoinList || []).length > 0 ? (
                            <React.Fragment>
                              {searchCoinList.map((data) => (
                                <div
                                  className="mention_user_card"
                                  onClick={() => handleSelectMentionCoin(data)}
                                >
                                  <img
                                    src={`https://assets.coingecko.com/coins/images/${data.image}`}
                                    className="user_small_avatar"
                                  />

                                  <span className="user_list_name">
                                    {data.name}
                                  </span>
                                </div>
                              ))}
                            </React.Fragment>
                          ) : (
                            <div>No user found</div>
                          )}
                        </>
                      )}
                    </div>
                  )}
                  {/* Gallery */}
                  <label
                    htmlFor="post_comment_file"
                    className="modal_file_icon"
                  >
                    <span class="icon-gallery"></span>
                  </label>
                  <input
                    type="file"
                    id="post_comment_file"
                    className="input_file"
                    onChange={(e) => handleImageChange(e)}
                    accept="image/*"
                  />

                  {/* Emoji */}
                  <button
                    className="modal_post_icons_button"
                    onClick={() => setOpenEmojiIcons(true)}
                  >
                    <span class="icon-emogy"></span>
                  </button>

                  {isBtnDisable ? null : (
                    <button
                      className="send_cmn_btn"
                      onClick={() => handleCommentsubmit()}
                    >
                      <AiOutlineSend
                        className={isBtnDisable ? "disable" : "not_disable"}
                      />
                    </button>
                  )}
                </div>
              )}
            </>
          }
        />
      )}

      {/* Donate crypto */}
      {openDonatModal && (
        <CustomModal
          title="Repost"
          onClose={onClose}
          body={
            <div className="modal_body">
              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="50"
                  checked={donateValue === "50"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="Public"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="50"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    50
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="50"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $50 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="100"
                  checked={donateValue === "100"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="Public"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="100"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    100
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="100"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $100 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="200"
                  checked={donateValue === "200"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="200"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="200"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    200
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="200"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $200 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="500"
                  checked={donateValue === "500"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="500"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="500"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    500
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="500"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $500 to your favourite creator
                  </span>
                </div>
              </div>

              <div className="option_box">
                <input
                  type="radio"
                  name="donateValue"
                  value="1000"
                  checked={donateValue === "1000"}
                  onChange={(e) => handleDonateChange(e.target.value)}
                />
                <div
                  className="options_text_scection"
                  id="1000"
                  onClick={(e) => handleDonateChange(e.target.id)}
                >
                  <span
                    className="options_text_scection_header"
                    id="1000"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    1000
                  </span>
                  <br />
                  <span
                    className="options_text_scection_text"
                    id="1000"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    Donate $1000 to your favourite creator
                  </span>
                </div>
              </div>
            </div>
          }
          footer={
            donateBtnVisible && (
              <>
                <textarea
                  type="text"
                  placeholder="Send message"
                  value={custommessage}
                  className="edit_form_section_textarea"
                  onChange={(e) =>
                    setCustomMessage(e.target.value.slice(0, 50))
                  }
                ></textarea>
                <button className="donate_btn" onClick={handleDonatPost}>
                  Donate ${donateValue}{" "}
                </button>
              </>
            )
          }
        />
      )}

      {/* Open Flag Modal */}
      {openFlagModal && (
        <CustomModal
          onClose={() => setOpenFlagModal(false)}
          title={t("Flag post")}
          body={<>{t("Do you want to flag this post?")}</>}
          footer={
            <div>
              <button className="update_btn" onClick={handleFlagPost}>
                <>{t("Flag")}</>
              </button>
            </div>
          }
        />
      )}

      {openSheareModal && (
        <CustomPostForm
          title={
            <div className="comment_modal_title_section">
              <div className="cmnt_header_box">
                <button
                  className="cmnt_modal_back_btn"
                  onClick={() => setOpenShareModal(false)}
                >
                  <BiArrowBack />
                </button>
                <span className="group_post_modal_title">Share Post</span>
              </div>
            </div>
          }
          body={
            <div>
              <textarea
                type="text"
                placeholder="Enter something..."
                className="modal_textarea"
                value={sharedText}
                onChange={(e) => setSharedText(e.target.value.slice(0, 100))}
              ></textarea>
              <GroupPostCard postData={postDetails} />
            </div>
          }
          footer={
            <button
              className="share_post_btn"
              onClick={() => handleSharePost()}
            >
              Share
            </button>
          }
        />
      )}

      {openShareViaModal && (
        <CustomPostForm
          title={
            <div className="comment_modal_title_section">
              <div className="cmnt_header_box">
                <button
                  className="cmnt_modal_back_btn"
                  onClick={() => setOpenShareViaModal(false)}
                >
                  <BiArrowBack />
                </button>
                <span className="group_post_modal_title">Share Post</span>
              </div>
            </div>
          }
          body={
            <div>
              <div>
                <div
                  className="share_modal_section_one"
                  onClick={() => handleCopyURL(postData.p_id)}
                >
                  <span className="post_url">
                    http://localhost:3001/group/full/post/{postId}
                  </span>
                  <button className="copy_icon">
                    {isCopied ? (
                      <AiOutlineCheck className="copied" />
                    ) : (
                      <FaCopy />
                    )}
                  </button>
                </div>
                <div className="share_modal_body_two">
                  <TwitterShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    className="Demo__some-network__share-button"
                  >
                    <XIcon size={32} round />
                  </TwitterShareButton>

                  <FacebookShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    className="Demo__some-network__share-button"
                  >
                    <FacebookIcon size={32} round />
                  </FacebookShareButton>

                  <FacebookMessengerShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    appId="521270401588372"
                    className="Demo__some-network__share-button"
                  >
                    <FacebookMessengerIcon size={32} round />
                  </FacebookMessengerShareButton>

                  <WhatsappShareButton
                    url={`http://localhost:3001/group/full/post/${postId}`}
                    separator=":: "
                    className="Demo__some-network__share-button"
                  >
                    <WhatsappIcon size={32} round />
                  </WhatsappShareButton>
                </div>
              </div>
            </div>
          }
        />
      )}

      {/* Footer Section one */}
      <div className="footer_section_one">
        {/* Likes count */}
        <div className="section_one_box">
          <span className="box_one_title">{t("Likes")}:</span>
          <span className="box_one_count">{likeCount || 0}</span>
        </div>

        {/* Comment count */}
        <div
          className="section_one_box"
          onClick={() => commentModalHandler(postData.p_id)}
        >
          <span className="box_one_title">{t("Comments")}:</span>
          <span className="box_one_count">{cmntCount || 0}</span>
        </div>

        {/* Views count */}
        <div className="section_one_box">
          <span className="box_one_title">{t("Views")}:</span>
          <span className="box_one_count">{postData.views || 0}</span>
        </div>

        {/* Earning count */}
        <div className="section_one_box">
          <span className="box_one_title">{t("Earning")}:</span>
          <span className="box_one_count">{postData.earn || 0}</span>
        </div>

        <div className="section_one_box">
          <span className="box_one_title">{t("Flagged count")}:</span>
          <span className="box_one_count">{postData.flag_c || 0}</span>
        </div>
      </div>

      {/* Footer Section Two */}
      <>
        {flag.includes(user.handleUn) ? (
          <div className="footer_section_two group_post_last_footer">
            <button className="post_footer_btn post_like falgged_btn">
              <AiOutlineHeart id="like" /> {likeCount}
            </button>

            {/* Comment */}
            <button className="post_footer_button falgged_btn">
              <BiComment />
            </button>

            {/* Share */}
            <button className="post_footer_button falgged_btn">
              <AiOutlineShareAlt />
            </button>

            {/* Flag post */}
            <button
              className="post_footer_button falgged_btn"
              onClick={() =>
                handleRedirectToTakeDownPost(postData.p_id, user.badges)
              }
            >
              <AiFillFlag />
            </button>

            <button className="post_footer_button falgged_btn">
              <AiOutlineStar />
            </button>
          </div>
        ) : (
          <div className="footer_section_two group_post_last_footer">
            {openEmojiIcons && (
              <div className="group_post_footer_emoji" ref={wrapperRef}>
                <EmojiLike
                  id={postData.p_id}
                  setQuery={setQuery}
                  clickHandler={likePost}
                />
              </div>
            )}

            {/* Like */}
            {flag.includes(user.handleUn) ? (
              <button className="post_footer_btn post_like">
                <Love id="like" className="custom_icon" /> {likeCount}
              </button>
            ) : (
              <button className="post_footer_button footer_like_btn">
                {like.includes(postData.p_id) ? (
                  <button
                    className="post_footer_btn post_like"
                    onClick={() => handleRemoveLike(postData.p_id)}
                  >
                    <Love id="like" className="custom_icon" /> {likeCount}
                  </button>
                ) : (
                  <>
                    {dislike.includes(postData.p_id) ? (
                      <button
                        className="post_footer_btn post_like"
                        onClick={() => handleRemoveLike(postData.p_id)}
                      >
                        <AiFillDislike className="post_like_active dislike_icon" />{" "}
                        {likeCount}
                      </button>
                    ) : (
                      <>
                        {haha.includes(postData.p_id) ? (
                          <button
                            className="post_footer_btn post_like"
                            onClick={() => handleRemoveLike(postData.p_id)}
                          >
                            <BsEmojiLaughingFill className="post_like_active funny_icon" />{" "}
                            {likeCount}
                          </button>
                        ) : (
                          <>
                            {angry.includes(postData.p_id) ? (
                              <button
                                className="post_footer_btn post_like"
                                onClick={() => handleRemoveLike(postData.p_id)}
                              >
                                <BsEmojiAngryFill className="post_like_active angry_icon" />{" "}
                                {likeCount}
                              </button>
                            ) : (
                              <button
                                className="post_footer_btn post_like"
                                onClick={() => handleLikeEmoji(postData.p_id)}
                              >
                                <Love id="like" className="custom_icon" />{" "}
                                {likeCount}
                              </button>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </>
                )}
              </button>
            )}

            {/* Comment */}
            <button
              className="post_footer_button"
              onClick={() => commentModalHandler(postData.p_id)}
            >
              <Comment
                id="comment"
                className="custom_icon post_card_footer_icon cmnt_footer_icon"
              />
            </button>

            {/* Share */}
            {postData.sub_view !== "subs" && (
              <Menu
                menuButton={
                  <MenuButton lassName="post_footer_button">
                    <Share
                      id="comment"
                      className="post_card_footer_icon cmnt_footer_icon custom_icon "
                    />
                  </MenuButton>
                }
              >
                <MenuItem
                  className={"group_post_card_menuItem"}
                  onClick={() => handleRepost(postData.p_id)}
                >
                  {reposted.includes(user.handleUn) ? (
                    <>{t("Remove")}</>
                  ) : (
                    <>{t("Repost")}</>
                  )}
                </MenuItem>

                <MenuItem
                  className={"group_post_card_menuItem"}
                  onClick={() => handleOpenShareModal(postData)}
                >
                  {t("Quote")}
                </MenuItem>

                <MenuItem
                  className={"group_post_card_menuItem"}
                  onClick={() => handleShareViaModal(postData.p_id)}
                >
                  {t("Share via")}
                </MenuItem>
              </Menu>
            )}

            {/* Flag post */}
            {user.handleUn !== postData.u_dun && (
              <>
                {flag.includes(user.handleUn) ? (
                  <button className="post_footer_button falgged_btn">
                    <Flag
                      id="comment"
                      className="post_card_footer_icon cmnt_footer_icon custom_icon"
                    />
                  </button>
                ) : (
                  <button
                    className="post_footer_button"
                    onClick={() => setOpenFlagModal(true)}
                  >
                    <Flag
                      id="comment"
                      className="post_card_footer_icon cmnt_footer_icon custom_icon"
                    />
                  </button>
                )}
              </>
            )}

            <>
              {postData.g_c_dun !== user.handleUn && (
                <button
                  className="post_footer_button"
                  onClick={handleDonateModal}
                >
                  <Gift
                    id="comment"
                    className="post_card_footer_icon cmnt_footer_icon custom_icon"
                  />
                </button>
              )}
            </>
          </div>
        )}
      </>
    </div>
  );
};

export default NormalPostFooter;
