/** @format */

import React from "react";
import { GoComment } from "react-icons/go";
import { FaHandHoldingHeart } from "react-icons/fa";
import {
  AiOutlineDislike,
  AiOutlineHeart,
  AiOutlineStar,
} from "react-icons/ai";

const InformativePost = ({ postData }) => {
  return (
    <>
      <div className='post_footer_count_section'>
        <div className='post_count'>
          <span className='count_text'>Helpful: </span>
          <span className='card_count'>{postData.hlp_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Comments: </span>
          <span className='card_count'>{postData.c_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Unhelpful: </span>
          <span className='card_count'>{postData.unhlp_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Misleading: </span>
          <span className='card_count'>{postData.misld_c || 0}</span>
        </div>
      </div>

      <div className='social_post_footer_one'>
        <button className='info_post_btn' id='comment'>
          <FaHandHoldingHeart id='comment' />
        </button>

        <button className='info_post_btn' id='comment'>
          <GoComment id='comment' />
        </button>

        <button className='info_post_btn' id='unhelpful'>
          <AiOutlineDislike id='unhelpful' />
        </button>

        <button className='info_post_btn' id='misleading'>
          <AiOutlineHeart id='misleading' />
        </button>

        <button id='donate' className='post_footer_btn post_share_btn'>
          {/* <AiOutlineShareAlt id="share" /> */}
          <AiOutlineStar />
        </button>
      </div>
    </>
  );
};

export default InformativePost;
