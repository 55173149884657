/** @format */

import { createSlice } from "@reduxjs/toolkit";
import { newNotification } from "../_notification/notificationSlice";
import {
  getPosts,
  handleCreateNewPost,
  handleCreateNewPoll,
  handlePinnedPost,
  handleBookmarkPost,
  handleHidePost,
  handleSocialPostUpdate,
  handleUpdateDeletePost,
  handleUpdateSharePrivacy,
  handleUpdatePostCommentPrivacy,
  handleSharePost,
  handleUpdateDonatePost,
  postAnalytics,
  handleUpdatePostSpam,
  handleUpdateLikePost,
  handleUpdateRemovePostLike,
  handleUpdateAngryPost,
  handleUpdateHahaPost,
  handleUpdateDislikePost,
  handlePostComment,
  fetchPostRelatedComment,
  pinnedComment,
  editComment,
  deleteComment,
  commentSpam,
  updateLikeComment,
  updateRemoveLikeComment,
  CommentReply,
  ReplyReply,
  fetchReplies,
  deleteReply,
  spamReply,
  spamInnerReply,
  likeReply,
  likeInnerReply,
  dislikeReply,
  innerDislikeReply,
  handleFetchLikeUser,
  fetchFullPost,
  fetTrendingPostList,
  fetTrendingCoinList,
  fetTrendingPosts,
  fetTrendingCoinPosts,
  fetNewsPosts,
  reliableNews,
  handleInterestingNews,
  handleFakeNews,
  likeAnnouncement,
  importentAnnouncement,
  fakeAnnouncement,
  helpfulInfo,
  unhelpfulInfo,
  misleadingInfo,
  fetchDonaterHistory,
  fetchActivity,
  likeNFT,
  nftComments,
  nftPinComments,
  nftEditComments,
  nftCreateComments,
  nftDeleteComments,
  handleNftLikeComment,
  getNFTReplies,
  handleCreateNFTReply,
  likeNFTReply,
  handleEditNFTReply,
  handleNFTDelete,
  handleReportPost,
  handlePollVoting,
  handleFetchAnalyticsData,
  handleRepostPost,
  handleFalggedPost,
  handleGetVottedUsers,
  handleGetMentionsPosts,
  handleGetReels,
  handleGetNfts,
  handleFetchCoinPrice,
  handlefetchDonationHistory,
} from "../../api/_post/postApi";

//reset state helper function
const resetState = (state) => {
  state.isLoading = true;
  state.error = null;
};

//success helper function
const successFetchPosts = (state, action) => {
  state.posts = action.payload;
  state.isLoading = false;
};

//error or fail helper function
const handleFail = (state, action) => {
  state.error = action.payload;
  state.isLoading = false;
};

//create slice
export const postSlice = createSlice({
  name: "post",
  initialState: {
    posts: [],
    pinnedPost: null,
    comments: [],
    myComments: [],
    selectComment: null,
    selectedPost: null,
    notificationPost: [],
    cusrrentPostCount: 0,
    currentCommentCount: 0,
    commentUpdate: null,
    // other state properties,
    replies: [],
  },
  reducers: {
    fetchPostsStart: resetState,
    fetchPostsSuccess: successFetchPosts,
    fetchPostsFail: handleFail,

    updatePostStart: resetState,
    updatePostSuccess: (state, action) => {
      // Assuming the API returns the updated post on success,
      // find the post in the state by its ID and update it
      const updatedPost = action.payload;
      const existingPost = state.posts.find(
        (post) => post.id === updatedPost.id
      );
      if (existingPost) {
        // Copy the updated fields to the existing post
        Object.assign(existingPost, updatedPost);
      }
      state.isLoading = false;
    },
    updatePostFail: handleFail,
    setPost: (state, action) => {
      state.posts = action.payload;
    },

    // Add other actions here...
    putPostLast: (state, action) => {
      state.posts = [...action.payload, ...state.posts];
    },
    removePosts: (state) => {
      state.posts = [];
    },
    removeNotificationPost: (state, action) => {
      state.notificationPost = [];
    },
    addNotificationPost: (state, action) => {
      state.notificationPost = [action.payload, ...state.notificationPost];
    },
    setPinnedPost: (state, action) => {
      state.pinnedPost = action.payload;
    },
    prependPost: (state, action) => {
      state.posts = [action.payload, ...state.posts];
    },
    appendPost: (state, action) => {
      state.posts = [...state.posts, ...action.payload];
    },
    setPostCount: (state, action) => {
      state.cusrrentPostCount = action.payload;
    },

    /**
     * @COMMENTS
     */
    setComments: (state, action) => {
      state.comments = action.payload;
    },
    prependComment: (state, action) => {
      state.comments = [action.payload, ...state.comments];
    },
    appendComment: (state, action) => {
      console.log("APPEND ", action.payload);
      state.comments = [...state.comments, ...action.payload];
    },
    setCommentCount: (state, action) => {
      state.currentCommentCount = action.payload;
    },
    removeComments: (state, action) => {
      state.comments = [];
    },
    setUpdateComment: (state, action) => {
      state.commentUpdate = action.payload;
    },

    /**
     * @REPLY
     */
    addReply: (state, action) => {
      state.replies = action.payload;
    },
    appendReply: (state, action) => {
      state.replies = [action.payload, ...state.replies];
    },
    removeReply: (state, action) => {
      state.replies = [];
    },
    putRepliesLast: (state, action) => {
      state.replies = [...state.replies, ...action.payload];
    },
  },
});

// Export actions outside
export const {
  setPost,
  fetchPostsStart,
  fetchPostsSuccess,
  fetchPostsFail,
  updatePostStart,
  updatePostSuccess,
  updatePostFail,
  putPostLast,
  addNotificationPost,
  removeNotificationPost,
  removePosts,
  setPinnedPost,
  prependPost,
  appendPost,
  setPostCount,
  setComments,
  prependComment,
  appendComment,
  setCommentCount,
  removeComments,
  setUpdateComment,
  appendReply,
  removeReply,
  addReply,
  putRepliesLast,
} = postSlice.actions;

// Define async thunks
export const fetchPosts = (data) => async (dispatch) => {
  dispatch(fetchPostsStart());
  console.log(data.token);
  try {
    const posts = await getPosts(data);
    console.log("posts from serverrrrrrrrrrrrrrrr", posts);

    if (data.page === 0) {
      dispatch(fetchPostsSuccess(posts));
      dispatch(setPostCount(posts.length));
    } else {
      dispatch(appendPost(posts));
      dispatch(setPostCount(posts.length));
    }
    return posts;
  } catch (error) {
    dispatch(fetchPostsFail(error.toString()));
  }
};

export const updatePost = (data) => async (dispatch) => {
  // console.log("Data >>> ", data);
  dispatch(updatePostStart());
  try {
    const posts = await getPosts(data);
    console.log("posts from serverrrrrrrrrrrrrrrr", posts);
    dispatch(updatePostSuccess(posts));
  } catch (error) {
    dispatch(updatePostFail(error.toString()));
  }
};

export const createNewPost = (data) => async (dispatch) => {
  const postData = await handleCreateNewPost(data);
  try {
    return postData;
  } catch (error) {
    console.log("Error");
  }
};

// create poll
export const createNewPoll = (data) => async (dispatch) => {
  const postData = await handleCreateNewPoll(data);
  try {
    return postData;
  } catch (error) {
    console.log("Error");
  }
};

//result retruned back to component without updating redux
export const updatePinnedPost = (data) => async (dispatch) => {
  const postData = await handlePinnedPost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

//result retruned back to component without updating redux
export const updateBookmarkPost = (data) => async (dispatch) => {
  const postData = await handleBookmarkPost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

//result retruned back to component without updating redux
export const updateHidePost = (data) => async (dispatch) => {
  const postData = await handleHidePost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateSocialPost = (data) => async (dispatch) => {
  // console.log(data);
  const postData = await handleSocialPostUpdate(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateDeletePost = (data) => async (dispatch) => {
  const postData = await handleUpdateDeletePost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updatePostSharePrivacy = (data) => async (dispatch) => {
  const postData = await handleUpdateSharePrivacy(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updatePostCommentPrivacy = (data) => async (dispatch) => {
  const postData = await handleUpdatePostCommentPrivacy(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateSharePost = (data) => async (dispatch) => {
  // console.log(data);
  const postData = await handleSharePost(data);
  try {
    console.log("Share post came ", postData);
    dispatch(prependPost(postData.post));
    /**
     * Also save the notification data
     */
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateDonatePost = (data) => async (dispatch) => {
  const postData = await handleUpdateDonatePost(data);
  try {
    dispatch(setPinnedPost(postData));
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateSpamPost = (data) => async (dispatch) => {
  const postData = await handleUpdatePostSpam(data);
  try {
    dispatch(setPinnedPost(postData));
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPostAnalytics = (data) => async (dispatch) => {
  const post = await postAnalytics(data);
  try {
  } catch (error) {
    console.log("ERROR");
  }
};

export const updateLikePost = (data) => async (dispatch) => {
  const postData = await handleUpdateLikePost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const handlePostRemoveLike = (data) => async (dispatch) => {
  const postData = await handleUpdateRemovePostLike(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateAngryPost = (data) => async (dispatch) => {
  const postData = await handleUpdateAngryPost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateHahaPost = (data) => async (dispatch) => {
  const postData = await handleUpdateHahaPost(data);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const updateDislikePost = (data) => async (dispatch) => {
  const postData = await handleUpdateDislikePost(data);
  console.log(postData);
  try {
    return postData;
  } catch (error) {
    console.log(error);
  }
};

export const fetchLikedUser = (data) => async (dispatch) => {
  const result = await handleFetchLikeUser(data);
  console.log("Data came: ", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchFullPost = (data) => async (dispatch) => {
  const result = await fetchFullPost(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetTrendingPostList = (data) => async (dispatch) => {
  const result = await fetTrendingPostList(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchTrendingCoins = (data) => async (dispatch) => {
  const result = await fetTrendingCoinList(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleTrendingPosts = (data) => async (dispatch) => {
  const result = await fetTrendingPosts(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleTrendingCoinPosts = (data) => async (dispatch) => {
  const result = await fetTrendingCoinPosts(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchNews = (data) => async (dispatch) => {
  const result = await fetNewsPosts(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @COMMENT
 */
export const postComment = (data) => async (dispatch) => {
  const comment = await handlePostComment(data);

  try {
    dispatch(prependComment(comment.data.comment));
    return comment.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPostComment = (data) => async (dispatch) => {
  //
  const comments = await fetchPostRelatedComment(data);
  console.log(comments);
  if (data.page === 1) {
    dispatch(setComments(comments));
  } else {
    dispatch(appendComment(comments));
  }
  dispatch(setCommentCount(comments.length));
};

export const updatePinnedComment = (data) => async (dispatch) => {
  const comment = await pinnedComment(data);

  try {
    return comment;
  } catch (error) {
    console.log(error);
  }
};

export const handleCommentEditComment = (data) => async (dispatch) => {
  const comment = await editComment(data);
  try {
    return comment;
  } catch (error) {
    console.log(error);
  }
};

export const handlePostCommentDelete = (data) => async (dispatch) => {
  const comment = await deleteComment(data);
  try {
    return comment;
  } catch (error) {
    return error;
  }
};

export const handlePostCommentSpam = (data) => async (dispatch) => {
  const comment = await commentSpam(data);
  try {
    return comment;
  } catch (error) {
    return error;
  }
};

export const handleCommentLike = (data) => async (dispatch) => {
  const comment = await updateLikeComment(data);
  try {
    return comment;
  } catch (error) {
    return error;
  }
};

export const handleCommentRemoveLike = (data) => async (dispatch) => {
  const comment = await updateRemoveLikeComment(data);
  try {
    return comment;
  } catch (error) {
    return error;
  }
};

/**
 * @REPLY
 */
export const handleReplyComment = (data) => async (dispatch) => {
  const reply = await CommentReply(data);
  try {
    return reply;
  } catch (error) {
    console.log(error);
  }
};

export const handleReplyReply = (data) => async (dispatch) => {
  const reply = await ReplyReply(data);
  try {
    return reply;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchReplies = (data) => async (dispatch) => {
  const repliesData = await fetchReplies(data);

  try {
    if (data.page === 1) {
      dispatch(addReply(repliesData));
    } else {
      dispatch(putRepliesLast(repliesData));
    }
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchReplyReply = (data) => async (dispatch) => {
  const repliesData = await fetchReplies(data);
  try {
    return repliesData;
  } catch (error) {
    console.log(error);
  }
};

export const handleDeleteReply = (data) => async (dispatch) => {
  const replyData = await deleteReply(data);
  // console.log(replyData);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleReplySpam = (data) => async (dispatch) => {
  const replyData = await spamReply(data);
};

export const handleInnerReplySpam = (data) => async (dispatch) => {
  const replyData = await spamInnerReply(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleReplyLike = (data) => async (dispatch) => {
  const replyData = await likeReply(data);
  //
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleInnerReplyLike = (data) => async (dispatch) => {
  const replyData = await likeInnerReply(data);
  //
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleReplyDislike = (data) => async (dispatch) => {
  const replyData = await dislikeReply(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleReplyInnerDislike = (data) => async (dispatch) => {
  const replyData = await innerDislikeReply(data);
  console.log(replyData);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleReliableNews = (data) => async (dispatch) => {
  const replyData = await reliableNews(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const InterestingNews = (data) => async (dispatch) => {
  const replyData = await handleInterestingNews(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const fakeNews = (data) => async (dispatch) => {
  const replyData = await handleFakeNews(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const likeAnnouncementHandler = (data) => async (dispatch) => {
  const replyData = await likeAnnouncement(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const importentAnnouncementHandler = (data) => async (dispatch) => {
  const replyData = await importentAnnouncement(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const fakeAnnouncementHandler = (data) => async (dispatch) => {
  const replyData = await fakeAnnouncement(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleHelpfulInfo = (data) => async (dispatch) => {
  const replyData = await helpfulInfo(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleUnhelpfulPost = (data) => async (dispatch) => {
  const replyData = await unhelpfulInfo(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleMisLeadingPost = (data) => async (dispatch) => {
  const replyData = await misleadingInfo(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const HandleFetchDonaterHistory = (data) => async (dispatch) => {
  const replyData = await fetchDonaterHistory(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchActivity = (data) => async (dispatch) => {
  const replyData = await fetchActivity(data);
  try {
    return replyData;
  } catch (error) {
    console.log(error);
  }
};

// export const handleFetchCoinPosts = (data) => async (dispatch) => {
//   const result = await fetchCoinPosts(data);
//   try {
//     if (typeof data.page === 1) {
//       dispatch(setPost(result));
//     } else {
//       dispatch(appendPost(result));
//     }
//   } catch (error) {
//     console.log(error);
//   }
// };

/**
 * NFT
 */

export const handleLikeNFT = (data) => async (dispatch) => {
  const result = await likeNFT(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handlegetNFTComments = (data) => async (dispatch) => {
  console.log("Came here");
  const result = await nftComments(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handlePinNFTComment = (data) => async (dispatch) => {
  console.log("Came here");
  const result = await nftPinComments(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleEditNFTComment = (data) => async (dispatch) => {
  console.log("Came here");
  const result = await nftEditComments(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleCreteNFTComment = (data) => async (dispatch) => {
  const result = await nftCreateComments(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleDeleteNFTComment = (data) => async (dispatch) => {
  const result = await nftDeleteComments(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// likeComment
export const likeComment = (data) => async (dispatch) => {
  const result = await handleNftLikeComment(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// handleGetReplies
export const handleGetReplies = (data) => async (dispatch) => {
  console.log(data);
  const result = await getNFTReplies(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const createReply = (data) => async (dispatch) => {
  const result = await handleCreateNFTReply(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleLikeReply = (data) => async (dispatch) => {
  const result = await likeNFTReply(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// handleEditNFTReply
export const editNFTReply = (data) => async (dispatch) => {
  const result = await handleEditNFTReply(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const deleteNFTReply = (data) => async (dispatch) => {
  const result = await handleNFTDelete(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const reportPost = (data) => async (dispatch) => {
  const result = await handleReportPost(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const pollVoting = (data) => async (dispatch) => {
  const result = await handlePollVoting(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log("ERROR");
  }
};

export const fetchAnalyticsData = (data) => async (dispatch) => {
  const result = await handleFetchAnalyticsData(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const respost = (data) => async (dispatch) => {
  const result = await handleRepostPost(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const postFalgged = (data) => async (dispatch) => {
  const result = await handleFalggedPost(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getVottedUsers = (data) => async (dispatch) => {
  const result = await handleGetVottedUsers(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getMentionPosts = (data) => async (dispatch) => {
  const result = await handleGetMentionsPosts(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getReels = (data) => async (dispatch) => {
  const result = await handleGetReels(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const getNFTs = (data) => async (dispatch) => {
  const result = await handleGetNfts(data);
  try {
    return result;
  } catch (error) {
    console.log("Error");
  }
};

export const fetchCoinPrice = (data) => async (dispatch) => {
  const result = await handleFetchCoinPrice(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export const fetchDonationHistory = (data) => async (dispatch) => {
  const result = await handlefetchDonationHistory(data);
  try {
    return result;
  } catch (error) {
    console.log(data);
  }
};

export default postSlice.reducer;
