/** @format */

import React from "react";
import { AiFillHeart, AiOutlineStar } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import { RiSpam2Line } from "react-icons/ri";

const NormalPost = ({ postData }) => {
  return (
    <>
      <div className='post_footer_count_section'>
        <div className='post_count'>
          <span className='count_text'>Likes: </span>
          <span className='card_count'>{postData.l_c || 0}</span>
        </div>
        <div className='post_count'>
          <span className='count_text'>Comments: </span>
          <span className='card_count'>{postData.c_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Spam: </span>
          <span className='card_count'>{postData.sp_c || 0}</span>
        </div>
      </div>

      <div className='post_footer_count_section'>
        <button id='like' className='post_footer_btn post_like'>
          <AiFillHeart id='like' className='post_like_active like_icon' />{" "}
          {postData.l_c}
        </button>

        <button className='post_footer_btn post_comment_btn'>
          <BiCommentDetail className='post_card_footer_icon cmnt_footer_icon' />{" "}
          {postData.c_c}
        </button>

        <button className='post_footer_btn post_spam_btn'>
          <RiSpam2Line id='spam' /> {postData.sp_c}
        </button>

        <button id='donate' className='post_footer_btn post_share_btn'>
          {/* <AiOutlineShareAlt id="share" /> */}
          <AiOutlineStar className='post_card_footer_icon' id='donate' />
        </button>
      </div>
    </>
  );
};
export default NormalPost;
