/** @format */

function maskString(str) {
  if (str.length <= 4) {
    return str; // If the string is 4 characters or less, return it unchanged
  }

  const firstPart = str.slice(0, 5); // Get the first 5 characters
  const lastPart = str.slice(-4); // Get the last 4 characters
  const masked = firstPart + "*".repeat(str.length - 9) + lastPart; // Create the masked string

  return masked;
}

module.exports = {
  maskString,
};
