/** @format */

import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { ImSpinner2 } from "react-icons/im";
import {
  updatePinnedPost,
  updateBookmarkPost,
  updateHidePost,
  setPinnedPost,
  updateSocialPost,
  updateDeletePost,
  updatePostSharePrivacy,
  updatePostCommentPrivacy,
  updateSharePost,
  handleFetchFullPost,
  setComments,
  reportPost,
  updateDonatePost,
  updateLikePost,
  handlePostRemoveLike,
  respost,
  postComment,
  fetchPostComment,
  removeComments,
  fetchLikedUser,
  postFalgged,
} from "../../redux/_post/postSlice";
import CustomModal from "../modal/CustomModal";
import {
  selectUser,
  selectToken,
  selectFollowing,
  selectReport,
} from "../../redux/_user/userSelectors";
import { useSelector, useDispatch } from "react-redux";
import { ReportConfig } from "../../config/Report.js";
import { BiArrowBack } from "react-icons/bi";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import {
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
  addReport,
  removeReport,
} from "../../redux/_user/userSlice";
import UserAvatar from "../../Assets/userAvatar.webp";
import { ReactComponent as AllIcon } from "../../Assets/Icons/all.svg";
import CustomPostFormModal from "../modal/CustomPostForm";
import { useNavigate } from "react-router-dom";
import FullPostComp from "../FullPostComp/FullPostComp";

function PostCardModal({
  openPinnedModal,
  setOpenPinnedModal,
  setHasPinned,
  hasPinned,
  type,
  postId,
  setPostId,
  // bookmark
  openBookModal,
  setOpenBookModal,
  bookmark,
  setBookmark,
  // Hide
  openHideModal,
  setOpenHideModal,
  hide,
  setHide,
  handleUn,
  // edit
  openEditModal,
  setOpenEditModal,
  editText,
  setEditText,
  // Delete
  setPostDelete,
  openDeleteModal,
  setOpenDeleteModal,
  // report
  openReportModal,
  setOpenReportModal,
  // privacy
  openPrivacyMenu,
  setOpenPrivacyMenu,
  sharePrivacy,
  setSharePrivacy,
  commentPrivacy,
  setCommentPrivacy,
  // share
  setOpenShareModal,
  postData,
  post,
  setPost,
  // full post
  setOpenFullPostModal,
  openFullPostModal,
  isModalLoading,
  fullpostData,
  setOpenFLoggedModal,
  flag,
  setFlag,
  setOpenDonatModal,
}) {
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isToken = useSelector(selectToken);
  const user = useSelector(selectUser);
  const following = useSelector(selectFollowing);
  const report = useSelector(selectReport);
  const [isLoading, setIsLoading] = useState(false);
  const [postReport, setPostReport] = React.useState("");
  const [reportMsg, setReportMsg] = React.useState("");
  const [reportType, setReportType] = React.useState();
  const [activeTab, setActiveTab] = React.useState("one");
  const [content, setContent] = React.useState("");
  const [donateValue, setDonateValue] = React.useState(0);

  // *** Update pinned modal
  const pinnedPostHandler = async () => {
    setIsLoading(true);
    // updating useState here
    if (!hasPinned) {
      setHasPinned((prev) => prev + 1);
    } else {
      setHasPinned((prev) => prev - 1);
    }
    const data = { postId, isToken };
    // from here we call the postSlice method
    const post = await dispatch(updatePinnedPost(data));
    try {
      // console.log("TRY: ", post);
      if (post.status === 200) {
        setPostId("");
        setOpenPinnedModal(false);
        setIsLoading(false);
      } else {
        setError("Something went wrong");
      }
    } catch (error) {
      setError("Something went wrong");
    }
  };

  // *** update bookmark modal
  const handleBookmarkPost = async () => {
    // updating useState here
    if (bookmark.includes(user.handleUn)) {
      const arr = bookmark;
      const temp = arr.filter((data) => data !== user.handleUn);
      setBookmark(temp);
    } else {
      setBookmark((prev) => [...prev, user.handleUn]);
    }
    const data = { isToken, postId };
    // from here we call the postSlice method
    const post = await dispatch(updateBookmarkPost(data));
    try {
      // console.log("TRY: ", post);
      if (post.status === 200) {
        setOpenBookModal(false);
        setPostId("");
        setIsLoading(false);
      } else {
        setError("Something went wrong");
        const arr = bookmark;
        const temp = arr.filter((data) => data !== user.handleUn);
        setBookmark(temp);
      }
    } catch (error) {
      setError("Something went wrong");
      const arr = bookmark;
      const temp = arr.filter((data) => data !== user.handleUn);
      setBookmark(temp);
    }
  };

  // *** update hide modal
  const handleHidePost = async () => {
    setIsLoading(true);
    const data = { isToken, postId };
    // from here we call the postSlice method
    const post = await dispatch(updateHidePost(data));
    if (hide.includes(handleUn)) {
      const temp = hide;
      const arr = hide.filter((data) => data !== handleUn);
      setHide(arr);
    } else {
      setHide((prev) => [...prev, handleUn]);
    }
    dispatch(setPinnedPost(post));
    setOpenHideModal(false);
    setIsLoading(false);
  };

  // *** Handle edit post
  const handleEditPost = async () => {
    const data = { editText, isToken, postId };
    const post = await dispatch(updateSocialPost(data));
    dispatch(setPinnedPost(post));
    setPostId("");
    setEditText("");
    setOpenEditModal(false);
  };

  // *** Handle delete post
  const handleDeletePost = async () => {
    setPostDelete(true);
    const data = { isToken, postId };
    const post = await dispatch(updateDeletePost(data));
    dispatch(setPinnedPost(post));
    setOpenDeleteModal(false);
  };

  // *** Handle report post
  const handleReportPost = async () => {
    const data = {
      id: postId,
      token: isToken,
      value: postReport,
      msg: reportMsg,
    };
    await dispatch(reportPost(data));
    setOpenReportModal(false);
    setReportMsg("");
    setReportType();
    setDisableReportBtn(true);
    if (report.includes(postId)) {
      dispatch(removeReport(postId));
    } else {
      dispatch(addReport(postId));
    }
  };

  // *** Handle change report post type
  const handleAddReport = (data) => {
    console.log(data);
    if (!postReport) {
      setPostReport(data.id);
    } else {
      if (data.id === postReport) {
        setPostReport("");
      } else {
        setPostReport(data.id);
      }
    }
  };

  // **** Handle update of post share privacy
  const updateSharePrivacy = async (value) => {
    const data = { isToken, postId, sharePrivacy: value };
    const post = await dispatch(updatePostSharePrivacy(data));
    setPinnedPost(post);
    setSharePrivacy(value);
    setOpenPrivacyMenu(false);
  };

  // **** Handle update of post comment privacy
  const updateCommentPrivacy = async (value) => {
    const data = { isToken, postId, commentPrivacy: value };
    const post = await dispatch(updatePostCommentPrivacy(data));
    setPinnedPost(post);
    setSharePrivacy(value);
    setOpenPrivacyMenu(false);
  };

  const handleChange = (e) => {
    setContent(e.target.value.slice(0, 100));
  };

  const closeCommentModal = () => {
    setOpenFullPostModal(false);
    navigate(-1);
  };

  const handlePostFlagged = async () => {
    const data = { token: isToken, id: postId };
    const result = await dispatch(postFalgged(data));
    console.log(result);
    setFlag((prev) => [...prev, user.handleUn]);

    // if (flag) {
    //   const arr = flag;
    //   const temp = arr.filter((data) => data !== user.handleUn);
    //   setFlag(temp);
    // } else {
    //   setFlag((prev) => [...prev, user.handleUn]);
    // }

    setOpenFLoggedModal(false);
  };

  const handleDonateChange = (value) => {
    setDonateValue(value);
  };

  const closeDonateModal = () => {
    setOpenDonatModal(false);
    navigate(-1);
  };

  const getModalProps = (type) => {
    switch (type) {
      case "pin":
        return (
          <CustomModal
            onClose={() => setOpenPinnedModal(false)}
            title={
              hasPinned > 0 ? (
                <>{t("Unpinned post")}</>
              ) : (
                <>{t("Pinned post")}</>
              )
            }
            body={
              hasPinned > 0 ? (
                <>{t("Do you want to unpinned this post?")}</>
              ) : (
                <>{t("Do you want to pinned this post?")}</>
              )
            }
            footer={
              <div>
                <button className="update_btn" onClick={pinnedPostHandler}>
                  {isLoading ? (
                    <ImSpinner2 className="spinner" />
                  ) : (
                    <>{hasPinned > 0 ? <>{t("Unpin")}</> : <>{t("Pin")}</>}</>
                  )}
                </button>
              </div>
            }
          />
        );
      case "bookmark":
        return (
          <CustomModal
            onClose={() => setOpenBookModal(false)}
            title={
              bookmark.includes(user.handleUn) ? (
                <>{t("Remove post")}</>
              ) : (
                <>{t("Bookmark post")}</>
              )
            }
            body={
              bookmark.includes(user.handleUn) ? (
                <>{t("Do you want to remove this post")}</>
              ) : (
                <>{t("Do you want to Save this post")}</>
              )
            }
            footer={
              <div className="modal_footer_section">
                <button className="update_btn" onClick={handleBookmarkPost}>
                  {isLoading ? (
                    <ImSpinner2 className="spinner" />
                  ) : (
                    <>
                      {bookmark.includes(user.handleUn) ? (
                        <>{t("Remove")}</>
                      ) : (
                        <>{t("Bookmark")}</>
                      )}
                    </>
                  )}
                </button>
              </div>
            }
          />
        );
      case "hide":
        return (
          <CustomModal
            onClose={() => setOpenHideModal(false)}
            title={
              hide.includes(user.handleUn) ? (
                <>{t("Unhide post")}</>
              ) : (
                <>{t("Hide post")}</>
              )
            }
            body={
              hide.includes(user.handleUn) ? (
                <>{t("Do you want to unhide this post")}</>
              ) : (
                <>{t("Do you want to hide this post")}</>
              )
            }
            footer={
              <div className="modal_footer_section">
                <button className="update_btn" onClick={handleHidePost}>
                  {isLoading ? (
                    <ImSpinner2 className="spinner" />
                  ) : (
                    <>
                      {hide.includes(user.handleUn) ? (
                        <>{t("Unhide")}</>
                      ) : (
                        <>{t("Hide")}</>
                      )}
                    </>
                  )}
                </button>
              </div>
            }
          />
        );
      case "edit":
        return (
          <CustomModal
            onClose={() => setOpenEditModal(false)}
            title={<>Edit post</>}
            body={
              <div className="edit_modal_section">
                <textarea
                  className="textarea_modal"
                  placeholder="Enter quote"
                  value={editText}
                  onChange={(e) => setEditText(e.target.value.slice(0, 100))}
                ></textarea>
              </div>
            }
            footer={
              <div className="modal_footer_section">
                <button className="post_modal_btn" onClick={handleEditPost}>
                  {isLoading ? (
                    <ImSpinner2 className="spinner" />
                  ) : (
                    <>{t("Edit")}</>
                  )}
                </button>
              </div>
            }
          />
        );
      case "delete":
        return (
          <CustomModal
            onClose={() => setPostDelete(false)}
            title={<>{t("Delete post")}</>}
            body={<>{t("Do you want to delete this post?")}</>}
            footer={
              <div className="modal_footer_section">
                <button
                  className="update_btn delete_btn"
                  onClick={handleDeletePost}
                >
                  {isLoading ? (
                    <ImSpinner2 className="spinner" />
                  ) : (
                    <>{t("Delete")}</>
                  )}
                </button>
              </div>
            }
          />
        );
      case "report":
        return (
          <CustomModal
            onClose={() => setOpenReportModal(false)}
            title={
              <div className="post_form_modal_title">
                <div className="modal_post_form_section">
                  <button
                    className="post_close_modal_button"
                    onClick={() => setOpenReportModal(false)}
                  >
                    <BiArrowBack />
                  </button>
                  <span className="modal_title_text">Report</span>
                </div>
                {postReport && (
                  <button
                    className="_modal_post_button"
                    onClick={handleReportPost}
                  >
                    Report
                  </button>
                )}
              </div>
            }
            body={
              <div className="group_post_report_card_body">
                {Object.keys(ReportConfig).map((data, index) => (
                  <div
                    className="post_report_card"
                    key={index}
                    onClick={() => handleAddReport(ReportConfig[data])}
                  >
                    <div className="radio_section">
                      {postReport === ReportConfig[data].id ? (
                        <BiRadioCircleMarked className="radio_active" />
                      ) : (
                        <BiRadioCircle />
                      )}
                    </div>
                    <div className="post_report_card_info_section">
                      <div className="post_card_report_title">
                        {ReportConfig[data].title}
                      </div>
                      <div className="post_card_report_details">
                        {ReportConfig[data].details}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
          />
        );
      case "privacy":
        return (
          <CustomModal
            title={
              <div className="comment_modal_title_section">
                <div className="cmnt_header_box">
                  <button
                    className="cmnt_modal_back_btn"
                    onClick={() => setOpenPrivacyMenu(false)}
                  >
                    <BiArrowBack />
                    <span>Post privacy settings</span>
                  </button>
                </div>
              </div>
            }
            onClose={() => setOpenPrivacyMenu(false)}
            body={
              <div className={`share_content_section `}>
                {/* Share privacy settings */}
                <div className="modal_privacy_seetings_section">
                  <div className="modal_privacy_seetings_text_section">
                    <div className="modal_privacy_seetings_title_section">
                      Share settings
                    </div>
                    <div className="modal_privacy_seetings_subheader_section">
                      Only selected type of user can only share your post
                    </div>
                  </div>

                  <div className="privacy_modal_radio_section">
                    <div
                      className="radio_btn_section"
                      onClick={() => updateSharePrivacy("all")}
                    >
                      {sharePrivacy === "all" ? (
                        <BiRadioCircleMarked className="active_privacy_radio_icon" />
                      ) : (
                        <BiRadioCircle className="privacy_radio_icon" />
                      )}
                      <span className="privacy_radio_text">All</span>
                    </div>
                    <div
                      className="radio_btn_section"
                      onClick={() => updateSharePrivacy("none")}
                    >
                      {sharePrivacy === "none" ? (
                        <BiRadioCircleMarked className="active_privacy_radio_icon" />
                      ) : (
                        <BiRadioCircle className="privacy_radio_icon" />
                      )}
                      <span className="privacy_radio_text">None</span>
                    </div>
                  </div>
                </div>

                {/* Comment privacy settings */}
                <div className="modal_privacy_seetings_section">
                  <div className="modal_privacy_seetings_text_section">
                    <div className="modal_privacy_seetings_title_section">
                      Comment settings
                    </div>
                    <div className="modal_privacy_seetings_subheader_section">
                      Only selected type of user can only comment on your post
                    </div>
                  </div>

                  <div className="privacy_modal_radio_section">
                    <div
                      className="radio_btn_section"
                      onClick={() => updateCommentPrivacy("all")}
                    >
                      {commentPrivacy === "all" ? (
                        <BiRadioCircleMarked className="active_privacy_radio_icon" />
                      ) : (
                        <BiRadioCircle className="privacy_radio_icon" />
                      )}
                      <span className="privacy_radio_text">All</span>
                    </div>
                    <div
                      className="radio_btn_section"
                      onClick={() => updateCommentPrivacy("none")}
                    >
                      {commentPrivacy === "none" ? (
                        <BiRadioCircleMarked className="active_privacy_radio_icon" />
                      ) : (
                        <BiRadioCircle className="privacy_radio_icon" />
                      )}
                      <span className="privacy_radio_text">None</span>
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        );
      case "share":
        return (
          <CustomModal
            title={
              <div className="comment_modal_title_section">
                <div className="cmnt_header_box">
                  <button
                    className="cmnt_modal_back_btn"
                    onClick={() => setOpenShareModal(false)}
                  >
                    <BiArrowBack />
                    <span>Share post</span>
                  </button>
                </div>
              </div>
            }
            onClose={() => setOpenShareModal(false)}
            body={
              <div>
                <div className="modal_body_tab">
                  <li
                    onClick={() => setActiveTab("one")}
                    className={
                      activeTab == "one"
                        ? "modal_body_tab_list modal_body_tab_list_active"
                        : "modal_body_tab_list"
                    }
                  >
                    Share with quote
                  </li>
                  <li
                    onClick={() => setActiveTab("two")}
                    className={
                      activeTab == "two"
                        ? "modal_body_tab_list modal_body_tab_list_active"
                        : "modal_body_tab_list"
                    }
                  >
                    Share with others
                  </li>
                </div>

                {activeTab === "one" ? (
                  <div className="share_model_body">
                    <textarea
                      type="text"
                      placeholder={t("share_placeholder")}
                      className="share_post_textarea"
                      value={content}
                      onChange={(e) => handleChange(e)}
                    ></textarea>
                    <div className="social_media_post_card">
                      <div className="social_media_post_card_header">
                        <img
                          src={
                            postData.u_img
                              ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.u_img}`
                              : UserAvatar
                          }
                          className="_posted_user_avatar"
                          id="profile"
                        />
                        {/* Post creator personal info */}
                        <div className="post_creator_info">
                          <>
                            {/* creator name */}
                            <span className="creator_name" id="profile">
                              {postData.u_fn} {postData.u_ln}
                            </span>
                            {/* creator username */}
                            <span className="creator_username">
                              @{postData.u_dun}
                            </span>
                          </>
                          <div className="post_basic_info">
                            {/* post privacy section */}
                            <span className="post_privacy">
                              {postData.privacy === "Public" ? (
                                <AllIcon className="post_icon" />
                              ) : (
                                <>
                                  {postData.privacy === "follower" ? (
                                    <span class="icon-followers"></span>
                                  ) : (
                                    <span class="icon-only_me"></span>
                                  )}
                                </>
                              )}
                            </span>

                            {/* post paid promotion */}
                            <span className="post_paid_section">
                              {postData.isPaid === "true" && (
                                <span className="paid_promotion">
                                  Paid promotion with
                                  <Link
                                    to={`/group/${postData.blockId}`}
                                    className="cmpny_name"
                                  >
                                    {postData.cName}
                                  </Link>
                                </span>
                              )}
                            </span>
                          </div>
                          {/* post feelings */}
                          <div className="post_feelings">
                            {postData.feelingIcon}
                            <span className="post_feelings_text">
                              {postData.feeling}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="post_card_body" id="post_body">
                        <span className="main_body_text" id="post_body">
                          {post.content.split(" ").map((val, index) => (
                            <span
                              id="post_body"
                              key={index}
                              className={val.includes("#") ? "trend_tag" : ""}
                            >
                              {val}{" "}
                            </span>
                          ))}
                        </span>
                        <br />
                        {post.image && (
                          <img
                            src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${postData.image}`}
                            className="post_card__body_image"
                            id="post_body"
                          />
                        )}
                        {post.gif && (
                          <img
                            src={post.gif}
                            className="post_card__body_image"
                            id="post_body"
                          />
                        )}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div>Other social media </div>
                )}
              </div>
            }
          />
        );
      case "full-post":
        return (
          <CustomPostFormModal
            title={
              <div className="full_post_modal_title_section">
                <button
                  className="full_post_modal_back_btn"
                  onClick={closeCommentModal}
                >
                  <BiArrowBack />
                </button>
                <span className="full_post_modal_title_text">
                  View full post
                </span>
              </div>
            }
            body={
              <div className="full_post_modal_body_section">
                {isModalLoading ? (
                  <div className="modal_loading_section">Loading...</div>
                ) : (
                  <>
                    {fullpostData ? (
                      <>
                        <FullPostComp
                          postData={fullpostData}
                          openFullPostModal={openFullPostModal}
                        />
                      </>
                    ) : (
                      <div className="modal_empty_post_section">
                        No post available
                      </div>
                    )}
                  </>
                )}
              </div>
            }
          />
        );
      case "flag":
        return (
          <CustomModal
            title="Flag Post"
            onClose={() => setOpenFLoggedModal(false)}
            body={
              <>{t("Once you mark flag for this post you cannot change it.")}</>
            }
            footer={
              <div className="modal_footer">
                <button
                  className="update_btn"
                  onClick={() => handlePostFlagged()}
                >
                  {t("Okay")}
                </button>
              </div>
            }
          />
        );
      case "donate":
        return (
          <CustomPostFormModal
            title={
              <div className="donate_title">
                <button className={` `} onClick={closeDonateModal}>
                  <BiArrowBack />
                </button>
                <p>Repost</p>
              </div>
            }
            onClose={() => setOpenDonatModal(false)}
            body={
              <div className="modal_body">
                <div className="option_box">
                  <input
                    type="radio"
                    name="donateValue"
                    value="50"
                    checked={donateValue === "50"}
                    onChange={(e) => handleDonateChange(e.target.value)}
                  />
                  <div
                    className="options_text_scection"
                    id="Public"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    <span
                      className="options_text_scection_header"
                      id="50"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      50
                    </span>
                    <br />
                    <span
                      className="options_text_scection_text"
                      id="50"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      Donate $50 to your favourite creator
                    </span>
                  </div>
                </div>

                <div className="option_box">
                  <input
                    type="radio"
                    name="donateValue"
                    value="100"
                    checked={donateValue === "100"}
                    onChange={(e) => handleDonateChange(e.target.value)}
                  />
                  <div
                    className="options_text_scection"
                    id="Public"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    <span
                      className="options_text_scection_header"
                      id="100"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      100
                    </span>
                    <br />
                    <span
                      className="options_text_scection_text"
                      id="100"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      Donate $100 to your favourite creator
                    </span>
                  </div>
                </div>

                <div className="option_box">
                  <input
                    type="radio"
                    name="donateValue"
                    value="200"
                    checked={donateValue === "200"}
                    onChange={(e) => handleDonateChange(e.target.value)}
                  />
                  <div
                    className="options_text_scection"
                    id="200"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    <span
                      className="options_text_scection_header"
                      id="200"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      200
                    </span>
                    <br />
                    <span
                      className="options_text_scection_text"
                      id="200"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      Donate $200 to your favourite creator
                    </span>
                  </div>
                </div>

                <div className="option_box">
                  <input
                    type="radio"
                    name="donateValue"
                    value="500"
                    checked={donateValue === "500"}
                    onChange={(e) => handleDonateChange(e.target.value)}
                  />
                  <div
                    className="options_text_scection"
                    id="500"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    <span
                      className="options_text_scection_header"
                      id="500"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      500
                    </span>
                    <br />
                    <span
                      className="options_text_scection_text"
                      id="500"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      Donate $500 to your favourite creator
                    </span>
                  </div>
                </div>

                <div className="option_box">
                  <input
                    type="radio"
                    name="donateValue"
                    value="1000"
                    checked={donateValue === "1000"}
                    onChange={(e) => handleDonateChange(e.target.value)}
                  />
                  <div
                    className="options_text_scection"
                    id="1000"
                    onClick={(e) => handleDonateChange(e.target.id)}
                  >
                    <span
                      className="options_text_scection_header"
                      id="1000"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      1000
                    </span>
                    <br />
                    <span
                      className="options_text_scection_text"
                      id="1000"
                      onClick={(e) => handleDonateChange(e.target.id)}
                    >
                      Donate $1000 to your favourite creator
                    </span>
                  </div>
                </div>
              </div>
            }
          />
        );
    }
  };

  return getModalProps(type);
}

export default PostCardModal;
