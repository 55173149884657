/** @format */

import { BASE_URL } from "../constants";
import { POST, GET, PUT, POSTFILE, POSTWITHTOKEN } from "../index";

export const registerUser = async (data) => {
  const ENDPOINT = "api/register";
  return handleRequest(ENDPOINT, data);
};

export const loginUser = async (data) => {
  const ENDPOINT = "api/login";
  return handleRequest(ENDPOINT, data);
};

export const getProfile = async (data) => {
  const ENDPOINT = `api/profile/fetch/${data.handleUn}`;
  return handleFetchRequest(ENDPOINT, data.isToken);
};

export const fetchProfileAnalytics = async (data) => {
  const ENDPOINT = `api/users/analytics/${data.handleUn}`;
  return handleFetchRequest(ENDPOINT, data.isToken);
};

// Generic handler for POST requests
const handleRequest = async (endpoint, data) => {
  try {
    const response = await POST(BASE_URL, endpoint, data);
    console.log("API reponse:", response.data);
    return response.data;
  } catch (error) {
    return error;
  }
};

//
const handleFetchRequest = async (endpoint, token) => {
  console.log(" endpoint: ", endpoint);
  const response = await GET(BASE_URL, endpoint, token);
  // console.log("++++++++ ", response.flw);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateGender = async (data) => {
  const ENDPOINT = `api/users/update/gender`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    gender: data.gender,
  });
  try {
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// handleUpdateLanguage
export const handleUpdateLanguage = async (data) => {
  const ENDPOINT = `api/users/update/language`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    language: data.language,
  });
  try {
    // console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateCountry = async (data) => {
  const ENDPOINT = `api/users/update/country`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    country: data.country,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateUserCity = async (data) => {
  const ENDPOINT = `api/users/update/city`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    city: data.city,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateDisplayName = async (data) => {
  console.log(data.token);
  const ENDPOINT = `api/users/update/display_name`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    name: data.name,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateBio = async (data) => {
  const ENDPOINT = `api/users/update/about`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    bio: data.bio,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateLink = async (data) => {
  const ENDPOINT = `api/users/update/link`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    fbLink: data.fbLink,
    twitterLink: data.twitterLink,
    redditLink: data.redditLink,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateProfilePrivacy = async (data) => {
  const ENDPOINT = `api/users/update/profile/privacy`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    profileprivacy: data.profileprivacy,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateProfileStatusPrivacy = async (data) => {
  const ENDPOINT = `api/users/update/profile/status`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    status_privacy: data.status_privacy,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdatePostPrivacy = async (data) => {
  const ENDPOINT = `api/users/update/profile/comment`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    cmnt_prv: data.cmnt_prv,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateProfileViewPrivacy = async (data) => {
  const ENDPOINT = `api/users/update/profile/visit/privacy`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    prfl_prv: data.prfl_prv,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateProfileMessagePrivacy = async (data) => {
  const ENDPOINT = `api/users/update/profile/message/privacy`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    msgPrivacy: data.msgPrivacy,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdatePassword = async (data) => {
  const ENDPOINT = `api/users/update/password`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    currentPassword: data.currentPassword,
    newPassword: data.newPassword,
  });
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateCover = async (data) => {
  const ENDPOINT = `api/users/cover/image`;
  const response = await POSTFILE(
    BASE_URL,
    ENDPOINT,
    data.isToken,
    data.coverImg,
    "coverPicture"
  );
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleUpdateProfileImage = async (data) => {
  const ENDPOINT = `api/users/profile/image`;
  const response = await POSTFILE(
    BASE_URL,
    ENDPOINT,
    data.isToken,
    data.profileImg,
    "p_iture"
  );
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleUpdateProfile = async (data) => {
  const ENDPOINT = `api/users/profile/image`;
  const response = await POSTFILE(
    BASE_URL,
    ENDPOINT,
    data.isToken,
    data.profileImg,
    "p_iture"
  );
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleUpdateUserInfo = async (data) => {
  const ENDPOINT = `api/users/update/personalInfo/${data.handleUn}`;
  const response = await PUT(BASE_URL, ENDPOINT, data.isToken, {
    log_un: data.log_un,
    name: data.name,
    gender: data.gender,
  });
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const UpdateUserInterest = async (data) => {
  const ENDPOINT = `api/users/update/interest/${data.handleUn}`;
  const response = await PUT(BASE_URL, ENDPOINT, data.isToken, {
    selected: data.interestsArr,
  });
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateUserProfileAvatar = async (data) => {
  const ENDPOINT = `api/users/update/avatar`;
  const response = await PUT(BASE_URL, ENDPOINT, data.isToken, {
    url: data.selectUrl,
  });
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleFetchProfileDetails = async (data) => {
  const ENDPOINT = `api/users/update/info/${data.handleUn}`;
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateFollowUser = async (data) => {
  const ENDPOINT = `api/users/follow-following/${data.userId}`;
  const response = await PUT(BASE_URL, ENDPOINT, data.isToken, data);
  try {
    return response;
  } catch (error) {
    return error;
  }
};

export const pdateProfileVisitors = async (data) => {
  const ENDPOINT = `api/users/visitors/${data.handleUn}`;
  const response = await PUT(BASE_URL, ENDPOINT, data.isToken);
  try {
    console.log(">>>> API: ", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchUserProfilePosts = async (data) => {
  const ENDPOINT = `api/posts/user/post/${data.handleUn}?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchUserProfileFollowing = async (data) => {
  const ENDPOINT = `api/users/${data.handleUn}/following-list`;
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchUserProfileFollowers = async (data) => {
  const ENDPOINT = `api/users/${data.handleUn}/follower-list`;
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchUserAbout = async (data) => {
  const ENDPOINT = `api/users/full/profile/${data.handleUn}`;
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchSearchUser = async (data) => {
  const ENDPOINT = `api/users/search/user?search=${data.search}`;
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const profileVisitCount = async (data) => {
  console.log(data);
  const ENDPOINT = `api/users/visit/${data.id}/${data.postId}`;
  const response = await PUT(BASE_URL, ENDPOINT, data.isToken);
  try {
    console.log("CURRENT API: ", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updateFavCoin = async (data) => {
  const endpoint = `api/users/update/fav/coin`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log("CURRENT API: ");
  console.log(response.data);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const updatePurpose = async (data) => {
  const ENDPOINT = `api/users/purpose`;
  const response = await POSTWITHTOKEN(BASE_URL, ENDPOINT, data.token, data);
  console.log("CURRENT API: ");
  console.log(response.data);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

//
export const updateLegalName = async (data) => {
  const ENDPOINT = `api/users/update/legal/name`;
  const response = await POSTWITHTOKEN(BASE_URL, ENDPOINT, data.token, data);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const fetchSuggestedUsers = async (data) => {
  const ENDPOINT = `api/users/profile/suggested?page=${data.page}&limit=${data.limit}`;
  console.log("ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserPrivacySettingsApi = async (data, token) => {
  const ENDPOINT = `api/users/privacy/settings/update`;
  console.log("**** data timeline from api:", data);
  const response = await PUT(BASE_URL, ENDPOINT, token, data);
  try {
    console.log("RESPONSE from timeline API:::::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const verifyProfile = async (data) => {
  const ENDPOINT = `api/users/verify/profile/`;
  console.log("ENDPOINT:", ENDPOINT);
  const response = await POSTWITHTOKEN(BASE_URL, ENDPOINT, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// submitProfileLinks
export const submitProfileLinks = async (data) => {
  const ENDPOINT = `api/users/social/links`;
  console.log("ENDPOINT:", ENDPOINT);
  const response = await POSTWITHTOKEN(BASE_URL, ENDPOINT, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const claimBadge = async (data) => {
  // console.log(data);
  const ENDPOINT = `api/users/claim/badge?type=${data.type}`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token);
  try {
    console.log("CURRENT API: ", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const userEarning = async (data) => {
  const ENDPOINT = `api/users/get/earning?value=${data.value}&startTime=${data.startTime}&endTime=${data.endTime}`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const userSpending = async (data) => {
  const ENDPOINT = `api/users/get/spent?value=${data.value}`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchMyBlocks = async (data) => {
  const ENDPOINT = `api/users/get/myBlock`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//
export const fetchMemberBlocks = async (data) => {
  const ENDPOINT = `api/users/get/memberBlock`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.isToken);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const getUserAnalytics = async (data) => {
  const ENDPOINT = `api/users/analytics?type=${data.type}`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateTimelinePrivacy = async (data) => {
  const ENDPOINT = `api/users/timeline/privacy/update`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    timelinePrivacy: data.timelinePrivacy,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateProfileDetails = async (data) => {
  const ENDPOINT = `api/users/details/privacy/update`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    profileDetails: data.profileDetails,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateCoinDetails = async (data) => {
  const ENDPOINT = `api/users/coin/privacy/update`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    coinPrivcay: data.coinPrivcay,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateFlwrListDetails = async (data) => {
  const ENDPOINT = `api/users/flwr/privacy/update`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    flwrPrivacy: data.flwrPrivacy,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleGetEarningFeed = async (data) => {
  // handleGetEarning
  const ENDPOINT = `api/users/earning/feed`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleGetEarning = async (data) => {
  const ENDPOINT = `api/users/earning/ranking?page=${data.page}&limit=${data.limit}`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleGetAnalyticsData = async (data) => {
  console.log(data);
  const ENDPOINT = `api/users/earning/${data.username}`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateUpdateLinkPrivacy = async (data) => {
  const ENDPOINT = `api/users/link/privacy/update`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    linkPrivacy: data.linkPrivacy,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateUpdateProfileLockPrivacy = async (data) => {
  const ENDPOINT = `api/users/profile/lock/privacy/update`;
  console.log("**** ENDPOINT:", ENDPOINT);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    profile_lock: data.profile_lock,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateReelsPrivacy = async (data) => {
  const ENDPOINT = `api/users/profile/reels/privacy/update`;
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    reels_privacy: data.reels_privacy,
  });
  try {
    console.log("RESPONSE::", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateMentionsPostsPrivacy = async (data) => {
  const ENDPOINT = `api/users/profile/mention/posts/privacy/update`;
  // console.log(data);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    mentions_privacy: data.mentions_privacy,
  });
  try {
    console.log(">> RESPONSE:", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdatePostsPrivacy = async (data) => {
  const ENDPOINT = `api/users/profile/update_posts/privacy`;
  // console.log(data);
  const response = await PUT(BASE_URL, ENDPOINT, data.token, {
    posts_privacy: data.posts_privacy,
  });
  try {
    console.log(">> RESPONSE:", response);
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleGenerateWallet = async (data) => {
  console.log(data);
  const endpoint = `api/users/generate/wallet`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log("CURRENT API: ");
  console.log(response.data);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handlegetSubscriptionData = async (data) => {
  console.log("######### Came");
  const ENDPOINT = `api/users/subscription/details?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, ENDPOINT, data.token);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateSocialAccount = async (data) => {
  const endpoint = `api/users/verify/social/profile`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log("CURRENT API: ", endpoint);
  // console.log(response.data);
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};
// handleFetchPopulateUsers
export const handleFetchPopulateUsers = async (data) => {
  const endpoint = `api/users/most-followed/users`;
  const response = await GET(BASE_URL, endpoint, data.token, data);
  console.log("CURRENT API: ", endpoint);
  return response.data;
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

export const handleUpdateWallet = async (data) => {
  const endpoint = `api/users/update-wallet`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, {
    walletAddress: data.walletAddress,
  });
  // return response.data;
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};

//logout user
export const logoutUser = async (data) => {
  const endpoint = `api/users/logout`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  // return response.data;
  try {
    return response.data;
  } catch (error) {
    return error;
  }
};
