/** @format */

// userSelectors.js

export const selectUser = (state) => state.user.user;
export const selectFollowing = (state) => state.user.following;
export const selectIsLoading = (state) => state.user.isLoading;
export const selectUpdateUser = (state) => state.user.updateUser;
export const selectProfile = (state) => state.user.profile;
export const selectToken = (state) => state.user.isToken;
export const selectLoading = (state) => state.user.isLoading;
export const selectSpam = (state) => state.user.spam;
export const selectUserAnalytics = (state) => state.user.user_analytics;
export const selectReport = (state) => state.user.report;
export const selectResponse = (state) => ({
  type: state.user.responseType,
  message: state.user.response,
});

export const selectLike = (state) => state.user.emoji_likes;
export const selectAngry = (state) => state.user.emoji_angry;
export const selectHaha = (state) => state.user.emoji_haha;
export const selectDislikes = (state) => state.user.emoji_dislikes;
export const selectSad = (state) => state.user.emoji_sad;
export const selectFire = (state) => state.user.emoji_fire;
export const selectUpVote = (state) => state.user.emoji_upvote;
export const selectcommentSpam = (state) => state.user.cmntSpam;

export const selectReliable = (state) => state.user.reliable;
export const selectIntersting = (state) => state.user.intersting;
// export const selectFake = (state) => state.user.fake;
export const selectLikeAnc = (state) => state.user.likeAnc;
export const selectImportentAnc = (state) => state.user.impAnc;

/**
 * helpful: [],
    unhelpful: [],
    misleading: [],
 */
export const selectHelpful = (state) => state.user.helpful;
export const selectUnhelpful = (state) => state.user.unhelpful;
// export const selectMisleading = (state) => state.user.misleading;
export const selectSearchHistory = (state) => state.user.searchHistory;

export const selectUpvote = (state) => state.user.upvote;
export const selectDownvote = (state) => state.user.downvote;
export const selectFake = (state) => state.user.fake;

export const selectExcited = (state) => state.user.exited;
export const selectDisapointed = (state) => state.user.disapointed;
export const selectMisleading = (state) => state.user.misleading;
