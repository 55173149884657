/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  fetchRecomendenBlockcast,
  handleFetchUserDms,
  fetchSubscribeBlockcast,
  fetchMyBlockcast,
  joinBlockcast,
  fetchjoinBlockcast,
  searchBlockcast,
  fetchBlockCast,
  submitImageMessage,
  deleteMessage,
  pinnedMessage,
  likeMessage,
  createComment,
  fetchComment,
  fetchDms,
  createDms,
  createDmMessage,
  fetchDmMessage,
  handleUpdateMessage,
  handleDonateMessage,
  likeComment,
  dislikeComment,
  emojiLikeMessage,
  replyMessage,
} from "../../api/_blockcast/blockcastApi";

export const blockcastSlice = createSlice({
  name: "blockCast",
  initialState: {
    messages: [],
    blockCast: null,
    pinnedMessage: [],
    comments: [],
    updatedBlock: null,
    like: [],
    heart: [],
    idea: [],
    dislike: [],
    funny: [],
    myBlockcast: [],
    join: [],
    selectBlock: null,
    unreadCounts: {},
  },
  reducers: {
    setSelectBlock: (state, action) => {
      state.selectBlock = action.payload;
    },
    setMessages: (state, action) => {
      state.messages = action.payload;
    },
    setAddMoremessages: (state, action) => {
      state.messages = [...state.messages, ...action.payload];
    },
    setAppendMessage: (state, action) => {
      console.log("Message came here ", action.payload);
      state.messages = [...state.messages, action.payload];
    },
    setRemoveMessage: (state, action) => {
      const arr = state.messages;
      const temp = arr.filter((data) => data._id !== action.payload._id);
      state.messages = temp;
    },

    setComments: (state, action) => {
      state.comments = action.payload;
    },
    appendComments: (state, action) => {
      state.comments = [...state.comments, action.payload];
    },

    updateUnreadMessageCount: (state, action) => {
      state.unreadCounts = {
        ...state.unreadCounts,
        [action.payload.chatId]: action.payload.unreadCount,
      };
    },
  },
});

export const {
  setSelectBlock,
  setMessages,
  setAppendMessage,
  setRemoveMessage,
  setAddMoremessages,
  setComments,
  appendComments,
  updateUnreadMessageCount,
} = blockcastSlice.actions;

export const handleFetchRecomemndedBlockcast = (data) => async (dispatch) => {
  const result = await fetchRecomendenBlockcast(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUserDms = (data) => async (dispatch) => {
  const result = await handleFetchUserDms(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchSubscribeBlockcast = (data) => async (dispatch) => {
  const result = await fetchSubscribeBlockcast(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchMyBlockcast = (data) => async (dispatch) => {
  const result = await fetchMyBlockcast(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleJoinBlockcast = (data) => async (dispatch) => {
  // console.log("Came in slice");
  const result = await joinBlockcast(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchJoinBlockcast = (data) => async (dispatch) => {
  console.log("Came in slice");
  const result = await fetchjoinBlockcast(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleSearchBlockcast = (data) => async (dispatch) => {
  const result = await searchBlockcast(data);
  // console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchBlockcast = (data) => async (dispatch) => {
  // console.log("Came in slice");
  const result = await fetchBlockCast(data);

  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleSubmitImageMessage = (data) => async (dispatch) => {
  const result = await submitImageMessage(data);
  // console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// handleDeleteMessage
export const handleDeleteMessage = (data) => async (dispatch) => {
  console.log(data);
  const result = await deleteMessage(data);
  // console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handlePinnedMessage = (data) => async (dispatch) => {
  console.log(data);
  const result = await pinnedMessage(data);
  // console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleLikeMessage = (data) => async (dispatch) => {
  // console.log(data);
  const result = await likeMessage(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

/**
 * @COMMENTS
 */
export const handleCreateComment = (data) => async (dispatch) => {
  const result = await createComment(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchComment = (data) => async (dispatch) => {
  const result = await fetchComment(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchDms = (data) => async (dispatch) => {
  const result = await fetchDms(data);
  // console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleCreateDms = (data) => async (dispatch) => {
  console.log(data);
  const result = await createDms(data);
  console.log("In blockcast slice:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleSubmitMessage = (data) => async (dispatch) => {
  const result = await createDmMessage(data);
  // console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchDmMessage = (data) => async (dispatch) => {
  const result = await fetchDmMessage(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateMessage = (data) => async (dispatch) => {
  const result = await handleUpdateMessage(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const donateMessage = (data) => async (dispatch) => {
  const result = await handleDonateMessage(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// handleLikeComment, handleDislikeComment

export const handleLikeComment = (data) => async (dispatch) => {
  const result = await likeComment(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleDislikeComment = (data) => async (dispatch) => {
  const result = await dislikeComment(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleEmojiLikeMessage = (data) => async (dispatch) => {
  const result = await emojiLikeMessage(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleReplyMessage = (data) => async (dispatch) => {
  const result = await replyMessage(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export default blockcastSlice.reducer;
