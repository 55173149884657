/** @format */

import { BASE_URL } from "../constants";
import { POST, GET, PUT, FormPost, DELETE, POSTWITHTOKEN } from "../index";

//GET
export const getPosts = async (data) => {
  console.log("CAME HERE FOR FEED", data);
  let ENDPOINT = "";
  if (data.type === "for_you") {
    ENDPOINT = `api/posts/feed/for_you?page=${data.page}&sort=${data.sortedBy}&limit=${data.limit}`;
  } else if (data.type === "following") {
    ENDPOINT = `api/posts/feed/following?page=${data.page}&sort=${data.sortedBy}&limit=${data.limit}`;
  } else {
    ENDPOINT = `api/posts/feed/all?page=${data.page}&sort=${data.sortedBy}&limit=${data.limit}`;
  }
  const result = await handleRequestGet(ENDPOINT, data.isToken);
  console.log(result);
  return result;
};
//put
export const putPosts = async (data) => {
  const ENDPOINT = `api/posts/./${data.postId}`;
  return handleRequestPut(ENDPOINT, data.isToken);
};
export const putBookmarks = async (data) => {
  const ENDPOINT = `api/posts/pinned/${data.postId}`;
  return handleRequestPut(ENDPOINT, data.isToken);
};

// Generic handler for GET requests
const handleRequestGet = async (endpoint, token) => {
  try {
    const response = await GET(BASE_URL, endpoint, token);

    return response.data;
  } catch (error) {
    console.log("FEED ERROR: ", error);
    let errorMessage = "Something went wrong.";

    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }
    console.log(errorMessage);
    throw new Error(errorMessage);
  }
};

// Generic handler for POST requests
const handleRequestPost = async (endpoint, data) => {
  try {
    const response = await POST(BASE_URL, endpoint, data);
    return response.data;
  } catch (error) {
    let errorMessage = "Something went wrong.";

    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};

const handleRequestPut = async (endpoint, token) => {
  try {
    const response = await PUT(BASE_URL, endpoint, token);
    return response.data;
  } catch (error) {
    let errorMessage = "Something went wrong.";

    if (error.response) {
      errorMessage = `Error: ${error.response.status} - ${errorMessage}`;
    } else if (error.request) {
      errorMessage = "Server did not respond.";
    }

    throw new Error(errorMessage);
  }
};

// Generic handler for POST requests,
export const handleCreateNewPost = async (data) => {
  const endpoint = "api/posts/create";
  const response = await FormPost(BASE_URL, endpoint, data.isToken, data);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleCreateNewPoll = async (data) => {
  const endpoint = "api/posts/poll/create";
  const response = await PUT(BASE_URL, endpoint, data.isToken, data.post);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

// *** Pinned post
export const handlePinnedPost = async (data) => {
  // console.log(data);
  const endpoint = `api/posts/pinned/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

// *** Handle bookmark post
export const handleBookmarkPost = async (data) => {
  const endpoint = `api/posts/bookmark/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleHidePost = async (data) => {
  const endpoint = `api/posts/hide/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleSocialPostUpdate = async (data) => {
  const endpoint = `api/posts/edit/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, {
    editText: data.editText,
  });
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateDeletePost = async (data) => {
  const endpoint = `api/posts/delete/${data.postId}`;
  const response = await DELETE(BASE_URL, endpoint, data.isToken);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateSharePrivacy = async (data) => {
  // console.log("Came here:   ", data);
  const endpoint = `api/posts/share/post/privacy/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, {
    sharePrivacy: data.sharePrivacy,
  });
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdatePostCommentPrivacy = async (data) => {
  const endpoint = `api/posts/comment/post/privacy/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.isToken, {
    commentPrivacy: data.commentPrivacy,
  });
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleSharePost = async (data) => {
  const endpoint = `api/posts/share/post/${data.postId}`;
  // const bodyData = {
  //   originalPost: data.originalPost,
  //   content: data.content,
  // };
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.isToken, {
    originalPost: data.originalPost,
    content: data.content,
  });
  console.log(response.data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateDonatePost = async (data) => {
  const endpoint = `api/posts/donate/${data.postId}?amount=${data.donateValue}&handleUn=${data.postUserId}&message=${data.custommessage}`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdatePostSpam = async (data) => {
  const endpoint = `api/posts/spam/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    editText: data.editText,
  });
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const postAnalytics = async (data) => {
  const endpoint = `api/posts/fetch/post/analytics/${data.id}`;
  const response = await GET(BASE_URL, endpoint, data.isToken);
  try {
    console.log(response);
  } catch (error) {
    console.log(response);
  }
};

export const handleUpdateLikePost = async (data) => {
  const endpoint = `api/posts/emoji/add/like/${data.id}`;
  console.log(data.id, data.value);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateRemovePostLike = async (data) => {
  const endpoint = `api/posts/emoji/remove/like/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateAngryPost = async (data) => {
  const endpoint = `api/posts/emoji/angry/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateHahaPost = async (data) => {
  const endpoint = `api/posts/emoji/haha/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateDislikePost = async (data) => {
  const endpoint = `api/posts/emoji/dislike/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchLikeUser = async (data) => {
  const endpoint = `api/posts/like/users/${data.postId}?type=${data.likeType}`;
  const response = await GET(BASE_URL, endpoint, data.token);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchFullPost = async (data) => {
  const endpoint = `api/posts/fetch/full/post/${data.id}?page=${data.cmntPage}&limit=${data.cmntLimit}`;
  const response = await GET(BASE_URL, endpoint, data.isToken);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetTrendingPostList = async (data) => {
  const endpoint = `api/posts/trending/list`;
  const response = await GET(BASE_URL, endpoint, data.token);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetTrendingCoinList = async (data) => {
  const endpoint = `api/posts/trending/list/coins`;
  const response = await GET(BASE_URL, endpoint, data.token);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetTrendingPosts = async (data) => {
  const endpoint = `api/posts/trending/posts?key=${data.key}&page=${data.page}`;
  const response = await GET(BASE_URL, endpoint, data.token);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//
export const fetTrendingCoinPosts = async (data) => {
  const endpoint = `api/posts/trending/coin/posts?key=${data.key}&page=${data.page}`;
  const response = await GET(BASE_URL, endpoint, data.token);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetNewsPosts = async (data) => {
  const endpoint = `/api/news?page=${data.page}&limit=${data.limit}`;
  console.log("END POINT:", endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);

  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/***
 * @COMMENT
 */
export const handlePostComment = async (data) => {
  const endpoint = `api/posts/comment/${data.post.postId}`;
  const response = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchPostRelatedComment = async (data) => {
  const ENDPOINT = `api/posts/my_comment/${data.postId}?sortedBy=${data.sortedBy}&page=${data.page}&limit=${data.limit}`;

  console.log(`${BASE_URL}${ENDPOINT}`);
  const response = await GET(BASE_URL, ENDPOINT, data.token);

  return response.data;
};

export const pinnedComment = async (data) => {
  const endpoint = `api/posts/comment/pinned/${data.cmntId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const editComment = async (data) => {
  const endpoint = `api/posts/comment/edit/${data.cmntId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteComment = async (data) => {
  const endpoint = `api/posts/comment/delete/${data.cmntId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const commentSpam = async (data) => {
  // console.log("*** ", data);
  const endpoint = `api/posts/comment/spam/${data.id}`;
  // /comment/spam/:id
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateLikeComment = async (data) => {
  const endpoint = `api/posts/comment/${data.value}/${data.id}/${data.ownerName}/${data.likeCount}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(">>> Like count uppdate : ", response);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateRemoveLikeComment = async (data) => {
  const endpoint = `api/posts/comment/like/remove/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const CommentReply = async (data) => {
  const endpoint = `api/posts/comment/reply/${data.cmntId}`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    text: data.text,
    username: data.username,
    // content: data.content,
  });

  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const ReplyReply = async (data) => {
  const endpoint = `api/posts/reply/reply/${data.cmntId}`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    text: data.text,
    username: data.username,
    // content: data.content,
  });

  try {
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchReplies = async (data) => {
  const endpoint = `api/posts/comment/reply/${data.id}?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  console.log(response.data);
  try {
    return response.data;
  } catch (error) {
    console.log(response);
  }
};

export const deleteReply = async (data) => {
  const endpoint = `api/posts/comment/reply/delete/${data.replyId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const spamReply = async (data) => {
  const endpoint = `api/posts/comment/reply/spam/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const spamInnerReply = async (data) => {
  const endpoint = `api/posts/comment/inner-reply/spam/${data.id}`;
  // console.log(data);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const likeReply = async (data) => {
  const endpoint = `api/posts/comment/reply/like/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  // console.log("Like response: ", response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const likeInnerReply = async (data) => {
  const endpoint = `api/posts/comment/inner-reply/like/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  // console.log("Like response: ", response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const dislikeReply = async (data) => {
  const endpoint = `api/posts/comment/reply/dislike/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const innerDislikeReply = async (data) => {
  const endpoint = `api/posts/comment/inner-reply/dislike/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const reliableNews = async (data) => {
  const endpoint = `api/posts/reliable/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleInterestingNews = async (data) => {
  const endpoint = `api/posts/interesting/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleFakeNews = async (data) => {
  const endpoint = `api/posts/fake/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const likeAnnouncement = async (data) => {
  const endpoint = `api/posts/announcement/like/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const importentAnnouncement = async (data) => {
  const endpoint = `api/posts/announcement/impotent/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fakeAnnouncement = async (data) => {
  const endpoint = `api/posts/scam/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const helpfulInfo = async (data) => {
  const endpoint = `api/posts/helpfull/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const unhelpfulInfo = async (data) => {
  const endpoint = `api/posts/unhelpfull/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const misleadingInfo = async (data) => {
  const endpoint = `api/posts/misleading/${data.id}`;
  console.log(endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const fetchDonaterHistory = async (data) => {
  const endpoint = `api/posts/fetch/donate/history/${data.id}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

//
export const fetchActivity = async (data) => {
  const endpoint = `api/posts/activity?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const likeNFT = async (data) => {
  const ENDPOINT = `api/posts/nft/like/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const nftComments = async (data) => {
  const endpoint = `api/posts/nft/comment/${data.id}?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(response);
  }
};

// nftPinComments
export const nftPinComments = async (data) => {
  const ENDPOINT = `api/posts/nft/pin/comment/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// nftEditComments
export const nftEditComments = async (data) => {
  const ENDPOINT = `api/posts/nft/edit/comment/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token, data);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// nftCreateComments
export const nftCreateComments = async (data) => {
  const ENDPOINT = `api/posts/nft/comment/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token, data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// nftDeleteComments

export const nftDeleteComments = async (data) => {
  const ENDPOINT = `api/posts/nft/delete/comment/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleNftLikeComment = async (data) => {
  const ENDPOINT = `api/posts/nft/like/comment/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getNFTReplies = async (data) => {
  // console.log(data);
  const endpoint = `api/posts/nft/replies/${data.id}?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(response);
  }
};

// handleCreateNFTReply
export const handleCreateNFTReply = async (data) => {
  // console.log(data);
  const ENDPOINT = `api/posts/nft/reply/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token, data);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const likeNFTReply = async (data) => {
  const ENDPOINT = `api/posts/nft/reply/like/${data.id}`;
  const result = await PUT(BASE_URL, ENDPOINT, data.token);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleEditNFTReply = async (data) => {
  const ENDPOINT = `api/posts/nft/reply/edit/${data.id}`;
  // console.log(`${BASE_URL}${ENDPOINT}`);
  const result = await PUT(BASE_URL, ENDPOINT, data.token, data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleNFTDelete = async (data) => {
  console.log(data);

  const ENDPOINT = `api/posts/nft/reply/delete/${data.id}`;
  const result = await PUT(BASE_URL, ENDPOINT, data.token);
  console.log("RESULT:", result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleReportPost = async (data) => {
  const endpoint = `api/posts/report/${data.id}`;

  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handlePollVoting = async (data) => {
  const endpoint = `api/posts/poll/voting/${data.id}`;
  console.log(data);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchAnalyticsData = async (data) => {
  console.log("***** ", data);
  const endpoint = `api/posts/fetch/analytics/${data.id}`;
  const result = await GET(BASE_URL, endpoint, data.token);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleRepostPost = async (data) => {
  const endpoint = `api/posts/repost/${data.id}`;
  console.log(data);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFalggedPost = async (data) => {
  const endpoint = `api/posts/flag/${data.id}`;
  console.log(data);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGetVottedUsers = async (data) => {
  const endpoint = `api/posts/voters/lists/${data.postId}?page=${data.page}&limit$=${data.limit}&type=${data.active}`;
  const result = await GET(BASE_URL, endpoint, data.token);
  console.log(result);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGetMentionsPosts = async (data) => {
  const endpoint = `api/posts/mentions/${data.id}?page=${data.page}&limit$=${data.limit}`;
  const result = await GET(BASE_URL, endpoint, data.token);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGetReels = async (data) => {
  const endpoint = `api/reels/fetch/?page=${data.page}&limit$=${data.limit}`;
  const result = await GET(BASE_URL, endpoint, data.token);
  try {
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleGetNfts = async (data) => {
  const endpoint = `api/nft/fetchAllNft?page=${data.page}&limit=${data.limit}`;
  const result = await GET(BASE_URL, endpoint, data.token);
  try {
    console.log(result.data);
    return result.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchCoinPrice = async (data) => {
  //
  const endpoint = `api/posts/get-coin-price/${data.coinId}`;
  console.log(endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handlefetchDonationHistory = async (data) => {
  //
  const endpoint = `api/posts/fetch/single/details/${data.id}`;
  console.log(endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};
