/** @format */

import React from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { MdCreateNewFolder } from "react-icons/md";
import "./BlockCastDownNavbar.css";
import CustomPostFormModal from "../../modal/CustomPostForm";
import { BiArrowBack, BiSearchAlt } from "react-icons/bi";
import { useLocation } from "react-router-dom";
import BlockSekeletonLoader from "../../SkeletonLoading/BlockSekeletonLoader";
import BlockSearchList from "../../SearchList/BlockSearchList";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import { handleSearchBlockcast } from "../../../redux/_blockcast/blockcastSlice";
import { ThemeContext } from "../../Common/ThemeProvider";
import {
  handleCreateNewBlock,
  putMyBlock,
} from "../../../redux/_block/blockSlice";
import { FaUserFriends, FaLink, FaPlus } from "react-icons/fa";
import { BiMessageRoundedDetail } from "react-icons/bi";
import "./BlockCastDownNavbar.css";
import { getCoinList } from "../../../redux/_coin/coinSlice";
import { FaUserCheck } from "react-icons/fa";
import { MdMessage } from "react-icons/md";
import { seleteSelectBlock } from "../../../redux/_blockcast/blockcastSelectos";
// import RecomendedIcon from "../../../Assets/blockcast/recommended_blockcast.svg";
import { ReactComponent as RecomendedIcon } from "../../../Assets/blockcast/recommended_blockcast.svg";
// import MyBlockIcon from "../../../Assets/blockcast/my_blockcast.svg";
import { ReactComponent as MyBlockIcon } from "../../../Assets/blockcast/my_blockcast.svg";
// import JoinBlockIcon from "../../../Assets/blockcast/join_blockcast.svg";
import { ReactComponent as JoinBlockIcon } from "../../../Assets/blockcast/join_blockcast.svg";
// import SubBlockIcon from "../../../Assets/blockcast/sub_blockcast.svg";
import { ReactComponent as SubBlockIcon } from "../../../Assets/blockcast/sub_blockcast.svg";
// import SearchBlockIcon from "../../../Assets/blockcast/search_blockcast.svg";
import { ReactComponent as SearchBlockIcon } from "../../../Assets/blockcast/search_blockcast.svg";

const BlockCastDownNavbar = () => {
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const selectBlockcast = useSelector(seleteSelectBlock);
  const { t } = useTranslation(["common"]);

  const [pathname, setPathname] = React.useState("/blockcast");
  const [openSearchModal, setOpenSearchModal] = React.useState(false);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [lists, setLists] = React.useState([]);

  // New states
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(40);
  const [cointCount, setCoinCount] = React.useState(40);
  const [openModal, setOpenModal] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);
  const [description, setDescription] = React.useState("");
  const [isDesFocus, setIsDesFocus] = React.useState(false);
  const [isDesEmpty, setIsDesEmpty] = React.useState(true);
  const [isCoinMenuLoading, setIsCoinMenuLoading] = React.useState(false);

  // token name
  const [type, setType] = React.useState("");
  const [isTypeFocus, setIsTypeFocus] = React.useState(false);
  const [isTypeEmpty, setIsTypeEmpty] = React.useState(true);
  const [coinList, setCoinList] = React.useState([]);

  // Token symbol
  const [symbol, setSymbol] = React.useState("");
  const [isSymbolFocus, setIsSymbolFocus] = React.useState(false);
  const [isSymbolEmpty, setIsSymbolEmpty] = React.useState(true);
  const [showSymbolInput, setShowSymbolInput] = React.useState(false);

  // name
  const [name, setName] = React.useState("");
  const [coinPrice, setCoinPrice] = React.useState(0);
  const [blockchain, setBlockChain] = React.useState(0);
  const [coinAddress, setCoinAddress] = React.useState("");
  const [coinDecimals, setCoinDecimals] = React.useState("");
  const [marketCap, setMarketCap] = React.useState("");
  const [tokenData, setTokenData] = React.useState("");
  const [handlename, setHandlename] = React.useState("");
  const [accountType, setAccountType] = React.useState("business");
  const location = useLocation();

  console.log("selectBlockcast", selectBlockcast);

  React.useEffect(() => {
    const str = location.pathname.split("/");
    setPathname(str[str.length - 1]);
  });

  const handleOpenModal = () => {
    setOpenSearchModal(true);
  };

  const userRef = React.useRef(null);
  // React.useEffect(() => {
  //   const handler = (event) => {
  //     if (!userRef.current.contains(event.target)) {
  //       // setOpenCurrency(false);
  //       setIsOpen(false);
  //       setOpenCreateModal(false);
  //     }
  //   };
  //   document.addEventListener("mousedown", handler);
  //   return () => {
  //     document.removeEventListener("mousedown", handler);
  //   };
  // }, []);

  const onClose = () => {
    setOpenSearchModal(false);
    setOpenModal(false);
    setSymbol("");
  };

  React.useEffect(() => {
    if (search.length === 2) {
      setLoading(true);
      handleSearchGroup(search);
    } else if (search.length > 2) {
      setLoading(true);
      const delayCall = setTimeout(() => {
        handleSearchGroup(search);
      }, 1000);

      return () => clearTimeout(delayCall);
    }
  }, [search]);

  const handleSearchGroup = async (search) => {
    const data = { token, search };
    setLoading(true);
    if (search.trim()) {
      const result = await dispatch(handleSearchBlockcast(data));
      setLists(result.block);
      setLoading(false);
    }
  };

  const menuRef = React.useRef(null);
  React.useEffect(() => {
    const handler = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsTypeFocus(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  }, []);

  const handleCryptoInput = (e) => {
    setType(e.target.value.slice(0, 40));
    const temp = lists;
    var result = [];
    const arr = temp.filter((data) =>
      data.name.toLowerCase().includes(e.target.value)
    );
    // setSearchList(arr);
  };

  React.useEffect(() => {
    if (isTypeFocus) {
      fetchCoins();
    }
  }, [page, isTypeFocus]);

  const fetchCoins = async () => {
    if (page === 1) {
      setIsCoinMenuLoading(true);
    }
    const data = { page, limit };
    const result = await dispatch(getCoinList(data));
    console.log(result);
    if (page === 1) {
      setCoinList(result);
    } else {
      setCoinList((prev) => [...prev, ...result]);
    }
    setIsCoinMenuLoading(false);
  };

  const handleAddType = (data) => {
    setCoinPrice(data.current_price);
    setBlockChain(data.blockchain);
    setCoinAddress(data.address);
    setCoinDecimals(data.decimals);
    setMarketCap(data.market_cap_rank);
    setTokenData(data.id);

    setType(data.name);
    setIsTypeFocus(false);
    setSymbol(data.image);
  };

  React.useEffect(() => {
    if (!name.trim() || !description.trim()) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [name, description]);

  const createGroup = async () => {
    // setIsLoading(true);
    const data = {
      token: token,
      handlename: handlename,
      name: name,
      body: description,
      type: type,
      accountType: accountType,
      symbol: symbol,
      coinPrice: coinPrice,
      blockchain: blockchain,
      coinAddress: coinAddress,
      coinDecimals: coinDecimals,
      marketCap: marketCap,
      tokenData: tokenData,
    };

    const result = await dispatch(handleCreateNewBlock(data));
    dispatch(putMyBlock(result.group));
    console.log(result);
    // setIsLoading(false);
    setName("");
    setHandlename("");
    setDescription("");
    setType("");
    setAccountType("normal");
    setIsDisable(true);
    setOpenModal(false);
    setSuggestedName([]);
    navigate(`/group/${result.group.g_id}`);
  };

  return (
    <div className={`__down_navbar_container ${theme}`}>
      {/* Create a new Block */}
      {openModal && (
        <CustomPostFormModal
          title={
            <div className="post_form_modal_title">
              <div className="modal_post_form_section">
                <button className="post_close_modal_button" onClick={onClose}>
                  <BiArrowBack />
                </button>
                <span className="modal_title_text">
                  {t("Create new block")}
                </span>
              </div>

              {!isDisable ? (
                <button className="_modal_post_button" onClick={createGroup}>
                  {t("Create")}
                </button>
              ) : (
                <button className="_modal_post_button disable_modal_post_button">
                  {t("Create")}
                </button>
              )}
            </div>
          }
          body={
            <div className="modal_body_container">
              {/* Select TOKEN name */}
              <div className="modal_input_box">
                <input
                  type="text"
                  placeholder={"Select coin name"}
                  className="block_modal_input"
                  id="type"
                  value={type}
                  autoComplete="off"
                  onChange={(e) => handleCryptoInput(e)}
                  onFocus={() => setIsTypeFocus(true)}
                />
                {isTypeFocus && (
                  <div className="drop_menu" ref={menuRef}>
                    <>
                      {isCoinMenuLoading ? (
                        <div className="drop_menu_loader_section">
                          <span class="coin_loader"></span>
                        </div>
                      ) : (
                        <>
                          {(coinList || []).length > 0 ? (
                            <>
                              {coinList.map((data) => (
                                <div
                                  key={data._id}
                                  className="crypto_list"
                                  onClick={() => handleAddType(data)}
                                >
                                  <img
                                    src={`https://assets.coingecko.com/coins/images/${data.image}`}
                                    className="cypto_coin_symbol"
                                  />
                                  <span className="crypto_name">
                                    {data.name}
                                  </span>
                                </div>
                              ))}
                              <>
                                {cointCount >= limit && (
                                  <>
                                    {
                                      <div
                                        className="coin_page_btn"
                                        onClick={() =>
                                          setPage((prev) => prev + 1)
                                        }
                                      >
                                        <button
                                          className="page_btn"
                                          onClick={() =>
                                            setPage((prev) => prev + 1)
                                          }
                                        >
                                          Load more
                                        </button>
                                      </div>
                                    }
                                  </>
                                )}
                              </>
                            </>
                          ) : (
                            <div className="empty_coin_list_menu">
                              No data found
                            </div>
                          )}
                        </>
                      )}
                    </>
                  </div>
                )}
              </div>

              {/* Block Name */}
              <div className="modal_input_box">
                <input
                  type="text"
                  placeholder={"Enter block name"}
                  className="block_modal_input"
                  id="name"
                  value={name}
                  onChange={(e) => setName(e.target.value.slice(0, 50))}
                  autoComplete="off"
                />
              </div>

              {/* Block Handle Name */}
              <div className="modal_input_box">
                <input
                  type="text"
                  placeholder={"Enter block handle name"}
                  className="block_modal_input"
                  id="handlename"
                  value={handlename}
                  onChange={(e) => setHandlename(e.target.value.slice(0, 50))}
                />
              </div>

              {/* Block Name */}
              <div className="modal_input_box">
                <textarea
                  type="text"
                  placeholder={"Enter block destails"}
                  className="__block_modal_textares"
                  id="des"
                  value={description}
                  onChange={(e) => setDescription(e.target.value.slice(0, 50))}
                  onFocus={() => setIsDesFocus(true)}
                  onBlur={() => setIsDesFocus(false)}
                  autoComplete="off"
                />
              </div>
            </div>
          }
        />
      )}
      {/* Block search modal */}
      {openSearchModal && (
        <CustomPostFormModal
          onClose={onClose}
          title={
            <div className="block_search_title_section">
              <button className="back_btn" onClick={onClose}>
                <BiArrowBack />
              </button>
              <span className="block_search_title_text">
                {t("Block search")}
              </span>
            </div>
          }
          body={
            <div className="block_search_body">
              <div className="block_search_form_section">
                <AiOutlineSearch className="block_search_icon" />
                <input
                  type="search"
                  className="block_search_input"
                  placeholder="Search block"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </div>

              <div className="search_list_section">
                {loading ? (
                  <BlockSekeletonLoader />
                ) : (
                  <>
                    {(lists || []).length > 0 ? (
                      <>
                        {lists.map((data) => (
                          <BlockSearchList key={data.b_id} blockData={data} />
                        ))}
                      </>
                    ) : (
                      <div className="empty_block_search">
                        {t("No block found")}
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          }
        />
      )}

      {/* Recomended blockcast */}
      <NavLink
        activeclassName="active_navlink_blockcast"
        to="/blockcast"
        className={
          pathname === "blockcast"
            ? "down_navbar_link active_down_navbar_link"
            : "down_navbar_link"
        }
      >
        <RecomendedIcon className="blockcast_down_navbar_icon custom_icon" />
        <br />
        <span className="block_cast_down_text">{t("Recomended")}</span>
      </NavLink>

      {/* My blockcast */}
      <NavLink
        activeclassName="active_navlink_blockcast"
        to="my_blockcast"
        className={
          pathname === "my_blockcast"
            ? "down_navbar_link active_down_navbar_link"
            : "down_navbar_link"
        }
      >
        <MyBlockIcon className="blockcast_down_navbar_icon custom_icon" />
        <br />
        <span className="block_cast_down_text">{t("Mine")}</span>
      </NavLink>

      {/* Search */}
      <button className="down_navbar_link" onClick={handleOpenModal}>
        <SearchBlockIcon className="blockcast_down_navbar_icon custom_icon" />
        <br />
        <span className="block_cast_down_text">{t("Search")}</span>
      </button>

      {/* Join blockcast */}
      <NavLink
        activeclassName="active_navlink_blockcast"
        to="join"
        className={
          pathname === "join"
            ? "down_navbar_link active_down_navbar_link"
            : "down_navbar_link"
        }
      >
        <JoinBlockIcon className="blockcast_down_navbar_icon custom_icon" />
        <br />
        <span className="block_cast_down_text">{t("Joined")}</span>
      </NavLink>

      <NavLink
        activeclassName="active_navlink_blockcast"
        to="subscribe"
        className={
          pathname === "subscribe"
            ? "down_navbar_link active_down_navbar_link"
            : "down_navbar_link"
        }
      >
        <SubBlockIcon className="blockcast_down_navbar_icon custom_icon" />
        <br />
        <span className="block_cast_down_text">{t("Subs")}</span>
      </NavLink>

      <NavLink
        activeclassName="active_navlink_blockcast"
        to="my_dms"
        className={
          pathname === "my_dms"
            ? "down_navbar_link active_down_navbar_link"
            : "down_navbar_link"
        }
      >
        <BiMessageRoundedDetail className="blockcast_down_navbar_icon" />
        <br />
        <span className="block_cast_down_text">{t("DM's")}</span>
      </NavLink>
    </div>
  );
};
export default BlockCastDownNavbar;
