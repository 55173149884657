/** @format */

import React from "react";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai";
import { RiSpam2Line, RiSpam2Fill } from "react-icons/ri";
import CustomSmallModal from "../modal/CustomSmallModal";
import UserAvatar from "../../Assets/userAvatar.webp";
import { Link } from "react-router-dom";

// TOOLKIT
import { useDispatch, useSelector } from "react-redux";
import {
  selectUser,
  selectToken,
  selectFollowing,
} from "../../redux/_user/userSelectors";
import {
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
} from "../../redux/_user/userSlice";
import {
  handleEditBlockReply,
  handleSpamBlockReply,
  handleLikBlockReply,
  handleDislikBlockReply,
  handleDeleteBlockReply,
  handleCreateBlockPostReplyReply,
  handleFetchRepliesReply,
} from "../../redux/_blockPost/blockPostSlice";
import {
  BsFillPinAngleFill,
  BsFillPlusCircleFill,
  BsFillCheckCircleFill,
  BsFillPatchCheckFill,
  BsReply,
} from "react-icons/bs";
import { GrSend } from "react-icons/gr";
import InnerReply from "./InnerReply";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";

const GroupPostReply = ({ reply, setReplyCount }) => {
  useSocket();
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const following = useSelector(selectFollowing);

  const [replyId, setReplyId] = React.useState(reply.r_id);
  const [content, setContent] = React.useState(reply.content);
  const [firstname, setfirstname] = React.useState(reply.c_fn);
  const [lastname, setLastname] = React.useState(reply.c_ln);
  const [username, setusername] = React.useState(reply.c_un);
  const [image, setimage] = React.useState(reply.c_pi);
  const [time, setTime] = React.useState(reply.c_t);
  const [isDelete, setIsDelete] = React.useState(reply.delete);
  const [likes, setLikes] = React.useState(reply.likes);
  const [likeCount, setLikeCount] = React.useState(reply.likes.length);
  const [dislikes, setDislikes] = React.useState(reply.dislikes);
  const [dislikeCount, setDislikeCount] = React.useState(reply.dislikes.length);
  const [spam, setSpam] = React.useState(reply.spam);
  const [spamCount, setSpamCount] = React.useState(reply.spam.length);

  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [openEditModal, setOpenEditModal] = React.useState(false);
  const [text, setText] = React.useState("");

  // ************
  const [openReplyForm, setOpenReplyForm] = React.useState(false);
  const [selectUsername, setSelectUsername] = React.useState("");
  const [replyText, setReplyText] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);
  const [isDisable, setIsDisable] = React.useState(true);
  const [replies, setReplies] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(5);
  const [repliesCount, setRepliesCount] = React.useState(0);

  console.log("REPLY:", reply);
  // *** Handle open Delete modal
  const handleOpenDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setReplyId(id);
  };

  // *** Handle delete reply id
  const handleDeleteReply = async () => {
    const data = { token, replyId };
    await dispatch(handleDeleteBlockReply(data));
    setIsDelete(true);
    setOpenDeleteModal(false);
    setReplyCount((prev) => prev - 1);
  };

  const onClose = () => {
    setOpenDeleteModal(false);
    setOpenEditModal(false);
  };

  // *** Handle open Edit modal
  const handleEditModal = (reply) => {
    setOpenEditModal(true);
    setText(reply.content);
  };

  // Handle edit reply
  const handleEditReply = async () => {
    const data = { token, text, replyId };
    await dispatch(handleEditBlockReply(data));
    setContent(text);
    setOpenEditModal(false);
    setText("");
  };

  // handle spam reply
  const handleSpam = () => {
    if (spam.includes(user.handleUn)) {
      const arr = spam;
      const temp = arr.filter((data) => data !== user.handleUn);
      setSpam(temp);
      setSpamCount((prev) => prev - 1);
    } else {
      setSpam((prev) => [...prev, user.handleUn]);
      setSpamCount((prev) => prev + 1);
    }
    const data = { token, replyId };
    dispatch(handleSpamBlockReply(data));
  };

  // handle like reply
  const handleLike = async () => {
    if (dislikes.includes(user.handleUn)) {
      const arr = dislikes;
      const temp = arr.filter((data) => data !== user.handleUn);
      setDislikes(temp);
      setDislikeCount((prev) => prev - 1);
      setLikes((prev) => [...prev, user.handleUn]);
      setLikeCount((prev) => prev + 1);
    } else {
      if (likes.includes(user.handleUn)) {
        const arr = likes;
        const temp = arr.filter((data) => data !== user.handleUn);
        setLikes(temp);
        setLikeCount((prev) => prev - 1);
      } else {
        setLikes((prev) => [...prev, user.handleUn]);
        setLikeCount((prev) => prev + 1);
      }
    }
    const data = { token, replyId };
    const result = await dispatch(handleLikBlockReply(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", responseData);
    }
  };

  const handleDislike = () => {
    if (likes.includes(user.handleUn)) {
      const arr = likes;
      const temp = arr.filter((data) => data !== user.handleUn);
      setLikes(temp);
      setDislikes((prev) => [...prev, user.handleUn]);
      setLikeCount((prev) => prev - 1);
      setDislikeCount((prev) => prev + 1);
    } else {
      if (dislikes.includes(user.handleUn)) {
        const arr = dislikes;
        const temp = arr.filter((data) => data !== user.handleUn);
        setDislikes(temp);
        setDislikeCount((prev) => prev - 1);
      } else {
        setDislikes((prev) => [...prev, user.handleUn]);
        setDislikeCount((prev) => prev + 1);
      }
    }
    const data = { token, replyId };
    dispatch(handleDislikBlockReply(data));
  };

  const PostHandleFollowUser = (userId) => {
    // alert(cardUsername);
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { isToken: token, userId };
    dispatch(handleFollowUser(data));
  };

  const handleOpenReply = async (reply) => {
    setReplyId(reply.r_id);
    setOpenReplyForm((p) => !p);
    setSelectUsername(reply.c_un);
    if (!openReplyForm) {
      setPage(1);
    }
  };

  const handleInputKeyDown = (e) => {
    if (!replyText.trim()) {
      if (e.keyCode === 8) {
        setSelectUsername("");
      }
    }
  };

  React.useEffect(() => {
    if (!replyText.trim()) {
      setIsDisable(true);
    } else {
      setIsDisable(false);
    }
  }, [replyText]);

  const handleReply = async () => {
    setIsLoading(true);
    const data = {
      token,
      replyText,
      cmntId: replyId,
      repliedUser: selectUsername,
    };
    const replyData = await dispatch(handleCreateBlockPostReplyReply(data));
    console.log(replyData);
    setSelectUsername("");
    setReplies((prev) => [replyData.data, ...prev]);
    setIsLoading(false);
    setIsDisable(true);
    setSelectUsername("");
    setReplyText("");
  };

  const fetchReplies = async () => {
    console.log("INNER REPLY FETCHING FUNCTION");
    console.log("replyId:", replyId);
    const data = { cmntId: replyId, page, limit, token };
    const result = await dispatch(handleFetchRepliesReply(data));
    console.log(">>>>>", result);
    //
  };

  React.useEffect(() => {
    if (openReplyForm) {
      fetchReplies();
    }
  }, [page, replyId, openReplyForm]);

  return (
    <React.Fragment>
      {!isDelete && (
        <div className='block_reply_card'>
          {/* Delete modal */}
          {openDeleteModal && (
            <CustomSmallModal
              title='Delete reply'
              onClose={onClose}
              body={
                <div className='comment_edit_container'>
                  Do you want to delete this reply?
                </div>
              }
              footer={
                <div>
                  <button className='update_btn' onClick={handleDeleteReply}>
                    Delete
                  </button>
                </div>
              }
            />
          )}

          {/* Edit modal */}
          {openEditModal && (
            <CustomSmallModal
              title='Edit reply'
              onClose={onClose}
              body={
                <div className='comment_edit_container'>
                  <textarea
                    placeholder='Update reply'
                    className='modal_textarea'
                    value={text}
                    onChange={(e) =>
                      setText(e.target.value.slice(0, 50))
                    }></textarea>
                </div>
              }
              footer={
                <div>
                  <button className='update_btn' onClick={handleEditReply}>
                    Update
                  </button>
                </div>
              }
            />
          )}

          {/* Header */}
          <div className='block_reply_header'>
            {/* info */}
            <div className='block_reply_info'>
              <div className='profile_avatar_section'>
                <img
                  src={
                    reply.c_pi
                      ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${reply.c_pi}`
                      : UserAvatar
                  }
                  className='posted_user_avatar'
                  id='profile'
                />

                {user.handleUn !== username && (
                  <button
                    className='post_card_follow_btn'
                    onClick={() => PostHandleFollowUser(username)}>
                    {following.includes(username) ? (
                      <BsFillCheckCircleFill className='postcard_follow_icon' />
                    ) : (
                      <BsFillPlusCircleFill className='postcard_follow_icon' />
                    )}
                  </button>
                )}
              </div>
              <Link
                to={`/user/profile/${username}`}
                className='reply_user_name'>
                {firstname} {lastname}
              </Link>
              <span className='reply_user_username'>@{username}</span>
            </div>

            {user.handleUn === username && (
              <Menu
                menuButton={
                  <MenuButton className={"__menu_btn"}>
                    <FiMoreHorizontal />
                  </MenuButton>
                }>
                <MenuItem
                  className={"block_post_menuItem"}
                  onClick={() => handleEditModal(reply)}>
                  Edit
                </MenuItem>
                <MenuItem
                  className={"block_post_menuItem"}
                  onClick={() => handleOpenDeleteModal(reply.r_id)}>
                  Delete
                </MenuItem>
              </Menu>
            )}
          </div>

          {/* Body */}
          <div className='reply_content'>
            {reply.repliedUser && (
              <Link to='' className='tagged_user_link'>
                {reply.repliedUser}
              </Link>
            )}
            {content}
          </div>

          {/* Footer */}
          <div className='reply_block_footer'>
            <button className='block_reply_footer_btn' onClick={handleLike}>
              {likes.includes(user.handleUn) ? (
                <AiFillLike />
              ) : (
                <AiOutlineLike />
              )}{" "}
              {likeCount}
            </button>
            <button className='block_reply_footer_btn' onClick={handleDislike}>
              {dislikes.includes(user.handleUn) ? (
                <AiFillDislike />
              ) : (
                <AiOutlineDislike />
              )}{" "}
              {dislikes.length}
            </button>
            <button className='block_reply_footer_btn' onClick={handleSpam}>
              {spam.includes(user.handleUn) ? <RiSpam2Fill /> : <RiSpam2Line />}{" "}
              {spam.length}
            </button>

            <button
              className='reply_footer_btn'
              onClick={() => handleOpenReply(reply)}>
              <BsReply />
              <span className='reply_footer_count'>{reply.i_reply.length}</span>
            </button>
          </div>

          {openReplyForm && (
            <div className='inner_reply_form_section'>
              {/* Inner REPLY INPUT FORM */}
              <div className='__reply_form_container'>
                {selectUsername && (
                  <div className='inner_selected_user'>@{selectUsername}</div>
                )}
                <input
                  type='text'
                  placeholder={`Reply to ${selectUsername}`}
                  className={
                    selectUsername
                      ? "_input_field has_username"
                      : "_input_field no_username"
                  }
                  value={replyText}
                  onChange={(e) => setReplyText(e.target.value.slice(0, 100))}
                  onKeyDown={(e) => handleInputKeyDown(e)}
                />
                {!isDisable && (
                  <button className='reply_btn' onClick={handleReply}>
                    {isLoading ? (
                      <div className='reply_spinner_icon'></div>
                    ) : (
                      <GrSend className='reply_icon' />
                    )}
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      )}
    </React.Fragment>
  );
};

export default GroupPostReply;
