/** @format */

import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectToken, selectUser } from "../../../redux/_user/userSelectors";
import { useNavigate } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import "./FullPostHeader.css";

const FullPostHeader = () => {
  const user = useSelector(selectUser) || null;

  return (
    <div>
      {user ? (
        <div className='post_header_without_token'>
          <div className='section_one'>
            <BiArrowBack className='post_navbar_back_btn' />
            <span className='post_nav_header_title'>Post</span>
          </div>
        </div>
      ) : (
        <div className='post_header_without_token'>
          <div className='section_one'>
            <BiArrowBack className='post_navbar_back_btn' />
            <span className='post_nav_header_title'>Post</span>
          </div>

          <div className='section_two'>
            <button
              className='login_header_btn'
              onClick={() => navigate("/login")}>
              Login
            </button>
            <button
              className='register_header_btn'
              onClick={() => navigate("/register")}>
              Register
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FullPostHeader;
