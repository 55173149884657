/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import UserAvatar from "../../Assets/userAvatar.webp";
import { FiMoreHorizontal } from "react-icons/fi";
import {
  AiOutlineLike,
  AiOutlineDislike,
  AiFillLike,
  AiFillDislike,
} from "react-icons/ai";
import { RiSpam2Line, RiSpam2Fill } from "react-icons/ri";
import CustomSmallModal from "../modal/CustomSmallModal";
import { GrSend } from "react-icons/gr";

import { useSocket, socket, isSocketConnected } from "../../socket/socket";

import { useSelector, useDispatch } from "react-redux";
import {
  selectUser,
  selectToken,
  selectcommentSpam,
  selectLike,
  selectDislikes,
  selectFollowing,
} from "../../redux/_user/userSelectors";
import {
  addcommentSpam,
  removecommentSpam,
  addLike,
  removeLike,
  addDislikes,
  removeDislikes,
  handleFollowUser,
  setAddFollowing,
  setRemoveFollowing,
} from "../../redux/_user/userSlice";
import {
  handleDeleteReply,
  handleInnerReplyLike,
  handleReplyInnerDislike,
  handleInnerReplySpam,
} from "../../redux/_post/postSlice";
import {
  BsFillPinAngleFill,
  BsFillPlusCircleFill,
  BsFillCheckCircleFill,
  BsReply,
} from "react-icons/bs";
import "./CommentReply.css";
import {
  handleReplyReply,
  handleFetchReplyReply,
} from "../../redux/_post/postSlice";

const InnerReply = ({ replyData }) => {
  useSocket();
  const { t } = useTranslation(["common"]);
  const dispatch = useDispatch();
  const token = useSelector(selectToken);
  const user = useSelector(selectUser);
  const spam = useSelector(selectcommentSpam);
  const likes = useSelector(selectLike);
  const dislikes = useSelector(selectDislikes);
  const following = useSelector(selectFollowing);

  const [content, setContent] = React.useState(replyData.content);
  const [likeCount, setLikeCount] = React.useState(replyData.l_c);
  const [dislikeCount, setDisikeCount] = React.useState(replyData.d_c);
  const [spamCount, setSpamCount] = React.useState(replyData.s_c);
  const [deleteReply, setDeleteReply] = React.useState(replyData.delete);
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false);
  const [replyId, setReplyId] = React.useState("");

  // *** Handle spam reply
  const handleSpam = (id) => {
    if (spam.includes(id)) {
      dispatch(removecommentSpam(id));
      setSpamCount((prev) => prev - 1);
    } else {
      dispatch(addcommentSpam(id));
      setSpamCount((prev) => prev + 1);
    }
    const data = { token, id };
    dispatch(handleInnerReplySpam(data));
  };

  // *** Handle like reply
  const handleLike = async (id) => {
    if (dislikes.includes(id)) {
      // removeCommentDislike(id);
      dispatch(removeDislikes(id));
      setDisikeCount((prev) => prev - 1);
      // addCommentLike(id);
      dispatch(addLike(id));
      setLikeCount((prev) => prev + 1);
    } else {
      if (likes.includes(id)) {
        setLikeCount((prev) => prev - 1);
        // removeCommentLike(id);
        dispatch(removeLike(id));
      } else {
        setLikeCount((prev) => prev + 1);
        // addCommentLike(id);
        dispatch(addLike(id));
      }
    }
    const data = { token, id };
    const result = await dispatch(handleInnerReplyLike(data));
    console.log(result);
    try {
      if (result.notificationData) {
        socket.emit("notification receive", result);
      } else {
        console.log(result);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDislike = async (id) => {
    if (likes.includes(id)) {
      // removeCommentLike(id);
      dispatch(removeLike(id));
      setLikeCount((prev) => prev - 1);
      // addCommentDislike(id);
      dispatch(addDislikes(id));
      setDisikeCount((prev) => prev + 1);
    } else {
      if (dislikes.includes(id)) {
        setDisikeCount((prev) => prev - 1);
        // removeCommentDislike(id);
        dispatch(removeDislikes(id));
      } else {
        setDisikeCount((prev) => prev + 1);
        // addCommentDislike(id);
        dispatch(addDislikes(id));
      }
    }
    const data = { id, token };
    const result = await dispatch(handleReplyInnerDislike(data));
    // if (result.notificationData) {
    //   socket.emit("notification receive", result);
    // } else {
    //   console.log(result);
    // }
  };

  const handleDeleteModal = (id) => {
    setOpenDeleteModal(true);
    setReplyId(id);
  };

  const closeModal = () => {
    setOpenDeleteModal(false);
    setReplyId("");
  };

  // *** Handle delete reply
  const handleDeleteComment = async () => {
    const data = { token, replyId };
    const replyData = await dispatch(handleDeleteReply(data));
    console.log(replyData);
    try {
      setDeleteReply(true);
      closeModal();
    } catch (error) {
      setDeleteReply(false);
      closeModal();
    }
  };

  const PostHandleFollowUser = (userId) => {
    // alert(cardUsername);
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { isToken: token, userId: userId };
    dispatch(handleFollowUser(data));
  };

  return (
    <div className='reply_card'>
      <div className='reply_header_section'>
        <div className='reply_user_info'>
          <div className='profile_avatar_section'>
            <img
              src={
                replyData.r_u_pic
                  ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}$replyData.r_u_pic}`
                  : UserAvatar
              }
              className='comment_avatar'
            />

            {user.handleUn !== replyData.r_u_dun && (
              <button
                className='post_card_follow_btn'
                onClick={() => PostHandleFollowUser(replyData.r_u_dun)}>
                {following.includes(replyData.r_u_dun) ? (
                  <BsFillCheckCircleFill className='postcard_follow_icon' />
                ) : (
                  <BsFillPlusCircleFill className='postcard_follow_icon' />
                )}
              </button>
            )}
          </div>
          <Link
            to={`/user/profile/${replyData.r_u_dun}`}
            className='reply_user_name'>
            {replyData.r_u_fn} {replyData.r_u_ln}
          </Link>
          <span className='reply_user_username'>@{replyData.r_u_dun}</span>
        </div>
      </div>

      <div className='reply_body_section'>
        {replyData.username && (
          <span className='reply_username'>@{replyData.username}</span>
        )}
        {content}
      </div>

      <div className='reply_footer_section'>
        <button
          className='reply_footer_btn'
          onClick={() => handleLike(replyData.id)}>
          {likes.includes(replyData.id) ? (
            <AiFillLike className='cmnt_icon like_cmnt_icon_active' />
          ) : (
            <AiOutlineLike className='cmnt_icon' />
          )}{" "}
          <span className='reply_footer_count'>{likeCount}</span>
        </button>

        {/* Dislike */}
        <button
          className='reply_footer_btn'
          onClick={() => handleDislike(replyData.id)}>
          {dislikes.includes(replyData.id) ? (
            <AiFillDislike className='cmnt_icon dislike_cmnt_icon_active' />
          ) : (
            <AiOutlineDislike className='cmnt_icon' />
          )}{" "}
          <span className='reply_footer_count'>{dislikeCount}</span>
        </button>

        {/* Spam */}
        <button
          className='reply_footer_btn'
          onClick={() => handleSpam(replyData.id)}>
          {!spam.includes(replyData.id) ? <RiSpam2Line /> : <RiSpam2Fill />}{" "}
          <span className='reply_footer_count'>{spamCount}</span>
        </button>
      </div>
    </div>
  );
};

export default InnerReply;
