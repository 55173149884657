/** @format */

export const pageTypeSelector = (state) => state.page.pageType;
export const scrollAxis = (state) => state.page.axisValue;
export const selectCoins = (state) => state.page.coins;
export const openPostModal = (state) => state.page.openPostModal;
export const openBlockCreateModal = (state) => state.page.openBlockCreateModal;
export const scrollPosition = (state) => state.page.scrollPosition;
export const openBlockPostModal = (state) => state.page.openPostBlockModal;
export const openBlockEventModal = (state) => state.page.openEventBlockModal;
