/** @format */

import React, { useEffect } from "react";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router";
import { ReactComponent as Arrow } from "../../../Assets/Icons/arrow1.svg";

const SocialPagesHeader = ({ title }) => {
  const navigate = useNavigate();

  const backBtn = () => {
    // alert("Click")
    navigate(-1);
  };

  React.useEffect(() => {
    const currentURL = window.location.href;
    console.log("Current URL:", currentURL);
    const savedUrl = localStorage.getItem("currenturl");
    if (currentURL === savedUrl) {
    } else {
      localStorage.setItem("currenturl", currentURL);
    }
  }, []);
  return (
    <div className="custom_header_container">
      <button className="custom_header_back_button" onClick={() => backBtn()}>
        <Arrow className="custom_icon" />
      </button>
      <span className="custom_header_title">{title}</span>
    </div>
  );
};

export default SocialPagesHeader;
