/** @format */

import { createSlice } from "@reduxjs/toolkit";
import {
  registerUser,
  loginUser,
  getProfile,
  fetchProfileAnalytics,
  handleUpdateGender,
  handleUpdateLanguage,
  handleUpdateCountry,
  handleUpdateUserCity,
  // handleUpdateBirthday,
  handleUpdateDisplayName,
  handleUpdateBio,
  handleUpdateLink,
  handleUpdateProfilePrivacy,
  handleUpdateProfileStatusPrivacy,
  handleUpdatePostPrivacy,
  handleUpdateProfileViewPrivacy,
  handleUpdateProfileMessagePrivacy,
  handleUpdatePassword,
  handleUpdateCover,
  handleUpdateProfileImage,
  handleUpdateUserInfo,
  UpdateUserInterest,
  updateUserProfileAvatar,
  handleFetchProfileDetails,
  updateFollowUser,
  pdateProfileVisitors,
  fetchUserProfilePosts,
  fetchUserProfileFollowing,
  fetchUserProfileFollowers,
  fetchUserAbout,
  fetchSearchUser,
  profileVisitCount,
  updateFavCoin,
  updatePurpose,
  updateLegalName,
  fetchSuggestedUsers,
  verifyProfile,
  submitProfileLinks,
  claimBadge,
  userEarning,
  userSpending,
  fetchMyBlocks,
  fetchMemberBlocks,
  getUserAnalytics,
  handleUpdateTimelinePrivacy,
  handleUpdateProfileDetails,
  handleUpdateCoinDetails,
  handleUpdateFlwrListDetails,
  handleGetEarningFeed,
  handleGetEarning,
  handleGetAnalyticsData,
  handleUpdateUpdateLinkPrivacy,
  handleUpdateUpdateProfileLockPrivacy,
  handleUpdateReelsPrivacy,
  handleUpdateMentionsPostsPrivacy,
  handleUpdatePostsPrivacy,
  handleGenerateWallet,
  handlegetSubscriptionData,
  handleUpdateSocialAccount,
  handleFetchPopulateUsers,
  handleUpdateWallet,
  logoutUser,
  updateUserPrivacySettingsApi,
} from "../../api/_user/userApi";
import { socket } from "../../socket/socket";

//reset state helper function
const resetState = (state) => {
  state.isLoading = true;
  state.responseType = "idle";
  state.response = null;
};

//success helper function
const successLoginRegister = (state, action) => {
  console.log("*** action.payload:", action.payload);
  state.user = action.payload.user;
  state.isToken = action.payload.token;
  state.following = action.payload.user.flw || [];
  state.isLoading = false;
  state.responseType = "success";
  state.isLoggedIn = true;
};

//success helper function
const successUserUpdate = (state, action) => {
  state.user = action.payload.user;
  state.isToken = action.payload.token;
  state.isLoading = false;
  state.responseType = "success";
  state.isLoggedIn = true;
};

//error or fail helper function
const handleFail = (state, action) => {
  state.responseType = "error";
  state.response = action.payload;
  state.isLoading = false;
};

//create slice
export const userSlice = createSlice({
  name: "user",
  initialState: {
    user_analytics: null,
    profile: null,
    isLoggedIn: false,
    user: null,
    isToken: null,
    likes: [],
    spam: [],
    shares: [],
    dislikes: [],
    poll: [],
    following: [],
    update: null,
    hide: [],
    bookmark: [],
    angry: [],
    updateUser: null,
    report: [],

    emoji_likes: [],
    emoji_angry: [],
    emoji_haha: [],
    emoji_dislikes: [],
    emoji_sad: [],
    emoji_upvote: [],
    emoji_fire: [],

    // *** This is for News Block post
    upvote: [],
    downvote: [],
    fake: [],

    // *** This is for Announcement Block post
    exited: [],
    disapointed: [],
    misleading: [],

    // Blockcast
    blockcast: [],
    myblockcast: [],
    updateBlockcast: null,

    // Comment
    cmntLike: [],
    cmntDislike: [],
    cmntSpam: [],

    // news post
    reliable: [],
    intersting: [],
    // fake: [],

    // informative post
    helpful: [],
    unhelpful: [],
    misleading: [],

    // Announcement post
    likeAnc: [],
    impAnc: [],

    interestEvent: [],
    notInterestEvent: [],
    join: [],

    join_block: [],
    my_block: [],
    searchHistory: [],
    searchTag: [],

    flag: [],
  },
  reducers: {
    //for register
    registerStart: resetState,
    registerSuccess: successLoginRegister, //call helper function
    registerFail: handleFail,

    //for login
    loginStart: resetState,
    loginSuccess: successLoginRegister,
    loginFail: handleFail,

    //for user update
    userUpdateStart: resetState,
    userUpdateSuccess: successUserUpdate,
    userUpdateFail: handleFail,

    //clear messages
    clearMessages: resetState,

    setAddFollowing: (state, action) => {
      state.following = [...state.following, action.payload];
    },
    setRemoveFollowing: (state, action) => {
      const arr = state.following;
      const temp = arr.filter((data) => data !== action.payload);
      state.following = temp;
    },
    resetFollowing: (state, action) => {
      console.log("reset->>", action.payload);
      state.following = action.payload;
    },

    setSearchHistory: (state, action) => {
      state.searchHistory = [action.payload, ...state.searchHistory];
    },

    removeSearchHistory: (state, action) => {
      state.searchHistory = [];
    },

    setUser: (state, action) => {
      console.log(action.payload);
      state.user = action.payload;
      state.isToken = action.payload.token;
      state.following = action.payload.flw;
      state.isLoading = false;
      state.responseType = "success";
      state.isLoggedIn = true;
    },

    setUpdateUser: (state, action) => {
      state.user = action.payload;
    },

    setSearchTag: (state, action) => {
      state.searchTag = [...state.searchTag, action.payload];
    },

    logout: (state, action) => {
      state.isLoggedIn = false;
      state.user = null;
      state.isToken = null;
    },

    setProfile: (state, action) => {
      console.log("Action:", action.payload);
      state.profile = action.payload;
      state.isLoading = false;
    },
    setUserAnalytics: (state, action) => {
      state.user_analytics = action.payload;
      state.isLoading = false;
    },

    setUserUpdate: (state, action) => {
      state.updateUser = action.payload;
    },
    setAddSpam: (state, action) => {
      state.spam = [...state.spam, action.payload];
    },
    setRemoveSpam: (state, action) => {
      const arr = state.spam;
      const temp = arr.filter((data) => data !== action.payload);
      state.spam = temp;
    },

    /**
     * @News_POST
     */
    addReliable: (state, action) => {
      state.reliable = [...state.reliable, action.payload];
    },
    removeReliable: (state, action) => {
      const arr = state.reliable;
      const temp = arr.filter((data) => data !== action.payload);
      state.reliable = temp;
    },

    addIntersting: (state, action) => {
      state.intersting = [...state.intersting, action.payload];
    },
    removeInterstinge: (state, action) => {
      const arr = state.intersting;
      const temp = arr.filter((data) => data !== action.payload);
      state.intersting = temp;
    },

    addFake: (state, action) => {
      state.fake = [...state.fake, action.payload];
    },
    removeFake: (state, action) => {
      const arr = state.fake;
      const temp = arr.filter((data) => data !== action.payload);
      state.fake = temp;
    },

    addLikeAnnouncement: (state, action) => {
      state.likeAnc = [...state.likeAnc, action.payload];
    },
    removeLikeAnnouncement: (state, action) => {
      const arr = state.likeAnc;
      const temp = arr.filter((data) => data !== action.payload);
      state.likeAnc = temp;
    },

    addImportentAnnouncement: (state, action) => {
      state.impAnc = [...state.impAnc, action.payload];
    },
    removeImportentAnnouncement: (state, action) => {
      const arr = state.impAnc;
      const temp = arr.filter((data) => data !== action.payload);
      state.impAnc = temp;
    },

    addHelpful: (state, action) => {
      state.helpful = [...state.helpful, action.payload];
    },
    removeHelpful: (state, action) => {
      const arr = state.helpful;
      const temp = arr.filter((data) => data !== action.payload);
      state.helpful = temp;
    },
    addUnHelpfun: (state, action) => {
      state.unhelpful = [...state.unhelpful, action.payload];
    },
    removeUnHelpful: (state, action) => {
      const arr = state.unhelpful;
      const temp = arr.filter((data) => data !== action.payload);
      state.unhelpful = temp;
    },
    addMisLeading: (state, action) => {
      state.misleading = [...state.misleading, action.payload];
    },
    removeMisleading: (state, action) => {
      const arr = state.misleading;
      const temp = arr.filter((data) => data !== action.payload);
      state.misleading = temp;
    },
    // misleading

    // ***** ADDED
    // like
    addLike: (state, action) => {
      state.emoji_likes = [...state.emoji_likes, action.payload];
    },
    removeLike: (state, action) => {
      const arr = state.emoji_likes;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_likes = temp;
    },

    // angry
    addAngry: (state, action) => {
      state.emoji_angry = [...state.emoji_angry, action.payload];
    },
    removeAngry: (state, action) => {
      const arr = state.emoji_angry;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_angry = temp;
    },

    // haha
    addHAHA: (state, action) => {
      state.emoji_haha = [...state.emoji_haha, action.payload];
    },
    removeHaha: (state, action) => {
      const arr = state.emoji_haha;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_haha = temp;
    },

    // dislikes
    addDislikes: (state, action) => {
      state.emoji_dislikes = [...state.emoji_dislikes, action.payload];
    },
    removeDislikes: (state, action) => {
      const arr = state.emoji_dislikes;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_dislikes = temp;
    },

    // sad
    addSad: (state, action) => {
      state.emoji_sad = [...state.emoji_sad, action.payload];
    },
    removeSad: (state, action) => {
      const arr = state.emoji_sad;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_sad = temp;
    },

    // fire
    addFire: (state, action) => {
      state.emoji_fire = [...state.emoji_fire, action.payload];
    },
    removeFire: (state, action) => {
      const arr = state.emoji_fire;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_fire = temp;
    },

    // sad
    addUpVote: (state, action) => {
      state.emoji_upvote = [...state.emoji_upvote, action.payload];
    },
    removeUpvote: (state, action) => {
      const arr = state.emoji_upvote;
      const temp = arr.filter((data) => data !== action.payload);
      state.emoji_upvote = temp;
    },

    /**
     * @COMMENT
     */
    addcommentSpam: (state, action) => {
      state.cmntSpam = [...state.cmntSpam, action.payload];
    },
    removecommentSpam: (state, action) => {
      const arr = state.cmntSpam;
      const temp = arr.filter((data) => data !== action.payload);
      state.cmntSpam = temp;
    },

    addReport: (state, action) => {
      state.report = [...state.report, action.payload];
    },
    removeReport: (state, action) => {
      const arr = state.report;
      const temp = arr.filter((data) => data !== action.payload);
      state.report = temp;
    },

    // **** Block news post update state
    // *** UPVOTE POST
    setUpvote: (state, action) => {
      state.upvote = [...state.upvote, action.payload];
    },
    setRemoveUpvote: (state, action) => {
      const arr = state.upvote;
      const temp = arr.filter((data) => data !== action.payload);
      state.upvote = temp;
    },
    // *** DOWNVOTE POST
    setDownvote: (state, action) => {
      state.downvote = [...state.downvote, action.payload];
    },
    setRemoveDownvote: (state, action) => {
      const arr = state.downvote;
      const temp = arr.filter((data) => data !== action.payload);
      state.downvote = temp;
    },
    // *** FAKE POST
    setFakePost: (state, action) => {
      state.fake = [...state.fake, action.payload];
    },
    setRemoveFakePost: (state, action) => {
      const arr = state.fake;
      const temp = arr.filter((data) => data !== action.payload);
      state.fake = temp;
    },

    // **** Block Announcement post update state
    // *** EXCITED POST
    setExcited: (state, action) => {
      state.exited = [...state.exited, action.payload];
    },
    setRemoveExcitedt: (state, action) => {
      const arr = state.exited;
      const temp = arr.filter((data) => data !== action.payload);
      state.exited = temp;
    },

    // *** DISAPOINTED POST
    setDisapointed: (state, action) => {
      state.disapointed = [...state.disapointed, action.payload];
    },
    setRemoveDisapointed: (state, action) => {
      const arr = state.disapointed;
      const temp = arr.filter((data) => data !== action.payload);
      state.disapointed = temp;
    },

    // *** MISLEADING POST
    setMisleading: (state, action) => {
      state.misleading = [...state.misleading, action.payload];
    },
    setRemoveMisleading: (state, action) => {
      const arr = state.misleading;
      const temp = arr.filter((data) => data !== action.payload);
      state.misleading = temp;
    },

    setFlag: (state, action) => {
      state.flag = [...state.misleading, action.payload];
    },

    setWalletAddress: (state, action) => {
      if (state.user) {
        // Ensure that there is a user object to update
        state.user = {
          ...state.user,
          walletAddress: action.payload, // Update the wallet address
        };
      }
    },
  },
});

//export actions outside
export const {
  setUser,
  setUpdateUser,
  clearMessages,
  setAddSpam,
  setRemoveSpam,
  addLike,
  removeLike,
  addAngry,
  removeAngry,
  addHAHA,
  removeHaha,
  addDislikes,
  removeDislikes,
  addSad,
  addFire,
  addUpVote,
  removeSad,
  removeFire,
  removeUpvote,
  addcommentSpam,
  removecommentSpam,
  addReport,
  removeReport,
  addReliable,
  removeReliable,
  addIntersting,
  removeInterstinge,
  addFake,
  removeFake,
  addLikeAnnouncement,
  removeLikeAnnouncement,
  addImportentAnnouncement,
  removeImportentAnnouncement,
  addHelpful,
  removeHelpful,
  addUnHelpfun,
  removeUnHelpful,
  addMisLeading,
  removeMisleading,
  setAddFollowing,
  setRemoveFollowing,
  resetFollowing,
  setSearchHistory,
  removeSearchHistory,
  setUpvote,
  setRemoveUpvote,
  setDownvote,
  setRemoveDownvote,
  setFakePost,
  setRemoveFakePost,
  setExcited,
  setRemoveExcitedt,
  setDisapointed,
  setRemoveDisapointed,
  setMisleading,
  setRemoveMisleading,
  setWalletAddress,
} = userSlice.actions;

//export actions inside
const {
  registerStart,
  registerSuccess,
  registerFail,
  loginStart,
  loginSuccess,
  loginFail,
  setSearchTag,
  logout,
  setProfile,
  userUpdateSuccess,
  setUserAnalytics,
  setUserUpdate,
} = userSlice.actions;

export const register = (data) => async (dispatch) => {
  const response = await registerUser(data);
  console.log("After registration:", response);
  try {
    dispatch(registerSuccess(response));
    return response;
  } catch (error) {
    return error;
  }
};

export const login = (data) => async (dispatch) => {
  // dispatch(loginStart());
  try {
    const response = await loginUser(data);
    console.log("Response of:", response);
    dispatch(loginSuccess(response));
    // return response;
  } catch (error) {
    dispatch(loginFail(error.message));
  }
};

export const updateUserState = (data) => (dispatch) => {
  console.log("Data: >> ", data);
  // dispatch(userUpdateSuccess(data));
};

export const fetchProfileDetails = (data) => async (dispatch) => {
  dispatch(registerStart());
  try {
    const profileData = await getProfile(data);
    dispatch(setProfile(profileData));
    if (data.myHandleUn === data.handleUn) {
      // console.log("Same user");
      dispatch(setUser(profileData));
    }
    return profileData;
  } catch (error) {
    console.log(error);
  }
};

export const getProfileAnalytics = (data) => async (dispatch) => {
  dispatch(registerStart());
  try {
    const analyticsData = await fetchProfileAnalytics(data);
    // dispatch(setUserAnalytics(analyticsData));
    return analyticsData;
  } catch (error) {
    console.log(error);
  }
};

export const updateUserGender = (data) => async (dispatch) => {
  const userData = await handleUpdateGender(data);
  try {
    // console.log(userData);
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateUserLanguage = (data) => async (dispatch) => {
  const userData = await handleUpdateLanguage(data);
  try {
    // console.log(userData);
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateUserCountry = (data) => async (dispatch) => {
  const userData = await handleUpdateCountry(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateUserCity = (data) => async (dispatch) => {
  const userData = await handleUpdateUserCity(data);
  try {
    dispatch(userUpdateSuccess(userData));
    return userData;
  } catch (error) {
    console.log("Error: ", error);
  }
};

// export const updateUserBirthday = (data) => async (dispatch) => {
//   const userData = await handleUpdateBirthday(data);
//   try {
//     dispatch(userUpdateSuccess(userData));
//   } catch (error) {
//     console.log("Error: ", error);
//   }
// };

export const updateUserDisplayName = (data) => async (dispatch) => {
  const userData = await handleUpdateDisplayName(data);
  console.log(userData);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateUserBio = (data) => async (dispatch) => {
  const userData = await handleUpdateBio(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateUProfileLink = (data) => async (dispatch) => {
  const userData = await handleUpdateLink(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfilePrivacy = (data) => async (dispatch) => {
  const userData = await handleUpdateProfilePrivacy(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfileStatusPrivacy = (data) => async (dispatch) => {
  // console.log(data);
  const userData = await handleUpdateProfileStatusPrivacy(data);
  try {
    console.log(userData);
    // dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updatePostPriacy = (data) => async (dispatch) => {
  const userData = await handleUpdatePostPrivacy(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfileViewPrivacy = (data) => async (dispatch) => {
  const userData = await handleUpdateProfileViewPrivacy(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateMessagePrivacy = (data) => async (dispatch) => {
  const userData = await handleUpdateProfileMessagePrivacy(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateUserPassword = (data) => async (dispatch) => {
  const userData = await handleUpdatePassword(data);
  try {
    dispatch(userUpdateSuccess(userData));
    dispatch(logout());
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfileCoverImage = (data) => async (dispatch) => {
  const userData = await handleUpdateCover(data);
  // console.log("File data came here: ", data);

  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfileImage = (data) => async (dispatch) => {
  const userData = await handleUpdateProfileImage(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfileHandle = (data) => async (dispatch) => {
  const userData = await handleUpdateUserInfo(data);
  // console.log(userData);
  try {
    return userData;
  } catch (error) {
    return "Something went wrong";
  }
};

export const handleUpdateUserInterest = (data) => async (dispatch) => {
  const userData = await UpdateUserInterest(data);
  // console.log(userData);
  try {
    return userData;
  } catch (error) {
    return "Something went wrong";
  }
};

export const handleFetchSuggestedUser = (data) => async (dispatch) => {
  console.log(data);
};

export const handleUpdateProfileAvatar = (data) => async (dispatch) => {
  const userData = await updateUserProfileAvatar(data);
  // console.log(userData);
  try {
    return userData;
  } catch (error) {
    return "Something went wrong";
  }
};

export const handleUpdateUserDetails = (data) => async (dispatch) => {
  const userData = await handleFetchProfileDetails(data);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFollowUser = (data) => async (dispatch, getState) => {
  console.log("Came in userSlice", getState());
  const following = getState().user?.following;
  try {
    let btnType;
    if (following && following.includes(data.userId)) {
      btnType = "unfollow";
      dispatch(setRemoveFollowing(data.userId));
    } else {
      btnType = "follow";
      dispatch(setAddFollowing(data.userId));
    }
    const userData = await updateFollowUser({ ...data, btnType });
    console.log("yay", userData);
    dispatch(setUserUpdate(userData));
    if (userData.notificationData) {
      socket.emit("notification receive", result);
    }
  } catch (error) {
    dispatch(resetFollowing(following));
    console.log(error);
  }
};

export const handleUpdateProfileVisitors = (data) => async (dispatch) => {
  await pdateProfileVisitors(data);
};

export const handleFetchUserProfilePosts = (data) => async (dispatch) => {
  const result = await fetchUserProfilePosts(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchUserProfileFollowers = (data) => async (dispatch) => {
  const result = await fetchUserProfileFollowers(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchUserProfileFollowing = (data) => async (dispatch) => {
  const result = await fetchUserProfileFollowing(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchUserAbout = (data) => async (dispatch) => {
  const result = await fetchUserAbout(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchSearchUser = (data) => async (dispatch) => {
  const result = await fetchSearchUser(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleProfileVisitCount = (data) => async (dispatch) => {
  // console.log(data);
  const result = await profileVisitCount(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateFavCoin = (data) => async (dispatch) => {
  const result = await updateFavCoin(data);
  console.log(result.user);
  try {
    dispatch(userUpdateSuccess(result));
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdatePurpose = (data) => async (dispatch) => {
  const result = await updatePurpose(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateLegalName = (data) => async (dispatch) => {
  const userData = await updateLegalName(data);
  // console.log(userData);

  try {
    dispatch(userUpdateSuccess(userData));
    return userData;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchSuggestedUsers = (data) => async (dispatch) => {
  const result = await fetchSuggestedUsers(data);
  try {
    return result;
  } catch (error) {
    console.log("Error!!");
  }
};

export const handleVerifyProfile = (data) => async (dispatch) => {
  // console.log("*** ", data);
  const result = await verifyProfile(data);
  try {
    return result;
  } catch (error) {
    console.log("Error!!");
  }
};

export const handleSubmitLinks = (data) => async (dispatch) => {
  // console.log("*** ", data);
  const result = await submitProfileLinks(data);
  try {
    return result;
  } catch (error) {
    console.log("Error!!");
  }
};

export const handleClaimBadge = (data) => async (dispatch) => {
  const result = await claimBadge(data);
  try {
    return result;
  } catch (error) {
    console.log("Error!!");
  }
};

export const handleUserEarning = (data) => async (dispatch) => {
  const result = await userEarning(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleUserSpent = (data) => async (dispatch) => {
  const result = await userSpending(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchMyBlock = (data) => async (dispatch) => {
  const result = await fetchMyBlocks(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// handleFetchMemberBlock
export const handleFetchMemberBlock = (data) => async (dispatch) => {
  const result = await fetchMemberBlocks(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const handleGetProfileAnalytics = (data) => async (dispatch) => {
  const result = await getUserAnalytics(data);
  console.log(result);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateTimelinePrivacy = (data) => async (dispatch) => {
  const userData = await handleUpdateTimelinePrivacy(data);
  // console.log("*************", userData);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateProfileDetails = (data) => async (dispatch) => {
  const userData = await handleUpdateProfileDetails(data);
  // console.log("*************", userData);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateCoinDetails = (data) => async (dispatch) => {
  const userData = await handleUpdateCoinDetails(data);
  // console.log("*************", userData);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const updateFlwrListDetails = (data) => async (dispatch) => {
  const userData = await handleUpdateFlwrListDetails(data);
  // console.log("*************", userData);
  try {
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

/**
 *
 * @param {} data
 * @returns
 * Users privacy settings
 */

export const updateUserPrivacySettings = (data, token) => async (dispatch) => {
  try {
    //
    console.log("slice privacy", data);
    const userData = await updateUserPrivacySettingsApi(data, token);
    console.log("************* response data", userData);
    dispatch(userUpdateSuccess(userData));
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const getEarningFeed = (data) => async (dispatch) => {
  const result = await handleGetEarningFeed(data);

  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getEarning = (data) => async (dispatch) => {
  const result = await handleGetEarning(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getAnalyticsData = (data) => async (dispatch) => {
  const result = await handleGetAnalyticsData(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateUpdateLinkPrivacy = (data) => async (dispatch) => {
  const result = await handleUpdateUpdateLinkPrivacy(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateUpdateProfileLockPrivacy = (data) => async (dispatch) => {
  const result = await handleUpdateUpdateProfileLockPrivacy(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateReelsPrivacy = (data) => async (dispatch) => {
  const result = await handleUpdateReelsPrivacy(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateMentionsPostsPrivacy = (data) => async (dispatch) => {
  const result = await handleUpdateMentionsPostsPrivacy(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updatePostsPrivacy = (data) => async (dispatch) => {
  const result = await handleUpdatePostsPrivacy(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const getnerateWallet = (data) => async (dispatch) => {
  const result = await handleGenerateWallet(data);
  try {
    // return result;
    return result;
    dispatch(userUpdateSuccess(result.user));
  } catch (error) {
    console.log(error);
  }
  console.log("came here");
};

export const getSubscriptionData = (data) => async (dispatch) => {
  const result = await handlegetSubscriptionData(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const updateSocialAccount = (data) => async (dispatch) => {
  const result = await handleUpdateSocialAccount(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const fetchPopulateUsers = (data) => async (dispatch) => {
  const result = await handleFetchPopulateUsers(data);
  try {
    return result;
  } catch (error) {
    console.log(error);
  }
};

// updateWallet
export const updateWallet = (data) => async (dispatch) => {
  console.log("data", data);
  try {
    const result = await handleUpdateWallet(data);
    dispatch(setWalletAddress(data.walletAddress));
    return result;
  } catch (error) {
    console.log(error);
  }
};

export const setLogout = (user) => async (dispatch) => {
  try {
    const response = await logoutUser(user);
    if (response) {
      dispatch(logout());
    }
    dispatch(logout());
  } catch (error) {
    console.log(error);
  }
};

//common update handler
export const updateUserDetails = (key, data) => {
  console.log("called 3");
  return async (dispatch) => {
    try {
      console.log("data from userSlice", data);
      // const userData = await updateUserProfile(key, data);
      let userData;

      if (key === "gender") {
        userData = await handleUpdateGender(data);
        console.log("update gender :", userData);
      } else if (key === "language") {
        console.log("called again 5", data);
        userData = await handleUpdateLanguage(data);
        console.log("update language :", userData);
      } else if (key === "name") {
        userData = await handleUpdateDisplayName(data);
        console.log("update name :", userData);
      } else if (key === "bio") {
        userData = await handleUpdateBio(data);
        console.log("update bio :", userData);
      } else if (key === "password") {
        console.log("called again 4");
        userData = await handleUpdatePassword(data);
        console.log("update password :", userData);
      }
      dispatch(userUpdateSuccess(userData));
    } catch (error) {
      console.log("Error", error);
      console.log(`Invalid key: ${key}`);
    }
  };
};

export default userSlice.reducer;
