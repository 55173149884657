
const ActionTypes = {
  SAVE_MESSAGE: 'SAVE_MESSAGE',
  REMOVE_MESSAGE: 'REMOVE_MESSAGE',
  DELETE_MESSAGE: "DELETE_MESSAGE",
  LIKE_MESSAGE: "LIKE_MESSAGE",
  REMOVE_LIKE_MESSAGE: "REMOVE_LIKE_MESSAGE",
  UPDATE_MESSAGE: "UPDATE_MESSAGE"
};

export default ActionTypes;