/** @format */

import React from "react";
import { FaRegHeart, FaBan } from "react-icons/fa";
import { BiCommentDetail } from "react-icons/bi";
import { AiOutlineLike, AiOutlineStar } from "react-icons/ai";
const NewsPost = ({ postData }) => {
  return (
    <div>
      <div className='post_footer_count_section'>
        <div className='post_count'>
          <span className='count_text'>Reliable: </span>
          <span className='card_count'>{postData.relib_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Comments: </span>
          <span className='card_count'>{postData.c_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Interesting: </span>
          <span className='card_count'>{postData.intrst_c || 0}</span>
        </div>

        <div className='post_count'>
          <span className='count_text'>Fake: </span>
          <span className='card_count'>{postData.fake_c || 0}</span>
        </div>
      </div>

      <div className='social_post_footer_section'>
        <button className='news_post_btn' id='reliable'>
          <FaRegHeart className={""} id='reliable' />
        </button>

        <button id='comment' className='post_footer_btn post_comment_btn'>
          <BiCommentDetail
            id='comment'
            className='post_card_footer_icon cmnt_footer_icon'
          />{" "}
        </button>

        <button className='news_post_btn' id='interesting'>
          <AiOutlineLike className={""} id='interesting' />
        </button>

        <button className='news_post_btn' id='fake'>
          <FaBan className='' id='fake' />
        </button>

        <button id='donate' className='post_footer_btn post_share_btn'>
          <AiOutlineStar className='' id='donate' />
        </button>
      </div>
    </div>
  );
};

export default NewsPost;
