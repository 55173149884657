/** @format */

import { createSlice } from "@reduxjs/toolkit";

const profileUpdateHandlerSlice = createSlice({
  name: "profileUpdateHandler",
  initialState: {
    openNameModal: false,
    isDisable: true,
    isDisableBio: true,
    name: "",
    bio: "",
  },
  reducers: {
    setOpenNameModal: (state, action) => {
      state.openNameModal = action.payload;
    },
    setIsDisable: (state, action) => {
      state.isDisable = action.payload;
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setBio: (state, action) => {
      state.bio = action.payload;
    },
    setIsDisableBio: (state, action) => {
      state.isDisableBio = action.payload;
    },
  },
});

export const {
  setOpenNameModal,
  setIsDisable,
  setName,
  setBio,
  setIsDisableBio,
} = profileUpdateHandlerSlice.actions;

export default profileUpdateHandlerSlice.reducer;
