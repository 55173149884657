// LoadingOverlay.js
import React from "react";
import "./LoadingOverlay.css";

const LoadingOverlay = ({ isLoading }) => {
  if (!isLoading) {
    return null;
  }

  return (
    <div className="icon-loading-overlay">
      <div className="el_spinner"></div>
    </div>
  );
};

export default LoadingOverlay;
