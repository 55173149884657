import React from 'react';
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from 'react-router';

const BlockEditHeader = ({ title }) => {
  const navigate = useNavigate();

  const backBtn = () => {
    navigate(-1)
  }

React.useEffect(() => {
  const currentURL = window.location.href;
  console.log("Current URL:", currentURL);
  const savedUrl = localStorage.getItem("currenturl");
  console.log("Save URL:", savedUrl);
  if (currentURL === savedUrl) {
    console.log("SAME");
  } else {
    console.log("OTHER");
    localStorage.setItem("currenturl", currentURL);
  }
}, []);



  return (
    <div className='trending_header'>
      <button className='header_back_btn' onClick={backBtn}>
        <BiArrowBack />
      </button>
      <span className='header_title'>{title}</span>
    </div>
  )
}

export default BlockEditHeader