/** @format */

import React from "react";
const MainLoading = () => {
  return (
    <div className='main_loader'>
      {/* <LogoIcon className='LOADER_LOGO' /> */}
      <span className='icon icon-d_icon LOADER_LOGO'></span>
      <br />
      {/* <span className='main_spinner'></span> */}
    </div>
  );
};

export default MainLoading;
