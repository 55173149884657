/** @format */

import React from "react";
import { ReactComponent as Logo } from "../../Assets/Icons/logo.svg";
import { useNavigate } from "react-router-dom";

const LeftSideBarWithOutToken = () => {
  const navigate = useNavigate();
  return (
    <div className='left_side_navbar_without_token'>
      <div className='nav_logo_icon'>
        <Logo className='logo_icon' />
      </div>

      <div className='navbar_login_section'>
        <button
          className='login_redirect_btn'
          onClick={() => navigate("/login")}>
          Login
        </button>
        <br />
        <button
          className='register_redirect_btn'
          onClick={() => navigate("/register")}>
          Register
        </button>
      </div>
    </div>
  );
};

export default LeftSideBarWithOutToken;
