/** @format */

function getPricePercentage(data) {
  const current_price = data[1][1];
  const previous_price = data[0][1];

  const percentageChange =
    ((current_price - previous_price) / previous_price) * 100;

  return Number(percentageChange.toFixed(2));
  // console.log(data);
}

export default getPricePercentage;
