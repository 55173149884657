/** @format */

import React from "react";
import { useTranslation } from "react-i18next";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import "./EarnCenterHeader.css";
import { useNavigate, NavLink } from "react-router-dom";
import { BiDollar } from "react-icons/bi";
import { MdClose } from "react-icons/md";
import { IoCloseCircleOutline } from "react-icons/io5";

const EarnCenterHeader = () => {
  const navigate = useNavigate();
  const { t } = useTranslation(["common"]);

  const backBtn = () => {
    navigate("/");
  };
  return (
    <div className='earn_center_header_center'>
      {/*  */}
      <div className='earn_center_box'>
        <BiDollar className='earn_center_icon' />
        <span className='earn_center_title'>{t("Earn Center")}</span>
      </div>

      <button className='header_close_btn' onClick={backBtn}>
        <IoCloseCircleOutline />
      </button>
    </div>
  );
};
export default EarnCenterHeader;
