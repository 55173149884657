/** @format */

import React from "react";

const BlockCommentLoading = () => {
  return (
    <div>
      <div className='comment_card_loader'>
        <div className='block_cmnt_card_header_loader'>
          <div className='loader_block_avatar skeleton_color'></div>
          <div className='loader_block_cmnt_user_box'>
            <div className='block_user_cmnt_name skeleton_color'></div>
            <div className='block_user_cmnt_username skeleton_color'></div>
          </div>
        </div>
        <div className='loader_cmnt_block_body skeleton_color'></div>
      </div>

      <div className='comment_card_loader'>
        <div className='block_cmnt_card_header_loader'>
          <div className='loader_block_avatar skeleton_color'></div>
          <div className='loader_block_cmnt_user_box'>
            <div className='block_user_cmnt_name skeleton_color'></div>
            <div className='block_user_cmnt_username skeleton_color'></div>
          </div>
        </div>
        <div className='loader_cmnt_block_body skeleton_color'></div>
      </div>

      <div className='comment_card_loader'>
        <div className='block_cmnt_card_header_loader'>
          <div className='loader_block_avatar skeleton_color'></div>
          <div className='loader_block_cmnt_user_box'>
            <div className='block_user_cmnt_name skeleton_color'></div>
            <div className='block_user_cmnt_username skeleton_color'></div>
          </div>
        </div>
        <div className='loader_cmnt_block_body skeleton_color'></div>
      </div>

      <div className='comment_card_loader'>
        <div className='block_cmnt_card_header_loader'>
          <div className='loader_block_avatar skeleton_color'></div>
          <div className='loader_block_cmnt_user_box'>
            <div className='block_user_cmnt_name skeleton_color'></div>
            <div className='block_user_cmnt_username skeleton_color'></div>
          </div>
        </div>
        <div className='loader_cmnt_block_body skeleton_color'></div>
      </div>
    </div>
  );
};

export default BlockCommentLoading;
