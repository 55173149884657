/** @format */

import React, { useState, useEffect } from "react";
import CustomModal from "../modal/CustomModal";
import CustomPostForm from "../modal/CustomPostForm";
import { useDispatch, useSelector } from "react-redux";
import {
  selectToken,
  selectUser,
  selectReport,
} from "../../redux/_user/userSelectors";
import { useTranslation } from "react-i18next";
import { ImSpinner2 } from "react-icons/im";
import {
  handlePinnedBlockPost,
  handleBookmarkBlockPost,
  handleDeleteBlockPost,
  handleEditBlockPost,
  fetchSinglePostData,
  handleSpamBlockPost,
  handleLikeBlockPost,
  handleFetchLikedUser,
  handleCreateComment,
  prepandComment,
  handleFetchComments,
  setRemoveComments,
  reportPost,
  updateDonatePost,
} from "../../redux/_blockPost/blockPostSlice";
import { ReportConfig } from "../../config/Report.js";
import { BiRadioCircle, BiRadioCircleMarked } from "react-icons/bi";
import { BiArrowBack } from "react-icons/bi";
import UserAvatar from "../../Assets/userAvatar.webp";
import timeDifference from "../../utils/getCreateTime";
import getPricePercentage from "../../utils/getPricePercentage";
import { FiMoreHorizontal } from "react-icons/fi";
import { Menu, MenuItem, MenuButton, SubMenu } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import { Link, useNavigate } from "react-router-dom";
import FullNormalPostFooter from "./FullNormalPostFooter";
import { AiOutlineClose, AiOutlineSend } from "react-icons/ai";
import CommentCard from "../Group.Post.Comment/CommentCard";
import BlockCommentLoading from "../SkeletonLoading/BlockCommentLoading";
import {
  selectcurrentCommentCount,
  selectComments,
} from "../../redux/_blockPost/blockPostSelectors";

const PostModalComponent = ({
  type,
  postId,
  // Pin model state
  openPinModal,
  setOpenPinModal,
  isPin,
  setIsPin,
  // Delete post state
  setDeletePost,
  setOpenDeleteModal,
  // Post bookmark state
  setOpenBookmarkModal,
  setBookmark,
  bookmark,
  // post edit state
  setUpdateContent,
  setOpenEditModal,
  updateContent,
  isDisableEditBtn,
  setIsDisableEditBtn,
  setContent,
  // post report state
  setOpenReportModal,
  // full post state
  setOpenFullpostModal,
  openFullpostModal,
  reposted,
  setReposted,
  postData,
  flag,
  setFlag,
}) => {
  const { t } = useTranslation(["common"]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const comments = useSelector(selectComments);
  const user = useSelector(selectUser);
  const report = useSelector(selectReport);
  const token = useSelector(selectToken);
  const [isBtnLoading, setIsBtnLoading] = useState(false);
  const [postReport, setPostReport] = useState("");
  const [loading, setLoading] = useState(false);
  const [post, setPost] = useState(null);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [cmntCount, setCmntCount] = useState(10);
  const [coinDetails, setCoinDetails] = React.useState([]);
  const [prevImage, setPrevImage] = React.useState("");
  const [image, setImage] = React.useState("");
  const [searchCoinList, setSearchCoinList] = React.useState([]);
  const [coinpage, setCoinPage] = React.useState(1);
  const [coinlimit, setCoinLimit] = React.useState(20);
  const [coinCount, setCoinCount] = React.useState(20);
  const [coinLoading, setCoinLoading] = React.useState(false);
  const [openCryptoList, setOpenCryptoList] = React.useState(false);
  const [text, setText] = React.useState("");
  const [openEmojiIcons, setOpenEmojiIcons] = React.useState(false);
  const [isBtnDisable, setIsBtnDisable] = React.useState(true);
  const [isCommentsLoading, setIsCommentLoading] = React.useState(false);
  const [sortedBy, setSortedBy] = React.useState("new");
  const [prevSortedBy, setprevSortedBy] = React.useState("new");
  const [mentionCoinCurrentPrice, setMentionCoinCurrentPrice] =
    React.useState("");
  const [mentionCoinId, setMentionCoinId] = React.useState("");
  const currentCommentCount = useSelector(selectcurrentCommentCount);

  const getModalProps = (type) => {
    // *** Handle update Group post Pinned status
    const handlePinnedPost = async () => {
      setIsBtnLoading(true);
      const data = { postId, token };
      const result = await dispatch(handlePinnedBlockPost(data));
      console.log(result);
      setIsPin((previous) => !previous);
      setIsBtnLoading(false);
      setOpenPinModal(false);
    };

    // *** Handle delete post
    const handleDeletePost = async () => {
      const data = { postId, token };
      const result = await dispatch(handleDeleteBlockPost(data));
      setDeletePost(true);
      setOpenDeleteModal(false);
    };

    // *** Handle Bookmark post
    const handleSavePost = async () => {
      const data = { postId, token };
      const result = await dispatch(handleBookmarkBlockPost(data));
      if (bookmark.includes(user.handleUn)) {
        const arr = bookmark;
        let temp = arr.filter((data) => data !== user.handleUn);
        setBookmark(temp);
      } else {
        setBookmark((prev) => [...prev, user.handleUn]);
      }
      setOpenBookmarkModal(false);
    };

    // *** Handle Eit post
    const handleEdit = async () => {
      setIsBtnLoading(true);
      const data = { token, content: updateContent, postId };
      const result = await dispatch(handleEditBlockPost(data));

      setIsBtnLoading(false);
      setIsDisableEditBtn(true);
      setContent(updateContent);
      setOpenEditModal(false);
    };

    const handleAddReport = (data) => {
      if (!postReport) {
        setPostReport(data.id);
      } else {
        if (data.id === postReport) {
          setPostReport("");
        } else {
          setPostReport(data.id);
        }
      }
    };

    // *** Handle report post
    const handleReportPost = async () => {
      const data = { token, value: postReport, id: postId };
      console.log(data);
      const result = await dispatch(reportPost(data));
      console.log(result);
      setPostReport("");
      setOpenReportModal(false);
    };

    // *** Fetch full group post
    async function fetchFullPost() {
      setLoading(true);
      const data = { page, limit, token, postId };
      const result = await dispatch(fetchSinglePostData(data));
      // console.log(result);
      setPost(result);
      setLoading(false);
    }

    useEffect(() => {
      // setLoading(true);
      if (openFullpostModal) {
        fetchFullPost();
      } else {
        console.log("Not open");
      }
    }, [postId, openFullpostModal]);

    React.useEffect(() => {
      if (!text.trim()) {
        setIsBtnDisable(true);
      } else {
        setIsBtnDisable(false);
      }
    }, [text, prevImage]);

    // React.useEffect(() => {
    //   if (postData.coin_id.length > 0) {
    //     postData.coin_id.map((id) => {
    //       fetchChartData(id);
    //     });
    //   }
    // }, [postData.id]);

    // const fetchChartData = async (id) => {
    //   // setGraphLoading(true);
    //   const data = {
    //     id: id,
    //     timeSpan: "2",
    //   };
    //   const result = await dispatch(getCoinPrices(data));
    //   console.log(result);
    //   setCoinDetails((prev) => [...prev, { id, price: result }]);
    //   // setCoinPrices(result);
    // };

    const closeCmntImage = () => {
      setPrevImage("");
      setImage;
      ("");
    };

    const fetchCoins = async () => {
      setCoinLoading(true);
      const data = { page: coinpage, limit: coinlimit };
      const result = await dispatch(getCoinList(data));
      setCoinCount(result.length);
      if (coinpage === 1) {
        setSearchCoinList(result);
      } else {
        setSearchCoinList((prev) => [...prev, ...result]);
      }
      setCoinLoading(false);
    };
    React.useEffect(() => {
      if (openCryptoList) {
        fetchCoins();
      }
    }, [openCryptoList, coinpage]);

    function handleScrollToFetchCoins(e) {
      let cl = e.currentTarget.clientHeight;
      let sy = Math.round(e.currentTarget.scrollTop);
      let sh = e.currentTarget.scrollHeight;
      if (cl + sy + 1 >= sh) {
        if (coinCount !== 0) {
          setCoinPage((prev) => prev + 1);
        }
      }
    }

    const handleKeyChange = (e) => {
      if (e.keyCode === 32 || e.keyCode === 8) {
        setOpenCryptoList(false);
      } else if (e.keyCode === 50) {
        setOpenCryptoList(false);
      } else if (e.keyCode === 52) {
        setOpenCryptoList(true);
      }
    };

    const handleImageChange = (e) => {
      setPrevImage(URL.createObjectURL(e.target.files[0]));
      setImage(e.target.files[0]);
    };

    const fetchComments = async () => {
      if (page == 1) {
        setIsCommentLoading(true);
      }
      const data = { postId, token, page, limit, sortedBy };
      const result = await dispatch(handleFetchComments(data));
      setCmntCount(result.length);
      setIsCommentLoading(false);
      setBtnLoading(false);
    };

    React.useEffect(() => {
      if (openFullpostModal) {
        fetchComments(postId, sortedBy);
      }
    }, [page, postId, sortedBy, openFullpostModal]);

    const handleSelectMentionCoin = (data) => {
      setMentionCoinId(data.id);
      setMentionCoinCurrentPrice(data.current_price);
      setMentionCoin(data.name);
      const arr = text.split(" ");
      const temp = arr.filter((data) => !data.includes("$"));
      setText(temp.join(" "));

      setText((prev) => prev + " $" + data.name + " ");
      setOpenCryptoList(false);
    };

    const handleCommentsubmit = async () => {
      setIsBtnDisable(true);
      // alert("Comment");
      const data = {
        token,
        postId,
        post: {
          cmnt_img: image,
          text: text,
          mentionCoinCurrentPrice: mentionCoinCurrentPrice,
          mentionCoinId: mentionCoinId,
        },
      };
      const result = await dispatch(handleCreateComment(data));
      console.log(result.comment.bins);
      if (result.notificationData) {
        socket.emit("notification receive", result);
      }
      setCmntCount((p) => p + 1);
      dispatch(prepandComment(result.comment.bins));
      setPrevImage("");
      setImage("");
      setText("");
      setIsBtnLoading(false);
      setIsVisble(false);
    };

    switch (type) {
      case "pin":
        return (
          <CustomModal
            onClose={() => setOpenPinModal(false)}
            title={t("Pinned post")}
            body={
              isPin ? (
                <>{t("Do you want to unpinned this post?")}</>
              ) : (
                <>{t("Do you want to pinned this post?")}</>
              )
            }
            footer={
              <div>
                <button className='update_btn' onClick={handlePinnedPost}>
                  {isBtnLoading ? (
                    <ImSpinner2 className='spinner' />
                  ) : (
                    <>{isPin ? <>{t("Unpinn")}</> : <>{t("Pinn")}</>}</>
                  )}
                </button>
              </div>
            }
          />
        );
      case "delete":
        return (
          <CustomModal
            onClose={() => setOpenDeleteModal(false)}
            title={t("Delete post")}
            body={<>{t("Do you want to delete this post?")}</>}
            footer={
              <div>
                <button className='update_btn' onClick={handleDeletePost}>
                  {isBtnLoading ? (
                    <ImSpinner2 className='spinner' />
                  ) : (
                    <>{t("Delete")}</>
                  )}
                </button>
              </div>
            }
          />
        );
      case "bookmark":
        return (
          <CustomModal
            onClose={() => setOpenBookmarkModal(false)}
            title={
              bookmark.includes(user.handleUn) ? (
                <>{t("Remove post")}</>
              ) : (
                <>{t("Bookmark post")}</>
              )
            }
            body={
              bookmark.includes(user.handleUn) ? (
                <>{t("Do you want to remove this post?")}</>
              ) : (
                <>{t("Do you want to save this post?")}</>
              )
            }
            footer={
              <div>
                <button className='update_btn' onClick={handleSavePost}>
                  {isBtnLoading ? (
                    <ImSpinner2 className='spinner' />
                  ) : (
                    <>
                      {bookmark.includes(user.handleUn) ? (
                        <>{t("Remove")}</>
                      ) : (
                        <>{t("Bookmark")}</>
                      )}
                    </>
                  )}
                </button>
              </div>
            }
          />
        );
      case "edit":
        return (
          <CustomModal
            onClose={() => setOpenEditModal(false)}
            title={t("Edit post")}
            body={
              <div className='group_post_modal_body'>
                <textarea
                  type='text'
                  className='group_post_modal_textarea'
                  placeholder='Update post content'
                  value={updateContent}
                  onChange={(e) =>
                    setUpdateContent(e.target.value.slice(0, 420))
                  }></textarea>
              </div>
            }
            footer={
              <div>
                {isDisableEditBtn ? (
                  <button className='modal_edit_disable_update_btn'>
                    <>{t("Update")}</>
                  </button>
                ) : (
                  <button
                    className='modal_edit_update_btn'
                    onClick={handleEdit}>
                    {isBtnLoading ? (
                      <ImSpinner2 className='spinner' />
                    ) : (
                      <>{t("Update")}</>
                    )}
                  </button>
                )}
              </div>
            }
          />
        );
      case "report":
        return (
          <CustomPostForm
            title={
              <div className='comment_modal_title_section'>
                <div className='cmnt_header_box'>
                  <button
                    className='cmnt_modal_back_btn'
                    onClick={() => setOpenReportModal(false)}>
                    <BiArrowBack />
                  </button>
                  <span className='group_post_modal_title'>Report post</span>
                </div>
              </div>
            }
            body={
              <div className='group_post_report_card_body'>
                {Object.keys(ReportConfig).map((data, index) => (
                  <div
                    className='post_report_card'
                    key={index}
                    onClick={() => handleAddReport(ReportConfig[data])}>
                    <div className='radio_section'>
                      {postReport === ReportConfig[data].id ? (
                        <BiRadioCircleMarked className='radio_active' />
                      ) : (
                        <BiRadioCircle />
                      )}
                    </div>
                    <div className='post_report_card_info_section'>
                      <div className='post_card_report_title'>
                        {ReportConfig[data].title}
                      </div>
                      <div className='post_card_report_details'>
                        {ReportConfig[data].details}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            }
            footer={
              <div className='group_post_report_card_footer'>
                {postReport ? (
                  <button
                    className='group_post_card_footer_btn'
                    onClick={handleReportPost}>
                    Submit
                  </button>
                ) : (
                  <button className='group_post_card_footer_btn_disable'>
                    Submit
                  </button>
                )}
              </div>
            }
          />
        );
      case "full-post":
        return (
          <CustomPostForm
            title={
              <div className='comment_modal_title_section'>
                <div className='cmnt_header_box'>
                  <button
                    className='cmnt_modal_back_btn'
                    onClick={() => setOpenFullpostModal(false)}>
                    <BiArrowBack />
                  </button>
                  <span className='group_post_modal_title'>View full post</span>
                </div>
              </div>
            }
            body={
              <div>
                {loading ? (
                  <div className='full_post_loader_section'>
                    <span className='loader'></span>
                  </div>
                ) : (
                  <>
                    {post ? (
                      <div className='group_post_card full_group_post_card'>
                        {reposted.includes(user.handleUn) && (
                          <>
                            <BiRepost /> <span>Reposted</span>
                          </>
                        )}
                        {/* Show post PIN icon */}
                        {post.pinned > 0 && (
                          <span className='group_post_pinn_icon'>
                            <BsFillPinAngleFill />
                          </span>
                        )}

                        {/* Header Seaction */}
                        <div className='group_post_header_section'>
                          <div className='group_post_card_user_info'>
                            {/* Avatar */}
                            <img
                              src={
                                post.u_img
                                  ? `${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${post.u_img}`
                                  : UserAvatar
                              }
                              className='group_post_card_avatar'
                              id='profile'
                            />

                            <div className='group_post_info_box'>
                              {/* Block name */}
                              <Link
                                to={`/group/${post.group_id}`}
                                className='post_card_block_name'>
                                {post.g_n}
                              </Link>

                              {/* Group post card Name */}
                              <div className='post_card_user_section'>
                                <Link
                                  to={`/user/profile/${post.u_dun}`}
                                  className='upost_card_user_username'>
                                  {post.u_fn} {post.u_ln}
                                </Link>
                                <span className='post_card_username'>
                                  @{post.g_c_dun}
                                </span>
                              </div>

                              {/* Group post time */}
                              <span className='group_post_card_time'>
                                {timeDifference(
                                  new Date().getTime(),
                                  Number(post.timestamp)
                                )}
                              </span>
                            </div>
                          </div>

                          {/* Post card Menu section */}
                          {!post.is_share && (
                            <Menu
                              menuButton={
                                <MenuButton
                                  className={"group_post_card_menu_btn"}>
                                  <FiMoreHorizontal />
                                </MenuButton>
                              }>
                              {/* Pin Post menu */}
                              {user.handleUn === post.g_c_dun && (
                                <MenuItem
                                  className={"group_post_card_menuItem"}
                                  onClick={() => setOpenPinModal(true)}>
                                  {isPin ? <>{t("Unpin")}</> : <>{t("Pin")}</>}
                                </MenuItem>
                              )}

                              {/* Bookmark post menu */}
                              {/* <MenuItem
                                  className={"group_post_card_menuItem"}
                                  onClick={() => setOpenBookmarkModal(true)}>
                                  {bookmark.includes(user.handleUn) ? (
                                    <>{t("Remove")}</>
                                  ) : (
                                    <>{t("bookmark")}</>
                                  )}
                                </MenuItem> */}

                              {/* Edit Post menu */}
                              {user.handleUn === post.g_c_dun && (
                                <MenuItem
                                  className={"group_post_card_menuItem"}
                                  onClick={() => handlePostEditModal(post)}>
                                  {t("Edit")}
                                </MenuItem>
                              )}

                              {/* Analytics Page menu */}
                              <MenuItem className={"group_post_card_menuItem"}>
                                {t("Analytics")}
                              </MenuItem>

                              {/* Delete Post menu */}
                              {user.handleUn === post.g_c_dun && (
                                <MenuItem
                                  onClick={() => setOpenDeleteModal(true)}
                                  className={
                                    "group_post_card_menuItem delete_menu_item"
                                  }>
                                  {t("Delete")}
                                </MenuItem>
                              )}

                              {/* Embed Page menu */}
                              <MenuItem
                                className={"group_post_card_menuItem"}
                                onClick={() =>
                                  handleRedirectToEmbedPage(post.p_id)
                                }>
                                {t("Embed")}
                              </MenuItem>

                              {/* Report page menu */}
                              {post.u_id !== user.u_id && (
                                <MenuItem
                                  className={"group_post_card_menuItem"}
                                  onClick={() => setOpenReportModal(true)}>
                                  {t("Report")}
                                </MenuItem>
                              )}
                            </Menu>
                          )}
                        </div>

                        {/* Body Section */}
                        <div className='group_post_body_section'>
                          <span className='block_post_content'>
                            {post.content.split(" ").map((val, index) => (
                              <span
                                id='post_body'
                                key={index}
                                className={
                                  val.includes("#") ? "trend_tag" : ""
                                }>
                                {val.includes("@") ? (
                                  <Link
                                    to={`/user/profile/${checkMention(val)}`}
                                    className='mentions'>
                                    {" "}
                                    @{checkMention(val)}
                                  </Link>
                                ) : (
                                  <>
                                    {val.includes("$") ? (
                                      <Link
                                        to={`trending/posts/coin/${checkCryptoMention(
                                          val
                                        )}`}
                                        className='crypto_mentions'>
                                        {" "}
                                        ${checkCryptoMention(val)}
                                      </Link>
                                    ) : (
                                      <>{val} </>
                                    )}
                                  </>
                                )}
                              </span>
                            ))}
                          </span>
                          {post.image && (
                            <img
                              src={`${process.env.REACT_APP_GOOGLE_CLOUD_IMAGE_LINK}${post.image}`}
                              className='block_post_image'
                            />
                          )}
                        </div>
                        {(coinDetails || []).length > 0 && (
                          <div className='_coin_obj_section'>
                            {coinDetails.map((data) => (
                              <>
                                {getPricePercentage(data.price) > 0 ? (
                                  <div className='_coin_price_btn high_btn'>
                                    <span className='coin_symbol high'>
                                      {data.id.toUpperCase()}
                                    </span>
                                    <span className='_coin_price high'>
                                      {getPricePercentage(data.price)}%
                                    </span>
                                  </div>
                                ) : (
                                  <div className='_coin_price_btn low_btn'>
                                    <span className='coin_symbol low'>
                                      {data.id.toUpperCase()}
                                    </span>
                                    <span className='_coin_price low'>
                                      {getPricePercentage(data.price)}%
                                    </span>
                                  </div>
                                )}
                              </>
                            ))}
                          </div>
                        )}

                        {/* Card Footer section */}
                        {post.postOf === "news" ? (
                          <FullNewsPostFooter
                            postData={post}
                            reposted={reposted}
                            setReposted={setReposted}
                          />
                        ) : (
                          <>
                            {post.postOf === "anc" ? (
                              <FullAnnouncementPostFooter
                                postData={post}
                                reposted={reposted}
                                setReposted={setReposted}
                              />
                            ) : (
                              <>
                                {post.postOf === "np" ? (
                                  <FullNormalPostFooter
                                    postData={post}
                                    reposted={reposted}
                                    setReposted={setReposted}
                                  />
                                ) : null}
                              </>
                            )}
                          </>
                        )}

                        {flag ? (
                          <div className='gp_flag_status_text'>
                            You marked this post as flag so you cannot comment
                            on this post
                          </div>
                        ) : (
                          <div className='comment_form_section'>
                            {prevImage && (
                              <div className='prev_image_section'>
                                <img
                                  src={prevImage}
                                  className='prev_image_box'
                                />
                                <button
                                  className='close_btn_prev'
                                  onClick={closeCmntImage}>
                                  <AiOutlineClose />
                                </button>
                              </div>
                            )}

                            {/* Show crypto coins */}
                            {openCryptoList && (
                              <div
                                className='full_post_group_crypto_list'
                                onScroll={(e) => handleScrollToFetchCoins(e)}>
                                {coinLoading ? (
                                  <div className='loader_section'>
                                    <span className='loader'></span>
                                  </div>
                                ) : (
                                  <>
                                    {(searchCoinList || []).length > 0 ? (
                                      <React.Fragment>
                                        {searchCoinList.map((data) => (
                                          <div
                                            className='mention_user_card'
                                            onClick={() =>
                                              handleSelectMentionCoin(data)
                                            }>
                                            <img
                                              src={`https://assets.coingecko.com/coins/images/${data.image}`}
                                              className='user_small_avatar'
                                            />

                                            <span className='user_list_name'>
                                              {data.name}
                                            </span>
                                          </div>
                                        ))}
                                      </React.Fragment>
                                    ) : (
                                      <div>No user found</div>
                                    )}
                                  </>
                                )}
                              </div>
                            )}
                            <input
                              type='text'
                              placeholder='Enter your comment'
                              className='comment_input'
                              value={text}
                              onKeyDown={(e) => handleKeyChange(e)}
                              onChange={(e) =>
                                setText(e.target.value.slice(0, 100))
                              }
                            />

                            <div className='post_footer_btn_sections'>
                              <div>
                                {/* Gallery */}
                                <label
                                  htmlFor='post_comment_file'
                                  className='modal_file_icon'>
                                  <span class='icon-gallery'></span>
                                </label>
                                <input
                                  type='file'
                                  id='post_comment_file'
                                  className='input_file'
                                  onChange={(e) => handleImageChange(e)}
                                />

                                {/* Emoji */}
                                <button
                                  className='modal_post_icons_button'
                                  onClick={() => setOpenEmojiIcons(true)}>
                                  <span class='icon-emogy'></span>
                                </button>

                                {/* Gif */}
                                <button className='modal_post_icons_button'>
                                  <span class='icon-gif'></span>
                                </button>
                              </div>
                              {isBtnDisable ? null : (
                                <button
                                  className='send_cmn_btn'
                                  onClick={() => handleCommentsubmit()}>
                                  <AiOutlineSend
                                    className={
                                      isBtnDisable ? "disable" : "not_disable"
                                    }
                                  />
                                </button>
                              )}
                            </div>
                            {/* RederingPost Comment */}
                            {isCommentsLoading ? (
                              <BlockCommentLoading />
                            ) : (
                              <>
                                {(comments || []).length > 0 ? (
                                  <>
                                    {comments.map((data) => (
                                      <CommentCard
                                        key={data.c_id}
                                        commentData={data}
                                      />
                                    ))}
                                    {currentCommentCount === limit && (
                                      <div className='comment_load_more_btn_section'>
                                        <button onClick={handleIncrementPage}>
                                          {btnLoading ? (
                                            <ImSpinner2 className='btn_loader' />
                                          ) : (
                                            <>Load more</>
                                          )}
                                        </button>
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <div className='empty_block_modal_comment'>
                                    No comment found
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      </div>
                    ) : (
                      <div className='empty_post_modal'>
                        {t("No post found")}
                      </div>
                    )}
                  </>
                )}
              </div>
            }
          />
        );
    }
  };
  return getModalProps(type);
};

export default PostModalComponent;
