/** @format */

import React from "react";
import UserAvatar from "../../Assets/userAvatar.webp";
import { useNavigate } from "react-router-dom";
import { BiLocationPlus, BiUserCheck, BiUserPlus } from "react-icons/bi";
import { BsFillPatchCheckFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import {
  selectFollowing,
  selectUser,
  selectToken,
} from "../../redux/_user/userSelectors";
import {
  setRemoveFollowing,
  setAddFollowing,
  handleFollowUser,
} from "../../redux/_user/userSlice";
import "./User.css";
import { useSocket, socket, isSocketConnected } from "../../socket/socket";

const TestUser = ({ userData }) => {
  useSocket();
  const dispatch = useDispatch();
  const following = useSelector(selectFollowing);
  const user = useSelector(selectUser);
  const isToken = useSelector(selectToken);
  const navigate = useNavigate();

  const handleFollow = async (userId) => {
    if (following.includes(userId)) {
      dispatch(setRemoveFollowing(userId));
    } else {
      dispatch(setAddFollowing(userId));
    }
    const data = { isToken, userId };
    const result = await dispatch(handleFollowUser(data));
    if (result.data.notificationData) {
      socket.emit("notification receive", result.data);
    }
  };
  return (
    <div className='user_list_card'>
      <div
        className='user_card_info_section'
        onClick={() =>
          navigate(`/user/profile/${userData.record.bins.handleUn}`)
        }>
        <img
          src={userData.record.bins.p_i || UserAvatar}
          alt=''
          srcset=''
          className='user_card_avatar'
        />
        <div>
          <span className='modal_user_name'>
            {userData.record.bins.fn} {userData.record.bins.ln}
          </span>
          {/* Profile badge */}
          {(userData.record.bins.badges || []).length > 0 && (
            <>
              {userData.record.bins.badges[
                userData.record.bins.badges.length - 1
              ] === "claim_sub" ? (
                <BsFillPatchCheckFill className='subscriber_badge' />
              ) : (
                <>
                  {userData.record.bins.badges[
                    userData.record.bins.badges.length - 1
                  ] === "claim_cc" ? (
                    <BsFillPatchCheckFill className='cc_badge' />
                  ) : userData.record.bins.badges[
                      userData.record.bins.badges.length - 1
                    ] === "claim_verify" ? (
                    <BsFillPatchCheckFill className='verify_badge' />
                  ) : null}
                </>
              )}
            </>
          )}
          <span className='modal_user_username'>
            @{userData.record.bins.handleUn}
          </span>
          <br />
          <div className='modal_user_username'>
            {userData.record.bins.p_bio}
          </div>
          <div className='modal_user_location'>
            <BiLocationPlus /> {userData.record.bins.city},
            {userData.record.bins.country}
          </div>
          <span className='user_card_follwers'>
            Followrs:{" "}
            <span className='user_card_followers_count'>
              {userData.record.bins.flwr_c}
            </span>
          </span>{" "}
          <span className='user_card_follwing'>
            Following:{" "}
            <span className='user_card_following_count'>
              {userData.record.bins.flw_c}
            </span>
          </span>
        </div>
      </div>

      {user.handleUn === userData.record.bins.handleUn ? null : (
        <button
          className='user_card_flwr_btn'
          onClick={() => handleFollow(userData.record.bins.handleUn)}>
          {" "}
          {following.includes(userData.record.bins.handleUn) ? (
            <>Followed</>
          ) : (
            <>Following</>
          )}
        </button>
      )}
    </div>
  );
};

export default TestUser;
