/** @format */
export function chatLoggedUser(group, loggedUser) {
  // console.log("**************");
  // console.log("GROUP:", group);
  // console.log("loggedUser", loggedUser);
  // console.log("**************");
  if (group?.user1?.handleUsername === loggedUser?.handleUn) {
    return group?.user2;
  } else {
    return group?.user1;
  }
}
