/** @format */
import { useLocation } from "react-router-dom";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../redux/_user/userSelectors";
import { ThemeContext } from "../components/Common/ThemeProvider";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { pageTypeSelector } from "../redux/_page/pageSelectors";
// Headers
import DefaultHeader from "../components/Headers/defaultHeader/DefaultHeader";
import EarnCenterHeader from "../components/Headers/EarnCenterHeader/EarnCenterHeader";
import TestHeader from "../components/Headers/socialHeader/TestHeader";
import ProfileHeader from "../components/Headers/ProfileHeader/ProfileHeader";
import SocialPagesHeader from "../components/Headers/SocialPagesHeader/SocialPagesHeader";
import BlockCastSingleChat from "../components/Headers/BlockCastSingleChat/BlockCastSingleChat";
import BlockPageHeader from "../components/Headers/BlockPageHeader/BlockPageHeader";

import FullPostHeader from "../components/Headers/FullPostHeader/FullPostHeader";
import FullEventHeader from "../components/Headers/FullEventHeader/FullEventHeader";
import BadgeHeader from "../components/Headers/BadgeHeader/BadgeHeader";

// LeftSideBars
import SocialLeftSideBar from "../components/LeftSideBars/socialLeftSidebar/SocialLeftSideBar";
import BlockLeftSideBar from "../components/LeftSideBars/BlockLeftSideBar/BlockLeftSideBar";
import BlockcastLeftsidebar from "../components/LeftSideBars/BlockcastLeftsidebar/BlockcastLeftsidebar";
import LeftSideBarWithOutToken from "../components/LeftSideBars/LeftSideBarWithOutToken";
import GroupLeftSidebar from "../components/LeftSideBars/GroupLeftSidebar/GroupLeftSidebar";

// RightSideBars
import DefaultRightSideBar from "../components/RightSideBar/DefaultRightSideBar/DefaultRightSideBar";
import SocialRightSideBar from "../components/RightSideBar/SocialRightSideBar/SocialRightSideBar";

// DownNavs
// import DefaultDownNav from "../components/DownNav/DefaultDownNav/DefaultDownNav";
import SocialDownNav from "../components/DownNav/SocialDownNav/SocialDownNav";
import EarningCenterDownNavbar from "../components/DownNav/EarningCenterDownNavbar/EarningCenterDownNavbar";
import BlockEditHeader from "../components/Headers/BlockEdit/BlockEditHeader";
import BlockCastDownNavbar from "../components/DownNav/BlockCastDownNavbar/BlockCastDownNavbar";
import BlockCastMainHeader from "../components/Headers/BlockCastMainHeader/BlockCastMainHeader";
import BlockDownNavbar from "../components/DownNav/BlockDownNav/BlockDownNavbar";
import NormalChatHeader from "../components/Headers/NormalChatHeader/NormalChatHeader";

import FullPost from "../components/FullPostWithoutToken/FullPost";

const Header = ({ goBack, menu, members, title }) => {
  const pageType = useSelector(pageTypeSelector);
  if (
    pageType == "social" ||
    pageType === "block" ||
    pageType === "blockcast"
  ) {
    // return <SocialHeader />
    return <TestHeader goBack={goBack} title={title} />;
  } else if (
    pageType === "trending" ||
    pageType === "post_analytics" ||
    pageType === "full_post_view" ||
    pageType === "block_create" ||
    pageType === "content_creator" ||
    pageType === "profile_edit" ||
    pageType === "menu" ||
    pageType === "settings" ||
    pageType === "block_cast_message_settings" ||
    pageType === "search" ||
    pageType === "block_setting" ||
    pageType === "create_nft" ||
    pageType === "block_analytics" ||
    pageType === "search_page" ||
    pageType === "notification" ||
    pageType === "profile_settings" ||
    pageType === "profile_about" ||
    pageType === "Subscriber" ||
    pageType === "block_badge_page" ||
    pageType === "profile_earning" ||
    pageType === "take_down_post" ||
    pageType === "block_details" ||
    pageType === "earning_history" ||
    pageType === "block_settings_page"
  ) {
    return <SocialPagesHeader title={title} />;
  } else if (pageType === "profile") {
    return <ProfileHeader title={title} />;
  } else if (pageType === "block_edit") {
    return <BlockEditHeader title={title} />;
  } else if (pageType === "block_cast_single") {
    return <BlockCastSingleChat />;
  } else if (pageType === "blockcast_main_page") {
    return <BlockCastMainHeader />;
  } else if (pageType === "block_page") {
    return <BlockPageHeader title={title} />;
  } else if (pageType === "normal_chat") {
    return <NormalChatHeader />;
  } else if (pageType === "full_post") {
    return <FullPostHeader />;
  } else if (pageType === "full_event") {
    return <FullEventHeader />;
  } else if (
    pageType === "earning_center" ||
    pageType === "earning_center_analytics"
  ) {
    return <EarnCenterHeader />;
  } else if (pageType === "badge") {
    return <BadgeHeader title={title} />;
  } else {
    return <DefaultHeader />;
  }
};

const LeftSideBar = () => {
  const pageType = useSelector((state) => state.page.pageType);
  if (
    pageType == "social" ||
    pageType === "trending" ||
    pageType === "profile" ||
    pageType === "post_analytics" ||
    pageType === "block_edit" ||
    pageType === "profile_earning"
  ) {
    return <SocialLeftSideBar />;
  } else if (pageType === "block") {
    return <BlockLeftSideBar />;
  } else if (pageType === "blockcast") {
    return <BlockcastLeftsidebar />;
  } else if (pageType === "block_page") {
    return <GroupLeftSidebar />;
  } else {
    return <SocialLeftSideBar />;
  }
};

const RightSideBar = () => {
  const pageType = useSelector((state) => state.page.pageType);
  if (pageType == "social") {
    return <SocialRightSideBar />;
  } else {
    return <DefaultRightSideBar />;
  }
};

const DownNav = () => {
  const pageType = useSelector((state) => state.page.pageType);
  if (pageType == "social") {
    return <SocialDownNav />;
  } else if (
    pageType == "profile" ||
    pageType === "block_page" ||
    pageType === "block_cast_single" ||
    pageType === "blockcast_main_page" ||
    pageType === "badge" ||
    pageType === "content_creator" ||
    pageType === "Subscriber" ||
    pageType === "full_event" ||
    pageType === "profile_about" ||
    pageType === "normal_chat" ||
    pageType === "profile_settings" ||
    pageType === "block_full_post" ||
    pageType === "notification" ||
    pageType === "trending" ||
    pageType === "search" ||
    pageType === "search_page" ||
    pageType === "menu" ||
    pageType === "profile_follwers" ||
    pageType === "block_analytics" ||
    pageType === "create_nft" ||
    pageType === "post_analytics" ||
    pageType === "profile_edit" ||
    pageType === "full_post_view" ||
    pageType === "block_settings" ||
    pageType === "profile_earning" ||
    pageType === "full_post" ||
    pageType === "take_down_post" ||
    pageType === "block_details" ||
    pageType === "earning_history" ||
    pageType === "block_settings_page" ||
    pageType === "following_followers"
  ) {
    return null;
  } else if (pageType === "block") {
    return <BlockDownNavbar />;
  } else if (pageType === "blockcast") {
    return <BlockCastDownNavbar />;
  } else if (
    pageType === "earning_center" ||
    pageType === "earning_center_analytics"
  ) {
    return <EarningCenterDownNavbar />;
  } else {
    // return <DefaultDownNav />;
    return null;
  }
};
// block

function MainLayout({ children, goBack, menu, title }) {
  const [mobilescrolled, setMobileScrolled] = React.useState(false);
  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const location = useLocation();
  const [path, setPath] = React.useState(location.pathname);

  const { i18n, t } = useTranslation(["common"]);
  // getting theme state
  const { theme, themeColor, fontSize } = React.useContext(ThemeContext);
  const dynamicStyles = {
    "--theme-color": themeColor,
    "--font-size": `${fontSize}px`,
    "--font-cap": `${parseInt(fontSize) + 4}px`,
    "--font-small": `${parseInt(fontSize) - 3}px`,
  };

  React.useEffect(() => {
    if (localStorage.getItem("i18nextLng")?.length > 2) {
      i18next.changeLanguage("en");
    }
  }, []);

  const handleLanguageChange = (event) => {
    i18n.changeLanguage(event.target.value);
  };

  const searchRef = React.useRef();
  return (
    <div className={`app ${theme}`} style={dynamicStyles}>
      <div className="left_side_header">
        {user ? <LeftSideBar /> : <LeftSideBarWithOutToken />}
      </div>
      <div className="main_container">
        <div className="app_container">
          <Header goBack={goBack} menu={menu} title={title} />
          {children}
          <DownNav />
        </div>
      </div>
    </div>
  );
}

export default MainLayout;
