/** @format */

import React from "react";
import "./Skeleton.css";

const ExternalLinkLoader = () => {
  return (
    <div className="el_page_container">
      <div className="el_page_header"></div>

      <div className="el_page_ad_bar"></div>
      <div className="el_page_tabs"></div>
      <div className="el_page_post_bloc"></div>
    </div>
  );
};

export default ExternalLinkLoader;
