/** @format */

import { BASE_URL } from "../constants";
import {
  POST,
  GET,
  PUT,
  FormPost,
  DELETE,
  POSTWITHTOKEN,
  POSTFILE,
} from "../index";

export const createBlockPost = async (data) => {
  console.log(data);
  const endpoint = `api/group/post/create/post/${data.id}`;
  const response = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchBlockPosts = async (data) => {
  const endpoint = `api/group/post/${data.id}?sortedBy=${data.sortedBy}&page=${data.page}&limit=${data.limit}`;
  console.log("ENDPOINT: ", endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchBlockSubsPosts = async (data) => {
  const endpoint = `api/group/post/subs-post/${data.id}?sortedBy=${data.sortedBy}&page=${data.page}&limit=${data.limit}`;
  console.log("ENDPOINT: ", endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const pinnedBlockPost = async (data) => {
  const endpoint = `api/group/post/pinned/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const bookmarkBlockPost = async (data) => {
  const endpoint = `api/group/post/bookmark/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const deleteBlockPost = async (data) => {
  const endpoint = `api/group/post/delete/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

// editBlockPost
export const editBlockPost = async (data) => {
  const endpoint = `api/group/post/edit/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    content: data.content,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const spamBlockPost = async (data) => {
  const endpoint = `api/group/post/spam/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    content: data.content,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const likePost = async (data) => {
  console.log("Block post API");
  const endpoint = `api/group/post/${data.value}/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchLikedUser = async (data) => {
  console.log("Block post API");
  const endpoint = `api/group/post/like/users/${data.postId}?type=${data.likeType}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

/**
 * @BLOCK_POST_COMMENT
 */
export const createBlockPostComment = async (data) => {
  console.log(data);
  const endpoint = `api/group/post/comments/${data.postId}`;
  const response = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchComments = async (data) => {
  const endpoint = `api/group/post/comments/${data.postId}?page=${data.page}&limit=${data.limit}&sortedBy=${data.sortedBy}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const editBlockComment = async (data) => {
  const endpoint = `api/group/post/comment/edit/${data.cmntId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    message: data.message,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const hideBlockComment = async (data) => {
  const endpoint = `api/group/post/comment/hide/${data.cmntId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const deleteBlockComment = async (data) => {
  const endpoint = `api/group/post/comment/delete/${data.cmntId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const spamBlockComment = async (data) => {
  console.log(data);
  const endpoint = `api/group/post/comment/spam/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const blockCommentLike = async (data) => {
  console.log(data);
  const endpoint = `api/group/post/comment/${data.value}/${data.id}/${data.ownerName}/${data.likeCount}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const blockCommentRemoveLike = async (data) => {
  const endpoint = `api/group/post/comment/like/remove/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

/**
 * @BLOCK_POST_COMMENT_REPLY
 */
export const handleBlockPostCommentReply = async (data) => {
  // console.log(data);
  const endpoint = `api/group/post/comment/reply/${data.cmntId}`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    replyText: data.replyText,
    cmntOwner: data.cmntOwner,
    repliedUser: data.repliedUser,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleBlockPostCommentReplyReply = async (data) => {
  // console.log("?????", data);
  const endpoint = `api/group/post/reply/reply/${data.cmntId}`;
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, {
    replyText: data.replyText,
    username: data.username,
    repliedUser: data.repliedUser,
  });
  console.log("??????", response);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchReplies = async (data) => {
  const endpoint = `api/group/post/comment/reply/${data.cmntId}?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchRepliesReply = async (data) => {
  const endpoint = `api/group/post/comment/replies/reply/:id${data.cmntId}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const editBlockReply = async (data) => {
  const endpoint = `api/group/post/comment/reply/edit/${data.replyId}`;
  console.log("End point:", endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, {
    text: data.text,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const spamBlockReply = async (data) => {
  const endpoint = `api/group/post/comment/reply/spam/${data.replyId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    text: data.text,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const likeBlockReply = async (data) => {
  const endpoint = `api/group/post/comment/reply/like/${data.replyId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    text: data.text,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

// dislikeBlockReply
export const dislikeBlockReply = async (data) => {
  const endpoint = `api/group/post/comment/reply/dislike/${data.replyId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    text: data.text,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const DeleteBlockReply = async (data) => {
  const endpoint = `api/group/post/comment/reply/delete/${data.replyId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, {
    text: data.text,
  });
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

/**
 * @EVENT
 */
export const createEvent = async (data) => {
  const endpoint = `api/group/post/event/create`;
  const response = await FormPost(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchBlockEvent = async (data) => {
  const endpoint = `api/group/post/events/${data.id}?page=${data.page}&limit=${data.limit}&filter=${data.filter}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const notIntersetEvent = async (data) => {
  const endpoint = `api/group/post/event/not_interest/${data.id}?value=${data.value}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const intersetEvent = async (data) => {
  const endpoint = `api/group/post/event/interest/${data.id}?value=${data.value}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const joinEvent = async (data) => {
  const endpoint = `api/group/post/event/join/${data.id}?value=${data.value}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleDeleteEvent = async (data) => {
  const endpoint = `api/group/post/event/delete/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const editEvent = async (data) => {
  const endpoint = `api/group/post/event/edit/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data.post);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};
//

export const blockEventFeed = async (data) => {
  const endpoint = `api/group/post/block/event/feed?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchFullEvent = async (data) => {
  const endpoint = `api/group/post/event/${data.id}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleFetchFullPost = async (data) => {
  console.log("CAME HERE ", data);
};

export const hideEvent = async (data) => {
  const endpoint = `api/group/post/event/hide/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data.post);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleReportEvent = async (data) => {
  const endpoint = `api/group/post/event/report/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleReportPost = async (data) => {
  const endpoint = `api/group/post/report/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    return response;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleDonatePost = async (data) => {
  const endpoint = `api/group/post/donate/${data.postId}?amount=${data.donateValue}&handleUn=${data.postUserId}&message=${data.custommessage}`;
  // console.log(">>>>", endpoint);
  const response = await POSTWITHTOKEN(BASE_URL, endpoint, data.token, data);
  console.log(response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchPostData = async (data) => {
  const endpoint = `api/group/post/analytics/${data.id}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleFetchSinglePostData = async (data) => {
  const endpoint = `api/group/post/full-post/${data.postId}?page=${data.page}&limit=${data.limit}`;
  const response = await GET(BASE_URL, endpoint, data.token);
  console.log("Response:", response);
  try {
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

export const handleUpdateLikePost = async (data) => {
  const endpoint = `api/group/post/emoji/add/like/${data.id}?type=${data.value}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
export const handleRemoveLikePost = async (data) => {
  const endpoint = `api/group/post/emoji/remove/like/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleNewsUpvote = async (data) => {
  const endpoint = `/api/group/post/upvote/${data.postId}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleNewsDownvote = async (data) => {
  const endpoint = `/api/group/post/downvote/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleNewsFake = async (data) => {
  const endpoint = `/api/group/post/fake/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleAnnouncementExcited = async (data) => {
  const endpoint = `/api/group/post/exicted/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleAnnouncementDisappointed = async (data) => {
  const endpoint = `/api/group/post/disapointed/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleAnnouncementMisleading = async (data) => {
  const endpoint = `/api/group/post/misleading/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleFlagBlockPost = async (data) => {
  const endpoint = `api/group/post/flag/${data.postId}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const fetchUpvotedUser = async (data) => {
  const endpoint = `api/group/post/upvote/${data.postId}?page=${data.listPage}&limit=${data.listLimit}`;
  console.log("** ENDPOINT: ", endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const fetchExcitedUser = async (data) => {
  const endpoint = `api/group/post/excited/${data.postId}?page=${data.listPage}&limit=${data.listLimit}`;
  console.log("** ENDPOINT: ", endpoint);
  const response = await GET(BASE_URL, endpoint, data.token);
  try {
    return response.data;
  } catch (error) {
    console.log("Error: ", error);
  }
};

export const handleSharePost = async (data) => {
  const endpoint = `/api/group/post/share/${data.post.p_id}`;
  console.log(endpoint);
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};

export const handleRepostPost = async (data) => {
  // console.log(data.post);
  const endpoint = `/api/group/post/repost/${data.id}`;
  const response = await PUT(BASE_URL, endpoint, data.token, data);
  try {
    console.log(response);
    return response;
  } catch (error) {
    console.log(error);
  }
};
